import React, {FC, ReactElement} from 'react';
import {WalletMultiButton} from '@solana/wallet-adapter-react-ui';
import {ColoredButtonInverse} from 'components/ui/button/Button';
import {ReactComponent as WalletIcon} from 'assets/icons/WalletIcon.svg';
import {BRADIUS, COLORS, SHADOW, SPACING} from 'constants/theme';
import {ReactComponent as InfoIcon} from 'assets/icons/InfoIcon.svg';
import {EStoreItemStatus} from 'store/yardsale';
import './CTAButton.scss';
import {Wallet} from '@project-serum/anchor';

interface Props {
  isOwnedByUser: boolean;
  wallet: Wallet;
  handleBuyPress: () => void;
  initialLoad: boolean;
  wasPurchased: boolean;
  isPurchasing: boolean;
  status: EStoreItemStatus;
  soldAt?: number;
}

export const CTAButton: FC<any> = ({
  isOwnedByUser,
  wallet,
  handleBuyPress,
  initialLoad,
  status,
  soldAt,
  isPurchasing,
  wasPurchased,
}: Props): ReactElement => {
  const formatTime = React.useMemo(() => {
    if (!!soldAt) {
      const date = new Date(soldAt);
      return date.toLocaleString();
    }
  }, [soldAt]);

  if (initialLoad) return <></>;

  if (wasPurchased)
    return (
      <div className="cta-button notInShopCon">
        <img src={require('../../assets/pngs/Stache_Found.png')} className="cta-image" />
        <div className="soldRightCon">
          <p style={{color: COLORS.SUCCESS_GREEN}}>You bought this</p>
          <p style={{color: COLORS.SUCCESS_GREEN, fontSize: 10}}>{formatTime}</p>
        </div>
      </div>
    );

  if (status !== EStoreItemStatus.ACTIVE)
    return (
      <div className="cta-button notInShopCon">
        <div className="soldLeftCon">
          <InfoIcon width={25} height={25} stroke={COLORS.TEXT_RED} />
        </div>
        <div className="soldRightCon">
          {status === EStoreItemStatus.SOLD ? (
            <>
              <p style={{color: COLORS.TEXT_RED}}>Sold out</p>
              <p style={{color: COLORS.TEXT_RED, fontSize: 10}}>{formatTime}</p>
            </>
          ) : (
            <p style={{color: COLORS.TEXT_RED}}>Item has been deslisted</p>
          )}
        </div>
      </div>
    );

  return (
    <div className="cta-button">
      {!isOwnedByUser &&
        (!!wallet?.publicKey ? (
          <ColoredButtonInverse disabled={initialLoad} onClick={handleBuyPress} style={{width: '100%'}} icon>
            {isPurchasing ? 'Buying...' : 'Buy Now'}
          </ColoredButtonInverse>
        ) : (
          <div className="cta-connect-button">
            <WalletMultiButton style={{width: '100%'}}>
              <WalletIcon />
              <p className="normal">Connect Wallet</p>
            </WalletMultiButton>
          </div>
        ))}
    </div>
  );
};

export default CTAButton;
