import {PublicKey} from '@solana/web3.js';
import {IWallet} from 'store/connectedWallets';

export interface IKey {
  walletAddress: PublicKey,
  accountAddress: PublicKey,
  label?: string,
  contents?: IWallet
}

export interface IKeychain {
  // string type for account address is only for test purpose
  accountAddress: PublicKey | null
  // name type not stated
  name: any
  keys: IKey[]
  // whether the connected wallet is linked or not (verified)
  walletLinked: boolean,
  pfpUrl?: string,
  pda: string
}

export enum KeyChainActionType {
  AddKey,
  RemoveKey,
}

export interface IPendingKeyChainAction {
  action_type: KeyChainActionType,
  key: PublicKey,
  verified: boolean,
  votes: {
    data: number
  }
}

export interface IKeychainState {
  keychain_version: number,
  pending_action?: IPendingKeyChainAction,
  action_threshold?: number
}
