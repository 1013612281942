import {DOMAINPDA, findKeychainKeyPda, findKeychainPda, findKeychainStatePda} from '../../programs/keychain-utils';
import {
  AccountMeta,
  ComputeBudgetProgram,
  Connection,
  LAMPORTS_PER_SOL,
  PublicKey,
  SystemProgram,
  SYSVAR_INSTRUCTIONS_PUBKEY,
  Transaction,
  TransactionInstruction,
} from '@solana/web3.js';
import {PROGRAM_ID as TMETA_PROG_ID} from '@metaplex-foundation/mpl-token-metadata';
import {PROGRAM_ID as AUTH_PROG_ID} from '@metaplex-foundation/mpl-token-auth-rules';
import * as anchor from '@project-serum/anchor';
import {AnchorProvider, BN, Program} from '@project-serum/anchor';
import {
  getBazaarProgram,
  getKeychainProgram,
  getSquadsProgram,
  getStacheProgram,
  getYardsaleProgram,
} from '../../programs/program-utils';
import {findStachePda} from '../../programs/stache-utils';
import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  createAssociatedTokenAccountInstruction,
  createCloseAccountInstruction,
  createTransferCheckedInstruction,
  getAssociatedTokenAddressSync,
  NATIVE_MINT,
  TOKEN_PROGRAM_ID,
} from '@solana/spl-token';
import {
  AccountResponse,
  DelistBazaarPayload,
  ListingPayload,
  MoneyAccounts,
  PurchasingBazaarPayload,
  PurchasingYardsalePayload,
  SActionType,
  SAuto,
  SBalanceCondition,
  SDomain,
  SKeychain,
  SStache,
  StandardListingsPayload,
  STransactionHolder,
  STriggerType,
  SVault,
  SVaultParams,
  SVaultType,
  YardsaleDelistingPayload
} from './types';

import {getMsPDA} from '../../programs/squads-utils';
import {printObject} from '../../utils/debug';
import {prepPnftAccounts, printAccounts} from './util';
import {FriendlyError} from '../../utils/errors';
import {STokenAccount} from '../apiTypes';
import {Metaplex, ReadApiAsset, token, WalletAdapter, walletAdapterIdentity} from '@metaplex-foundation/js';
import {TokenStandard} from '@metaplex-foundation/mpl-token-metadata/dist/src/generated';
import {
  ConcurrentMerkleTreeAccount,
  SPL_ACCOUNT_COMPRESSION_PROGRAM_ID,
  SPL_NOOP_PROGRAM_ID,
} from '@solana/spl-account-compression';
import {PROGRAM_ID as BUBBLEGUM_PROGRAM_ID} from '@metaplex-foundation/mpl-bubblegum';
import {HeliusConnectionWrapper} from 'apis/heliusRpc/HeliusConnectionWrapper';
import {findBazaarListingPda, findSellerAccountPda} from 'programs/bazaar-utils';
import {EAssetSelectionType} from 'store/yardsale';
import {ensureIsPublicKey} from 'utils/string-formatting';
import Bottleneck from "bottleneck";

export class SolanaClient {
  private keychainProg: Program;
  private stacheProg: Program;
  private squadsProg: Program;
  private bazaarProg: Program;
  // after login, these get set (like having a jwt/session)
  public initialized: boolean = false; // indicates we've "connected" to our stache
  private walletPubKey: PublicKey;
  private yardsaleProg: Program;
  private stachePda: PublicKey;
  private keychainPda: PublicKey;
  private keychainStatePda: PublicKey;

  private connection: Connection;
  private throttler: Bottleneck;
  private heliusConnection: HeliusConnectionWrapper;
  private programsInit: boolean = false;

  private clockworkProgramId: PublicKey;
  private stacheEnabled: boolean;
  private keychainDomain: string;
  private keychainTreasury: PublicKey;

  constructor(
    connection: Connection,
    throttler: Bottleneck,
    keychainDomain: string,
    keychainTreasury: PublicKey,
    clockworkProgId: PublicKey,
    stacheEnabled: boolean,
    heliusEnabled: boolean
  ) {
    this.connection = connection;
    this.throttler = throttler;
    this.heliusConnection = heliusEnabled ? (connection as HeliusConnectionWrapper) : null;
    this.clockworkProgramId = clockworkProgId;
    this.stacheEnabled = stacheEnabled;
    this.keychainDomain = keychainDomain;
    this.keychainTreasury = keychainTreasury;
  }

  async initPrograms(anchorProvider: AnchorProvider) {
    if (!this.programsInit) {
      console.log('constructing solana client...');
      this.walletPubKey = anchorProvider.wallet.publicKey;
      this.keychainProg = getKeychainProgram(anchorProvider);
      this.stacheProg = getStacheProgram(anchorProvider);
      this.squadsProg = getSquadsProgram(anchorProvider);
      this.yardsaleProg = getYardsaleProgram(anchorProvider);
      this.bazaarProg = getBazaarProgram(anchorProvider);
      this.programsInit = true;
    } else {
      console.log(`solana client already constructed`);
    }
  }

  initKeychain(keychainPda: PublicKey, stachePda?: PublicKey) {
    if (!!stachePda) this.stachePda = stachePda;
    this.keychainPda = keychainPda;
    [this.keychainStatePda] = findKeychainStatePda(keychainPda);
    this.initialized = true;
  }

  reset() {
    console.log('resetting solanaclient...');
    this.programsInit = false;
    this.initialized = false;
    this.keychainProg = undefined;
    this.walletPubKey = undefined;
    this.stacheProg = undefined;
    this.yardsaleProg = undefined;
    // this.provider = undefined;
    this.stachePda = undefined;
    this.keychainPda = undefined;
    this.keychainStatePda = undefined;
  }

  async createCloseTokenAccountInstruction(account: PublicKey): Promise<TransactionInstruction> {
    return createCloseAccountInstruction(account, this.walletPubKey, this.walletPubKey);
  }

  // pull the keychain account for a given wallet address (if it exists)
  async getKeychainByKeyWallet(walletAddress: PublicKey): Promise<AccountResponse<SKeychain | null>> {
    const [keychainKeyPda] = findKeychainKeyPda(walletAddress);

    // first: see if wallet is connected to a keychain
    let keychainKeyAcct = await this.keychainProg.account.keyChainKey.fetchNullable(keychainKeyPda, 'confirmed');
    if (keychainKeyAcct) {
      // @ts-ignore
      console.log(
        `found keychain key account for wallet: ${walletAddress.toBase58()}: ${keychainKeyPda}, checking keychain account: ${
          keychainKeyAcct.keychain
        }`
      );

      // then the keychain should exist
      const account = await this.fetchKeychainByPda(keychainKeyAcct.keychain as PublicKey);
      if (account) {
        return {
          pda: keychainKeyAcct.keychain as PublicKey,
          account,
        };
      } else {
        console.log(`couldn't find keychain account for keychain key: ${keychainKeyPda.toBase58()}`);
      }
    } else {
      // shouldn't happen
      console.log(`couldn't find keychain key account for keychain key: ${keychainKeyPda.toBase58()}`);
      // todo: bugsnag
    }
    return null;
  }

  async fetchDomainByPda(domainPda: PublicKey): Promise<AccountResponse<SDomain>> {
    const resp = {
      pda: domainPda,
    };
    const domainAcct = await this.keychainProg.account.currentDomain.fetchNullable(domainPda);
    if (domainAcct) {
      resp['account'] = {
        name: domainAcct.name,
      };
    }
    return resp;
  }

  async getKeychainByPda(keychainPda: PublicKey): Promise<AccountResponse<SKeychain>> {
    const resp = {
      pda: keychainPda,
    };
    resp['account'] = await this.fetchKeychainByPda(keychainPda);
    return resp;
  }

  async fetchKeychainByName(name: string): Promise<SKeychain> {
    const [keychainPda] = findKeychainPda(name);
    return await this.fetchKeychainByPda(keychainPda);
  }

  async fetchKeychainByPda(keychainPda: PublicKey): Promise<SKeychain> {
    const keychainAcct = await this.keychainProg.account.currentKeyChain.fetchNullable(keychainPda, 'confirmed');
    let keychain = null;
    if (keychainAcct) {
      keychain = {
        name: keychainAcct.name,
        domain: keychainAcct.domain,
        numKeys: keychainAcct.numKeys,
        bump: keychainAcct.bump,
        version: keychainAcct.version,
        keys: [],
      };
      // @ts-ignore
      for (let key of keychainAcct.keys) {
        keychain.keys.push({key: key.key});
      }
    }
    return keychain;
  }

  // pull a stache account, given the stacheid (keychain name)
  async getStacheById(stacheid: string): Promise<AccountResponse<SStache>> {
    const [stachePda] = findStachePda(stacheid, this.keychainDomain, this.stacheProg.programId);
    return await this.fetchStacheByPda(stachePda);
  }

  async fetchStacheByPda(stachePda: PublicKey): Promise<AccountResponse<SStache>> {
    const stache = await this.stacheProg.account.currentStache.fetchNullable(stachePda, 'confirmed');
    // console.log(`fetched state for stache: ${JSON.stringify(stache, null, 3)}`);
    const resp = {
      pda: stachePda,
    };
    if (stache) {
      // @ts-ignore
      const vaults = new Uint8Array(stache.vaults);
      const vaultIndexes = [];
      vaults.forEach((vaultNum) => {
        vaultIndexes.push(vaultNum);
      });
      //@ts-ignore
      const autos = new Uint8Array(stache.autos);
      const autoIndexes = [];
      autos.forEach((autoNum) => {
        autoIndexes.push(autoNum);
      });
      resp['account'] = {
        stacheid: stache.stacheid,
        keychainPda: stache.keychain,
        domain: stache.domain,
        keychain: null,
        version: stache.version,
        bump: stache.bump,
        nextVaultIndex: stache.nextVaultIndex,
        nextAutoIndex: stache.nextAutoIndex,
        vaults: vaultIndexes,
        autos: autoIndexes,
      };
      console.log(`stache account for stache: ${stachePda.toBase58()}: ${JSON.stringify(resp, null, 3)}`);
      return resp;
    } else {
      console.log(`couldn't find stache account for stache: ${stachePda.toBase58()}`);
      return null;
    }
  }

  async getCreateStacheTx(stacheid: string, walletAddress: PublicKey): Promise<STransactionHolder> {
    const [keychainPda] = findKeychainPda(stacheid);
    const [keychainStatePda] = findKeychainStatePda(keychainPda);
    const [keychainKeyPda] = findKeychainKeyPda(walletAddress);
    const [stachePda, _] = findStachePda(stacheid, this.keychainDomain, this.stacheProg.programId);

    console.log(`keychain program id: ${this.keychainProg.programId.toBase58()}`);

    const tx = new Transaction();
    // add the create keychain ix
    tx.add(
      await this.keychainProg.methods
        .createKeychain(stacheid)
        .accounts({
          keychain: keychainPda,
          keychainState: keychainStatePda,
          keychainKey: keychainKeyPda,
          domain: DOMAINPDA,
          wallet: walletAddress,
          authority: this.walletPubKey,
          systemProgram: SystemProgram.programId,
        })
        .instruction()
    );

    // add the create stache ix
    if (this.stacheEnabled) {
      tx.add(
        await this.stacheProg.methods
          .createStache()
          .accounts({
            stache: stachePda,
            keychain: keychainPda,
            authority: this.walletPubKey,
            keychainProgram: this.keychainProg.programId,
            systemProgram: SystemProgram.programId,
          })
          .instruction()
      );
    }

    // add the seller account creation
    tx.add(await this.getCreateSellerForKeychainIx(keychainPda));

    // console.log("Data we got: ", keychainPda, keychainStatePda, keychainKeyPda, DOMAINPDA, walletAddress, this.walletPubKey, stachePda, this.keychainProg.programId);

    return {
      tx,
      pda: stachePda,
    };
  }

  async getWithdrawFromSessionVaultTx(
    withdrawAmount: number,
    systemStachePda: PublicKey,
    vaultPda: PublicKey,
    vaultAta: PublicKey,
    sessionPda: PublicKey,
    mint: PublicKey,
    toWallet: PublicKey
  ) {
    const toToken = getAssociatedTokenAddressSync(mint, toWallet, true);

    console.log(`withdrawAmount: ${withdrawAmount}`);
    console.log(`toToken: ${toToken.toBase58()}`);
    console.log(`systemStachePda: ${systemStachePda.toBase58()}`);
    console.log(`vaultAta: ${vaultAta.toBase58()}`);
    console.log(`vaultPda: ${vaultPda.toBase58()}`);
    console.log(`mint: ${mint.toBase58()}`);
    console.log(`sessionPda: ${sessionPda.toBase58()}`);
    console.log(`toWallet: ${toWallet.toBase58()}`);

    const withdrawAmountBN = new anchor.BN(withdrawAmount);
    console.log(`withdrawAmountBN: ${withdrawAmountBN.toString()}`);

    // first, check if the account exists or not
    const ataInfo = await this.connection.getAccountInfo(toToken);
    const tx = new Transaction();
    if (!ataInfo) {
      // then we need to create the stache ata
      tx.add(createAssociatedTokenAccountInstruction(this.walletPubKey, toToken, toWallet, mint));
    }

    tx.add(
      await this.stacheProg.methods
        .withdrawFromSessionVault(withdrawAmountBN)
        .accounts({
          stache: systemStachePda,
          vault: vaultPda,
          session: sessionPda,
          vaultAta: vaultAta,
          toToken,
          mint,
          authority: this.walletPubKey,
          tokenProgram: TOKEN_PROGRAM_ID,
          associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
          clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
        })
        .instruction()
    );

    return tx;
  }

  async airdrop(publicKey: PublicKey, amountInSol: number) {
    const txid = await this.connection.requestAirdrop(publicKey, amountInSol * LAMPORTS_PER_SOL);
    await this.connection.confirmTransaction(txid);
  }

  checkInit() {
    if (!this.initialized) {
      throw new Error('StacheClient not initialized');
    }
  }

  async transferPnft(walletAdapter: WalletAdapter, mint: PublicKey, fromAddress: PublicKey, toAddress: PublicKey) {
    console.log('transferPnft: ', mint);
    const metaplex = Metaplex.make(this.connection).use(walletAdapterIdentity(walletAdapter));

    const txBuilder = metaplex
      .nfts()
      .builders()
      .transfer({
        nftOrSft: {address: mint, tokenStandard: TokenStandard.ProgrammableNonFungible},
        // authority: AUTHORITY,
        fromOwner: this.walletPubKey,
        toOwner: toAddress,
        amount: token(1),
      });

    const blockhash = await this.connection.getLatestBlockhash();
    return txBuilder.toTransaction(blockhash);
  }

  async transferStandard(
    mint: PublicKey,
    fromTokenAccount: PublicKey,
    toAddress: PublicKey,
    amount: number,
    decimals: number
  ): Promise<Transaction> {
    const toTokenAccount = getAssociatedTokenAddressSync(mint, toAddress, true);

    // console.log("Accounts: ",         fromTokenAccount,
    //     mint,
    //     toTokenAccount,
    //     this.walletPubKey,
    //     amount,
    //     decimals)

    const tx = new Transaction();

    // first check if the toTokenAccount exists or not
    const ataInfo = await this.connection.getAccountInfo(toTokenAccount);
    const fromTokenBalance = await this.connection.getTokenAccountBalance(fromTokenAccount);

    if (!ataInfo) {
      tx.add(createAssociatedTokenAccountInstruction(this.walletPubKey, toTokenAccount, toAddress, mint));
    }
    tx.add(
      createTransferCheckedInstruction(
        fromTokenAccount, // from (should be a token account)
        mint, // mint
        toTokenAccount, // to (should be a token account)
        this.walletPubKey, // from's owner
        amount, // amount, if your deciamls is 8, send 10^8 for 1 token
        decimals // decimals
      )
    );

    // add a close token account instruction if the fromTokenAccount will be empty
    if (fromTokenBalance.value.uiAmount == amount) {
      tx.add(await this.createCloseTokenAccountInstruction(fromTokenAccount));
    }

    return tx;
  }

  // send a token(s) to stache's ata
  async getStashTokensTx(
    fromTokenAccount: PublicKey,
    mint: PublicKey,
    amountDecimal: number,
    numDecimals: number
  ): Promise<Transaction> {
    this.checkInit();

    const stacheMintAta = getAssociatedTokenAddressSync(mint, this.stachePda, true);

    // first, check if the account exists or not
    const ataInfo = await this.connection.getAccountInfo(stacheMintAta);
    const tx = new Transaction();
    if (!ataInfo) {
      // then we need to create the stache ata
      tx.add(createAssociatedTokenAccountInstruction(this.walletPubKey, stacheMintAta, this.stachePda, mint));
    }

    const stashAmount = new anchor.BN(amountDecimal * 10 ** numDecimals);
    console.log(
      `stashing ${amountDecimal} tokens, BN: ${stashAmount.toString()} from ${fromTokenAccount.toString()} to ${stacheMintAta.toString()}`
    );

    // now the stash instruction
    tx.add(
      await this.stacheProg.methods
        .stash(stashAmount)
        .accounts({
          stache: this.stachePda,
          keychain: this.keychainPda,
          stacheAta: stacheMintAta,
          mint: mint,
          owner: this.walletPubKey,
          fromToken: fromTokenAccount,
          systemProgram: SystemProgram.programId,
          tokenProgram: TOKEN_PROGRAM_ID,
          associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
        })
        .instruction()
    );

    return tx;
  }

  async getStashSolIx(amountDecimal: number): Promise<TransactionInstruction> {
    this.checkInit();
    const stashAmount = new anchor.BN(amountDecimal * LAMPORTS_PER_SOL);
    console.log('stashAmount: ', stashAmount.toString());

    return SystemProgram.transfer({
      fromPubkey: this.walletPubKey,
      toPubkey: this.stachePda,
      lamports: stashAmount.toNumber(),
    });
  }

  async getUnstashSolIx(amountDecimal: number): Promise<TransactionInstruction> {
    this.checkInit();
    const unstashAmount = new anchor.BN(amountDecimal * LAMPORTS_PER_SOL);
    return await this.stacheProg.methods
      .unstashSol(unstashAmount)
      .accounts({
        stache: this.stachePda,
        keychain: this.keychainPda,
        owner: this.walletPubKey,
        rent: anchor.web3.SYSVAR_RENT_PUBKEY,
      })
      .instruction();
  }

  // withdraw token(s) from a stache ata to user's ata
  async getUnstashTokenTx(
    fromStacheAta: PublicKey,
    mint: PublicKey,
    amountDecimal: number,
    numDecimals: number
  ): Promise<Transaction> {
    this.checkInit();

    // we'll allow off-curve since we might be unstashing to a vault or something
    const userAta = getAssociatedTokenAddressSync(mint, this.walletPubKey, true);

    const ataInfo = await this.connection.getAccountInfo(userAta);
    const tx = new Transaction();
    if (!ataInfo) {
      // then we need to create the stache ata for the user
      tx.add(createAssociatedTokenAccountInstruction(this.walletPubKey, userAta, this.walletPubKey, mint));
    }

    const unstashAmount = new anchor.BN(amountDecimal * 10 ** numDecimals);
    tx.add(
      await this.stacheProg.methods
        .unstash(unstashAmount)
        .accounts({
          stache: this.stachePda,
          keychain: this.keychainPda,
          stacheAta: fromStacheAta,
          mint: mint,
          owner: this.walletPubKey,
          toToken: userAta,
          tokenProgram: TOKEN_PROGRAM_ID,
          associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
        })
        .instruction()
    );

    return tx;
  }

  async isBalanceAboveZero(wallet: PublicKey): Promise<boolean> {
    return (await this.connection.getBalance(wallet)) > 0;
  }

  //////// Not working correctly. Estimation always returns the base network fee of 5000 lamports
  // async estimateTxSuccess(tx: Transaction, userWallet: PublicKey): Promise<boolean>{
  //   const bal = await connection.getBalance(userWallet);
  //   console.log("Got bal", bal);
  //   console.log("have connection: ", connection);
  //   const blockhash = await connection.getLatestBlockhash();
  //   tx.recentBlockhash = blockhash.blockhash;
  //   tx.feePayer = userWallet;

  //   const est = await connection.getFeeForMessage(tx.compileMessage());

  //   console.log("BAL: ", bal, "EST: ", est);
  //   return false;
  // }

  async getAddKeyTx(key: PublicKey): Promise<Transaction> {
    return await this.keychainProg.methods
      .addKey(key)
      .accounts({
        keychain: this.keychainPda,
        keychainState: this.keychainStatePda,
        // domain: DOMAINPDA,
        authority: this.walletPubKey,
      })
      .transaction();
  }

  async getVerifyKeyTx(key: PublicKey, stacheid: string): Promise<Transaction> {
    const [keyPda] = findKeychainKeyPda(key);
    const [keychainPda] = findKeychainPda(stacheid);
    const [keychainStatePda] = findKeychainStatePda(keychainPda);

    const accounts = {
      keychain: keychainPda,
      domain: DOMAINPDA,
      treasury: this.keychainTreasury,
      authority: this.walletPubKey,
      keychainState: keychainStatePda,
      keychainKey: keyPda,
      systemProgram: SystemProgram.programId,
    };
    console.log(`verification accounts: ${JSON.stringify(accounts)}`);

    return await this.keychainProg.methods.verifyKey().accounts(accounts).transaction();
  }

  async getRemoveKeyTx(keyWallet: PublicKey, keychainKeyPda: PublicKey): Promise<Transaction> {
    const ix1 = await this.keychainProg.methods
      .removeKey(keyWallet)
      .accounts({
        keychain: this.keychainPda,
        keychainState: this.keychainStatePda,
        keychainKey: keychainKeyPda,
        authority: this.walletPubKey,
      })
      .instruction();

    // const ix2 = await this.createCloseTokenAccountInstruction(keychainKeyPda);
    return new Transaction().add(ix1);
  }

  async getVotePendingActionTx(
    keychainKeyPda: PublicKey,
    isApproving: boolean,
    isRemoving: boolean
  ): Promise<Transaction> {
    const accounts = {
      keychain: this.keychainPda,
      keychainState: this.keychainStatePda,
      keychainKey: isRemoving ? keychainKeyPda : null,
      authority: this.walletPubKey,
    };
    console.log('ALl accounts: ', JSON.stringify(accounts), 'is approving: ', isApproving);
    return await this.keychainProg.methods.votePendingAction(isApproving).accounts(accounts).transaction();
  }

  async getSolTokenAccount(walletAddress: PublicKey): Promise<STokenAccount> {
    const solAmount = await this.connection.getBalance(walletAddress);
    return {
      address: walletAddress,
      // mint: null,     // native SOL
      mint: NATIVE_MINT, // technically this is wSOL, but we'll use this as the mint
      decimals: 9,
      amount: solAmount,
      amountUi: solAmount / LAMPORTS_PER_SOL,
      amountUiString: (solAmount / LAMPORTS_PER_SOL).toString(),
      lamports: solAmount,
    };
  }

  // ix for creating an automation
  async getCreateAutoIx(name: string, autoPda): Promise<TransactionInstruction> {
    const ix = await this.stacheProg.methods
      .createAuto(name)
      .accounts({
        stache: this.stachePda,
        keychain: this.keychainPda,
        auto: autoPda,
        authority: this.walletPubKey,
        systemProgram: SystemProgram.programId,
      })
      .instruction();
    return ix;
  }

  async getSetAutoBalanceTriggerIx(
    autoPda: PublicKey,
    balanceTriggerTokenAccount: PublicKey,
    triggerBalance: BN,
    balanceCondition: SBalanceCondition
  ): Promise<TransactionInstruction> {
    const ix = await this.stacheProg.methods
      .setAutoBalanceTrigger(triggerBalance, balanceCondition)
      .accounts({
        stache: this.stachePda,
        keychain: this.keychainPda,
        auto: autoPda,
        authority: this.walletPubKey,
        token: balanceTriggerTokenAccount,
      })
      .instruction();
    return ix;
  }

  async getSetAutoTransferActionIx(
    autoPda: PublicKey,
    fromTokenAccount: PublicKey,
    toTokenAccount: PublicKey,
    mint: PublicKey,
    transferAmount: BN
  ): Promise<TransactionInstruction> {
    const ix = await this.stacheProg.methods
      .setAutoAction(transferAmount)
      .accounts({
        stache: this.stachePda,
        keychain: this.keychainPda,
        auto: autoPda,
        authority: this.walletPubKey,
        fromToken: fromTokenAccount,
        toToken: toTokenAccount,
        mint: mint,
        associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
      })
      .instruction();
    return ix;
  }

  async getDestroyAutoIx(autoPda: PublicKey): Promise<TransactionInstruction> {
    const ix = await this.stacheProg.methods
      .destroyAuto()
      .accounts({
        stache: this.stachePda,
        keychain: this.keychainPda,
        auto: autoPda,
        authority: this.walletPubKey,
        // @ts-ignore
        thread: threadPda,
        clockworkProgram: this.clockworkProgramId,
        systemProgram: SystemProgram.programId,
      })
      .instruction();
    return ix;
  }

  // ix to activate an automation
  async getActivateAutoIx(autoPda: PublicKey, threadPda: PublicKey): Promise<TransactionInstruction> {
    const ix = await this.stacheProg.methods
      .activateAuto()
      .accounts({
        stache: this.stachePda,
        keychain: this.keychainPda,
        auto: autoPda,
        authority: this.walletPubKey,
        // since automation = true, these are needed (otherwise optional if manual triggering)
        thread: threadPda,
        clockworkProgram: this.clockworkProgramId,
        systemProgram: SystemProgram.programId,
      })
      .instruction();
    return ix;
  }

  async getAuto(autoPda: PublicKey): Promise<SAuto> {
    const autoAcct = await this.stacheProg.account.auto.fetch(autoPda, 'confirmed');
    const auto = {
      name: autoAcct.name,
      stache: autoAcct.stache,
      index: autoAcct.index,
      bump: autoAcct.bump,
      active: autoAcct.active,
      paused: autoAcct.paused,
      numTriggers: autoAcct.numTriggers,
      numExecutions: autoAcct.numExecs,
      thread: autoAcct.thread,
      // set below
      actionType: null,
      triggerType: null,
    };

    // possible for action & trigger to not be set
    const actionType = autoAcct.actionType;
    // @ts-ignore
    if (actionType && 'transfer' in actionType) {
      auto.actionType = SActionType.TRANSFER;
    }
    const triggerType = autoAcct.triggerType;
    // @ts-ignore
    if (triggerType && 'balance' in triggerType) {
      auto.triggerType = STriggerType.BALANCE;
    }

    // todo: borsh deserialization of action/trigger

    // @ts-ignore
    return auto;
  }

  async getVault(vaultPda: PublicKey): Promise<SVault> {
    const vaultAcct = await this.stacheProg.account.vault.fetch(vaultPda, 'confirmed');
    const vault = {
      name: vaultAcct.name,
      stache: vaultAcct.stache,
      index: vaultAcct.index,
      bump: vaultAcct.bump,
      locked: vaultAcct.locked,
      nextActionIndex: vaultAcct.nextActionIndex,
      // vaultType filled below
      vaultType: null,
      //todo: fill in after grizzly
      actions: [],
    };

    const vaultType = vaultAcct.vaultType;
    // @ts-ignore
    if ('squads' in vaultType) {
      vault.vaultType = SVaultType.MULTISIG;
      // @ts-ignore
      vault['multisig'] = vaultType.squads.multisig;
      // @ts-ignore
      vault['sigs'] = vaultType.squads.sigs;
      // @ts-ignore
    } else if ('easy' in vaultType) {
      vault.vaultType = SVaultType.EASY;
      // @ts-ignore
    } else if ('twoSig' in vaultType) {
      vault.vaultType = SVaultType.TWOSIG;
    } else {
      // shoudln't happen
      console.log(`dont know vault type:`, JSON.stringify(vaultType, null, 2));
    }

    // @ts-ignore
    return vault;
  }

  // async getCreateSquadsMultisigIx(name: string, threshold: number, multisig: PublicKey, members: PublicKey[]): Promise<Instruction> {
  //   return await this.squadsProg.methods.create(threshold, multisig, members, name).accounts({
  //     multisig: multisig,
  //     creator: this.walletPubKey
  //   }).instruction();
  // }

  async getDestroyVaultIx(vaultPda: PublicKey): Promise<TransactionInstruction> {
    this.checkInit();
    let ix = await this.stacheProg.methods
      .destroyVault()
      .accounts({
        stache: this.stachePda,
        keychain: this.keychainPda,
        vault: vaultPda,
        authority: this.walletPubKey,
      })
      .instruction();
    return ix;
  }

  async getCreateVaultTx(
    name: string,
    vaultType: SVaultType,
    vaultPda: PublicKey,
    params?: SVaultParams
  ): Promise<Transaction> {
    this.checkInit();

    const tx = new Transaction();
    let vaultTypeParam = null;
    switch (vaultType) {
      case SVaultType.MULTISIG:
        if (!params?.members || !params?.threshold) {
          throw new Error('missing params for multisig vault');
        }
        const [multisig] = getMsPDA(vaultPda, this.squadsProg.programId);
        vaultTypeParam = {squads: {multisig, sigs: params.threshold}};

        console.log(`squadsProg: ${this.squadsProg}`);

        // add the ix to create the squads vault first
        tx.add(
          await this.squadsProg.methods
            .create(params.threshold, vaultPda, params.members, name)
            .accounts({
              multisig,
              creator: this.walletPubKey,
              systemProgram: SystemProgram.programId,
            })
            .instruction()
        );
        break;
      case SVaultType.EASY:
        vaultTypeParam = {easy: {}};
        break;
      case SVaultType.TWOSIG:
        vaultTypeParam = {twoSig: {}};
        break;
    }

    console.log(`vaultTypeParam: ${JSON.stringify(vaultTypeParam, null, 3)}`);

    // now add the ix to create the stache vault
    tx.add(
      await this.stacheProg.methods
        .createVault(name, vaultTypeParam)
        .accounts({
          stache: this.stachePda,
          keychain: this.keychainPda,
          vault: vaultPda,
          authority: this.walletPubKey,
          systemProgram: SystemProgram.programId,
        })
        .instruction()
    );

    return tx;
  }

  ////////// -------- YARDSALE --------- ///////////

  async getCreateSellerForKeychainIx(sellerKeychainPda: PublicKey): Promise<TransactionInstruction> {
    let [sellerAccountPda] = findSellerAccountPda(sellerKeychainPda);
    let ix: TransactionInstruction = await this.bazaarProg.methods
      .createSeller()
      .accounts({
        keychain: sellerKeychainPda,
        sellerAccount: sellerAccountPda,
        seller: this.walletPubKey,
        systemProgram: SystemProgram.programId,
      })
      .instruction();
    return ix;
  }

  async getBazaarListingPdaForCreateListing(sellerAccountPda: PublicKey): Promise<PublicKey> {
    let sellerAccount = await this.bazaarProg.account.sellerAccount.fetch(sellerAccountPda);
    let sellerAccountListingIndex: number = sellerAccount.listingIndex as unknown as number;
    let [bazaarListingPda] = findBazaarListingPda(sellerAccountPda, ++sellerAccountListingIndex);
    return bazaarListingPda;
  }

  async createBazaarListingInstructions({
    nftMints,
    fromTokenAccounts,
    keychainPda,
    sellerAccountPda,
    listingDomainPda,
    listingPda,
    price,
    decimals,
    itemQuantities,
    assetListingType,
    currencyAccount,
    proceedsAccount,
    proceedsTokenAccount,
  }: StandardListingsPayload): Promise<TransactionInstruction[]> {
    console.log("SOl 1")
    const multiplier = decimals === 1 ? 1 : 10 ** decimals;
    const finalPrice = new BN(price * multiplier);

    let itemCount = 0;
    const listingTokenAccounts: (PublicKey | null)[] = nftMints.map((mint: PublicKey) => {
      if (!!mint) {
        itemCount += 1;
        return getAssociatedTokenAddressSync(ensureIsPublicKey(mint), listingPda, true);
      } else {
         return null;
      }
    });

    const createBazaarListingAccounts = {
      listingDomain: listingDomainPda,
      seller: this.walletPubKey,
      sellerAccount: sellerAccountPda,
      keychain: keychainPda,
      listing: listingPda,
      currency: currencyAccount,
      proceedsToken: proceedsTokenAccount,
      proceeds: proceedsAccount,
      item0: nftMints[0],
      item0SellerToken: fromTokenAccounts[0],
      item0ListingToken: listingTokenAccounts[0],
      item1: nftMints[1],
      item1SellerToken: fromTokenAccounts[1],
      item1ListingToken: listingTokenAccounts[1],
      item2: nftMints[2],
      item2SellerToken: fromTokenAccounts[2],
      item2ListingToken: listingTokenAccounts[2],
      item3: nftMints[3],
      item3SellerToken: fromTokenAccounts[3],
      item3ListingToken: listingTokenAccounts[3],
      item4: nftMints[4],
      item4SellerToken: fromTokenAccounts[4],
      item4ListingToken: listingTokenAccounts[4],
      associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
      tokenProgram: TOKEN_PROGRAM_ID,
      systemProgram: SystemProgram.programId,
    };

    printAccounts(createBazaarListingAccounts);

    const listingType = assetListingType === EAssetSelectionType.UNIT ? {unit: {}} : {bag: {}};

    const ixs: TransactionInstruction[] = [];

    // need to increase tx size if there's 4+ items
    // ran out of instructions @ 200k, so need to increase the cap
    if (itemCount >= 4) {
      ixs.push(this.getIncreaseTxSizeIx(300_000));
    }

    const ix1 = await this.bazaarProg.methods
      .createListing({price: finalPrice, listingType, itemQuantities})
      .accounts(createBazaarListingAccounts)
      .instruction();

    ixs.push(ix1);

    // close associated token accounts, unless its an sft with quantity > 1
    fromTokenAccounts.forEach(async (fromAccount) => {
      if (fromAccount) {
        const buyerTokenBalance = await this.getTokenAccountBalance(fromAccount);
        if (buyerTokenBalance === 1) {
          ixs.push(await this.createCloseTokenAccountInstruction(fromAccount));
        }
      }
    });

    return ixs;
  }

  async createYardsaleListingInstructions({
                                    price,
                                    decimals,
                                    currencyAccount,
                                    nftMint,
                                    proceedsAccount,
                                    proceedsTokenAccount,
                                    fromItemToken,
                                    listingPda,
                                    listingItemToken
                                  }: ListingPayload) {

    const multiplier = decimals === 1 ? 1 : (10 ** (decimals));
    const finalPrice = new BN(price * multiplier);

    const accounts = {
      domain: DOMAINPDA,
      keychain: this.keychainPda,
      authority: this.walletPubKey,
      item: nftMint,
      authorityItemToken: fromItemToken,
      listing: listingPda,
      listingItemToken,
      currency: currencyAccount,
      tokenProgram: TOKEN_PROGRAM_ID,
      systemProgram: SystemProgram.programId,
      associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
      proceeds: proceedsAccount,
      proceedsToken: proceedsTokenAccount
    }

    printObject(accounts);

    console.log("Final price before listing: ", finalPrice);
    const ixs = [];
    const ix1: TransactionInstruction = await this.yardsaleProg.methods
        .listItem(finalPrice)
        .accounts(accounts)
        .instruction();
    ixs.push(ix1);

    // nft's should only ever have a balance of 0 or 1, but just in case we're somehow dealing with an sft here, we need to
    // make sure we don't try to close the account if it has a balance > 1
    const buyerTokenBalance = await this.getTokenAccountBalance(fromItemToken);
    if (buyerTokenBalance === 1) {
      ixs.push(await this.createCloseTokenAccountInstruction(fromItemToken));
    }
    return ixs;
  }


  async getTokenAccountBalance(tokenAccount: PublicKey): Promise<number> {
    const tokenAmountResponse = await this.connection.getTokenAccountBalance(tokenAccount);
    return tokenAmountResponse.value.uiAmount;
  }

  async createPNFTListingInstructions({
    price,
    decimals,
    currencyAccount,
    nftMint,
    fromItemToken,
    listingPda,
    proceedsAccount,
    proceedsTokenAccount,
    listingItemToken,
  }: ListingPayload) {
    const multiplier = decimals === 1 ? 1 : 10 ** decimals;
    const finalPrice = new BN(price * multiplier);

    //pnft
    const {
      meta,
      ownerTokenRecordBump,
      ownerTokenRecordPda,
      destTokenRecordBump,
      destTokenRecordPda,
      ruleSet,
      nftEditionPda,
      authDataSerialized,
    } = await prepPnftAccounts({
      nftMint,
      destAta: listingItemToken,
      authData: null, //currently useless
      sourceAta: fromItemToken,
    });
    const remainingAccounts = [];
    if (ruleSet) {
      console.log('>>>> ruleset provided: ', ruleSet.toBase58());
      remainingAccounts.push({
        pubkey: ruleSet,
        isSigner: false,
        isWritable: false,
      });
    } else {
      console.log('>>>> no ruleset provided');
    }

    const accounts = {
      domain: DOMAINPDA,
      keychain: this.keychainPda,
      item: nftMint,
      authorityItemToken: fromItemToken,
      listing: listingPda,
      listingItemToken,
      currency: currencyAccount,
      proceeds: proceedsAccount,
      proceedsToken: proceedsTokenAccount,
      seller: this.walletPubKey,
      tokenProgram: TOKEN_PROGRAM_ID,
      systemProgram: SystemProgram.programId,
      rent: anchor.web3.SYSVAR_RENT_PUBKEY,
      associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
      itemMetadata: meta,
      edition: nftEditionPda,
      authorityTokenRecord: ownerTokenRecordPda,
      listingTokenRecord: destTokenRecordPda,
      tokenMetadataProgram: TMETA_PROG_ID,
      instructions: SYSVAR_INSTRUCTIONS_PUBKEY,
      authorizationRulesProgram: AUTH_PROG_ID,
    };

    printAccounts(accounts);

    let buyerTokenBalance = await this.getTokenAccountBalance(fromItemToken);
    const ixs = [];
    const ix1: TransactionInstruction = await this.yardsaleProg.methods
      .listPnft(finalPrice, authDataSerialized, !!ruleSet)
      .accounts(accounts)
      .remainingAccounts(remainingAccounts)
      .instruction();
    ixs.push(ix1);

    // nft's should only ever have a balance of 0 or 1, but just in case we're somehow dealing with an sft here, we need to
    // make sure we don't try to close the account if it has a balance > 1
    if (buyerTokenBalance === 1) {
      ixs.push(await this.createCloseTokenAccountInstruction(fromItemToken));
    }
    return ixs;
  }

  async createCNFTListingInstructions({
    price,
    decimals,
    currencyAccount,
    nftMint,
    listingPda,
    proceedsAccount,
    proceedsTokenAccount,
  }: ListingPayload): Promise<TransactionInstruction[]> {
    this.checkCNFTSupport();

    const multiplier = decimals === 1 ? 1 : 10 ** decimals;
    const finalPrice = new BN(price * multiplier);

    let asset: ReadApiAsset = await this.heliusConnection.getAsset(nftMint.toBase58());
    let assetProof = await this.heliusConnection.getAssetProof(nftMint.toBase58());
    let treeAddress = new PublicKey(asset.compression.tree);
    let treeAccount = await ConcurrentMerkleTreeAccount.fromAccountAddress(this.heliusConnection, treeAddress);
    const treeAuthority = treeAccount.getAuthority();
    const canopyDepth = treeAccount.getCanopyDepth();

    // get "proof path" from asset proof, these are the accounts that need to be passed to the program as remaining accounts
    // may also be empty if tree is small enough, and canopy depth is large enough
    const proofPath: AccountMeta[] = assetProof.proof
      .map((node: string) => ({
        pubkey: new PublicKey(node),
        isSigner: false,
        isWritable: false,
      }))
      .slice(0, assetProof.proof.length - (!!canopyDepth ? canopyDepth : 0));

    console.log(
      `canopy depth: ${canopyDepth}, asset proof.proof.length: ${assetProof.proof.length}. proof path length: ${proofPath.length}`
    );
    console.log('proof path: ', proofPath);

    // get root, data hash, creator hash, nonce, and index from asset and asset proof
    const root = [...new PublicKey(assetProof.root.trim()).toBytes()];
    const dataHash = [...new PublicKey(asset.compression.data_hash.trim()).toBytes()];
    const creatorHash = [...new PublicKey(asset.compression.creator_hash.trim()).toBytes()];
    const nonce = asset.compression.leaf_id;
    const index = asset.compression.leaf_id;

    const accounts = {
      domain: DOMAINPDA,
      keychain: this.keychainPda,
      listing: listingPda,
      currency: currencyAccount,
      proceedsToken: proceedsTokenAccount,
      proceeds: proceedsAccount,
      treeAuthority,
      leafOwner: this.walletPubKey,
      merkleTree: treeAddress,
      logWrapper: SPL_NOOP_PROGRAM_ID,
      bubblegumProgram: BUBBLEGUM_PROGRAM_ID,
      compressionProgram: SPL_ACCOUNT_COMPRESSION_PROGRAM_ID,
      systemProgram: SystemProgram.programId,
    };

    printAccounts(accounts);

    const ix: TransactionInstruction = await this.yardsaleProg.methods
      .listCompressedNft(nftMint, root, dataHash, creatorHash, new anchor.BN(nonce), index, finalPrice)
      .accounts(accounts)
      .remainingAccounts(proofPath)
      .instruction();

    return [ix];
  }

  async delistYardsaleListing({listingPda, nftMint, sellerItemToken, listingItemToken}: YardsaleDelistingPayload) {
    const tx = await this.createTransactionWithMissingAta(nftMint, sellerItemToken);
    tx.add(
      await this.yardsaleProg.methods
        .delistItem()
        .accounts({
          listing: listingPda,
          item: nftMint,
          keychain: this.keychainPda,
          authorityItemToken: sellerItemToken,
          listingItemToken,
          authority: this.walletPubKey,
          associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
          tokenProgram: TOKEN_PROGRAM_ID,
        })
        .instruction()
    );
    return tx;
  }

  async delistBazaarListing({
    nftMints,
    fromTokenAccounts,
    sellerAccountPda,
    listingPda,
  }: DelistBazaarPayload): Promise<TransactionInstruction> {
    const listingTokenAccounts: (PublicKey | null)[] = nftMints.map((mint: PublicKey) =>
      !!mint ? getAssociatedTokenAddressSync(mint, listingPda, true) : null
    );

    let delistAccounts = {
      listing: listingPda,
      seller: this.walletPubKey,
      sellerAccount: sellerAccountPda,
      keychain: this.keychainPda,
      item0: nftMints[0],
      item0SellerToken: fromTokenAccounts[0],
      item0ListingToken: listingTokenAccounts[0],
      item1: nftMints[1],
      item1SellerToken: fromTokenAccounts[1],
      item1ListingToken: listingTokenAccounts[1],
      item2: nftMints[2],
      item2SellerToken: fromTokenAccounts[2],
      item2ListingToken: listingTokenAccounts[2],
      item3: nftMints[3],
      item3SellerToken: fromTokenAccounts[3],
      item3ListingToken: listingTokenAccounts[3],
      item4: nftMints[4],
      item4SellerToken: fromTokenAccounts[4],
      item4ListingToken: listingTokenAccounts[4],
      associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
      tokenProgram: TOKEN_PROGRAM_ID,
      systemProgram: SystemProgram.programId,
    };
    printAccounts(delistAccounts);

    const ix = await this.bazaarProg.methods.delist().accounts(delistAccounts).instruction();
    return ix;
  }

  // creates a new tx object and checks to see if the given ata exists. if it doesn't, it adds an instruction to create it
  async createTransactionWithMissingAta(mint: PublicKey, ata: PublicKey) {
    const missingAccount = await this.connection.getAccountInfo(ata);
    const tx: Transaction = new Transaction();
    if (!missingAccount) {
      console.log(
        "token account doesn't exist. adding create associated token account instruction to created tx for token account: ",
        ata.toBase58()
      );
      tx.add(createAssociatedTokenAccountInstruction(this.walletPubKey, ata, this.walletPubKey, mint));
    } else {
      console.log('token account already exists. no need to create: ', ata.toBase58());
    }
    return tx;
  }

  async createInstructionForMissingAta(mint: PublicKey, ata: PublicKey): Promise<TransactionInstruction | undefined> {
    const missingAccount = await this.connection.getAccountInfo(ata);
    let ix: TransactionInstruction;
    if (!missingAccount) {
      console.log(
        "token account doesn't exist. creating instruction to make an associated token account: ",
        ata.toBase58()
      );
      ix = createAssociatedTokenAccountInstruction(this.walletPubKey, ata, this.walletPubKey, mint);
    } else {
      console.log('token account already exists. no need to create: ', ata.toBase58());
    }
    return ix;
  }

  async delistPNFT({listingPda, nftMint, sellerItemToken, listingItemToken}: YardsaleDelistingPayload) {
    const {
      meta,
      ownerTokenRecordBump,
      ownerTokenRecordPda,
      destTokenRecordBump,
      destTokenRecordPda,
      ruleSet,
      nftEditionPda,
      authDataSerialized,
    } = await prepPnftAccounts({
      nftMint,
      destAta: sellerItemToken,
      authData: null, //currently useless
      sourceAta: listingItemToken,
    });
    const remainingAccounts = [];
    if (ruleSet) {
      console.log('>>>> ruleSet: ', ruleSet.toBase58());
      remainingAccounts.push({
        pubkey: ruleSet,
        isSigner: false,
        isWritable: false,
      });
    } else {
      console.log('>>>> no ruleSet');
    }

    const accounts = {
      listing: listingPda,
      keychain: this.keychainPda,
      item: nftMint,
      sellerItemToken,
      listingItemToken,
      seller: this.walletPubKey,
      itemMetadata: meta,
      edition: nftEditionPda,
      sellerTokenRecord: destTokenRecordPda,
      listingTokenRecord: ownerTokenRecordPda,
      associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
      tokenProgram: TOKEN_PROGRAM_ID,
      systemProgram: SystemProgram.programId,
      authorizationRulesProgram: AUTH_PROG_ID,
      tokenMetadataProgram: TMETA_PROG_ID,
      instructions: SYSVAR_INSTRUCTIONS_PUBKEY,
      ruleset: ruleSet,
    };
    printAccounts(accounts);

    const tx = await this.createTransactionWithMissingAta(nftMint, sellerItemToken);
    tx.add(await this.yardsaleProg.methods.delistPnft().accounts(accounts).instruction());
    return tx;
  }

  async delistCNFT({listingPda, nftMint, sellerItemToken, listingItemToken}: YardsaleDelistingPayload) {
    this.checkCNFTSupport();
    // some of this stuff is redundant from the previous test, but demoing how to do it
    let asset = await this.heliusConnection.getAsset(nftMint.toBase58());
    let assetProof = await this.heliusConnection.getAssetProof(nftMint.toBase58());
    let treeAddress = new PublicKey(asset.compression.tree);
    let treeAccount = await ConcurrentMerkleTreeAccount.fromAccountAddress(this.heliusConnection, treeAddress);
    let treeAuthority = treeAccount.getAuthority();
    let canopyDepth = treeAccount.getCanopyDepth();

    // get "proof path" from asset proof, these are the accounts that need to be passed to the program as remaining accounts
    // may also be empty if tree is small enough, and canopy depth is large enough
    let proofPath: AccountMeta[] = assetProof.proof
      .map((node: string) => ({
        pubkey: new PublicKey(node),
        isSigner: false,
        isWritable: false,
      }))
      .slice(0, assetProof.proof.length - (!!canopyDepth ? canopyDepth : 0));

    console.log(
      `canopy depth: ${canopyDepth}, asset proof.proof.length: ${assetProof.proof.length}. proof path length: ${proofPath.length}`
    );

    // get root, data hash, creator hash, nonce, and index from asset and asset proof
    let root = [...new PublicKey(assetProof.root.trim()).toBytes()];
    let dataHash = [...new PublicKey(asset.compression.data_hash.trim()).toBytes()];
    let creatorHash = [...new PublicKey(asset.compression.creator_hash.trim()).toBytes()];
    let nonce = asset.compression.leaf_id;
    let index = asset.compression.leaf_id;

    const accounts = {
      listing: listingPda,
      keychain: this.keychainPda,
      authority: this.walletPubKey,
      treeAuthority,
      merkleTree: treeAddress,
      logWrapper: SPL_NOOP_PROGRAM_ID,
      bubblegumProgram: BUBBLEGUM_PROGRAM_ID,
      compressionProgram: SPL_ACCOUNT_COMPRESSION_PROGRAM_ID,
      systemProgram: SystemProgram.programId,
    };

    printAccounts(accounts);

    const tx: Transaction = await this.yardsaleProg.methods
      .delistCnft(root, dataHash, creatorHash, new anchor.BN(nonce), index)
      .accounts(accounts)
      .remainingAccounts(proofPath)
      .transaction();
    return tx;
  }

  async updateYardsaleListing(
    newPrice: number,
    decimals: number,
    nftMint: PublicKey,
    listingPda: PublicKey
  ): Promise<Transaction> {
    const accounts = {
      listing: listingPda,
      keychain: this.keychainPda,
      item: nftMint,
      authority: this.walletPubKey,
    };

    const multiplier = decimals === 1 ? 1 : 10 ** decimals;
    const finalPrice = newPrice * multiplier;
    return await this.yardsaleProg.methods.updatePrice(new BN(finalPrice)).accounts(accounts).transaction();
  }

  async updateBazaarListing(
    newPrice: number,
    decimals: number,
    sellerPda: PublicKey,
    listingPda: PublicKey
  ): Promise<Transaction> {
    let listing = await this.fetchBazaarListing(listingPda);

    const accounts = {
      listing: listingPda,
      keychain: this.keychainPda,
      sellerAccount: sellerPda,
      seller: this.walletPubKey,
    };

    const multiplier = decimals === 1 ? 1 : 10 ** decimals;
    const finalPrice = newPrice * multiplier;
    return await this.bazaarProg.methods.updateListing(new BN(finalPrice)).accounts(accounts).transaction();
  }

  getMoneyAccounts(listing: any): MoneyAccounts {
    const currencyAccount = listing?.currency as PublicKey;
    let proceedsAccount: PublicKey | null;
    let proceedsTokenAccount: PublicKey | null;
    if (currencyAccount.toBase58() === NATIVE_MINT.toBase58()) {
      proceedsAccount = listing.proceeds as PublicKey;
      proceedsTokenAccount = null;
    } else {
      proceedsAccount = null;
      proceedsTokenAccount = listing.proceeds as PublicKey;
    }
    return {currencyAccount, proceedsAccount, proceedsTokenAccount};
  }

  async purchaseBazaarListing({
    nftMints,
    sellerAccountPda,
    buyerCurrencyTokenAccount,
    buyerItemTokenAccounts,
    listingPda,
    unitQuantity = 1,
  }: PurchasingBazaarPayload): Promise<Transaction> {
    let bazaarListing = await this.fetchBazaarListing(listingPda);
    const {currencyAccount, proceedsAccount, proceedsTokenAccount} = this.getMoneyAccounts(bazaarListing);

    const accounts = {
      buyer: this.walletPubKey,
      buyerCurrencyToken: buyerCurrencyTokenAccount,
      listing: listingPda,
      sellerAccount: sellerAccountPda,
      currency: currencyAccount,
      proceedsToken: proceedsTokenAccount,
      proceeds: proceedsAccount,
      item0: nftMints[0],
      item0BuyerToken: buyerItemTokenAccounts[0],
      item0ListingToken: !!bazaarListing.items[0] ? bazaarListing.items[0].itemToken : null,
      item1: nftMints[1] ?? null,
      item1BuyerToken: buyerItemTokenAccounts[1],
      item1ListingToken: !!bazaarListing.items[1] ? bazaarListing.items[1].itemToken : null,
      item2: nftMints[2] ?? null,
      item2BuyerToken: buyerItemTokenAccounts[2],
      item2ListingToken: !!bazaarListing.items[2] ? bazaarListing.items[2].itemToken : null,
      item3: nftMints[3],
      item3BuyerToken: buyerItemTokenAccounts[3],
      item3ListingToken: !!bazaarListing.items[3] ? bazaarListing.items[3].itemToken : null,
      item4: nftMints[4],
      item4BuyerToken: buyerItemTokenAccounts[4],
      item4ListingToken: !!bazaarListing.items[4] ? bazaarListing.items[4].itemToken : null,
      treasury: bazaarListing.treasury,
      associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
      tokenProgram: TOKEN_PROGRAM_ID,
      systemProgram: SystemProgram.programId,
    };
    printAccounts(accounts);

    let tx: Transaction = new anchor.web3.Transaction();

    // increase the tx size if >= 4 items
    if (bazaarListing.items.length >=4) {
      tx.add(this.getIncreaseTxSizeIx(300_000));
    }

    // If required, create an instruction to create an associated token account for each nft to be purchased
    const ixs: TransactionInstruction[] = await Promise.all(
      buyerItemTokenAccounts.reduce((array: Promise<TransactionInstruction>[], account: PublicKey, i: number) => {
        if (!!nftMints[i] && !!account) {
          array.push(this.createInstructionForMissingAta(nftMints[i], account));
        }
        return array;
      }, [] as Promise<TransactionInstruction>[])
    );

    // Filter out undefined values from the ixs array
    const definedIxs: TransactionInstruction[] = ixs.filter((ix) => !!ix);
    if (definedIxs.length > 0) {
      tx.add(...definedIxs);
    }

    const ix4 = await this.bazaarProg.methods.buy(new BN(unitQuantity)).accounts(accounts).instruction();
    tx.add(ix4);

    return tx;
  }

  async fetchYardsaleListing(listingPda: PublicKey, errorOnMissing = true): Promise<any> {
    let listing = await this.yardsaleProg.account.listing.fetchNullable(listingPda);
    if (listing) {
      return listing;
    } else {
      if (errorOnMissing) {
        throw new FriendlyError('Listing not found on chain.');
      } else {
        return null;
      }
    }
  }

  async fetchBazaarListing(bazaarListingPda: PublicKey, errorOnMissing = true): Promise<any> {
    let listing = await this.bazaarProg.account.listing.fetchNullable(bazaarListingPda);
    if (listing) {
      return listing;
    } else {
      if (errorOnMissing) {
        throw new FriendlyError('Listing not found on chain.');
      } else {
        return null;
      }
    }
  }

  ////// YARDSALE //////
    async purchaseYardsaleNft({
                      nftMint,
                      buyerItemTokenAccount,
                      buyerCurrencyTokenAccount,
                      listingPda
                    }: PurchasingYardsalePayload): Promise<Transaction> {


    let listing = await this.fetchYardsaleListing(listingPda);

    const currencyAccount = listing?.currency as PublicKey
    let proceedsAccount: PublicKey | null;
    let proceedsTokenAccount: PublicKey | null;
    if (currencyAccount.toBase58() === NATIVE_MINT.toBase58()) {
      proceedsAccount = listing.proceeds as PublicKey;
      proceedsTokenAccount = null;
    } else {
      proceedsAccount = null;
      proceedsTokenAccount = listing.proceeds as PublicKey;
    }

    const accounts = {
      listing: listingPda,
      item: nftMint,
      listingItemToken: listing.itemToken as PublicKey,
      authorityItemToken: buyerItemTokenAccount,
      currency: currencyAccount ?? NATIVE_MINT,
      authority: this.walletPubKey,
      treasury: this.keychainTreasury,
      associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
      tokenProgram: TOKEN_PROGRAM_ID,
      systemProgram: SystemProgram.programId,
      proceeds: proceedsAccount,
      proceedsToken: proceedsTokenAccount,
      authorityCurrencyToken: buyerCurrencyTokenAccount
    }

    const tx = await this.createTransactionWithMissingAta(nftMint, buyerItemTokenAccount);
    let ix = await this.yardsaleProg.methods.purchaseItem().accounts(accounts).instruction();
    tx.add(ix);
    return tx;
  }

  async purchaseProgrammableNft({
    nftMint,
    buyerItemTokenAccount,
    buyerCurrencyTokenAccount,
    listingPda,
  }: PurchasingYardsalePayload): Promise<Transaction> {
    let listing = await this.fetchYardsaleListing(listingPda);

    //pnft
    const {
      meta,
      ownerTokenRecordBump,
      ownerTokenRecordPda,
      destTokenRecordBump,
      destTokenRecordPda,
      ruleSet,
      nftEditionPda,
      authDataSerialized,
    } = await prepPnftAccounts({
      nftMint,
      destAta: buyerItemTokenAccount,
      authData: null, //currently useless
      sourceAta: listing.itemToken as PublicKey,
    });
    const remainingAccounts = [];
    if (ruleSet) {
      remainingAccounts.push({
        pubkey: ruleSet,
        isSigner: false,
        isWritable: false,
      });
    }

    //@ts-ignore
    const currencyAccount = listing?.currency as PublicKey;
    let proceedsAccount: PublicKey | null;
    let proceedsTokenAccount: PublicKey | null;
    if (currencyAccount.toBase58() === NATIVE_MINT.toBase58()) {
      proceedsAccount = listing.proceeds as PublicKey;
      proceedsTokenAccount = null;
    } else {
      proceedsAccount = null;
      proceedsTokenAccount = listing.proceeds as PublicKey;
    }

    const accounts = {
      listing: listingPda,
      item: nftMint,
      itemMetadata: meta,
      edition: nftEditionPda,
      buyerTokenRecord: destTokenRecordPda,
      listingTokenRecord: ownerTokenRecordPda,
      listingItemToken: listing.itemToken as PublicKey,
      buyerItemToken: buyerItemTokenAccount,
      currency: currencyAccount,
      proceeds: proceedsAccount,
      proceedsToken: proceedsTokenAccount,
      buyer: this.walletPubKey,
      buyerCurrencyToken: buyerCurrencyTokenAccount,
      treasury: this.keychainTreasury,
      associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
      tokenProgram: TOKEN_PROGRAM_ID,
      systemProgram: SystemProgram.programId,
      authorizationRulesProgram: AUTH_PROG_ID,
      tokenMetadataProgram: TMETA_PROG_ID,
      instructions: SYSVAR_INSTRUCTIONS_PUBKEY,
      ruleset: ruleSet,
    };

    console.log('PURCHASE PNFT accounts: ');
    printAccounts(accounts);

    const tx = await this.createTransactionWithMissingAta(nftMint, buyerItemTokenAccount);
    const ix = await this.yardsaleProg.methods.purchasePnft().accounts(accounts).instruction();

    tx.add(ix);
    return tx;
  }

  checkCNFTSupport() {
    if (!this.heliusConnection) {
      throw new Error('Compressed NFT support not enabled.');
    }
  }

  async purchaseCompressedNft({nftMint, buyerCurrencyTokenAccount, listingPda}: PurchasingYardsalePayload) {
    this.checkCNFTSupport();

    let listing = await this.fetchYardsaleListing(listingPda);
    //@ts-ignore
    const currencyAccount = listing?.currency as PublicKey;
    let proceedsAccount: PublicKey | null;
    let proceedsTokenAccount: PublicKey | null;
    if (currencyAccount.equals(NATIVE_MINT)) {
      proceedsAccount = listing.proceeds as PublicKey;
      proceedsTokenAccount = null;
    } else {
      proceedsAccount = null;
      proceedsTokenAccount = listing.proceeds as PublicKey;
    }

    let asset = await this.heliusConnection.getAsset(nftMint.toBase58());
    console.log('fetched asset: ', asset);
    let assetProof = await this.heliusConnection.getAssetProof(nftMint.toBase58());
    let treeAddress = new PublicKey(asset.compression.tree);
    let treeAccount = await ConcurrentMerkleTreeAccount.fromAccountAddress(this.heliusConnection, treeAddress);
    const treeAuthority = treeAccount.getAuthority();
    const canopyDepth = treeAccount.getCanopyDepth();

    // get "proof path" from asset proof, these are the accounts that need to be passed to the program as remaining accounts
    // may also be empty if tree is small enough, and canopy depth is large enough
    const proofPath: AccountMeta[] = assetProof.proof
      .map((node: string) => ({
        pubkey: new PublicKey(node),
        isSigner: false,
        isWritable: false,
      }))
      .slice(0, assetProof.proof.length - (!!canopyDepth ? canopyDepth : 0));

    console.log(
      `canopy depth: ${canopyDepth}, asset proof.proof.length: ${assetProof.proof.length}. proof path length: ${proofPath.length}`
    );
    console.log('proof path: ', proofPath);

    // get root, data hash, creator hash, nonce, and index from asset and asset proof
    const root = [...new PublicKey(assetProof.root.trim()).toBytes()];
    const dataHash = [...new PublicKey(asset.compression.data_hash.trim()).toBytes()];
    const creatorHash = [...new PublicKey(asset.compression.creator_hash.trim()).toBytes()];
    const nonce = asset.compression.leaf_id;
    const index = asset.compression.leaf_id;

    const accounts = {
      listing: listingPda,
      treasury: this.keychainTreasury,
      currency: currencyAccount,
      proceedsToken: proceedsTokenAccount,
      proceeds: proceedsAccount,
      buyerCurrencyToken: buyerCurrencyTokenAccount,
      tokenProgram: TOKEN_PROGRAM_ID,
      treeAuthority,
      merkleTree: treeAddress,
      logWrapper: SPL_NOOP_PROGRAM_ID,
      bubblegumProgram: BUBBLEGUM_PROGRAM_ID,
      compressionProgram: SPL_ACCOUNT_COMPRESSION_PROGRAM_ID,
      systemProgram: SystemProgram.programId,
      newLeafOwner: this.walletPubKey,
    };

    printAccounts(accounts);

    // now buyer can make the purchase
    const tx: Transaction = await this.yardsaleProg.methods
      .purchaseCnft(root, dataHash, creatorHash, new anchor.BN(nonce), index)
      .accounts(accounts)
      .remainingAccounts(proofPath)
      .transaction();

    return tx;
  }

  getIncreaseTxSizeIx(units: number = 300_000): TransactionInstruction {
    return ComputeBudgetProgram.setComputeUnitLimit({
      units,
    });
  }
}

