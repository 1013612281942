import * as React from 'react';

//Data
import {ENOTI_STATUS, IToasts, toastsAtom, useToastsActions} from 'store/toasts';

//Styles
import {useRecoilValue} from 'recoil';
import {COLORS} from 'constants/theme';
import "./Toasts.scss";

export const Toasts = () => {

  const toasts = useRecoilValue<IToasts>(toastsAtom);
  const toastActions = useToastsActions();

  React.useEffect(() => {
    toasts.toasts.forEach(({id, timeout}) => {
      setTimeout(() => {
        removeToast(id)
      }, timeout);
    });
  }, [toasts.toasts.length])

  const backgroundStyle = (type: ENOTI_STATUS) => {
    switch (type) {
      case ENOTI_STATUS.SUCCESS:
        return COLORS.SUCCESS_GREEN
      case ENOTI_STATUS.ERR:
        return COLORS.TEXT_RED
      default:
        return COLORS.BACKGROUND_TOAST
    }
  };

  const getTextColor = (type: ENOTI_STATUS) => {
    if (type === ENOTI_STATUS.DEFAULT) {
      return COLORS.TEXT_TOAST_DEFAULT
    } else return COLORS.TEXT_WHITE
  }

  const removeToast = (id: string) => toastActions.removeToast(id);

  return (
    <div className = "toasts">
      {toasts.toasts.map(({id, text, type}) => (
        <div className = "toastCon" key = {id} onClick={() => removeToast(id)}>
          <div className = "innerCon" style = {{backgroundColor: backgroundStyle(type)}}>
            <p className = "normal" style={{color: getTextColor(type)}}>{text}</p>
          </div>
        </div>
      ))}
    </div>
  )
};


export default Toasts;
