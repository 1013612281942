import { COLORS } from 'constants/theme'
import React, { useCallback, useState } from 'react'
import {ReactComponent as SearchIcon} from "assets/icons/MagnifyingGlassIcon.svg";

import "./Input.scss";

interface ICustomInputProps {
    value: string
    onChangeText: (text: string, name?: string) => void
    name?: string
    placeholder?: string
    label?: string
    defaultValue?: string
    inputMode?: 'text' | 'number'
    onBlur?: () => void
    disabled?: boolean
    maxLength?: number
    errorText?: string
    hint?: string
    numberOfLines?: number
    multiline?: boolean
    conStyle?: any
}


export const Input: React.FC<ICustomInputProps> = ({
    value,
    onChangeText,
    name,
    placeholder,
    label,
    defaultValue,
    inputMode,
    onBlur,
    disabled,
    maxLength,
    errorText,
    hint,
    multiline,
    conStyle,
    numberOfLines
}) => {
    const [focusStyle, setFocusStyle] = useState({
        borderColor: 'transparent',
    })

    const handleOnChangeText = useCallback((e: any) => {
        onChangeText(e.target.value, name)
    }, [onChangeText, name])


    return (
        <div className = "input-container" style={conStyle}>
            {label && <p className = "normal">{label}</p>}
            {multiline ? 
            <textarea                 
                placeholder={placeholder}
                value={value ? value : ""}
                onChange={handleOnChangeText}
                defaultValue={defaultValue}
                inputMode={inputMode}
                maxLength={maxLength}/>
            :
            <input
                placeholder={placeholder}
                value={value ? value : ""}
                onChange={handleOnChangeText}
                defaultValue={defaultValue}
                inputMode={inputMode}
                maxLength={maxLength}
                // multiline={numberOfLines > 1}
                // numberOfLines={numberOfLines}
            />} 
            {!!hint &&
                <p className = "hint">*{hint}</p>
            }
            {!!errorText && <p className = "error">{errorText}</p>}
        </div>
    )
}

export const SearchInput: React.FC<ICustomInputProps> = ({
    value,
    onChangeText,
    name,
    placeholder,
    label,
    defaultValue,
    inputMode,
    onBlur,
    disabled,
    maxLength,
    errorText,
    hint,
    numberOfLines
}) => {
    const [focusStyle, setFocusStyle] = useState({
        borderColor: 'transparent',
    })

    const handleOnChangeText = useCallback((e: any) => {
        onChangeText(e.target.value, name)
    }, [onChangeText, name])


    return (
        <div className = "search-input-container">
            {label && <p className = "normal">{label}</p>}
            <SearchIcon/>
            <input
                placeholder={placeholder}
                value={value ? value : ""}
                onChange={handleOnChangeText}
                defaultValue={defaultValue}
                inputMode={inputMode}
                maxLength={maxLength}
                // multiline={numberOfLines > 1}
                // numberOfLines={numberOfLines}
            />
            {!!hint &&
                <p className = "hint">*{hint}</p>
            }
            {!!errorText && <p className = "error">{errorText}</p>}
        </div>
    )
}