import {PublicKey} from "@solana/web3.js";
import * as anchor from '@project-serum/anchor';
import {AUTOMATIONS_SPACE, BEARD_SPACE, STACHE, VAULT_SPACE} from "./program-utils";

// stachepda = beard pda lul
export const findStachePda = (name: string, domain: string, stacheprogid: PublicKey): [PublicKey, number] => {
  return anchor.web3.PublicKey.findProgramAddressSync(
      [
        Buffer.from(anchor.utils.bytes.utf8.encode(name)),
        Buffer.from(anchor.utils.bytes.utf8.encode(BEARD_SPACE)),
        Buffer.from(anchor.utils.bytes.utf8.encode(domain)),
        Buffer.from(anchor.utils.bytes.utf8.encode(STACHE)),
      ],
      stacheprogid,
  );
};

export const findVaultPda = (vaultIndex: number, stacheid: string, domain: string, stacheprogid: PublicKey): [PublicKey, number] => {
  return anchor.web3.PublicKey.findProgramAddressSync(
      [
        Buffer.from([vaultIndex]),
        Buffer.from(anchor.utils.bytes.utf8.encode(VAULT_SPACE)),
        Buffer.from(anchor.utils.bytes.utf8.encode(stacheid)),
        Buffer.from(anchor.utils.bytes.utf8.encode(BEARD_SPACE)),
        Buffer.from(anchor.utils.bytes.utf8.encode(domain)),
        Buffer.from(anchor.utils.bytes.utf8.encode(STACHE)),
      ],
      stacheprogid,
  );
};

export const findAutoPda = (autoIndex: number, stacheid: string, domain: string, stacheprogid: PublicKey): [PublicKey, number] => {
  return anchor.web3.PublicKey.findProgramAddressSync(
      [
        Buffer.from([autoIndex]),
        Buffer.from(anchor.utils.bytes.utf8.encode(AUTOMATIONS_SPACE)),
        Buffer.from(anchor.utils.bytes.utf8.encode(stacheid)),
        Buffer.from(anchor.utils.bytes.utf8.encode(BEARD_SPACE)),
        Buffer.from(anchor.utils.bytes.utf8.encode(domain)),
        Buffer.from(anchor.utils.bytes.utf8.encode(STACHE)),
      ],
      stacheprogid,
  );
};

// get the thread pda for the given thread id and authority (program that will get executed/owns the thread)
export const findThreadPda = (id: string, threadAuthority: PublicKey, clockworkprogid): [PublicKey, number] => {
  return anchor.web3.PublicKey.findProgramAddressSync(
      [
        Buffer.from(anchor.utils.bytes.utf8.encode('thread')),
        threadAuthority.toBuffer(),
        Buffer.from(anchor.utils.bytes.utf8.encode(id)),
      ],
      clockworkprogid,
  );

}
