import useToasts from "hooks/useToasts";
import { useState } from "react";
import { ENOTI_STATUS } from "store/toasts";
import { IActivity } from "store/yardsale";
import useAsyncEffect from "use-async-effect";
import {ReactComponent as InfoIcon} from "assets/icons/InfoIcon.svg"
import {ReactComponent as Chrevon} from "assets/icons/ticks/Chevron.svg";
import Loader from 'components/Loader/Loader';
import "./Feed.scss";
import { truncate } from "lodash";
import moment from "moment";
import {formatAddress} from "../../utils/string-formatting";
import { Pagination } from "components/Pagination/Pagination";

interface FeedProps {
  itemsPerPage: number,
  getter: () => Promise<IActivity[]>
}

export const Feed = ({getter, itemsPerPage}: FeedProps) => {
  const [page, setPage] = useState(1);
  const [data, setData] = useState <IActivity[] | undefined> ();
  const {createToast} = useToasts();
  const pages = data ? Math.ceil(data.length / itemsPerPage) : 1;

  useAsyncEffect(async () => {
    try {
      const data = await getter();
      setData(data);
    }
    catch(e) {
      createToast("Error in retrieving feed", ENOTI_STATUS.ERR);
    }
  }, [])

  const Item = (data: IActivity) => (
    <div>
        <div className = "images">
          {data.listing.items.map((x, idx) => <img key = {idx} src = {x.imageUrl}/>)}
          {/* <img src = {data.listing.imageUrl}/> */}
        </div>
        <div className = "info">
          <div className = "name">
            <p className = "normal gray">Name</p>
            <p className = "normal">{data.listing.name}</p>
          </div>
          <div>
            <p className = "normal gray">Action</p>
            <p className = "normal accented">{data.activityType}</p>
          </div>
          <div>
            <p className = "normal gray">Total</p>
            <p className = "normal">{data.listing.price} {data.listing.currency.symbol}</p>
          </div>
          <div>
            <p className = "normal gray">Seller</p>
            <p className = "normal">@{data.listing.seller.stacheid}</p>
          </div>
          <div>
            <p className = "normal gray">Buyer</p>
            {/* Currently there is no buyer field in the data */}
            <p className = "normal">
              {data.buyerStacheid && <>@{data.buyerStacheid}</>}
              {!data.buyerStacheid && data.buyerWallet && <>{formatAddress(data.buyerWallet)}</>}

            </p>
          </div>
          <div className = "time-container">
            <p className = "normal gray">Time</p>
            <div>
              <p className = "normal">{moment(data.createdAt).fromNow()}</p>
              {/*<InfoIcon/>*/}
            </div>
          </div>
        </div>
      </div>
  )

  if (!data) return <Loader noBg/>

  return (
    <div className = "feed">
      <div className = "items">
        {/* Map over data here */}
        {data.slice((page - 1) * itemsPerPage, Math.min(data.length, page * itemsPerPage)).map((x, idx) => <Item key = {x.id} {...x}/>)}
      </div>
      <Pagination page={page} setPage={setPage} total = {pages}/>
    </div>
  )
}
