//Recoil
import {ICollectible, ITokenBag} from "store/connectedWallets";

//Web3
import {PublicKey} from "@solana/web3.js";

//Util
import {getSolTokenBag} from "../store/store-utils";
import {useRecoilValue} from "recoil";
import {rpcClient} from "../constants/factory";
import {blacklistAtom, ogCollectionsAtom} from "../store/system";


function useWalletLoader() {

  const blacklist = useRecoilValue(blacklistAtom);
  const ogCollections = useRecoilValue(ogCollectionsAtom);

  async function load(pubkey: PublicKey): Promise<{ collectibles: ICollectible[], tokens: ITokenBag[] }> {
    const assets = await rpcClient.fetchAssets(pubkey, blacklist, ogCollections);

    const solBag = await getSolTokenBag(pubkey);

    // make SOL the first tokenbag
    assets.tokens.unshift(solBag);

    return assets;
  }


  return {
    load
  }
}

export default useWalletLoader;

