import { useEffect, useState } from "react";
import { SidePanelProps, SidePanelWrapper } from "../SidePanelWrapper";
import "./FiltersPanel.scss";
import Select, { StylesConfig } from 'react-select';
import { Filter, NumFilter, StringFilter } from "store/hubs/types";
import { useHubActions } from "store/hubs/actions";
import useToasts from "hooks/useToasts";
import { ENOTI_STATUS } from "store/toasts";
import {ReactComponent as DeleteIcon} from "assets/icons/ticks/Trash.svg"
import { narrow } from "apis/solana/util";
import { ColoredButton } from "components/ui/button/Button";
import { DoubleRangeInput } from "components/ui/DoubleRangeInput/DoubleRangeInput";
import React from "react";
import { debounce } from "lodash";


interface Props {
  hubId: string,
  isOpen: boolean,
  onClose: () => unknown,
  applyFilters: (x: Filter[]) => unknown,
}

const selectStyles: StylesConfig<any> = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // console.log(data);
    return {
      ...styles,
      backgroundColor: (isFocused || isSelected) 
        ? "var(--accent-primary)"
        : data.color,
      color: "white",
      cursor: isDisabled ? 'not-allowed' : 'default',
      // zIndex: 100,

      ':active': {
        ...styles[':active'],
        // zIndex: 99,
        backgroundColor: !isDisabled
          ? isSelected
            ? "var(--accent-primary)"
            : "var(--accent-primary)"
          : undefined,
      },
    };
  },
  menu: (styles) => ({
    ...styles,
    zIndex: 10,
  }),
  input: (styles) => ({ ...styles}),
  placeholder: (styles) => ({ ...styles }),
  singleValue: (styles, { data }) => ({ ...styles}),
};


export const FiltersPanel = ({
  hubId,
  isOpen, 
  onClose, 
  applyFilters: apply
}: Props) => {
  const [filters, setFilters] = useState <Filter[]> ([]);
  const [selectedFilter, setSelectedFilter] = useState <Filter[]> ([]);
  const hubActions = useHubActions();
  const toasts = useToasts();

  useEffect(() => {
    (async () => {
      try {
        if (filters.length === 0) {
          const res = await hubActions.getFilters(hubId);
          setFilters(res);
        }
      } catch (e) {
        toasts.createToast("Failed to get filters", ENOTI_STATUS.ERR);
      }
    })();

  }, [isOpen])

  const handleSetAttribute = (x: Filter) => {
    if (x.attributeType === 'num') {
      setSelectedFilter([x, ...selectedFilter])
    }
    else {
      setSelectedFilter([{
        ...x,
        values: [],
      }, ...selectedFilter])
    }
  }

  const handleDeleteAttribute = (x: string) => {
    const pos = selectedFilter.findIndex(y => y.attributeName === x);
    setSelectedFilter(selectedFilter.filter((_, i) => i !== pos));
  }

  const handleSetValue = (idx: number, value: string) => () => {
    const newSelectedFilter = [...selectedFilter];
    if (narrow <StringFilter[]> (newSelectedFilter)) {
      const idx2 = newSelectedFilter[idx].values.findIndex(x => x === value);
      // console.log(idx2);
      if (idx2 !== -1) {
        newSelectedFilter[idx].values.splice(idx2, 1);
      }
      else {
        newSelectedFilter[idx].values.push(value);
      }
      console.log(newSelectedFilter);
      setSelectedFilter(newSelectedFilter);
      
    }
  }

  const handleSetRange = React.useRef((idx: number) => 
    debounce(
        (min: number, max: number) => {
          setSelectedFilter(selectedFilter => {
            console.log("we");
            const newSelectedFilter = [...selectedFilter];
            (newSelectedFilter[idx] as NumFilter).minLocal = min;
            (newSelectedFilter[idx] as NumFilter).maxLocal = max;
            return newSelectedFilter;
          })
        },
        500,
        {trailing: true, leading: false}
    ),
  );
  //@ts-ignore

  // const handleSetRange = (idx: number) => (min: number, max: number) => {
  //   const newSelectedFilter = [...selectedFilter];
  //   (newSelectedFilter[idx] as NumFilter).minLocal = min;
  //   (newSelectedFilter[idx] as NumFilter).maxLocal = max;
  //   setSelectedFilter(newSelectedFilter);
  // }

  useEffect(() => {
    if (!selectedFilter.length) {
      apply(undefined);
    }
  }, [selectedFilter])

  const clearFilters = () => {
    setSelectedFilter([]);
    apply(undefined);
  }

  const applyFilters = () => {
    apply(selectedFilter);
  }

  // console.log(handleSetRange.current(2));

  return (
    <SidePanelWrapper label = "Filter by attribute" isOpen = {isOpen} onClose={onClose} width={375}>
      <div className = "filters-panel">
        <div className = "search-wrapper">
          <Select
            className="custom-select"
            classNamePrefix="select"
            isSearchable
            styles={selectStyles}
            name="color"
            onChange={handleSetAttribute}
            options={filters.filter(x => !selectedFilter.find(y => y.attributeName === x.attributeName)).map(x => ({...x, label: x.attributeName, value: x.attributeName}))}
            placeholder = "Search attributes"
          />
        </div>
        <div className = "filters">
          {selectedFilter.map((x, idx) => (
            <div>
              <div className = "header-wrapper">
                <p className = "header">
                  {x.attributeName}
                </p>
                <div onClick={() => handleDeleteAttribute(x.attributeName)}>
                  <DeleteIcon/>
                </div>
              </div>
              {x.attributeType === "string" && narrow <StringFilter> (x) ? (
                <div className = "string-options">
                  {x.possibleValues.map(y => (
                    <div key = {y} onClick={handleSetValue(idx, y)} className = {x.values.find(val => val === y) ? "active" : ""}>
                      <p>{y}</p>
                    </div>
                  ))}
                </div>
              ) : narrow <NumFilter> && 
              (
                <div className = "num-options">
                  <DoubleRangeInput min = {x.minIntValue} max = {x.maxIntValue} onChange={handleSetRange.current(idx)}/>
                </div> 
              )
            }
            </div>
          ))}
        </div>
        {!!selectedFilter.length && 
          <div className = "filters-controls">
            <p className = "subheader" onClick={clearFilters}>Clear all filters</p>
            <ColoredButton onClick={applyFilters}>
              Apply Filters
            </ColoredButton>
          </div>
        }
      </div>
    </SidePanelWrapper>
  )
}