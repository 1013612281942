import {IActivity} from "../store/yardsale";
import {formatAddress} from "./string-formatting";

export function getBuyerDisplay(activity: IActivity): string | null {
  if (activity.listing?.buyer) {
    return '@' + activity.listing.buyer.stacheid;
  } else if (activity.listing?.buyerWallet) {
    return formatAddress(activity.listing.buyerWallet);
  } else {
    return null;
  }
}
