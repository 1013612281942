import {Keypair, PublicKey, SystemProgram, Transaction} from "@solana/web3.js";
import {
  createCreateMasterEditionV3Instruction,
  createCreateMetadataAccountV3Instruction,
  PROGRAM_ID as MPL_TOKEN_METADATA_PROGRAM_ID,
  TokenStandard
} from "@metaplex-foundation/mpl-token-metadata/dist/src/generated";
import * as anchor from "@project-serum/anchor";
import {
  createAssociatedTokenAccountInstruction,
  createInitializeMintInstruction,
  createMintToCheckedInstruction,
  getAssociatedTokenAddress,
  getMinimumBalanceForRentExemptMint,
  MINT_SIZE,
  TOKEN_PROGRAM_ID
} from "@solana/spl-token";
import {updateTx} from "./chain-utils";
import {Metaplex, WalletAdapter, walletAdapterIdentity} from "@metaplex-foundation/js";
import {connection} from "../constants/factory";

export function getMetadataPDA(mint: PublicKey): PublicKey {
  const [publicKey] = anchor.web3.PublicKey.findProgramAddressSync(
      [Buffer.from("metadata"), MPL_TOKEN_METADATA_PROGRAM_ID.toBuffer(), mint.toBuffer()],
      MPL_TOKEN_METADATA_PROGRAM_ID
  );
  return publicKey;
}

export function getMasterEditionPDA(mint: PublicKey): PublicKey {
  const [publicKey] = anchor.web3.PublicKey.findProgramAddressSync(
      [Buffer.from("metadata"), MPL_TOKEN_METADATA_PROGRAM_ID.toBuffer(), mint.toBuffer(), Buffer.from("edition")],
      MPL_TOKEN_METADATA_PROGRAM_ID
  );
  return publicKey;
}

const fakeNftData = [
  {
    name: "Fake Fox #",
    symbol: "FOX",
    uri: "https://famousfoxes.com/metadata/###.json",
    rangeLower: 1,
    rangeUpper: 7777
  },
  {
    name: "Fake Kai #",
    symbol: "KAI",
    uri: "https://kai1.kaizencorps.com/g1/###.json",
    rangeLower: 1,
    rangeUpper: 5000
  },
  {
    name: "Fake Shadowy Super Coder #",
    symbol: "SSC",
    uri: "https://shdw-drive.genesysgo.net/8yHTE5Cz3hwcTdghynB2jgLuvKyRgKEz2n5XvSiXQabG/###.json",
    rangeLower: 1,
    rangeUpper: 9999
  },

]

function getRandomFakeNftMetadata(feePayer: PublicKey, pnft: boolean = false, sft: boolean = false) {
  let nft = fakeNftData[Math.floor(Math.random() * fakeNftData.length)];
  let id = Math.floor(Math.random() * (nft.rangeUpper - nft.rangeLower)) + nft.rangeLower;
  let symbol = pnft ? "p" + nft.symbol : nft.symbol;
  symbol = sft ? "s" + nft.symbol : symbol;
  let name = pnft ? "p" + nft.name + id : nft.name + id;
  name = sft ? "s" + nft.name + id : name;
  return {
    name,
    symbol,
    uri: nft.uri.replace("###", id.toString()),
    sellerFeeBasisPoints: 100,
    creators: [
      {
        address: feePayer,
        verified: true,
        share: 100,
      },
    ],
    collection: null,
    uses: null,
  };
}

export async function mintRandomPNFT(feePayer: PublicKey, walletAdapter: WalletAdapter): Promise<string> {
  const metaplex = Metaplex.make(connection).use(walletAdapterIdentity(walletAdapter));
  const datav2 = getRandomFakeNftMetadata(feePayer, true);

  // the metaplex standard ruleset - use this so the pNFTs are more "realistic"
  const ruleSetAddr = new PublicKey('eBJLFYPxJmMGKuFwpDWkzxZeUrad92kZRC5BJLpzyT9');

  const txBuilder = await metaplex.nfts().builders().create({
    ...datav2,
    isMutable: true,
    isCollection: false,
    tokenStandard: TokenStandard.ProgrammableNonFungible,
    ruleSet: ruleSetAddr
  });
  let {signature, confirmResponse} = await metaplex.rpc().sendAndConfirmTransaction(txBuilder);
  if (confirmResponse.value.err) {
    throw new Error('failed to confirm pnft mint transaction');
  }
  const {mintAddress} = txBuilder.getContext();
  console.log(`new pNFT Mint Address: ${mintAddress.toString()}, txid: ${signature}`);
  return signature;
}

// creates a tx that mints a random nft (or SFTs if the numToMint > 1) to the given walletAddress if present, and to the feepayer otherwise
export async function getRandomMintTx(feePayer: PublicKey, destWallet?: PublicKey, numToMint: number = 1): Promise<Transaction> {
  let mint = Keypair.generate();
  const wallet = destWallet || feePayer;
  console.log(`mint: ${mint.publicKey.toString()}`);
  // console.log(`authority wallet pubkey: ${provider.wallet.publicKey.toString()} `);

  let ata = await getAssociatedTokenAddress(mint.publicKey, wallet, true);
  // console.log(`associated token account: ${ata.toString()}`);

  let tokenMetadataPubkey = getMetadataPDA(mint.publicKey);
  let masterEditionPubkey = getMasterEditionPDA(mint.publicKey);

  const datav2 = getRandomFakeNftMetadata(feePayer, false, numToMint > 1);

  let tx = new Transaction().add(
      SystemProgram.createAccount({
        fromPubkey: feePayer,
        newAccountPubkey: mint.publicKey,
        lamports: await getMinimumBalanceForRentExemptMint(connection),
        space: MINT_SIZE,
        programId: TOKEN_PROGRAM_ID,
      }),
      createInitializeMintInstruction(mint.publicKey, 0, feePayer, feePayer),
      createAssociatedTokenAccountInstruction(feePayer, ata, wallet, mint.publicKey),
      createMintToCheckedInstruction(mint.publicKey, ata, feePayer, numToMint, 0),
      createCreateMetadataAccountV3Instruction(
          {
            metadata: tokenMetadataPubkey,
            mint: mint.publicKey,
            mintAuthority: feePayer,
            payer: feePayer,
            updateAuthority: feePayer,
          },
          {
            createMetadataAccountArgsV3: {
              data: datav2,
              isMutable: true,
              collectionDetails: null
            },
          }
      ),
  );
  // NOT sft, make this an NFT
  if (numToMint == 1) {
    tx.add(
       createCreateMasterEditionV3Instruction(
              {
                edition: masterEditionPubkey,
                mint: mint.publicKey,
                updateAuthority: feePayer,
                mintAuthority: feePayer,
                payer: feePayer,
                metadata: tokenMetadataPubkey,
              },
              {
                createMasterEditionArgs: {
                  maxSupply: 0,
                },
              }
          )
    );
  }

  await updateTx(tx, feePayer);
  tx.partialSign(mint);

  return tx;
}
