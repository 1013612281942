import {NATIVE_MINT} from "@solana/spl-token";
import {ITokenBag} from "./connectedWallets";
import {PublicKey} from "@solana/web3.js";
import {tokenRegistry} from "../constants/config";
import {STokenAccount} from "../apis/apiTypes";
import {solanaClient} from "../constants/factory";

export async function getSolTokenBag(walletAddress: PublicKey): Promise<ITokenBag> {
  const solAccount: STokenAccount = await solanaClient.getSolTokenAccount(walletAddress);
  const solTokenInfo = tokenRegistry.getTokenInfo(NATIVE_MINT.toBase58());

  // console.log("Sol bag issue sometimes: ", solTokenInfo, "Tried with native mint: ", NATIVE_MINT.toBase58());
  return {
    name: "Solana",
    symbol: "SOL",
    mint: NATIVE_MINT,
    tokenAccount: solAccount.address,
    imageUrl: solTokenInfo?.logoURI,
    ...solAccount
  };
}
