import { IKeychain } from 'store/keychain';
import {IStache} from 'store/stache';
import {IProfileInfo} from 'store/userProfile';
import {IAttribute, IShop, IListing, ICurrency} from 'store/yardsale';

export interface NonceResponse {
  nonceId: number;
  nonce: string;
}

export type AddFavoriteRes = {};

export type SetProfilePicRes = {};

// same as backend version
export interface IContactDto {
  id: number;
  label: string;
  stacheid: string | null;
  contactType: string;
  addresses: string[];
}

export interface LoginResponse {
  authToken: string;
  // this is the "user" (IStache object from backend),
  stache: IStache;
  // Almost identical to a IProfileInfo type but Silo hates me and this has 'sellerInfo' instead
  profileInfo: {
    id: number;
    stacheid: string;
    keychain: IKeychain;
    numListed: number;
    numSold: number;
    avatarUrl?: string;
    desc?: string;
    twitterHandle?: string;
    discordId?: string;
    telegramId?: string;
    sellerInfo: {
      id: number;
      pda: string;
    }
  }
}

export enum EFeaturedType {
  LISTING = 'LISTING',
  SHOP = 'SHOP',
}

export interface FeaturedDto {
  itemType: EFeaturedType;
  shopDto: IShop;
  listingDto: IListing;
}

export interface ICreateListingResponse {
  collection: null;
  currency: ICurrency;
  desc: string;
  id: number;
  price: string;
  tokenAccount: string;
  mint: string;
  attributes: IAttribute[];
  imageUrl: string;
  verified: boolean;
}

export enum EUpdateListingStatus {
  UPDATED = 'UPDATED',
  DELISTED = 'DELISTED',
  SOLD = 'SOLD',
}

export enum ESEARCH_TYPE {
  LISTINGS = "LISTINGS",
  SHOPS = "SHOPS",
  HUBS = "HUBS"
}
