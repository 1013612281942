import {Connection, PublicKey} from "@solana/web3.js";
import {AuthorizationData, Metadata, PROGRAM_ID as TMETA_PROG_ID,} from "@metaplex-foundation/mpl-token-metadata";
import {Metaplex} from "@metaplex-foundation/js";
import {connection} from "constants/factory";

export const printAccounts = (accounts: Object) => {
  Object.entries(accounts).forEach(account => {
    try {
      console.log(`${account[0]}: ${account[1].toBase58()}`)
    } catch (e) {
      console.log("Invalid account: ", account[0], account[1]);
    }
  })
}

export const fetchNft = async (conn: Connection, mint: PublicKey) => {
  const mplex = new Metaplex(conn);
  return await mplex
      .nfts()
      .findByMint({mintAddress: mint, loadJsonMetadata: true});
};

export const findTokenRecordPDA = async (mint: PublicKey, token: PublicKey) => {
  return PublicKey.findProgramAddress(
      [
        Buffer.from('metadata'),
        TMETA_PROG_ID.toBuffer(),
        mint.toBuffer(),
        Buffer.from('token_record'),
        token.toBuffer(),
      ],
      TMETA_PROG_ID
  );
};

export const prepPnftAccounts = async ({
                                         nftMetadata,
                                         nftMint,
                                         sourceAta,
                                         destAta,
                                         authData = null,
                                       }: {
  nftMetadata?: PublicKey;
  nftMint: PublicKey;
  sourceAta: PublicKey;
  destAta: PublicKey;
  authData?: AuthorizationData | null;
}) => {
  let meta;
  let creators: PublicKey[] = [];
  if (nftMetadata) {
    meta = nftMetadata;
  } else {
    const nft = await fetchNft(connection, nftMint);
    meta = nft.metadataAddress;
    creators = nft.creators.map((c) => c.address);
  }

  const inflatedMeta = await Metadata.fromAccountAddress(connection, meta);
  console.log(`---- Inflated metadata: `, inflatedMeta);
  const ruleSet = inflatedMeta.programmableConfig?.ruleSet;
  const [ownerTokenRecordPda, ownerTokenRecordBump] = await findTokenRecordPDA(nftMint, sourceAta);
  const [destTokenRecordPda, destTokenRecordBump] = await findTokenRecordPDA(
      nftMint,
      destAta
  );

  //retrieve edition PDA
  const mplex = new Metaplex(connection);
  const nftEditionPda = mplex.nfts().pdas().edition({mint: nftMint});

  //have to re-serialize due to anchor limitations
  const authDataSerialized = authData
      ? {
        payload: Object.entries(authData.payload.map).map(([k, v]) => {
          return {name: k, payload: v};
        }),
      }
      : null;

  return {
    meta,
    creators,
    ownerTokenRecordBump,
    ownerTokenRecordPda,
    destTokenRecordBump,
    destTokenRecordPda,
    ruleSet,
    nftEditionPda,
    authDataSerialized,
  };
}

export const narrow = <T> (v: unknown): v is T => true;