import {atom} from 'recoil';
import {IFeatured, IListing} from './types';

export const featuredAtom = atom<IFeatured>({
  key: 'featured',
  default: {
    storefronts: [],
    items: [] as IListing[],
  },
});

export const storefrontAtom = atom<IListing[]>({
  key: 'storefront',
  default: [] as IListing[],
});

export const focusedItemSale = atom<IListing>({
  key: 'focusedItem',
  default: null,
});
