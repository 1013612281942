export const VIEWPORT = {
  MAX_MOBILE_WIDTH: 700,
  MAX_WIDTH_BUTTON: 450,
  MENU_HEIGHT: 80,
  HEADER_HEIGHT: 64
}

export const SPACING = {
  SM: 4,
  MD: 8,
  LG: 10,
  XL: 12,
  XXL: 24,
  XXXL: 32,
  XNFT_POWER_BUTTON_HEIGHT: 65,
  XNFT_POWER_BUTTON_WIDTH: 58
}

// You may notice there are duplicates of colors here. That is on purpose. These are organized not by the colors themselves, 
// but rather their purpose in the app. This allows for quick app-wide changes of color palette
export const COLORS = {
  //Main
  ACCENT_PRIMARY: '#ee9d12',
  ACCENT_ORANGE_DEEP: "#6f271c",
  
  //BACKGROUNDS
  BACKGROUND_APP: "#232223",
  BACKGROUND_MODAL: "#333333",
  BACKGROUND_INPUT: "#191918",
  BACKGROUND_FOCUSED_BLACK: "0A0A0D",
  BACKGROUND_TOAST: "#E1E7EC",

  //Text
  TEXT_WHITE: "#F2F2F2",
  TEXT_DIRTY_WHITE: '#CFDAEC',
  TEXT_GRAY: "#AAAAAA",
  TEXT_TOAST_DEFAULT: "#3887CB",
  PLACEHOLDER_TEXT: '#667085',
  MAIN_TEXT_DARK: '#030E20',
  TEXT_RED: "#DC3545",

  //Containers
  BORDER_BLUR_GRAY: "rgba(242,242, 242, 0.2)",
  BORDER_FOCUSED_WHITE: "#CFDAEC",
  BORDER_CONTAINER: "#4e4e4e",

  // Shades
  LIGHT_GRAY: '#727888',
  MEDIUM_GRAY: "#282929",
  GRAY: '#4e4e4e',
  WHITE: '#FFFFFF',
  BLACK: '#0A0A0D',

  //Gradients
  GRADIENT_ACCENTS_PALE: ["rgba(193, 49, 90, .2)", 'rgba(254, 51, 76, .2)', "rgba(255, 154, 3, .2)"],
  GRADIENT_WARM: ["#8a3513", "#ee9d12"],

  //Extra
  SUCCESS_GREEN: 'green',
  ALERT_RED: "#f23f42",

  SUB_TEXT_DARK: '#737373',
  SUB_TEXT_LIGHT: '#9A9A9A',
  NOT_ACTIVE: '#727888',
  MENU_DARK: '#2b2b2b',
}

export const BRADIUS = {
  NONE: 0,
  SM: 4,
  MD: 8,
  LG: 12,
  XL: 16,
  XXL: 20,
  XXXL: 24,
  ROUND: 50,
}

export const FONT_SIZE = {
  SM: 10,
  MD: 12,
  LG: 14,
  XL: 16,
  XXL: 18,
  XXXL: 24,
  EXTRA_LARGE: 30
}

export const LINE_HEIGHT = {
  SM: 12,
  MD: 15,
  LG: 28,
  XL: 20,
  XXL: 22,
  XXXL: 24,
  EXTRA_LARGE: 28
}

export const SHADOW = 
    {
      SM: {elevation: 2},
      MD: {elevation: 4},
      LG: {elevation: 8},
      XL: {elevation: 12}
    }
