import {useRecoilState, useResetRecoilState} from "recoil";
import {stacheAtom} from "../stache/state";

import {IUpdateableUserInfo, IUserProfile, userProfileAtom} from ".";
import {apiClient} from "apis/server";
import { IActivity } from "store/yardsale";
import { IMessage, MESSAGE_TYPE } from "store/notification";

function useUserProfileActions() {

  const [userProfile, setUserProfile] = useRecoilState(stacheAtom);
  const resetUserProfileState = useResetRecoilState(userProfileAtom);

  async function resetUserProfile(): Promise<boolean> {
    resetUserProfileState();
    return true;
  }

  async function updateUserProfile(userInfo: IUpdateableUserInfo): Promise<IUserProfile> {
    const user = await apiClient.updateUser(userInfo);
    return user;
  }

  async function getUserActivity(userid: number): Promise<IActivity[]> {
    const activities = await apiClient.getUserActivity(userid);
    return activities;
  }

  async function submitFeedback(text: string): Promise<void> {
    const res = await apiClient.submitFeedback(text);
    return res;
  }

  async function postMessageToShop(text: string, name: string): Promise<IMessage> {
    const res = await apiClient.postMessageToKeychain(name, text, MESSAGE_TYPE.SHOP);
    return res;
  }

  return {
    resetUserProfile,
    updateUserProfile,
    getUserActivity,
    submitFeedback,
    postMessageToShop
  }
}

export {useUserProfileActions}

