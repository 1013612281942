import {StandardRpcClient} from '../apis/standardRpc';
import {RpcClient} from '../apis/apiTypes';
import {HeliusRpcClient} from '../apis/heliusRpc';
import {HeliusConnectionWrapper} from '../apis/heliusRpc/HeliusConnectionWrapper';
import {web3} from '@project-serum/anchor';

import {
  ClockProgramId,
  ENABLE_STACHE,
  isHelius,
  KEYCHAIN_DOMAIN,
  KEYCHAIN_LISTING_TREASURY,
  KEYCHAIN_TREASURY, RPC_REQ_PER_SEC,
  RPC_URL,
  StacheProgramId,
  SYSTEM_STACHEID,
  tokenRegistry,
} from './config';
import {PublicKey} from '@solana/web3.js';
import {SolanaClient} from 'apis/solana';
import {findStachePda} from '../programs/stache-utils';
import Bottleneck from 'bottleneck';

const minReqTime = Math.floor(1000 / RPC_REQ_PER_SEC);
console.log(`min request time for throttling: ${minReqTime} ms`);
const throttler = new Bottleneck({
  minTime: minReqTime,
  maxConcurrent: 1,
});

throttler.on("failed", async (error, jobInfo) => {
  const id = jobInfo.options.id;
  console.warn(`Job ${id} failed: ${error}`);

  if (jobInfo.retryCount === 0) { // Here we only retry once
    console.log(`Retrying job ${id} in 25ms!`);
    return 25;
  }
});

export const connection = isHelius ? new HeliusConnectionWrapper(RPC_URL, throttler) : new web3.Connection(RPC_URL, 'confirmed');

export const rpcClient: RpcClient = isHelius
  ? new HeliusRpcClient(connection as HeliusConnectionWrapper, throttler, tokenRegistry)
  : new StandardRpcClient(connection, throttler, tokenRegistry);

export const solanaClient: SolanaClient = new SolanaClient(
  connection,
  throttler,
  KEYCHAIN_DOMAIN,
  new PublicKey(KEYCHAIN_TREASURY),
  ClockProgramId,
  ENABLE_STACHE,
  isHelius
);

export const [SYSTEM_STACHE_PDA] = findStachePda(SYSTEM_STACHEID, KEYCHAIN_DOMAIN, StacheProgramId);
