export const EVENTS = {
  screenView: 'Screen View',
  login: 'Login',
  click: 'Click',
  createdStache: 'Created Stache',
  createdKeychain: 'Created Keychain',
  addedKey: "Added Key",
  removedPendingKey: "Removed Pending Key",
  removedVerifiedKey: "Removed Verified Key",
  linkedWallet: "Linked Wallet",
  createdVault: "Created Vault",
  createdAutomation: "Created Automation",
  mintedNft: "Minted NFT",
  mintedSfts: "Minted SFTs",
  mintedPNft: "Minted pNFT",
  tokenFairy: "Token Fairy",
  stash: "Stashed",
  unstash: "Unstashed"
};
