import {PublicKey} from "@solana/web3.js";

export enum EActionType {
  TRANSFER = 'TRANSFER',
}

export enum ETriggerType {
  BALANCE = 'BALANCE',
}

export interface ITrigger {
  type: ETriggerType;
}

export enum EBalanceCondition {
  // not supported yet
  // EQUAL = 'EQUAL',
  GREATER_THAN = 'GREATER_THAN',
  LESS_THAN = 'LESS_THAN',
}

// this trigger fires when the balance of the token account changes
// it activates (performs the action) if the balance + condition is met
export interface IBalanceTrigger extends ITrigger {
  triggerBalance: number;
  tokenAccount: PublicKey;
  condition: EBalanceCondition;
}

export interface IAction {
  type: EActionType;
}

export interface ITransferAction extends IAction {
  fromTokenAccount: PublicKey;
  toTokenAccount: PublicKey;
  amount: number;
}

export interface IAutomation {
  name: string
  index: number;
  active: boolean;
  paused: boolean;
  numExecutions: number;
  numTriggers: number;
  trigger?: ITrigger;
  action?: IAction;
}

export interface IAutomationType {
  label: string,
  desc: string
}
