import NotAuthedHeader from 'components/Header/NotAuthed/NotAuthedHeader';
import Loader from 'components/Loader/Loader';
import NavFooter from 'components/NavFooter/NavFooter';
import {ENavStack, ERouteBazaar, ERouteHome, ERouteSettings, EUnauthedRoute} from 'constants/routes';
import Bazaar from 'pages/Bazaar/Bazaar';
import ConnectWallet from 'pages/ConnectWallet/ConnectWallet';
import CreateStache from 'pages/CreateStache/CreateStache';
import LinkWallet from 'pages/LinkWallet/LinkWallet';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  redirect,
  Route,
  RouterProvider,
} from 'react-router-dom';
import {useRecoilState} from 'recoil';
import {userProfileAtom} from 'store/userProfile';

import './Router.scss';
import {Home} from 'pages/Home/Home';
import {Settings} from 'pages/Settings/Settings';
import Feedback from 'pages/Feedback/Feedback';
import {FAQ, ViewFAQ} from 'pages/FAQ/FAQ';
import {AssetDetails} from 'pages/AssetDetails/AssetDetails';
import CreateListing from 'pages/CreateListing/CreateListing';
import Myshop from 'pages/Myshop/MyShop';
import Listing from 'pages/Listing/Listing';
import OtherShop from 'pages/Othershop/Othershop';
import {Notifications} from 'pages/Notifications/Notifications';
import {ManageWallets} from 'pages/ManageWallets/ManageWallets';
import {ManagePending} from 'pages/ManageWallets/ManagePending/ManagePending';
import {ManageConnected} from 'pages/ManageWallets/ManageConnected/ManageConnected';
import MyAssets from 'pages/MyAssets/MyAssets';
import {Playground} from 'pages/Playground/Playground';
import { Hub } from 'pages/Hub/Hub';
import MultiListing from 'pages/MultiListing/MultiListing';

const ProtectedRoute = (props: any) => {
  const [userProfile, setUserProfile] = useRecoilState(userProfileAtom);

  if (userProfile === undefined) {
    return <Loader />;
  }
  return userProfile.authed ? <Outlet /> : <Navigate to={'/' + EUnauthedRoute.CONNECT_WALLET} />;
};

const WithNavFooter = () => (
  <div className="with-navfooter">
    <Outlet />
    <NavFooter />
  </div>
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path={'/' + EUnauthedRoute.CONNECT_WALLET} element={<ConnectWallet />} />
      <Route path={'/' + EUnauthedRoute.CREATE_NEW_STACHE} element={<CreateStache />} />
      <Route path={'/' + EUnauthedRoute.LINK_WALLET} element={<LinkWallet />} />
      <Route element={<WithNavFooter />}>
        <Route path={'/' + EUnauthedRoute.BAZAAR} element={<Bazaar />} />
        <Route path={'/' + EUnauthedRoute.HUB + "/:hub"} element={<Hub />} />
      </Route>
      <Route path={'/' + ENavStack.BAZAAR + '/shop/:stacheid'} element={<OtherShop />} />
      <Route path={'/' + ENavStack.BAZAAR + '/shop/:stacheid/listing/:listingid'} element={<Listing />} />
      <Route element={<ProtectedRoute />}>
        <Route element={<WithNavFooter />}>
          <Route path={'/' + ENavStack.HOME} element={<Home />} />
          <Route path={'/' + ENavStack.SETTINGS} element={<Settings />} />
          <Route path={'/' + ENavStack.PLAYGROUND} element={<Playground />} />
        </Route>
        <Route path={'/' + ENavStack.SETTINGS + '/' + ERouteSettings.FEEDBACK} element={<Feedback />} />
        <Route path={'/' + ENavStack.SETTINGS + '/' + ERouteSettings.FAQ} element={<FAQ />} />
        <Route path={'/' + ENavStack.SETTINGS + '/' + ERouteSettings.FAQ + '/view'} element={<ViewFAQ />} />
        <Route path={'/' + ENavStack.SETTINGS + '/' + ERouteSettings.MANAGE_WALLETS} element={<ManageWallets />} />
        <Route
          path={'/' + ENavStack.SETTINGS + '/' + ERouteSettings.MANAGE_WALLETS + '/:address'}
          element={<ManageConnected />}
        />
        <Route
          path={'/' + ENavStack.SETTINGS + '/' + ERouteSettings.MANAGE_PENDING_WALLET}
          element={<ManagePending />}
        />
        <Route path={'/' + ENavStack.HOME + '/' + ERouteHome.ASSETS_DETAILS} element={<AssetDetails />} />
        <Route path={'/' + ENavStack.BAZAAR + '/' + ERouteBazaar.MYSHOP + '/' + ERouteBazaar.LISTING} element={<CreateListing />} />
        <Route path={'/' + ENavStack.BAZAAR + '/' + ERouteBazaar.MYSHOP} element={<Myshop />} />
        <Route
          path={'/' + ENavStack.BAZAAR + '/' + ERouteBazaar.MYSHOP + '/' + ERouteBazaar.MY_ASSETS}
          element={<MyAssets />}
        />
        <Route
          path={'/' + ENavStack.BAZAAR + '/' + ERouteBazaar.MYSHOP + '/' + ERouteBazaar.MY_ASSETS + '/' + ERouteBazaar.MULTILISTING}
          element={<MultiListing />}
        />
        <Route path={'/' + ERouteHome.NOTIFICATIONS} element={<Notifications />} />
      </Route>
    </>
  )
);

export default router;
