import {NATIVE_MINT} from '@solana/spl-token';
import {TextCopy} from 'components/TextCopy/TextCopy';
import UpdatingText from 'components/UpdatingText/UpdatingText';
import Switch from 'components/ui/switch/coin/Switch';
import {SPACING} from 'constants/theme';
import React, {FC} from 'react';
import {ReactElement} from 'react-markdown';
import {ICollectible, NftType} from 'store/connectedWallets';
import {IListing, IListingItem} from 'store/yardsale';
import {formatAddress} from 'utils/string-formatting';
import {ReactComponent as SolanaFm} from 'assets/icons/SolanaFM.svg';
import {SOLANA_NETWORK} from 'constants/config';
import '../AssetDetails.scss';

interface Props {
  item: IListing | ICollectible;
  idx: number,
}

interface SectionProps {
  item: IListingItem | ICollectible
}

const USDC_MINT = 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v';

export const Section: FC<any> = ({item}: SectionProps): ReactElement => {
  const [conversionCurrency, toggleConversionCurrency] = React.useState<'USDC' | 'SOL'>(
    item.currency?.mint === USDC_MINT ? 'SOL' : 'USDC'
  );

  return (
    <div className="sales-description">
      <div className="row">
        <p className="subheader">Name</p>
        <p className="subheader">{item.name}</p>
      </div>
      <div className="row">
        <p className="subheader">Type</p>
        <p className="subheader">{item.nftType}</p>
      </div>
      {item.qty > 1 && 
        <div className="row">
          <p className="subheader">Quantity</p>
          <p className="subheader">{item.qty}</p>
        </div>
      }
      <div className="row">
        <p className="subheader">{item.nftType === NftType.Compressed ? 'Asset ID' : 'Mint'}</p>
        <div className="rt">
          {item.nftType !== NftType.Compressed ? (
            <a
              style={{display: 'flex', alignItems: 'center'}}
              href={`https://solana.fm/address/${item.mint}?cluster=${SOLANA_NETWORK}-solanafmbeta`}
            >
              <SolanaFm />
            </a>
          ) : (
            <a href={`https://xray.helius.xyz/token/${item.mint}`} className="subheader">
              XRAY
            </a>
          )}
          <TextCopy text={formatAddress(item?.mint)} address={item.mint} />
        </div>
      </div>
      {item.collection && (
        <div className="row">
          <p className="subheader">Collection Address</p>
          <div className="rt">
            <a href={`https://solana.fm/address/${item.mint}?cluster=${SOLANA_NETWORK}-solanafmbeta`}>
              <SolanaFm />
            </a>
            <TextCopy text={formatAddress(item?.collection?.mint)} address={item?.collection?.mint} />
          </div>
        </div>
      )}
      {!!item.currency && (
        <>
          {item.currency?.mint !== NATIVE_MINT.toBase58() && (
            <>
              <div className="row">
                <p className="subheader">Listing Currency</p>
                <p className="subheader">{item.currency.symbol}</p>
              </div>
              <div className="row">
                <p className="subheader">Listing Currency Mint</p>
                <div className="rt">
                  <a href={`https://solana.fm/address/${item.currency.mint}?cluster=${SOLANA_NETWORK}-solanafmbeta`}>
                    <SolanaFm />
                  </a>
                  <TextCopy text={formatAddress(item.currency?.mint)} address={item.currency?.mint} />
                </div>
              </div>
            </>
          )}
          <div style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
            <div style={{display: 'flex', justifyContent: 'space-between', width: '160px'}}>
              <p className="subheader" style={{marginRight: SPACING.MD}}>
                {`Price in ${conversionCurrency}`}
              </p>
              {item.currency.mint !== NATIVE_MINT.toBase58() && item.currency.mint !== USDC_MINT && (
                <Switch
                  checked={conversionCurrency === 'SOL'}
                  setChecked={() => toggleConversionCurrency(conversionCurrency === 'USDC' ? 'SOL' : 'USDC')}
                />
              )}
            </div>
            <UpdatingText currency={item.currency} conversionCurrency={conversionCurrency} amount={item.price} />
          </div>
        </>
      )}
      <div className="row">
        <p className="subheader">Description:</p>
      </div>
      <p className="normal">{item.desc}</p>
    </div>
  );
};

export const SaleDescription: FC<any> = ({item, idx}: Props): ReactElement => {
  // IListing
  if (item.hasOwnProperty('items')) {
    return (
      <Section key = {idx} item = {(item as IListing).items[idx]}/>
    );
    // ICollectible
  } else {
    return <Section item={item} />;
  }
};

export default SaleDescription;
