import {PublicKey} from '@solana/web3.js';
import * as anchor from '@project-serum/anchor';
import {BazaarProgramId, KEYCHAIN_DOMAIN, LISTING_DOMAIN, LISTING_DOMAIN_INDEX} from 'constants/config';

export const SELLER = 'seller';
export const LISTING_DOMAIN_SEED_PHRASE = 'listing_domain';
export const LISTING = 'listing';
export const DOMAIN_INDEX_SEED_PHRASE = 'domain_index';

export const findBazaarListingPda = (
  sellerAccount: PublicKey,
  sellerAccountListingIndex: number
): [PublicKey, number] => {
  console.log("Seller account listing index: ", sellerAccountListingIndex);
  console.log("Seller account? ", sellerAccount.toBase58());
  return anchor.web3.PublicKey.findProgramAddressSync(
    [
      Buffer.from(anchor.utils.bytes.utf8.encode(LISTING)),
      sellerAccount.toBuffer(),
      new anchor.BN(sellerAccountListingIndex).toArrayLike(Buffer, 'le', 4),
      // Buffer.from([sellerAccountListingIndex]),
    ],
    BazaarProgramId
  );
};

export const findSellerAccountPda = (keychainPda: PublicKey): [PublicKey, number] => {
  return anchor.web3.PublicKey.findProgramAddressSync(
    [Buffer.from(anchor.utils.bytes.utf8.encode(SELLER)), keychainPda.toBuffer()],
    BazaarProgramId
  );
};

export const findListingDomainPda = (): [PublicKey, number] => {
  return anchor.web3.PublicKey.findProgramAddressSync(
    [
      Buffer.from(anchor.utils.bytes.utf8.encode(LISTING_DOMAIN_SEED_PHRASE)),
      Buffer.from(anchor.utils.bytes.utf8.encode(LISTING_DOMAIN)),
      Buffer.from(anchor.utils.bytes.utf8.encode(DOMAIN_INDEX_SEED_PHRASE)),
      Buffer.from([parseInt(LISTING_DOMAIN_INDEX)]),
    ],
    BazaarProgramId
  );
};
