import React, {FC, ReactElement, useState} from 'react';
import {ENOTI_STATUS} from 'store/toasts';
import {ENavStack, ENestedRoute} from 'constants/routes';
import {COLORS, SPACING, VIEWPORT} from 'constants/theme';
import {EAssetSelectionType, ELISTING_PROGRAM, focusedItemSale, IListing, IListingItem, useStorefrontActions} from 'store/yardsale';
import {ReactComponent as Chevron} from 'assets/icons/ticks/Chevron.svg';
import {ReactComponent as CheckCircle} from 'assets/icons/ticks/CheckCircle.svg';
import {connectedWalletAtom, useConnectedWalletActions} from 'store/connectedWallets';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {stacheAtom} from 'store/stache';
import {keychainAtom} from 'store/keychain';
import {userProfileAtom} from 'store/userProfile';
import useToasts from 'hooks/useToasts';
import useAsyncEffect from 'use-async-effect';
import {useAnalyticsActions} from '../../hooks/useAnalytics';
import {formatAddress, truncateStringWithLastWord} from '../../utils/string-formatting';
import {NATIVE_MINT} from '@solana/spl-token';
import {PublicKey} from '@solana/web3.js';
import {backpackPubkeyPromise, tokenRegistry} from '../../constants/config';
import {solanaClient} from '../../constants/factory';
import {AnchorWallet, useAnchorWallet} from '@solana/wallet-adapter-react';

import {useNavigate, useParams} from 'react-router';
import JSConfetti from 'js-confetti';
import './Listing.scss';
import Loader from 'components/Loader/Loader';
import {TextCopy} from 'components/TextCopy/TextCopy';
import {ColoredButton, ColoredButtonInverse} from 'components/ui/button/Button';
import ModalWrapper from 'components/Modals/ModalWrapper/ModalWrapper';
import {Input} from 'components/ui/input/Input';
import {Avatar} from 'components/Avatar/Avatar';
import {Link} from 'react-router-dom';
import Menu from 'components/ui/menu/Menu';
import SaleConversation from 'components/SaleTabs/SaleConversation/SaleConversation';
import {ScreenContainer} from 'components/ui/container/Container';
import {xNFTAtom} from '../../store/xnft';
import NotAuthedHeader from 'components/Header/NotAuthed/NotAuthedHeader';
import CTAButton from 'components/CTAButton/CTAButton';
import {useWalletAdapter} from 'hooks/useWalletAdapter';
import useSafeBack from '../../hooks/useSafeBack';
import {SaleAttributes} from 'pages/AssetDetails/Attributes/AssetAttributes';
import {SaleDescription} from 'pages/AssetDetails/Description/AssetDescription';
import Quantity from 'components/ui/quantity/Quantity';

type ListingProps = any;
export const Listing: FC<any> = (props: ListingProps): ReactElement => {
  const {listingid, stacheid} = useParams();
  const storefrontActions = useStorefrontActions();
  const navigate = useNavigate();
  const {createToast} = useToasts();
  const anchorWallet: AnchorWallet | undefined = useAnchorWallet();
  const connectedWalletActions = useConnectedWalletActions();
  const {wallet} = useWalletAdapter();
  const {goBack} = useSafeBack();

  const analyticsActions = useAnalyticsActions();
  const connectedWallet = useRecoilValue(connectedWalletAtom);
  const userProfile = useRecoilValue(userProfileAtom);
  const stache = useRecoilValue(stacheAtom);
  const keychain = useRecoilValue(keychainAtom);
  const [isXNFT, setIsXNFT] = useRecoilState(xNFTAtom);
  const [modal, setModal] = useState<boolean | string>(false);
  const [view, setView] = React.useState(ENestedRoute.DESCRIPTION);
  const [conHeight, setConHeight] = React.useState(0);
  const [newPrice, setNewPrice] = React.useState('');
  const [newDesc, setNewDesc] = React.useState('');
  const [newName, setNewName] = React.useState('');
  const [initialLoad, toggleInitialLoad] = React.useState(true);
  const [localItem, setLocalItem] = React.useState<IListing>();
  const setFocusedItem = useSetRecoilState(focusedItemSale);
  const [wasPurchased, togglePurchased] = React.useState(false);
  const [isProcessing, toggleProcessing] = React.useState(false);
  const [isPurchasing, togglePurchasing] = React.useState(false);
  const [messages, setMessages] = React.useState([]);
  const [unitQuantity, setUnitQuantity] = React.useState(0);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [quantity, setQuantity] = React.useState(0);
  const [showMoreDescription, setShowMoreDescription] = React.useState(false);

  console.log(localItem);

  React.useEffect(() => {
    (async function () {
      const _localItem = await storefrontActions.getStorefrontItem(parseInt(listingid));
      if (!!_localItem) {
        // Used for React-Helmet meta tags
        setFocusedItem(_localItem);

        //Used for local UI (and formatted as such)
        setLocalItem({
          ..._localItem,
          currency: {..._localItem.currency, mint: _localItem.currency.mint},
        });
        setNewName(_localItem.name);
        setNewPrice(_localItem?.price.toString());
        setNewDesc(_localItem?.desc);
        await storefrontActions
          .getListingMessages(_localItem.id)
          .then((res) => setMessages(res))
          .catch((e) => console.log('Error getting item msgs', e));
      } else {
        createToast('Error retrieving inventory', ENOTI_STATUS.ERR);
      }
      toggleInitialLoad(false);
    })();

    return () => {
      toggleInitialLoad(true);
      setLocalItem(null);
      setFocusedItem(null);
    };
  }, []);

  useAsyncEffect(async () => {
    const props = {
      stacheid,
      listingid,
    };
    if (userProfile.authed) {
      props['userId'] = userProfile.profileInfo.id;
    }
    analyticsActions.trackScreen('Listing', props);
  }, []);

  useAsyncEffect(async () => {
    if (anchorWallet && !connectedWallet) {
      await connectedWalletActions.connectWallet(anchorWallet);
    }
  }, [anchorWallet]);

  useAsyncEffect(async () => {
    if (anchorWallet && (!solanaClient.initialized || !connectedWallet.address)) {
      await connectedWalletActions.connectWallet(anchorWallet);
    }
  }, []);

  // for backpack / nxnft
  useAsyncEffect(async () => {
    const backpackWalletAddress = await backpackPubkeyPromise;

    // @ts-ignore
    if (backpackWalletAddress && !connectedWallet) {
      console.log('setting xnft & connecting wallet');
      setIsXNFT(true);
      // @ts-ignore
      await connectedWalletActions.connectWallet(window.xnft.solana);
    }
  }, []);

  const RenderComponent = React.useCallback(() => {
    switch (view) {
      case ENestedRoute.DESCRIPTION:
        return <SaleDescription idx = {selectedIndex} item={localItem} />;
      case ENestedRoute.ATTRIBUTES:
        return <SaleAttributes idx = {selectedIndex} item={localItem} />;
      case ENestedRoute.CONVERSATION:
        return (
          <SaleConversation idx = {selectedIndex}
            item={localItem}
            conHeight={conHeight}
            conversation={messages}
            setConversation={setMessages}
          />
        );
    }
  }, [view, localItem, initialLoad, connectedWallet, messages.length, selectedIndex]);

  const imgTag = React.useMemo(() => {
    const img1 = <img src={localItem?.items[0].imageUrl} />;
    const img2 = localItem?.items.length > 1 ? <img src={localItem?.items[1].imageUrl} /> : null;
    const img3 = localItem?.items.length > 2 ? <img src={localItem?.items[2].imageUrl} /> : null;
    const img4 = localItem?.items.length > 3 ? <img src={localItem?.items[3].imageUrl} /> : null;
    const img5 = localItem?.items.length > 4 ? <img src={localItem?.items[4].imageUrl} /> : null;
    return [img1, img2, img3, img4, img5];
  }, [localItem, selectedIndex]);

  const handleImgPress = () => {
    // todo go full screen
  };

  const openConfettiModal = () => setModal('confetti');

  const purchaseNft = async () => {
    togglePurchasing(true);
    setModal('conffeti');
    let res: true | 'Transaction not completed';
    if (localItem.program == ELISTING_PROGRAM.YARDSALE) {
      res = await storefrontActions.purchaseYardsaleListing(localItem, stacheid, openConfettiModal);
    } else {
      // bazaar listing
      const quantity = localItem.listingType === EAssetSelectionType.UNIT ? unitQuantity : 1;
      res = await storefrontActions.purchaseBazaarListing(
        localItem,
        [
          new PublicKey(localItem.items[0].mint),
          !!localItem.items[1]?.mint ? new PublicKey(localItem.items[1]?.mint) : null,
          !!localItem.items[2]?.mint ? new PublicKey(localItem.items[2]?.mint) : null,
          !!localItem.items[3]?.mint ? new PublicKey(localItem.items[3]?.mint) : null,
          !!localItem.items[4]?.mint ? new PublicKey(localItem.items[4]?.mint) : null,
          !!localItem.items[5]?.mint ? new PublicKey(localItem.items[5]?.mint) : null,
        ],
        quantity,
        openConfettiModal
      );
    }
    if (res === true) {
      if (userProfile.authed) {
        await connectedWalletActions.addListingItemsToWallet(localItem);
      }
      togglePurchased(true);
      // @ts-ignore
      new JSConfetti().addConfetti();
      setLocalItem((prev) => ({...prev, soldAt: Date.now()}));
    } else {
      createToast(res, ENOTI_STATUS.ERR);
      setModal(false);
    }
    togglePurchasing(false);
  };

  const updateListing = async () => {
    let res: true | 'Transaction not completed';
    if (localItem.program == ELISTING_PROGRAM.YARDSALE) {
      res = await storefrontActions.updateYardsaleListing(
        localItem,
        newDesc,
        parseFloat(newPrice),
        localItem.currency.decimals
      );
    } else {
      res = await storefrontActions.updateBazaarListing(
        localItem,
        newName,
        newDesc,
        parseFloat(newPrice),
        localItem.currency.decimals
      );
    }

    if (res === true) {
      setLocalItem((prev) => ({
        ...prev,
        price: parseFloat(newPrice),
        desc: newDesc,
        name: newName,
      }));
      setTimeout(() => storefrontActions.getStorefrontItems(keychain.name), 100); // This shit returns old data??????
      setModal(false);
      createToast('Updated listing', ENOTI_STATUS.SUCCESS);
      // todo stub in updated price
    } else {
      createToast(res, ENOTI_STATUS.ERR);
    }
  };

  const delist = async () => {
    setModal(false);
    toggleProcessing(true);
    let res: true | 'Transaction not completed';
    if (localItem.program == ELISTING_PROGRAM.YARDSALE) {
      res = await storefrontActions.delistYardsaleListing(localItem);
    } else {
      res = await storefrontActions.delistBazaarListing(localItem, [
        new PublicKey(localItem.items[0].mint),
        !!localItem.items[1]?.mint ? new PublicKey(localItem.items[1]?.mint) : null,
        !!localItem.items[2]?.mint ? new PublicKey(localItem.items[2]?.mint) : null,
        !!localItem.items[3]?.mint ? new PublicKey(localItem.items[3]?.mint) : null,
        !!localItem.items[4]?.mint ? new PublicKey(localItem.items[4]?.mint) : null,
      ]);
    }
    if (res === true) {
      console.log("Success!: ", res);
      await storefrontActions.getStorefrontItems(keychain.name);
      await connectedWalletActions.addListingItemsToWallet(localItem);
      console.log("STUB:", localItem);
      createToast('Removed listing', ENOTI_STATUS.SUCCESS);
      navigate('/' + ENavStack.HOME);
    } else {
      createToast(res, ENOTI_STATUS.ERR);
      toggleProcessing(false);
    }
  };

  const copyLink = () => {
    const base_url = window.location.origin;
    navigator.clipboard.writeText(`${base_url}/bazaar/shop/${keychain.name}/listing/${localItem?.id}`);
    createToast(`Copied to clipboard`, ENOTI_STATUS.DEFAULT);
  };

  const currencyInfo = React.useMemo(() => {
    if (localItem?.currency) {
      const mintInfo = !!localItem?.currency?.mint ? localItem.currency.mint : NATIVE_MINT.toBase58();
      return tokenRegistry.getTokenInfo(mintInfo);
    }
  }, [localItem?.currency]);

  const isOwnedByUser = React.useMemo(() => {
    return stacheid === (stache?.stacheid ?? keychain.name);
  }, []);

  const getFormattedName = React.useMemo(() => {
    if (!localItem) return "";
    if (localItem.items) return localItem.items[selectedIndex].name;
    return localItem.name
  }, [localItem?.name, selectedIndex]);

  console.log("Local item: ", localItem);

  if (!localItem) return <Loader />;

  return (
    <ScreenContainer>
      {isXNFT ? (
        <div
          className={`go-back touchable`}
          onClick={() => goBack('')}
          style={{
            paddingLeft: SPACING.SM,
          }}
        >
          <Chevron height={20} width={20} />
        </div>
      ) : null}
      {!userProfile.authed ? <NotAuthedHeader /> : null}
      {keychain.walletLinked && isOwnedByUser ? (
        <div
          className="itemsale-menuCon"
          style={{
            paddingTop: isXNFT ? SPACING.XNFT_POWER_BUTTON_HEIGHT - SPACING.XL * 2 : 0,
          }}
        >
          <Menu
            options={[
              {label: 'Copy link', onPress: copyLink},
              {label: 'Update', onPress: () => setModal('update')},
              {label: 'Delist', onPress: () => setModal('delist')},
            ]}
            overlayStyles={{top: '-36px', right: '-16px'}}
          />
        </div>
      ) : null}
      <ModalWrapper
        isOpen={modal === 'confetti'}
        // todo: rolled this back: causes the modal to perpetually stay open
        // onClose={() => (wasPurchased && isPurchasing) ? setModal(false) : {}} // closeModal disabled until purchase completes
          onClose={() => setModal(false)}
      >
        <div className="confetti-modal">
          {wasPurchased ? (
            <>
              {/* <img className="scg" src={require('assets/gifs/NFT_Card.gif')} /> */}
              <img src={require('assets/pngs/PurchaseSuccessful.png')} width={300} />
            </>
          ) : (
            <img src={require('assets/pngs/Processing.png')} width={300} />
          )}
        </div>
      </ModalWrapper>
      <ModalWrapper isOpen={modal === 'delist'} onClose={() => setModal(false)}>
        <div className="delist-modal">
          <p className="subheader mb-xxxl">Are you sure you'd like to remove this from your shop?</p>
          <ColoredButton onClick={delist} className="mb-lg">
            Yes, continue
          </ColoredButton>
          <ColoredButtonInverse onClick={() => setModal(false)}>No, cancel</ColoredButtonInverse>
        </div>
      </ModalWrapper>
      <ModalWrapper isOpen={modal === 'update'} onClose={() => setModal(false)}>
        <div className="update-modal">
          <p className="subheader mb-xxxl">What would you like to update your listing to?</p>
          {localItem.numItems > 1 &&
            <Input
              value={newName}
              onChangeText={setNewName}
              label={`Name`}
              conStyle={{marginBottom: SPACING.XL}}
            />
          }
          <Input
            value={newPrice}
            onChangeText={setNewPrice}
            label={`Price ( ${localItem?.currency?.symbol} )`}
            conStyle={{marginBottom: SPACING.XL}}
          />
          <Input
            value={newDesc}
            onChangeText={setNewDesc}
            label={`Description`}
            multiline
            conStyle={{marginBottom: SPACING.XXXL}}
          />
          {/* <Input value={newDesc} onChangeText={setNewDesc} multiline label="Description" /> */}
          <ColoredButton className="mb-lg" onClick={updateListing}>
            {isProcessing ? 'Updating...' : 'Update listing'}
          </ColoredButton>
          <ColoredButtonInverse onClick={() => setModal(false)}>Cancel</ColoredButtonInverse>
        </div>
      </ModalWrapper>
      <ModalWrapper isOpen={modal === 'buy'} onClose={() => setModal(false)}>
        <div className="update-modal">
          <p className="subheader mb-xxxl">Are you sure you'd like to purchase this?</p>
          <ColoredButton className="mb-lg" onClick={purchaseNft}>
            {isPurchasing ? 'Purchasing...' : 'Yes, continue'}
          </ColoredButton>
          <ColoredButtonInverse onClick={() => setModal(false)}>No, cancel</ColoredButtonInverse>
        </div>
      </ModalWrapper>
      <img className="jesus" src={require('assets/pngs/HolyJesusLight.png')} />
      <div className="item-sale">
        <div className="metadata-wrapper">
          <div className="one-image">{imgTag[selectedIndex]}</div>
          {localItem?.items.length > 1 ? (
            <div className="carousel">
              {(localItem?.items ?? []).map((item: IListingItem, i: number) => (
                <div
                  key={i}
                  onClick={() => setSelectedIndex(i)}
                  className={`img-con ${i === selectedIndex && 'active'}`}
                >
                  <img src={item.imageUrl} />
                  <div className="circle">
                    <p className="normal">{item.qty}</p>
                  </div>
                </div>
              ))}
            </div>
          ) : null}
          <div className="row">
            <p className="subheader">
              {localItem.numItems === 1 ?
                getFormattedName :
                localItem.name ?? `Bundle with ${localItem.numItems} items`
              }
            </p>
            <div className="currency-modal-ref">
              {modal === 'currency' ? (
                <div className="currency-modal">
                  <p className="normal">Currency: {localItem.currency.symbol}</p>
                  <div>
                    <p className="normal">Mint:&nbsp;</p>
                    <TextCopy text={formatAddress(localItem?.currency?.mint)} address={localItem?.currency?.mint} />
                  </div>
                </div>
              ) : null}
              {localItem.price != 0 ?
                <>
                  <img
                    className="touchable"
                    onClick={() => setModal(!modal ? 'currency' : false)}
                    src={currencyInfo?.logoURI ?? localItem?.currency?.icon}
                  />
                  <p className="subheader">{localItem.price}</p>

                </>
                :
                <p className="subheader">FREE</p>

              }
            </div>
          </div>
          {/* <div className="row">
            <p className="subheader">Quantity</p>
            <p className="subheader">{localItem.items[0].qty}</p>
          </div> */}
          {localItem?.listingType === EAssetSelectionType.UNIT ? (
            <Quantity
            maxQuantity={localItem?.unitsLeft}
              item={localItem}
              validityCheck={(int: number) => localItem?.unitsLeft >= int}
              onClick={(num: number) => setUnitQuantity(num)}
              buttonColor={COLORS.ACCENT_PRIMARY}
              quantity={quantity}
              setQuantity={setQuantity}
              />
              ) : null}
          {localItem?.desc && (
            <div className={"description"}>
              <p className='subheader accent'>Description: </p>
              {localItem.desc.length > 110 && !showMoreDescription ?
                <p className = "medium">
                  {localItem.desc.slice(0, 110)}... &nbsp;
                  <b className = "more" onClick={() => setShowMoreDescription(true)}>More</b>
                </p> :
                <p className='medium'>{localItem.desc}</p>
              }
              </div>
          )}
          {localItem.numItems > 1 &&
            <div className="row">
              <p className = "subheader">
                {getFormattedName} x{localItem.items[selectedIndex].qty}
              </p>
            </div>
          }
          {!!localItem?.items[selectedIndex].collection ? (
            <div className="col">
              <p className="medium accent">{localItem?.items[selectedIndex].collection.name}</p>
              {!!localItem?.items[selectedIndex].collection.mint ? <CheckCircle /> : null}
            </div>
          ) : null}
          <CTAButton
            isOwnedByUser={isOwnedByUser}
            wallet={wallet}
            handleBuyPress={purchaseNft}
            initialLoad={initialLoad}
            wasPurchased={wasPurchased}
            isPurchasing={isPurchasing}
            status={localItem?.status}
            soldAt={localItem?.soldAt}
          />
          <Link
            key={localItem?.seller?.stacheid}
            to={'/' + ENavStack.BAZAAR + `/shop/${localItem?.seller?.stacheid}`}
            className={`profileConOuter ${isOwnedByUser ? 'disabled' : ''}`}
          >
            <div className="profileCon">
              <Avatar
                iconUrl={localItem?.seller?.profilePic}
                username={localItem?.seller?.stacheid}
                size={35}
                style={{marginRight: SPACING.LG}}
              />
              <div style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-around'}}>
                <p className="subheader" style={{textAlign: 'start'}}>
                  @{localItem?.seller?.stacheid}
                </p>
                <div style={{display: 'flex'}}>
                  <div style={{display: 'flex'}}>
                    <p style={{marginRight: SPACING.SM, color: COLORS.TEXT_GRAY}}>Items</p>
                    <p style={{color: COLORS.TEXT_DIRTY_WHITE}}>{localItem?.seller?.numListed}</p>
                  </div>
                  <div
                    style={{
                      height: '100%',
                      justifyContent: 'center',
                      margin: '0 ' + SPACING.SM + 'px',
                      alignSelf: 'center',
                      flexDirection: 'row',
                    }}
                  >
                    <div className="orangeCircle" />
                  </div>
                  <div style={{display: 'flex'}}>
                    <p style={{marginRight: SPACING.SM, color: COLORS.TEXT_GRAY}}>Sold</p>
                    <p style={{color: COLORS.TEXT_DIRTY_WHITE}}>{localItem?.seller?.numSold}</p>
                  </div>
                </div>
              </div>
              {!isOwnedByUser ? (
                <div style={{transform: 'rotate(180deg)', display: 'flex'}}>
                  <Chevron />
                </div>
              ) : null}
            </div>
          </Link>
        </div>
        <div className="tabs">
          <div
            className={'touchable ' + (view === ENestedRoute.DESCRIPTION ? 'active' : '')}
            onClick={() => setView(ENestedRoute.DESCRIPTION)}
          >
            <p className="subheader">Details</p>
            <div />
          </div>
          <div
            className={'touchable ' + (view === ENestedRoute.ATTRIBUTES ? 'active' : '')}
            onClick={() => setView(ENestedRoute.ATTRIBUTES)}
          >
            <p className="subheader">Attributes</p>
            <div />
          </div>
          <div
            className={'touchable ' + (view === ENestedRoute.CONVERSATION ? 'active' : '')}
            onClick={() => setView(ENestedRoute.CONVERSATION)}
          >
            <p className="subheader">Conversation</p>
            <div />
          </div>
        </div>
        <RenderComponent />
      </div>
    </ScreenContainer>
  );
};

export default Listing;
