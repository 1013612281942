import {ScreenContainer} from 'components/ui/container/Container';
import {ReactComponent as BackpackIcon} from 'assets/icons/Backpack.svg';
import {ReactComponent as SuitcaseIcon} from 'assets/icons/Suitcase.svg';
import Card from 'components/ui/container/card/Card';
import {useNavigate} from 'react-router';
import {ENavStack, ERouteBazaar} from 'constants/routes';
import {COLORS, SPACING} from 'constants/theme';
import './MultiListing.scss';
import { EAssetSelectionType } from 'store/yardsale';

export default function MultiListing() {
  const navigate = useNavigate();

  const handleNavigate = (screenType: EAssetSelectionType) => {
    navigate('/' + ENavStack.BAZAAR + '/' + ERouteBazaar.MYSHOP + '/' + ERouteBazaar.MY_ASSETS, {state: {screenType}});
  };

  return (
    <ScreenContainer hasBackButton>
      <div className="multilisting-con">
        <p className="banner">Multi-listing</p>
        <p className="medium mb-xxxl" style={{color: COLORS.TEXT_GRAY}}>
          Choose how you want to list multiple assets
        </p>
        <Card onClick={() => handleNavigate(EAssetSelectionType.BUNDLE)} style={{marginBottom: SPACING.XL}}>
          <div className="card-contents">
            <BackpackIcon />
            <div style={{marginLeft: SPACING.XXL}}>
              <p className="subheader">Bundle</p>
              <p className="normal">You are selling all assets in a bundle together for one price.</p>
            </div>
          </div>
        </Card>
        {/* <Card onClick={() => handleNavigate(EAssetSelectionType.UNIT)} style={{marginBottom: SPACING.XL}}>
          <div className="card-contents">
            <SuitcaseIcon />
            <div style={{marginLeft: SPACING.XXL}}>
              <p className="subheader">Unit</p>
              <p className="normal">You are selling multiples of one asset at a specified price per unit.</p>
            </div>
          </div>
        </Card> */}
      </div>
    </ScreenContainer>
  );
}
