import React from 'react'
import "./NavFooter.scss";
import { ENavStack } from 'constants/routes';
import { Link, useLocation } from 'react-router-dom';
import {ReactComponent as HomeIcon} from "assets/icons/HomeIcon.svg";
import {ReactComponent as BazaarIcon} from "assets/icons/Bazaar.svg";
import {ReactComponent as SettingsIcon} from "assets/icons/Gear.svg";
import {ReactComponent as PlaygroundIcon} from "assets/icons/Playground.svg";
import { useRecoilState } from 'recoil';
import { userProfileAtom } from 'store/userProfile';
import { ENABLE_PLAYGROUND_TAB } from 'constants/config';
import { COLORS } from 'constants/theme';

export interface Props {
  [key: string]: any
}

export const NavFooter = (props: Props) => {
  const [userProfile, setUserProfile] = useRecoilState(userProfileAtom);
  const location = useLocation();
  const slug = location.pathname.split("/")[1];

  if (!userProfile.authed) return <></>

  return (
    <div className = "nav-footer">
      <Link className = {slug === ENavStack.HOME ? "selected" : ""} to = {"/" + ENavStack.HOME}>
        <HomeIcon stroke={COLORS.NOT_ACTIVE}/>
        <p className = "normal">Home</p>
      </Link>
      <Link className = {slug === ENavStack.BAZAAR ? "selected" : ""} to = {"/" + ENavStack.BAZAAR}>
        <BazaarIcon stroke={COLORS.NOT_ACTIVE}/>
        <p className = "normal">Bazaar</p>
      </Link>
      {ENABLE_PLAYGROUND_TAB &&
        <Link className = {slug === ENavStack.PLAYGROUND ? "selected" : ""} to = {"/" + ENavStack.PLAYGROUND}>
          <PlaygroundIcon  stroke={COLORS.NOT_ACTIVE}/>
          <p className = "normal">Playground</p>
        </Link>
      }
      <Link className = {slug === ENavStack.SETTINGS ? "selected" : ""} to = {"/" + ENavStack.SETTINGS}>
        <SettingsIcon stroke={COLORS.NOT_ACTIVE}/>
        <p className = "normal">Settings</p>
      </Link>
    </div>
  );
}

export default NavFooter;