//Clients
import {apiClient} from "../../apis/server";

//Constants
//Recoil
import {notificationsAtom, personalMessagesAtom} from "./state";
import {useRecoilState} from "recoil";
import { IMessage, MESSAGE_TYPE } from "./types";


function useNotificationActions() {

  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const [personalMessages, setPersonalMessages] = useRecoilState(personalMessagesAtom);

  const getUnreadMessagesCount = async () => {
    const res = await apiClient.getUnreadMessagesCount();
    if (res?.numUnreadMessages) setNotifications(prev => ({ ...prev, numUnread: res.numUnreadMessages }))
  }

  const getMessages = async () => {
    const res = await apiClient.getMessages();
    let notis: IMessage[] = [];
    let msgs: IMessage[] = [];
    res.forEach((item: IMessage) => {
      if(item.type === MESSAGE_TYPE.SHOP || item.type === MESSAGE_TYPE.LISTING){
        msgs.push(item);
      } else {
        notis.push(item);
      }
    })
    // Sets messages AND reads all of them
    setNotifications(prev => ({ ...prev, notifications: notis, numUnread: 0 }));
    setPersonalMessages(prev => ({ ...prev, messages: msgs, numUnread: 0 }))
  }

  // const getPersonalMessages = async () => {
  //   const res = await apiClient.getPersonalMessages();
  //   // Sets messages AND reads all of them
  //   setPersonalMessages(prev => ({ ...prev, messages: res, numUnread: 0 }))
  // }

  const dismissMessages = async (maxId: number) => {
    const res = await apiClient.dismissMessages(maxId);
    if (res) setNotifications(prev => ({ ...prev, notifications: [], numUnread: 0 }));
  }

  const readMessages = async (maxId: number) => {
    const res = await apiClient.readMessages(maxId);
    if (res) return res;
  }

  const readMessage = async (id: number) => {
    const res = await apiClient.readMessage(id);
    if (res) return res;
  }

  return {
    getUnreadMessagesCount,
    getMessages,
    dismissMessages,
    readMessages,
    readMessage
  }
}

export {useNotificationActions}
