import {useRecoilValue} from "recoil";
import {getRandomMintTx, mintRandomPNFT} from "../programs/nft-utils";
import {stacheAtom} from "../store/stache";
import {useWalletAdapter} from "./useWalletAdapter";
import {EVENTS} from "../constants/events";
import {useAnalyticsActions} from "./useAnalytics";
import {TOKEN_FAIRY_MINTS, TOKEN_FAIRY_SESSION_PDA, TOKEN_FAIRY_VAULT_PDA} from "../constants/config";
import {rpcClient, solanaClient, SYSTEM_STACHE_PDA,} from "../constants/factory";
import {getAssociatedTokenAddressSync} from "@solana/spl-token";
import {keychainAtom} from "store/keychain";
import {useConnectedWalletActions} from "store/connectedWallets";
import {WalletAdapter} from "@metaplex-foundation/js";

function usePlayground() {

  const stache = useRecoilValue(stacheAtom);
  const keychain = useRecoilValue(keychainAtom);
  const {wallet, signAndSendTransaction} = useWalletAdapter();
  const connectedWalletActions = useConnectedWalletActions();
  const analyticsActions = useAnalyticsActions();

  // mints a random nft to the user's stache
  async function mintRandomNft(): Promise<any> {

    const tx = await getRandomMintTx(wallet.publicKey);

    const txid = await signAndSendTransaction(tx);
    console.log(`mint random nft txid: ${txid}`);
    await rpcClient.confirmTransaction(txid);
    analyticsActions.trackEvent(EVENTS.mintedNft, {
      stacheid: stache?.stacheid ?? keychain.name,
    });
  }

  async function mintRandomPnft(): Promise<any> {
    const txid = await mintRandomPNFT(wallet.publicKey, wallet);
    console.log(`minted random pPNFT txid: ${txid}`);
    await rpcClient.confirmTransaction(txid);
    analyticsActions.trackEvent(EVENTS.mintedPNft, {
      stacheid: stache?.stacheid ?? keychain.name,
    });
  }

  async function mintSfts(): Promise<any> {
    const tx = await getRandomMintTx(wallet.publicKey, wallet.publicKey, 10);
    const txid = await signAndSendTransaction(tx);
    console.log(`minted 10 SFTs txid: ${txid}`);
    await rpcClient.confirmTransaction(txid);
    analyticsActions.trackEvent(EVENTS.mintedSfts, {
      stacheid: stache?.stacheid ?? keychain.name,
    });
  }

  // withdraws a random amount of tokens from the session-enabled vault of the 'stache' system stache
  async function withdrawSessionVaultRandomTokens(): Promise<any> {
    const randomMint = TOKEN_FAIRY_MINTS[Math.floor(Math.random() * TOKEN_FAIRY_MINTS.length)];
    const vaultAta = getAssociatedTokenAddressSync(randomMint.mint, TOKEN_FAIRY_VAULT_PDA, true);
    const withdrawAmountUi = Math.floor(Math.random() * 200);
    const withdrawAmount = withdrawAmountUi * (10 ** randomMint.numDecimals);
    const withdrawWallet = wallet.publicKey

    const tx = await solanaClient.getWithdrawFromSessionVaultTx(
        withdrawAmount,
        SYSTEM_STACHE_PDA,
        TOKEN_FAIRY_VAULT_PDA,
        vaultAta,
        TOKEN_FAIRY_SESSION_PDA,
        randomMint.mint,
        withdrawWallet);
    const txid = await signAndSendTransaction(tx);
    // console.log(`withdrew random tokens ${randomMint.mint.toBase58()} from session vault: ${txid}`);
    await rpcClient.confirmTransaction(txid);

    analyticsActions.trackEvent(EVENTS.tokenFairy, {
      stacheid: stache?.stacheid ?? keychain.name,
      mint: randomMint.mint.toString()
    });

    return {
      mint: randomMint.mint,
      name: randomMint.name,
      amount: withdrawAmountUi
    }
  }


  return {
    mintRandomPnft,
    mintRandomNft,
    withdrawSessionVaultRandomTokens,
    mintSfts
  };
}

export {usePlayground};
