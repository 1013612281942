import React, {useState} from 'react';
import useToasts from 'hooks/useToasts';
import {keychainStateAtom, useKeychainActions} from 'store/keychain';
import {useRecoilState, useRecoilValue} from 'recoil';
import {BRADIUS, SPACING} from 'constants/theme';
import {ENOTI_STATUS} from 'store/toasts';
import {ERouteSettings} from 'constants/routes';
import ScreenHeader from 'components/Header/Screen/ScreenHeader';
import {TextCopy} from 'components/TextCopy/TextCopy';
import {useNavigate, useParams} from 'react-router';
import './ManageConnected.scss';
import ModalWrapper from 'components/Modals/ModalWrapper/ModalWrapper';
import {PublicKey} from '@solana/web3.js';
import {connectedWalletAtom} from 'store/connectedWallets';
import {ColoredButton} from 'components/ui/button/Button';
import {ScreenContainer} from 'components/ui/container/Container';

type Props = any;

export const ManageConnected: React.FC<any> = (props: Props) => {
  const {address} = useParams();

  const {createToast} = useToasts();

  const connectedWallet = useRecoilValue(connectedWalletAtom);

  const [showModal, toggleModal] = React.useState(false);
  const [loading, toggleLoading] = React.useState(false);
  const navigate = useNavigate();

  const [modal, setModal] = useState<boolean>(false);

  const [keychainState, setKeychainState] = useRecoilState(keychainStateAtom);

  const handleNavigateToRoute = () => {
    props.navigation.navigate(ERouteSettings.MANAGE_WALLETS);
  };

  const keychainActions = useKeychainActions();

  const removeWallet = async () => {
    toggleLoading(true);
    try {
      if (props.address === connectedWallet.address.toBase58()) {
        createToast("You can't remove the wallet you're connected with!", ENOTI_STATUS.ERR);
        return;
      }
      const res = await keychainActions.removeKey(new PublicKey(address));
      if (res === true) {
        createToast('Wallet removal pending. Go to Pending Actions screen for next steps.', ENOTI_STATUS.DEFAULT);
        toggleModal(false);
        navigate(-1);
      } else createToast(res, ENOTI_STATUS.ERR);
    } catch (e) {
      createToast('Error removing wallet from Stache: ' + e.message, ENOTI_STATUS.ERR);
    } finally {
      toggleLoading(false);
    }
  };

  const showToast = () => createToast('You must first conclude the currently pending wallet action', ENOTI_STATUS.ERR);

  return (
    <ScreenContainer hasBackButton title="Manage Wallet">
      <ModalWrapper isOpen={modal} onClose={() => setModal(false)}>
        <div className="linking-modal sr">
          <p className="subheader mb-xxxl">Are you sure you want to remove this wallet from your account?</p>
          <ColoredButton onClick={removeWallet}>{loading ? 'Removing...' : 'Remove'}</ColoredButton>
        </div>
      </ModalWrapper>
      <div className="manage-connected">
        <TextCopy text={address} />
        <ColoredButton onClick={keychainState.pending_action ? showToast : () => setModal(true)}>
          Unlink wallet
        </ColoredButton>
      </div>
    </ScreenContainer>
  );
};
