import {Idl, Program, Provider} from "@project-serum/anchor";
import {
  BazaarIdl,
  BazaarProgramId,
  KeychainIdl,
  KeychainProgramId,
  SquadsIdl,
  SquadsProgramId,
  StacheIdl,
  StacheProgramId,
  YardsaleIdl,
  YardsaleProgramId
} from "../constants/config";

// the keychain domain to use
export const DOMAIN = 'stache';

// these are constant for the keychain program for pda derivation
export const KEYCHAIN = 'keychain';
export const DOMAIN_STATE = 'domain_state';
export const KEYCHAIN_STATE_SPACE = 'keychain_states';
export const KEYCHAIN_SPACE = 'keychains';
export const KEY_SPACE = 'keys';

// stache constants for pda derivation
export const STACHE = 'stache';
export const BEARD_SPACE = 'beards';
export const VAULT_SPACE = 'vaults';
export const AUTOMATIONS_SPACE = 'automations';

// yardsale constants for pda derivation
export const YARDSALE = 'yardsale';
export const LISTINGS_SPACE = 'listings';


export const getKeychainProgram = (provider: Provider): Program<Idl> => {
  return new Program(KeychainIdl, KeychainProgramId, provider);
};

export const getStacheProgram = (provider: Provider): Program => {
  return new Program(StacheIdl, StacheProgramId, provider);
};

export const getSquadsProgram = (provider: Provider): Program => {
  return new Program(SquadsIdl, SquadsProgramId, provider);
};

export const getYardsaleProgram = (provider: Provider): Program => {
  return new Program(YardsaleIdl, YardsaleProgramId, provider);
};

export const getBazaarProgram = (provider: Provider): Program => {
  return new Program(BazaarIdl, BazaarProgramId, provider);
};



