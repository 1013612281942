import React, {FC, ReactElement} from 'react';
import {ReactComponent as ChevronIcon} from 'assets/icons/ticks/Chevron.svg';
import {ReactComponent as SolanaFm} from 'assets/icons/SolanaFM.svg';
import {ENavStack, ENestedRoute, ERoute, ERouteBazaar, ERouteHome} from 'constants/routes';
import {COLORS, SPACING} from 'constants/theme';
import {connectedWalletAtom, ICollectible, NftType, useConnectedWalletActions} from 'store/connectedWallets';
import {ENABLE_STACHE, SOLANA_NETWORK} from 'constants/config';
import {userProfileAtom, useUserProfileActions} from 'store/userProfile';
import {useRecoilState} from 'recoil';
import useToasts from 'hooks/useToasts';
import {ENOTI_STATUS} from 'store/toasts';
import {PublicKey} from '@solana/web3.js';
import useFindWallets from 'hooks/useFindWallets';
import {formatAddress} from 'utils/string-formatting';
import ScreenHeader from 'components/Header/Screen/ScreenHeader';
import './AssetDetails.scss';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {ColoredButton, ColoredButtonInverse} from 'components/ui/button/Button';
import {NATIVE_MINT} from '@solana/spl-token';
import {TextCopy} from 'components/TextCopy/TextCopy';
import {EAssetSelectionType, IAttribute, IListing} from 'store/yardsale/types';
import ModalWrapper from 'components/Modals/ModalWrapper/ModalWrapper';
import {Input} from 'components/ui/input/Input';
import UpdatingText from 'components/UpdatingText';
import Switch from 'components/ui/switch/coin';
import Menu from 'components/ui/menu/Menu';
import {ScreenContainer} from 'components/ui/container/Container';
import { SaleDescription } from './Description/AssetDescription';
import { SaleAttributes } from './Attributes/AssetAttributes';
import { xNFTAtom } from 'store/xnft';

export enum EAssetDetailsScreenType {
  STASHING = 'STASHING',
  UNSTASHING = 'UNSTASHING',
  LISTING = 'LISTING',
}

interface Props {
  item: any;
}

type IAssetsDetailsProps = any;

export const AssetDetails: React.FC<IAssetsDetailsProps> = (props: IAssetsDetailsProps) => {
  const {
    state: {walletAddress, tokenBag, collectibleMint, screenType, collectible},
  } = useLocation(); //ts-fix
  const navigation = useNavigate();
  const {getTabFromPublicKey, getWalletFromPublicKey} = useFindWallets();

  const userProfileActions = useUserProfileActions();
  const {createToast} = useToasts();
  const connectedWalletActions = useConnectedWalletActions();

  const [userProfile, setUserProfile] = useRecoilState(userProfileAtom);
  const [connectedWallet, setConnectedWallet] = useRecoilState(connectedWalletAtom);
  const [tabView, setTabView] = React.useState(ENestedRoute.DESCRIPTION);
  const [recipient, setRecipient] = React.useState('');
  const [loading, toggleLoading] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const [isXNFT, setIsXNFT] = useRecoilState(xNFTAtom);

  const handleNavigateToListing = () => {
    console.log("Collectible: ", collectible);
    navigation('/' + ENavStack.BAZAAR + '/' + ERouteBazaar.MYSHOP + '/' + ERouteBazaar.LISTING, {
      state: {
        assetSelectionType: EAssetSelectionType.INDIVIDUAL,
        collectibleMints: JSON.stringify([collectibleMint]),
        //@ts-ignore
        tokenTypes: JSON.stringify([collectible.nftType]),
        quantities: JSON.stringify([1]),
        names: JSON.stringify([collectible.name]),
        //@ts-ignore
        decimals: JSON.stringify([0]),
        focusedWalletAddress: connectedWallet.address.toBase58(),
      },
    });
  }

  const buttonLabel = React.useMemo(() => {
    switch (screenType) {
      case EAssetDetailsScreenType.LISTING:
        return 'List this item';
      case EAssetDetailsScreenType.STASHING:
        return 'Stash';
      case EAssetDetailsScreenType.UNSTASHING:
        return 'Unstash';
    }
  }, []);

  const handleAvatarPress = async () => {
    const res = await userProfileActions.updateUserProfile({
      id: userProfile.profileInfo.id,
      avatarUrl: collectible.imageUrl,
    });
    if (!!res) {
      setUserProfile((prev) => ({...prev, profileInfo: {...prev.profileInfo, avatarUrl: collectible?.imageUrl}}));
      createToast('Updated avatar picture', ENOTI_STATUS.SUCCESS);
    } else {
      createToast('Error updating avatar picture', ENOTI_STATUS.ERR);
    }
  };

  const handleSend = async () => {
    toggleLoading(true);
    let toAddress: PublicKey;
    try {
      toAddress = new PublicKey(recipient);
      await connectedWalletActions.sendNft(collectible, toAddress);
      createToast('Sent!', ENOTI_STATUS.SUCCESS);
      setModal(false);
    } catch (e) {
      console.log('problem sending', e);
      createToast('Invalid wallet address', ENOTI_STATUS.ERR);
    }
  };

  const walletDisplay = React.useCallback(
    (str: string) => {
      return str.length > 20 ? formatAddress(str) : str;
    },
    [walletAddress]
  );

  const RenderView = React.useCallback(() => {
    return tabView === ENestedRoute.DESCRIPTION ? (
      <SaleDescription {...props} item={collectible} />
    ) : (
      <SaleAttributes {...props} item={collectible} />
    );
  }, [tabView]);

  return (
    <ScreenContainer
      hasBackButton={isXNFT}
      menu={
        <Menu
          options={[
            {label: 'Set as profile', onPress: handleAvatarPress},
            walletAddress === connectedWallet.address.toBase58() && {
              label: 'Create Listing',
              onPress: handleNavigateToListing,
            },
          ]}
        />
      }
    >
      <ModalWrapper isOpen={modal} onClose={() => setModal(false)}>
        <div className="sendnft-modal">
          <p className="subheader mb-xxxl">Enter the recipient's address below</p>
          <Input value={recipient} onChangeText={setRecipient} />
          <ColoredButton onClick={handleSend} className="mb-lg">
            Send NFT
          </ColoredButton>
          <ColoredButtonInverse onClick={() => setModal(false)}>Cancel</ColoredButtonInverse>
        </div>
      </ModalWrapper>
      <div className="asset-details">
        <div className="img-container">
          <img src={collectible.imageUrl} />
        </div>
        <div className="content">
          <div className="row">
            <p className="medium">{walletDisplay(getTabFromPublicKey(new PublicKey(walletAddress)))}</p>
            <p className="medium">Balance</p>
          </div>
          <div className="row">
            <p className="subheader">
              {!!tokenBag && tokenBag.name}
              {!!collectible && collectible.name}
            </p>
            <p className="subheader">
              {!!tokenBag && tokenBag.ammountUiString}
              {!!collectible && collectible.qty}
            </p>
          </div>
          {screenType === EAssetDetailsScreenType.LISTING && (
            <ColoredButton onClick={handleNavigateToListing} icon={<ChevronIcon />}>
              {buttonLabel}
            </ColoredButton>
          )}
          {screenType === EAssetDetailsScreenType.STASHING &&
            !!collectible &&
            walletAddress === connectedWallet?.address?.toBase58() && (
              <ColoredButton onClick={() => setModal(true)} icon={<ChevronIcon />}>
                Send
              </ColoredButton>
            )}
          <div className="tabs">
            <div
              className={'touchable ' + (tabView === ENestedRoute.DESCRIPTION ? 'active' : '')}
              onClick={() => setTabView(ENestedRoute.DESCRIPTION)}
            >
              <p className="subheader">Details</p>
              <div />
            </div>
            <div
              className={'touchable ' + (tabView === ENestedRoute.ATTRIBUTES ? 'active' : '')}
              onClick={() => setTabView(ENestedRoute.ATTRIBUTES)}
            >
              <p className="subheader">Attributes</p>
              <div />
            </div>
          </div>
          <RenderView />
        </div>
      </div>
    </ScreenContainer>
  );
};
