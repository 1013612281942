import {NATIVE_MINT} from '@solana/spl-token';
import axios from 'axios';
import {COLORS, SPACING} from 'constants/theme';
import React from 'react';
import {ICurrency} from 'store/yardsale';
import {ensureIsString} from 'utils/string-formatting';

interface Props {
  currency: ICurrency;
  conversionCurrency: 'USDC' | 'SOL';
  amount: number;
}

export default function ({currency, conversionCurrency, amount}: Props) {
  const [val, setVal] = React.useState('');

  React.useEffect(() => {
    callJupiter();
    let interval: NodeJS.Timer;
    if (currency) {
      interval = setInterval(() => {
        callJupiter();
      }, 5000);
    }
    return () => clearInterval(interval);
  }, [currency, conversionCurrency]);

  async function callJupiter() {
    const url = `https://price.jup.ag/v4/price?ids=${currency.mint}${
      conversionCurrency === 'SOL' ? `&vsToken=${NATIVE_MINT.toBase58()}` : ''
    }`;
    axios
      .get(url)
      .then((res) => {
        const price: number = res?.data?.data[ensureIsString(currency.mint)]?.price;
        setVal(!!price ? (price * amount).toFixed(2).toString() : 'Unknown');
      })
      .catch((e) => console.log('ERrrr', e));
  }

  return (
    <p className="subheader" style={{color: COLORS.TEXT_DIRTY_WHITE, marginRight: SPACING.MD}}>
      {val ?? ''}
    </p>
  );
}
