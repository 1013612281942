import {ENV, TokenInfo, TokenListProvider} from "@solana/spl-token-registry";

// don't pull these mints from the token registry cause they're old or garbage
const SKIP_MINTS: string[] = ['foodQJAztMzX1DKpLaiounNe2BDMds5RNuPC6jsNrDG']; // old star atlas food

export class TokenRegistry {

  private tokenMap = new Map<string, TokenInfo>();
  private isInitialized = false;

  constructor(isDevnet: boolean) {

    const chainId = isDevnet ? ENV.Devnet : ENV.MainnetBeta;

    new TokenListProvider().resolve().then(tokens => {
      const tokenList = tokens.filterByChainId(chainId).getList();
      for (const token of tokenList) {
        if (token.address === "NGK3iHqqQkyRZUj4uhJDQqEyKKcZ7mdawWpqwMffM3s") { // YAKU is big dumb
          this.tokenMap.set(token.address, {
            ...token,
            decimals: 9
          })
        } else {
          if (!SKIP_MINTS.includes(token.address)) {
            this.tokenMap.set(token.address, token);
          }
        }
      }
    });
    this.isInitialized = true;
  }

  getTokenInfo(address: string): TokenInfo | undefined {
    if (this.isInitialized) {
      return this.tokenMap.get(address);
    }
  }
}
