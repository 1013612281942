import {ICollectible, ICollection, ITokenBag, NftType} from "store/connectedWallets/types";
import {chunkArray, hasAnySubstring, hasSubstring, selectString} from "../../utils/misc";
import {StandardRpcClient} from "../standardRpc";
import {Assets, STokenAccount} from "../apiTypes";
import axios from "axios";
import {PublicKey, Transaction} from "@solana/web3.js";
import {HeliusConnectionWrapper} from "./HeliusConnectionWrapper";
import {ReadApiAsset, ReadApiAssetList, WalletAdapter} from "@metaplex-foundation/js";
import {createTransferAssetTx} from "./compression-utils";
import {TokenRegistry} from "../../utils/tokenregistry";
import {getFirstVerifiedCreator} from "./helpers";
import Bottleneck from "bottleneck";


export class HeliusRpcClient extends StandardRpcClient {

  private apiKey: string;
  private heliusConnection: HeliusConnectionWrapper;

  constructor(connection: HeliusConnectionWrapper, throttler: Bottleneck, tokenRegistry: TokenRegistry) {
    super(connection, throttler, tokenRegistry);
    this.heliusConnection = connection;
    this.apiKey = connection.rpcEndpoint.substring(connection.rpcEndpoint.indexOf('?'));
  }

  isVerified(mintInfo) {
    return !!mintInfo.onChainMetadata.metadata?.collection?.verified;
  }


  async fetchAssetMetaData(fetchedTokenAccounts: STokenAccount[], blacklist: string[], ogCollections: Map<string, ICollection>): Promise<Assets> {

    // separate into collectibles and tokens
    const collectibles: ICollectible[] = [];
    const tokens: ITokenBag[] = [];

    // first filter out the ones that are in the token registry

    const tokenAccounts = fetchedTokenAccounts.filter((t: STokenAccount) => {
      const tokenBag = this.createTokenBag(t);
      if (tokenBag) {
        tokens.push(tokenBag);
        return false;
      } else {
        return true;
      }
    });

    let tokenMints = tokenAccounts.map((t: any) => {
      return t.mint;
    });

    // break up into chunks of 100
    const chunks = chunkArray(tokenMints, 100);

    const headerConfig = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const url = 'https://api.helius.xyz/v0/token-metadata' + this.apiKey;

    // store a map of mint address to mint info
    const mintInfos = new Map<string, any>();
    for (let chunk of chunks) {
      let resp = await this.throttler.schedule(() => axios.post(url, {
          mintAccounts: chunk,
          includeOffChain: true,
          disableCache: false
        }, headerConfig));

      resp.data.forEach((mintInfo: any) => {
        mintInfos.set(mintInfo.account, mintInfo);
        // console.log('mintInfo: ', mintInfo);
      });
    }

    // map of collection infos to be populated by collections we come across
    const collectionInfos = new Map<string, ICollection>();

    for (let t of tokenAccounts) {
      const amount = t.amount;
      if (amount === 0 || t.amountUi === 0) {
        // empty token accounts
        continue;
      }
      const mint = t.mint;
      const mintInfo = mintInfos.get(mint.toBase58());
      if (mintInfo) {

        // if there's any errors, skip
        if (mintInfo.onChainMetadata.error || mintInfo.offChainMetadata.error) {
          console.log('skipping cause error in on or off chain metadata: ' + mint);
          console.log('onchain error: ', mintInfo.onChainMetadata.error);
          console.log('offchain error: ', mintInfo.offChainMetadata.error);
          continue;
        }

        let imageUrl = selectString([mintInfo.offChainMetadata.metadata?.image, mintInfo.legacyMetadata?.logoURI]);
        if (!imageUrl) {
          console.log('skipping, no image: ' + mint);
          continue;
        }

        // get all the urls and see if any are in the blacklist
        const urls = [imageUrl];
        if (mintInfo.onChainMetadata.metadata?.data?.uri) {
          urls.push(mintInfo.onChainMetadata.metadata?.data?.uri);
        }
        if (mintInfo.offChainMetadata.uri) {
          urls.push(mintInfo.offChainMetadata.uri);
        }
        if (mintInfo.offChainMetadata.metadata?.external_url) {
          urls.push(mintInfo.offChainMetadata.metadata?.external_url);
        }

        if (hasAnySubstring(urls, blacklist)) {
          console.log('skipping cause in blacklist: ' + mint);
          continue;
        }

        // let externalUrl = selectString([mintInfo.onChainMetadata.metadata?.data?.externalUrl, mintInfo.offChainMetadata.metadata?.externalUrl, mintInfo.legacyMetadata?.externalUrl]);
        let name = selectString([mintInfo.onChainMetadata.metadata?.data?.name, mintInfo.offChainMetadata.metadata?.name, mintInfo.legacyMetadata?.name]);
        let symbol = selectString([mintInfo.onChainMetadata.metadata?.data?.symbol, mintInfo.offChainMetadata.metadata?.symbol, mintInfo.legacyMetadata?.symbol]);
        let tokenStandard = mintInfo.onChainMetadata.metadata?.tokenStandard ?? null;
        let nftType = tokenStandard === 'ProgrammableNonFungible' ? NftType.Programmable : NftType.Standard;

        // there are cases where an nft has standard 1 (fungible), but it's still a collectible
        if (t.decimals === 0) {
          let attributes = mintInfo.offChainMetadata.metadata?.attributes;
          let desc = mintInfo.offChainMetadata.metadata?.description;

          // fetch collection info if we need to
          let collection: ICollection = null;
          let verified = this.isVerified(mintInfo);
          if (verified) {
            const collectionMint = mintInfo.onChainMetadata.metadata.collection.key;
            if (collectionMint) {
              collection = collectionInfos.get(collectionMint);
              if (!collection) {
                const mintAddress = new PublicKey(collectionMint);
                // then fetch the collection info
                try {
                  const fetchedCollection = await this.throttler.schedule(() => this.metaplex.nfts().findByMint({mintAddress}));
                  if (fetchedCollection) {
                    collection = {
                      name: fetchedCollection.name,
                      symbol: fetchedCollection.symbol,
                      mint: collectionMint
                    }
                    collectionInfos.set(collectionMint, collection);
                  }
                } catch (err) {
                  console.log(`error fetching collection metadata for mint: ${mintAddress.toBase58()}. skipping...`, err);
                }
              }
            }
          } else {
            // check if it's a legacy collection (og collection from db using first verified creator)
            const firstVerifiedCreator = getFirstVerifiedCreator(mintInfo);
            if (firstVerifiedCreator) {
              collection = ogCollections.get(firstVerifiedCreator);
              if (collection) {
                verified = true;
              }
            }
          }

          if (t.amountUi === 1) {
            collectibles.push({
              mint: mint.toBase58(),
              name,
              imageUrl,
              attributes,
              desc,
              tokenAccount: t.address.toBase58(),
              verified,
              collection,
              nftType,
              ruleset: null,
              compression: null,
              qty: 1
            });
          } else {
            console.log("found sft: ", t);
              collectibles.push({
                mint: mint.toBase58(),
                name,
                imageUrl,
                attributes,
                desc,
                tokenAccount: t.address.toBase58(),
              verified,
              collection,
                nftType: NftType.Semi,
                ruleset: null,
                compression: null,
                qty: t.amountUi
              });
            }
        } else {
          console.log(`found fungible asset: ${name}, standard: ${tokenStandard}, amount: ${t.amountUi}, decimals: ${t.decimals}`);
          tokens.push({
            mint,
            name,
            symbol,
            amount: t.amount,
            decimals: t.decimals,
            amountUi: t.amountUi,
            amountUiString: t.amountUiString,
            tokenAccount: t.address,
            imageUrl
          });
        }

      } else {
        console.log('no mint info for: ', mint.toBase58());
      }
    }

    // Filter out phishing scams in tokenbags. nfts filtered out above
    const whitelistedTokens = tokens.filter(token => !hasSubstring(token.imageUrl, blacklist));

    return {
      tokens: whitelistedTokens,
      collectibles
    }
  }

  getCollectionId(asset: ReadApiAsset) {
    if (asset.grouping.length > 0) {
      for (let i = 0; i < asset.grouping.length; i++) {
        if (asset.grouping[i].group_key === 'collection') {
          return asset.grouping[i].group_value;
        }
      }
    }
    return null;
  }

  async transferCompressed(walletAdapter: WalletAdapter, assetId: PublicKey, fromAddress: PublicKey, toAddress: PublicKey): Promise<Transaction> {
    console.log('transferring compressed nft. assetId: ', assetId);


    /* metaplex's way doesn't work for some reason (see the compressed-nfts project, it's the same fuckin code)
    const metaplex = Metaplex.make(this.connection).use(walletAdapterIdentity(walletAdapter));
    const asset = await this.heliusConnection.getAsset(assetId);
    const nft = toMetadataFromReadApiAsset(asset);
    console.log("metaplex asset: ", nft);
    const txBuilder = metaplex.nfts().builders().transfer({
      nftOrSft: nft,
      fromOwner: walletAdapter.publicKey,
      toOwner: toAddress,
    });
     */

    return createTransferAssetTx(this.heliusConnection, fromAddress, toAddress, assetId.toBase58());
  }

  async fetchCompressedAssets(walletAddress: PublicKey, blacklist: string[]): Promise<ICollectible[]> {
    const assetList: ReadApiAssetList = await this.heliusConnection.getAssetsByOwner({ownerAddress: walletAddress.toBase58()});

    const collectionIds = new Map<string, Promise<ReadApiAsset>>();
    const compressedAssets: ReadApiAsset[] = [];
    for (let i = 0; i < assetList.items.length; i++) {
      const asset = assetList.items[i];
      if (asset.compression.compressed) {
        const collectionId = this.getCollectionId(asset);
        if (collectionId && !collectionIds.has(collectionId)) {
          collectionIds.set(collectionId, this.heliusConnection.getAsset(collectionId));
        }
        compressedAssets.push(asset);
      }
    }

    console.log(`found ${compressedAssets.length} compressed assets`);

    const collectibles = [];

    for (let i = 0; i < compressedAssets.length; i++) {
      const asset: ReadApiAsset = compressedAssets[i];
      const content = asset.content;
      const metadata = asset.content.metadata;
      let imageUrl = '';
      // @ts-ignore
      if ('files' in content && content.files.length > 0) {
        imageUrl = selectString([content.files[0]['uri'], content.files[0]['cdn_uri']]);
      }

      let verified = asset.grouping.length > 0 ? !!asset.grouping[0].group_value : false;
      const collectionId = this.getCollectionId(asset);
      let collection: ICollection = null;
      if (collectionId) {
        const collectionAsset: ReadApiAsset = await collectionIds.get(collectionId);
        if (collectionAsset) {
          console.log('populating collection info for: ', collectionId, ' ', collectionAsset.content.metadata.name, ' ', collectionAsset.content.metadata.symbol);
          collection = {
            name: collectionAsset.content.metadata.name,
            symbol: collectionAsset.content.metadata.symbol,
            mint: collectionId
          }
        }
        verified = true;
      }
      collectibles.push({
        mint: asset.id,
        name: metadata.name,
        symbol: metadata.symbol,
        imageUrl,
        nftType: NftType.Compressed,
        attributes: metadata.attributes ?? [],
        desc: metadata.description,
        tokenAccount: null,
        collection,
        verified,
        ruleset: null,
        compression: asset.compression
      });
    }

    return collectibles;
  }

  // this call uses the DAS model to fetch assets, which includes compressed nft data
  async fetchAssets(walletAddress: PublicKey, blacklist: string[], ogCollections: Map<string, ICollection>): Promise<Assets> {
    const tokenAccounts: STokenAccount[] = await this.getTokenAccounts(walletAddress);
    const standardFetchedAssetsPromise = this.fetchAssetMetaData(tokenAccounts, blacklist, ogCollections);
    const compressedAssets = await this.fetchCompressedAssets(walletAddress, blacklist);
    const assets = await standardFetchedAssetsPromise;
    if (compressedAssets.length > 0) {
      assets.collectibles = assets.collectibles.concat(compressedAssets);
    }
    return assets;
  }


}

