import React, {BaseSyntheticEvent} from 'react';
import './Quantity.scss';

type Props = {
  onClick: (num: number, item?: any) => void;
  maxQuantity: number;
  validityCheck: (int?: number) => boolean;
  item: any;
  quantity: number;
  setQuantity: React.Dispatch<React.SetStateAction<number>>;
  conStyle?: any;
  buttonColor?: string;
};

export default function Quantity({onClick, maxQuantity, validityCheck, quantity, setQuantity, item, conStyle, buttonColor}: Props) {

  const handleQuantityPress = (num: 1 | -1) => {
    if (num + quantity >= 0 && num + quantity <= maxQuantity && validityCheck()) {
      onClick(quantity + num, item);
      setQuantity((prev) => prev + num);
    }
  };

  return (
    <div className="quantity-ticker" style={conStyle}>
      <div
        onClick={() => handleQuantityPress(-1)}
        className="ticker"
        style={{backgroundColor: buttonColor ?? 'transparent'}}
      >
        <p>-</p>
      </div>
      <input
        value={quantity.toString()}
        onChange={(val: BaseSyntheticEvent) => {
          try {
            if (val.target.value === '') {
              onClick(0, item);
              setQuantity(0);
            }
            const int: number = parseInt(val.target.value);
            if (int <= maxQuantity && validityCheck(int)) {
              onClick(int, item);
              setQuantity(int);
            }
          } catch (e) {
            // no op
          }
        }}
        className="ticker-input"
      />
      <div
        onClick={() => handleQuantityPress(1)}
        className="ticker"
        style={{backgroundColor: buttonColor ?? 'transparent'}}
      >
        <p>+</p>
      </div>
    </div>
  );
}
