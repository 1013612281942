import {useCallback} from 'react';
import {useRecoilState} from 'recoil';
import {IAddressBook, IContactPayload} from "./types";
import { addressBookAtom } from './state';
import { apiClient } from 'apis/server';

function useAddressBookActions() {

  const [, setAddressBook] = useRecoilState<IAddressBook>(addressBookAtom);

  const setContact = async (contact: IContactPayload) => {
    await apiClient.setContact(contact)
      .then(contacts => setAddressBook({contacts}))      
      .catch(e => console.log("errr deleting", e))
  }

  // Used in testing
  const getPublicContacts = (stacheId: number) => {
    apiClient.getPublicContacts(stacheId)
      .then(console.log)
  }

  const deleteContact = async (contactId: number) => {
    await apiClient.deleteContact(contactId)
      .then((contacts) => setAddressBook({ contacts })) 
      .catch(e => console.log("errr deleting", e))
  }

  const updateContact = async (contactId: number, label: string) => {
    await apiClient.updateContact(contactId, label)
      .then((contacts) => setAddressBook({ contacts })) 
      .catch(e => console.log("errr updating", e))
  }

  const getAllContacts = useCallback(async () => {
    return apiClient.getAllContacts()
      .then(contacts => setAddressBook({ contacts }))
  }, [setAddressBook])
  
  return {
    setContact,
    deleteContact,
    getAllContacts,
    updateContact,
    getPublicContacts,
  }
}

export {useAddressBookActions}
