import {atom, selector} from 'recoil';
import jwt_decode from 'jwt-decode';
import {IUserProfile} from './types';


export const userProfileAtom = atom<IUserProfile>({
  key: 'userProfile',
  default: {
    jwt: localStorage.getItem('jwt'),
    profileInfo: {
      id: undefined,
      keychain: undefined,
      stacheid: '',
      sellerAccountPda: undefined,
      numListed: 0,
      numSold: 0,
      avatarUrl: '',
      desc: '',
      twitterHandle: '',
      discordId: '',
      telegramId: '',
    },
    authed: false,
  }
});


export const isValidToken = selector<boolean>({
  key: 'validToken',
  get: async ({get}) => {
    const userProfileState: IUserProfile = get(userProfileAtom);
    if (userProfileState.jwt === null) return false;

    const decoded: any = jwt_decode(userProfileState.jwt);
    const expiration = decoded.exp * 1000; // gets returned in seconds, convert to millis for date comparisons
    if (decoded && 'exp' in decoded) return new Date() < new Date(expiration);
    else return false;
  }
})
