
// If the user refreshes the screen, the backstack gets cleared
// and a normal navigation.goBack() will have nothing to do
// this checks for that scenario, and adds a backup route

import { useNavigate } from "react-router";

export const useSafeBack = () => {

    const navigate = useNavigate();

    const goBack = (stack: string, params?: { screen: string, params?: any }) => {
      navigate(-1);  
    }

  return {
    goBack
  };
};


export default useSafeBack;