import './Button.scss';

interface Props {
  children: any;
  onClick?: () => void;
  className?: string;
  label?: string;
  icon?: React.ReactNode;
  backIcon?: boolean;
  style?: any;
  disabled?: boolean;
  backgroundColor?: string;
  borderColor?: string;
  textColor?: string;
}

export const ColoredButton: React.FC<Props> = ({
  onClick,
  className,
  children: label,
  icon,
  disabled = false,
  style = {},
}) => (
  <button className={'colored-button ' + className} onClick={onClick} disabled={disabled} style={style}>
    <p className="normal">{label}</p>
    {!!icon && icon}
  </button>
);

export const ColoredButtonInverse: React.FC<Props> = ({
  onClick,
  className,
  children: label,
  icon,
  disabled = false,
  style = {},
}) => (
  <button
    className={'colored-button-inverse ' + (!!className && className)}
    onClick={onClick}
    disabled={disabled}
    style={style}
  >
    <p className="normal">{label}</p>
    {!!icon && icon}
  </button>
);

//   return (
//       <TouchableOpacity
//           onPress={onPress}
//           style={[styles.inverseOuter, style]}
//           disabled={disabled}
//       >
//         <LinearGradient
//             colors={[COLORS.ACCENT_PRIMARY, COLORS.ACCENT_PRIMARY]}
//             start={{x: 0, y: 0.5}}
//             end={{x: 1, y: 0.5}}
//             style={styles.inverseLin}
//         >
//           <View style={[styles.inverseInner, {background-color: background-color ?? COLORS.BACKGROUND_APP}]}>
//             <NormalText style={{
//                 color: textColor ?? COLORS.WHITE,
//                 marginRight: !!icon ? SPACING.MD : 0
//               }}
//             >
//               {label}
//             </NormalText>
//             {!!icon && icon}
//           </View>
//         </LinearGradient>
//       </TouchableOpacity>
//   )
// }
