import { ReactNode, Ref, SyntheticEvent, useEffect, useRef, useState } from 'react';
import './ContextMenu.scss';

export interface Props {
  [key: string]: any
  menu: ReactNode,
  children: ReactNode,
  leftClick?: boolean,
  disable?: boolean,
  ref?: React.MutableRefObject <any>
}

export const Context = ({menu, children, leftClick = false, disable = false}: Props) => {
  const [open, setOpen] = useState(false);
  const triggerRef = useRef<HTMLDivElement>();
  const [pos, setPos] = useState({x: 0, y: 0});

  const handleListener = (e: MouseEvent) => {
    if (!triggerRef || !triggerRef.current) return;
    const pos = triggerRef.current.getBoundingClientRect();
    if (e.clientX >= pos.left && e.clientX <= pos.right && e.clientY >= pos.top && e.clientY <= pos.bottom) {
      e.preventDefault();
      setPos({x: e.clientX, y: e.clientY});
      setOpen(true);
    }
    else setOpen(false);
  }

  const close = () => {
    setOpen(false);
  }

  useEffect(() => {
    if (leftClick) {
      window.addEventListener('click', handleListener)
      window.addEventListener('wheel', close);
      return () => {
        window.removeEventListener("click", handleListener);
        window.removeEventListener('wheel', close);
      }
    }
    else {
      window.addEventListener("click", close);
      window.addEventListener('contextmenu', handleListener)
      window.addEventListener('wheel', close);
      return () => {
        window.removeEventListener('contextmenu', handleListener)
        window.removeEventListener("click", close);
        window.removeEventListener('wheel', close);
      }
    }
  })

  return (
    <>
      <div ref = {triggerRef} className = "context-menu-trigger">
        {children}
      </div>
      <div className = "context-menu-wrapper" style = {{top: pos.y, left: pos.x}}>
        {open && <div className = "context-menu">
          {menu}
        </div>}
      </div>
    </>
  );
}