import React, {useCallback} from 'react';

import './CreateStache.scss';

//Components
import {WalletMultiButton} from '@solana/wallet-adapter-react-ui';
import {WalletConnectedModal} from 'components/Modals/WalletLogin/WalletLoginModal';
import {ReactComponent as BackNavigationIcon} from 'assets/icons/ticks/Chevron.svg';

//Utils
import useToasts from '../../hooks/useToasts';
import {isValidKeychainName} from 'utils/validation';
import {useAnalyticsActions} from '../../hooks/useAnalytics';

//Recoil
import {useStacheActions} from 'store/stache';
import {useRecoilValue} from 'recoil';
import {connectedWalletAtom} from 'store/connectedWallets';

//Constants
import {BRADIUS, COLORS, SHADOW, SPACING} from '../../constants/theme';
import {ENOTI_STATUS} from '../../store/toasts';
import {ERouteHome, EUnauthedRoute} from 'constants/routes';
import {EVENTS} from '../../constants/events';
import {ENABLE_STACHE} from 'constants/config';
import {solanaClient} from 'constants/factory';
import useSafeBack from 'hooks/useSafeBack';
import {useNavigate, useNavigation} from 'react-router';
import {Input} from 'components/ui/input/Input';
import {ColoredButton} from 'components/ui/button/Button';
import {xNFTAtom} from 'store/xnft';
import {ScreenContainer} from 'components/ui/container/Container';
import useAuth from "../../hooks/useAuth";

interface ICreateStacheProps {
  value?: string;
}

export const CreateStache: React.FC<ICreateStacheProps> = ({value}: ICreateStacheProps) => {
  const stacheActions = useStacheActions();
  const {createToast} = useToasts();
  const navigate = useNavigate();
  const analyticsActions = useAnalyticsActions();
  const {goBack} = useSafeBack();
  const auth = useAuth();

  const connectedWallet = useRecoilValue(connectedWalletAtom);
  const [openConnectedModal, setOpenConnectedModal] = React.useState(false);
  const [stacheid, setStacheid] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [errorText, setErrorText] = React.useState('');
  const isXNFT = useRecoilValue(xNFTAtom);

  async function handleSubmit() {
    if (!connectedWallet) {
      createToast('You must first connect a wallet', ENOTI_STATUS.ERR);
      return;
    }

    setErrorText('');
    const res = isValidKeychainName(stacheid, 3, 32);
    if (typeof res === 'string') {
      setErrorText(res);
    } else {
      try {
        setLoading(true);
        const stacheidlower = stacheid.toLowerCase().trim();

        const isAboveZero = await solanaClient.isBalanceAboveZero(connectedWallet.address);
        if (!isAboveZero) {
          createToast('SOL balance is 0. Add some SOL to your wallet to continue.', ENOTI_STATUS.ERR);
          setLoading(false);
          return;
        }

        const {stacheState, keychainState} = await stacheActions.createStache(stacheidlower, connectedWallet.address);
        analyticsActions.trackEvent(EVENTS.createdStache, {
          stacheid: stacheidlower,
          wallet: connectedWallet.address.toString(),
        });

        if (stacheState || (!ENABLE_STACHE && !!keychainState)) {
          setOpenConnectedModal(true);
          // login button should kick off the login sequence, then land them on the home screen
        } else {
          createToast(
            'Sorry, there was a problem creating your stache. Devs are probably doing something...',
            ENOTI_STATUS.ERR
          );
        }
      } catch (err) {
        createToast(err.toString(), ENOTI_STATUS.ERR);
      }
    }
    setLoading(false);
  }

  const handleNavigateToRoute = useCallback(
    (loggedIn: boolean, route: string) => {
      if (loggedIn) {
        navigate(route);
      }
      setOpenConnectedModal(false);
    },
    [setOpenConnectedModal]
  );

  const handleStacheIdInput = useCallback(
    (text: string) => {
      setStacheid((stacheid) => (/^[a-zA-Z0-9]*$/.test(text) ? text : stacheid));
    },
    [setStacheid]
  );

  const handleBack = async () => {
    await auth.logout();
    goBack(EUnauthedRoute.CONNECT_WALLET);
  }

  return (
    <ScreenContainer className="create-stache" hasBackButton={true} overrideBack={handleBack}>
      <div className="content">
        <p className="banner" style={{marginBottom: SPACING.XXXL}}>
          Create your Stache Smart Account
        </p>
        <Input
          label="Stache ID"
          value={stacheid}
          placeholder="stacheman"
          onChangeText={handleStacheIdInput}
          maxLength={32}
          hint="Only alphanumeric characters allowed"
          errorText={errorText}
        />
        <Input
          label="Connected Wallet - This will be your first Stache-linked wallet. (autofilled)"
          value={connectedWallet?.address.toBase58()}
          placeholder={connectedWallet?.address.toBase58()}
          onChangeText={() => null}
          disabled={true}
        />
      </div>
      <div className="submit">
        <ColoredButton onClick={handleSubmit} disabled={loading}>
          {loading ? 'Creating...' : 'Create your Stache Smart Account'}
        </ColoredButton>
      </div>
      <WalletConnectedModal
        text="Your Stache is ready!"
        isOpen={openConnectedModal}
        handleNavigate={(loggedIn: boolean) => handleNavigateToRoute(loggedIn, '/' + ERouteHome.HOMEPAGE)}
        close={() => setOpenConnectedModal(false)}
      />
    </ScreenContainer>
  );
};

export default CreateStache;
