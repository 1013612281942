import React from 'react';

//Recoil
import {useRecoilState, useResetRecoilState} from 'recoil';
import {IStache, stacheAtom, useStacheActions} from 'store/stache';

//Constants
import {LoginResponse} from 'apis/server/types';

//Clients
import {apiClient} from 'apis/server';

//Util
import {useStacheWalletActions} from 'store/stache/stacheWallet/actions';
import {connectedWalletAtom, useConnectedWalletActions} from 'store/connectedWallets';

//Web3
import {PublicKey} from '@solana/web3.js';
import {IKeychain, useKeychainActions} from 'store/keychain';
import {IProfileInfo, userProfileAtom, useUserProfileActions} from 'store/userProfile';
import {useVaultActions} from 'store/vault';
import {useAutomationActions} from '../store/automations';
import {useAddressBookActions} from 'store/addressBook';
import {useStorefrontActions} from 'store/yardsale';
import {ENABLE_STACHE} from 'constants/config';
import {findKeychainPda} from 'programs/keychain-utils';
import {useNotificationActions} from 'store/notification';
import {solanaClient} from '../constants/factory';

function useAuth() {
  const connectedWalletActions = useConnectedWalletActions();
  const stacheWalletActions = useStacheWalletActions();
  const keychainActions = useKeychainActions();
  const stacheActions = useStacheActions();
  const vaultActions = useVaultActions();
  const storefrontActions = useStorefrontActions();
  const autoActions = useAutomationActions();
  const userProfileActions = useUserProfileActions();
  const resetUserProfile = useResetRecoilState(userProfileAtom);
  const notificationsActions = useNotificationActions();
  const addressBookActions = useAddressBookActions();

  const [connectedWallet, setConnectedWallet] = useRecoilState(connectedWalletAtom);
  const [stache, setStache] = useRecoilState(stacheAtom);
  const [userProfile, setUserProfile] = useRecoilState(userProfileAtom);

  async function logout(): Promise<void> {
    localStorage.clear();
    solanaClient.reset();
    apiClient.logout();
    stacheActions.resetStache();
    await keychainActions.resetKeychain();
    await userProfileActions.resetUserProfile();
    await connectedWalletActions.disconnectWallet(); // Must be called last
    resetUserProfile();
    // TODO v2 - invalidate the jwt token by adding it to a blacklist serverside. Currently just deleting it clientside so they wont reuse it
    return;
  }

  async function login(
    signMessage?: (message: Uint8Array) => Promise<Uint8Array>,
    stacheid?: string
  ): Promise<LoginResponse> {
    const _stacheid: string = stacheid ?? stache.stacheid;
    // step 1: fetch the nonce
    const nonceResp = await apiClient.getNonce(connectedWallet.address);
    // step 2: sign the nonce
    const message = new TextEncoder().encode(nonceResp.nonce);
    const signMessageResp = await signMessage(message);
    // step 3: send the signed nonce to the server
    const signature = Array.from(signMessageResp);

    const loginResp = await apiClient.login(nonceResp.nonceId, signature, _stacheid);
    const {stache: _stache, profileInfo} = loginResp;
    const _profileInfo: IProfileInfo = {
      ...profileInfo,
      sellerAccountPda: profileInfo.sellerInfo?.pda
    }
    await setLoginState(
      loginResp.authToken,
      _stache,
      new PublicKey(loginResp.stache.keychainPda),
      _profileInfo,
    );

    return loginResp;
  }

  const setLoginState = async (
    authToken: string,
    stacheInfo: IStache,
    keychainPda: PublicKey,
    profileInfo: IProfileInfo,
  ) => {
    const _stachePda = stacheInfo?.stachePda ? new PublicKey(stacheInfo.stachePda) : undefined;
    solanaClient.initKeychain(keychainPda, _stachePda);
    const keychain = await solanaClient.getKeychainByPda(keychainPda);

    if (ENABLE_STACHE) {
      const {stacheid, avatarUrl, contacts, keychainPda, stachePda, nextVaultIndex, nextAutoIndex, vaults} = stacheInfo;
      const stacheState = {
        stacheid: stacheid,
        avatarUrl: avatarUrl,
        contacts: contacts,
        keychainPda: new PublicKey(keychainPda),
        stachePda: new PublicKey(stachePda),
        nextVaultIndex: nextVaultIndex ?? stache.nextVaultIndex,
        nextAutoIndex: nextAutoIndex ?? stache.nextAutoIndex,
        vaults: vaults || stache.vaults,
      };

      // need to reload stache from chain
      let newStacheState = await stacheActions.fetch(stacheState.stachePda);
      newStacheState = {...stacheState, ...newStacheState};
      setStache(newStacheState);
      await stacheWalletActions.load(newStacheState);

      // todo: vaults & automations are not currently used
      // await vaultActions.loadVaults(newStacheState);
      // await autoActions.loadAutomations(newStacheState);
    }

    // Userprofile related
    apiClient.setAuthToken(authToken);
    setUserProfile((prev) => ({
      ...prev,
      jwt: authToken,
      profileInfo,
    }));
    localStorage.setItem('jwt', authToken);

    connectedWalletActions.load(true); // do NOT await this. Must load in background
    await notificationsActions.getUnreadMessagesCount();
    await storefrontActions.getStorefrontItems(keychain.account.name);
    await addressBookActions.getAllContacts();
    await keychainActions.loadKeychainStateAccount(keychain.account.name);
  };

  const attemptFullLogin = async (
    walletAddress: PublicKey,
    jwt: string,
  ): Promise<boolean> => {
    const states: {
      keychainState: IKeychain;
      stacheState?: IStache;
    } = await stacheActions.checkForStache(walletAddress);
    const isLinked = states.keychainState?.walletLinked;
    const profileInfo: IProfileInfo = await getUserProfile(states.keychainState.name);
    if (isLinked) {
      await keychainActions.loadBasicData(states.keychainState);
      keychainActions.loadAssetData(states.keychainState); // do NOT await this
      ENABLE_STACHE && setStache(states.stacheState);
      setConnectedWallet((prev) => ({...prev, walletLinked: isLinked}));
      const [keychainPda] = findKeychainPda(states.keychainState.name);
      await setLoginState(jwt, states.stacheState, keychainPda, profileInfo);
      setUserProfile((prev) => ({...prev, authed: true}));
      return true;
    } else {
      await logout();
      return false;
    }
  };

  const getUserProfile = async (stacheid: string): Promise<IProfileInfo> => {
    // todo convert shape
    const res: IProfileInfo = await apiClient.getUser(stacheid);
    return res;
  };

  return {
    attemptFullLogin,
    logout,
    login,
    setLoginState,
    getUserProfile,
  };
}

export default useAuth;
