import useToasts from "hooks/useToasts";
import { useState } from "react";
import { IActivity } from "store/yardsale";
import "./Pagination.scss";
import {ReactComponent as Chrevon} from "assets/icons/ticks/Chevron.svg";

interface Props {
  page: number,
  total: number,
  setPage: (x: number) => unknown
}

export const Pagination = ({page, total, setPage}: Props) => {

  const nextPage = () => {
    setPage(Math.min(page + 1, total));
  }

  const prevPage = () => {
    setPage(Math.max(page - 1, 1));
  }

  let lower = Math.max(1, page - 1);
  let upper = Math.min(total, page + 1);
  let len = upper - lower + 1;

  if (page <= 2) upper = Math.min(total, upper + 5 - len);
  if (page >= total - 1) lower = Math.max(1, lower - (5 - len));
  console.log(lower, upper);

  if (!total) return <></>

  return (
    <div className = "pagination">
      <div className = "touchable" onClick = {prevPage}>
        <Chrevon/>
      </div>
      {total <= 5 ?
        Array(total).fill(0).map((x, idx) => (
        <div key = {idx} onClick={() => setPage(idx + 1)} className = {"page-selector " + (idx === page - 1 ? "selected" : "")}>
          <p className = "normal">
            {idx + 1}
          </p>
        </div>)) :
        <>
          {lower !== 1 && (
            <>
              <div onClick = {() => setPage(1)} className = "page-selector">
                <p className = "normal">
                  1
                </p>
              </div>
              <div className = "page-selector">
                <p className = "normal">
                  ...
                </p>
              </div>
            </>
          )}
          {Array(upper - lower + 1).fill(0).map((x, idx) => (
            <div key = {idx} onClick={() => setPage(idx + lower)} className = {"page-selector " + (idx + lower === page  ? "selected" : "")}>
              <p className = "normal">
                {idx + lower }
              </p>
            </div>
          ))}
          {upper !== total && (
            <>
              <div className = "page-selector">
                <p className = "normal">
                    ...
                </p>
              </div>
              <div onClick = {() => setPage(total)} className = "page-selector">
                <p className = "normal">
                  {total}
                </p>
              </div>
            </>
          )}
        </>
      }
      <div className = "touchable rotate" onClick = {nextPage}>
        <Chrevon/>
      </div>
    </div>
      
  )
}