import {atom, selector} from "recoil";
import {IKey, keychainAtom} from "store/keychain";
import {stacheWalletAtom} from "store/stache";
import {IConnectedWallet} from "./types";

export const connectedWalletAtom = atom<IConnectedWallet>({
  key: 'connected-wallet',
  default: undefined,
});

export const walletsListSelector = selector({
  key: 'wallets-list',
  get: ({get}) => {
    const stacheWallet = get(stacheWalletAtom);
    const connectedWallet = get(connectedWalletAtom);
    const keychain = get(keychainAtom);
    const linkedWalletKeys = keychain.keys.filter((key: IKey) => !key.walletAddress.equals(connectedWallet.address));
    const linkedWallets = linkedWalletKeys.map((key: IKey) => {
      return key.contents
    });
    return [stacheWallet, connectedWallet, ...linkedWallets];
  }
})

// all linked wallets (including connected)
export const linkedWalletsListSelector = selector({
  key: 'linked-wallets-list',
  get: ({get}) => {
    const keychain = get(keychainAtom);
    const linkedWallets = keychain.keys.map((key: IKey) => {
      return key.contents
    });
    return [...linkedWallets];
  }
})
