import React, {useCallback, useEffect, useRef, useState} from 'react';
import {ReactComponent as ChevronIcon} from 'assets/icons/ticks/Chevron.svg';
import ScreenHeader from 'components/Header/Screen/ScreenHeader';
import {SearchInput} from 'components/ui/input/Input';
import ReactMarkdown from 'react-markdown';
import {ERoute, ERouteSettings} from 'constants/routes';
import {COLORS, SPACING} from 'constants/theme';
import data from 'constants/faq.json';
import lodash from 'lodash';
import './FAQ.scss';
import {Link, useLocation, useSearchParams} from 'react-router-dom';
import { ScreenContainer } from 'components/ui/container/Container';

export const FAQ: React.FC<any> = (props: any) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState(data);

  const search = useCallback(
    (text: string) => {
      setFilteredData(data.filter((item) => item.title.toLowerCase().includes(text.toLowerCase())));
    },
    [setFilteredData]
  );

  const debounceSearch = useRef(
    lodash.debounce((searchTerm) => search(searchTerm), 750, {
      trailing: true,
      leading: false,
    })
  );

  useEffect(() => {
    if (searchTerm === '') return setFilteredData(data);
    else debounceSearch.current(searchTerm);
  }, [searchTerm, setFilteredData, debounceSearch]);

  return (
    <ScreenContainer hasBackButton title="Help & FAQ">
      <div className="faq-page">
        <SearchInput placeholder="Search..." value={searchTerm} onChangeText={setSearchTerm} />
        {filteredData.map((x, idx) => (
          <Link key={idx} to={`view`} state={x}>
            <div className="faq-card">
              <p className="normal">{x.title}</p>
              <ChevronIcon />
            </div>
          </Link>
        ))}
      </div>
    </ScreenContainer>
  );
};

export const ViewFAQ: React.FC<any> = (props: any) => {
  let {state} = useLocation();
  console.log(state);
  const txt = state.text.split('\n');

  return (
    <ScreenContainer hasBackButton title="Help & FAQ" >
      <div className="faq-page faq-view">
        <p className="banner">{state.title}</p>
        <div className="content">
          {txt.map((x: string, i: number) => {
            if (x[0] === '*') {
              const subTitle = x.replaceAll('*', '');
              return (
                <p className="header" key={i}>
                  {subTitle}
                </p>
              );
            } else {
              return <ReactMarkdown key={i}>{x}</ReactMarkdown>;
            }
          })}
        </div>
      </div>
    </ScreenContainer>
  );
};
