import React from 'react';
import "./Loader.scss";
import {ReactComponent as LoaderIcon} from "assets/icons/Loading.svg";

export interface Props {
  [key: string]: any,
  isSmall?: boolean,
  noBg?: boolean,
}

const Loader = (props: Props) => {

  return (
    <div className = {"loader-container" + (props.noBg ? " nobg": "")}>
      <LoaderIcon width={props.isSmall ? "35px" : "100px"} height={props.isSmall ? "35px" : "100px"}/>
    </div>
  );
}

export default Loader;