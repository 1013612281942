import {ICollectible, ITokenBag, NftType} from 'store/connectedWallets';

export enum EStoreItemStatus {
  SOLD = 'SOLD',
  DELISTED = 'DELISTED',
  ACTIVE = 'ACTIVE',
}

export enum EActivityAction {
  SOLD = 'SOLD',
  LISTED = 'LISTED',
  DELISTED = 'DELISTED',
  UPDATED = 'UPDATED',
}

export interface IFeatured {
  storefronts: IShop[];
  items: IListing[];
}

export interface IActivity {
  id: number;
  userId: number;
  listing: IListing;
  createdAt: Date;
  activityType: EActivityAction;
  buyerWallet?: string;
  buyerStacheid?: string;
}

export interface IShop {
  seller: ISeller;
  name?: string; // shops don't have names yet
}

export enum AssetType {
  NFT = 'NFT',
  TOKEN = 'TOKEN',
}

export interface ICollection {
  name: string;
  symbol?: string;
  mint?: string;
  imageUrl?: string;
  description?: string;
}

export interface ICurrency {
  mint: string;
  symbol: string;
  name: string;
  icon: string;
  decimals: number;
}

export enum ELISTING_PROGRAM {
  YARDSALE = "YARDSALE",
  BAZAAR = "BAZAAR"
}

export interface IListingThumb {
  id: number;
  pda: string;
  program: ELISTING_PROGRAM;
  price: number;
  priceBN: string;
  status: string;
  currency: ICurrency;
  listingType: EAssetSelectionType;
  numItems: number;
  items: IListingItemThumb[];
  seller: ISeller;
  name?: string,
}

export interface IListing {
  nftType: NftType;
  name?: string,
  title: string;
  pda: string;
  program: ELISTING_PROGRAM;
  // todo: unit multilistings can have > 1 buyer
  // either may be populated or both null (if the listing is still active)
  units: number;
  unitsLeft: number;
  buyer?: ILiteUser;
  buyerWallet?: string;
  desc?: string;
  soldAt?: number;
  id: number;
  price: number;
  priceBN: string;
  status: string;
  currency: ICurrency;
  listingType: EAssetSelectionType;
  numItems: number;
  items: IListingItem[];
  seller: ISeller;
  imageUrl?: string;
}

export interface IListingItem extends IListingItemThumb {
  tokenStandard: number;
  tokenAccount: string;
  mint: string;
  attributes: []; // json array
  desc: string;
}

export enum EAssetSelectionType {
  BUNDLE = 'BUNDLE',
  UNIT = 'UNIT',
  INDIVIDUAL = 'INDIVIDUAL',
}

export interface IListingItemThumb {
  name: string;
  qty: number;
  imageUrl: string;
  verified: boolean;
  nftType: string;
  collection?: ICollection;
  assetType: AssetType;
}

export interface ILiteUser {
  id: number;
  stacheid: string;
  avatarUrl: string;
}

export interface ISeller {
  stacheid: string;
  numSold: number;
  numListed: number;
  profilePic: string;
  sellerAccountPda?: string;
}

export interface IAttribute {
  trait_type?: string;
  value?: string;
}

export namespace IAttribute {
  export function fromObj(obj: any): IAttribute | null {
    if ('trait_type' in obj && 'value' in obj) {
      return {
        trait_type: obj.trait_type,
        value: obj.value,
      };
    } else {
      return null;
    }
  }
}

export interface ICommentThread {
  text: string;
  pic: string;
  username: string;
  created_at: Date;
  replies?: ICommentThread[];
}
