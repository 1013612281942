import React from "react";
import {BRADIUS, COLORS, SPACING} from "constants/theme";
import {ReactComponent as CopyIcon} from "assets/icons/CopyIcon.svg";
import useToasts from "../../hooks/useToasts";
import {ENOTI_STATUS} from "../../store/toasts";
import "./TextCopy.scss";

interface ITextCopyIconProps {
  text: string
  size?: number
  address?: string,
}


export const TextCopy: React.FC<ITextCopyIconProps> = ({
                                                             text,
                                                             address,
                                                             size = 12,
                                                           }) => {

  const {createToast} = useToasts();

  const copyLink = () => {
    navigator.clipboard.writeText(address ?? text);
    createToast(`Copied to clipboard`, ENOTI_STATUS.DEFAULT);
  }

  return (
    <div className = "text-copy touchable" onClick={copyLink}>
        <p className = "normal" style={{fontSize: `${size}px`}}>
          {text}
        </p>
        <CopyIcon height={size} width={size} />
    </div>
  )
}