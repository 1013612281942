import React, { useCallback, useEffect, useState, useRef, ChangeEvent } from "react";
import PropTypes from "prop-types";
import "./DoubleRangeInput.scss";

interface Props {
  min: number
  max: number
  minVal: number,
  maxVal: number,
  onChange: (min: number, max: number) => void
}

export const DoubleRangeInput = ({ min, max, onChange }) => {
  const [minVal, setMinVal] = useState(min);
  const [maxVal, setMaxVal] = useState(max);
  const [minInputValue, setMinInputValue] = useState(min);
  const [maxInputValue, setMaxInputValue] = useState(max);
  const minValRef = useRef(min);
  const maxValRef = useRef(max);
  const range = useRef(null);

  const handleChangeMin = (e: ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;
    // if (val > maxVal || val < min) return;
    setMinInputValue(val);
  }

  const handleChangeMax = (e: ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;
    // if (val < minVal || val > max) return;
    setMaxInputValue(val);
  }

  // Convert to percentage
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  // Set width of the range to decrease from the left side
  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxValRef.current);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    const minPercent = getPercent(minValRef.current);
    const maxPercent = getPercent(maxVal);

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [maxVal, getPercent]);

  // Get min and max values when their state changes
  useEffect(() => {
    onChange(minVal, maxVal);
    setMaxInputValue(maxVal.toString());
    setMinInputValue(minVal.toString());
  }, [minVal, maxVal]);

  useEffect(() => {
    if (maxInputValue !== "" && minInputValue !== "") {
      const minV = parseInt(minInputValue), maxV = parseInt(maxInputValue);
      console.log(minV, maxV);
      if (maxV <= max && maxV >= minV && minV >= min) {
        maxValRef.current = maxV;
        minValRef.current = minV;
        setMaxVal(maxV);
        setMinVal(minV);
      }
    }
  }, [minInputValue, maxInputValue])

  return (
    <div className="container">
      <input
        type="range"
        min={min}
        max={max}
        value={minVal}
        onChange={(event) => {
          const value = Math.min(Number(event.target.value), maxVal - 1);
          setMinVal(value);
          minValRef.current = value;
        }}
        className="thumb thumb--left"
        style={{ zIndex: minVal > max - 100 && "5" }}
      />
      <input
        type="range"
        min={min}
        max={max}
        value={maxVal}
        onChange={(event) => {
          const value = Math.max(Number(event.target.value), minVal + 1);
          setMaxVal(value);
          maxValRef.current = value;
        }}
        className="thumb thumb--right"
      />

      <div className="slider">
        <div className="slider__track" />
        <div ref={range} className="slider__range" />
        <div className="slider__left-value">{minVal}</div>
        <div className="slider__right-value">{maxVal}</div>
      </div>

      <div className = "input-wrapper">
        <input value = {minInputValue} type = "number" onChange={handleChangeMin}/>
        <div/>
        <input value = {maxInputValue} type = "number" onChange={handleChangeMax}/>
      

      </div>

    </div>
  );
};
