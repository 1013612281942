import React, {ReactElement} from 'react';

//Recoil
import {keychainAtom, useKeychainActions} from 'store/keychain';
import {ENOTI_STATUS} from 'store/toasts';

//Web3
import {PublicKey} from '@solana/web3.js';
import useToasts from 'hooks/useToasts';
import {useAnalyticsActions} from '../../../hooks/useAnalytics';
import {useRecoilValue} from 'recoil';
import {stacheAtom} from '../../../store/stache';
import {EVENTS} from '../../../constants/events';
import {connectedWalletAtom} from 'store/connectedWallets';
import {SPACING} from 'constants/theme';
import ModalWrapper from '../ModalWrapper/ModalWrapper';
import {Input} from 'components/ui/input/Input';
import {ColoredButton} from 'components/ui/button/Button';
import './AddWallet.scss';

interface Props {}

export type AddWalletHandle = {
  isOpen: boolean;
  onClose: () => void;
  handler: () => void;
};

export const AddWallet = (props): ReactElement => {
  const keychainActions = useKeychainActions();
  const toast = useToasts();
  const analyticsActions = useAnalyticsActions();
  const stache = useRecoilValue(stacheAtom);
  const keychain = useRecoilValue(keychainAtom);
  const connectedWallet = useRecoilValue(connectedWalletAtom);

  const [showModal, toggleModal] = React.useState(false);
  const [loading, toggleLoading] = React.useState(false);
  const [input, setInput] = React.useState('');
  const [errorText, setErrorText] = React.useState('');

  React.useEffect(() => {
    if (!showModal) {
      setInput('');
      setErrorText('');
    }
  }, [showModal]);

  const validate = () => {
    // Check max keys permitted
    if (keychain.keys.length > 4) {
      setErrorText('A maximum of 5 linked wallets are allowed.');
      return false;
    }

    // Check if input is a valid address
    try {
      new PublicKey(input);
      return true;
    } catch (e) {
      setErrorText('You must enter a valid Solana wallet address.');
      return false;
    }
  };

  const submitNewWallet = async () => {
    toggleLoading(true);
    if (validate()) {
      setErrorText('');
      try {
        const pubKey = new PublicKey(input);
        const bool = await keychainActions.checkIfKeyAvailable(pubKey);
        if (!bool) {
          setErrorText('This key is already linked to a Stache account!');
          toggleLoading(false);
          return;
        }

        const res = await keychainActions.addKey(pubKey);
        if (res === true) {
          console.log('Res true...');
          analyticsActions.trackEvent(EVENTS.addedKey, {
            stacheid: stache?.stacheid ?? keychain.name,
            wallet: connectedWallet.address.toBase58(),
          });
          toast.createToast("Wallet added! Go to the 'Pending Action' screen for next steps.", ENOTI_STATUS.SUCCESS);
          props.onClose();
        } else toast.createToast(res, ENOTI_STATUS.ERR);
      } catch (e) {
        setErrorText('There was an error adding your wallet: ' + e.message);
      }
    }
    toggleLoading(false);
  };

  return (
    <ModalWrapper isOpen={props.isOpen} onClose={props.onClose}>
      <div className="add-wallet-modal">
        <p className="subheader mb-xxxl">Enter a wallet to link to your Stache account:</p>
        <Input value={input} onChangeText={setInput} errorText={errorText} />
        <ColoredButton onClick={submitNewWallet}>{loading ? 'Submitting...' : 'Submit'}</ColoredButton>
      </div>
    </ModalWrapper>
  );
};

export default AddWallet;
