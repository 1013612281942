import React, {useCallback, useState} from 'react';

//Components

//Constants
import {ENavStack, ENestedRoute, ERoute, ERouteHome} from 'constants/routes';
import {EWalletType, ITokenBag, IWallet, connectedWalletAtom} from 'store/connectedWallets';
import {ENABLE_STACHE} from 'constants/config';
import {SPACING} from '../../constants/theme';

//Utils
import useFindWallets from 'hooks/useFindWallets';
import useToasts from 'hooks/useToasts';
import {useRecoilValue} from 'recoil';
import {keychainAtom, keychainStateAtom} from 'store/keychain';
import {stacheAtom, stacheWalletAtom} from 'store/stache';
import {ENOTI_STATUS} from 'store/toasts';
import {userProfileAtom} from 'store/userProfile';
import {TextCopy} from 'components/TextCopy/TextCopy';
import {formatAddress} from 'utils/string-formatting';
import {Avatar} from 'components/Avatar/Avatar';
import {ReactComponent as NotificationIcon} from 'assets/icons/NotificationsIcon.svg';

import './Home.scss';
import {Link} from 'react-router-dom';
import BalanceContainer, {ScreenContainer} from 'components/ui/container/Container';
import {NftCard} from 'components/NftCard/NftCard';
import {EAssetDetailsScreenType} from 'pages/AssetDetails/AssetDetails';

interface IHomeProps {}

interface ITokensProps {
  isStashing: boolean;
  wallet: IWallet;
  style?: any;
  disabled: boolean;
}

const Tokens: React.FC<ITokensProps> = ({isStashing, wallet, disabled, style = {}}) => {
  return (
    <div className="tokens-container">
      {wallet.assetsLoaded ? (
        wallet?.tokens?.map((token: ITokenBag, i: number) => {
          if (token.amount == 0 && token.symbol != 'SOL') return null;
          return (
            <div key={i} className="token">
              <div>
                <img src={token.imageUrl} style={{height: '35px', width: '35px', marginRight: SPACING.MD}} />
                <div>
                  <p className="subheader">{token.symbol}</p>
                  <p className="subheader dk">{token.name}</p>
                </div>
              </div>
              <div>
                <p className="medium">
                  {token.amountUiString} {token.symbol}
                </p>
              </div>
            </div>
          );
        })
      ) : (
        <div className="not-ready">
          <p className="medium">Loading your collection of tokens and nfts...</p>
        </div>
      )}
    </div>
  );
};

interface ICollectiblesProps {
  isStashing: boolean;
  wallet: IWallet;
  disabled: boolean;
}

const Collectibles: React.FC<ICollectiblesProps> = ({isStashing, wallet}) => {
  const userProfile = useRecoilValue(userProfileAtom);

  return (
    <div className="grid">
      {wallet.assetsLoaded ? (
        wallet.collectibles.map((x, idx) => (
          <div className="grid-item" key={idx}>
            <NftCard
              item={x}
              link={`assetsdetails`}
              state={{
                collectible: x,
                walletAddress: wallet?.address?.toBase58(),
                collectibleMint: x.mint,
                screenType: isStashing ? EAssetDetailsScreenType.STASHING : EAssetDetailsScreenType.UNSTASHING,
              }}
            />
          </div>
        ))
      ) : (
        <div className="not-ready">
          <p className="medium">Loading your collection of tokens and nfts...</p>
        </div>
      )}
    </div>
  );
};

interface Props {}

export const History: React.FC = (props: Props) => {
  return (
    <div className="history-container">
      <p className="header">No history yet</p>
    </div>
  );
};

export const Home: React.FC<IHomeProps> = () => {
  const stache = useRecoilValue(stacheAtom);
  const userProfile = useRecoilValue(userProfileAtom);
  const keychain = useRecoilValue(keychainAtom);
  const stacheWallet = useRecoilValue(stacheWalletAtom);
  const connectedWallet = useRecoilValue(connectedWalletAtom);
  const keychainState = useRecoilValue(keychainStateAtom);
  const {createToast} = useToasts();

  const {getWalletFromTab, allWalletOptions, walletText} = useFindWallets();

  const [dropdownValue, setDropdownValue] = useState(ENABLE_STACHE ? 'stache' : 'connected');
  const [tabView, setTabView] = useState<ENestedRoute>(ENestedRoute.ASSETS);

  console.log(connectedWallet.collectibles);

  const handleDropdown = (e: any) => {
    console.log(e);
    setDropdownValue(e.target.value);
  };

  const isDisabled = useCallback((wallet: IWallet) => {
    if (!ENABLE_STACHE) return true;
    return wallet?.walletType === EWalletType.LINKED;
  }, []);

  const handlePfpPress = () => {
    createToast('To set your pfp: Open an NFT in Home > Collectibles and set from the menu.', ENOTI_STATUS.DEFAULT);
  };

  const RenderView = React.useCallback(() => {
    switch (tabView) {
      case ENestedRoute.ASSETS:
        return (
          <Tokens
            isStashing={dropdownValue !== 'stache'}
            wallet={getWalletFromTab(dropdownValue)}
            disabled={isDisabled(getWalletFromTab(dropdownValue))}
          />
        );
      case ENestedRoute.COLLECTIBLES:
        return (
          <Collectibles
            isStashing={dropdownValue !== 'stache'}
            wallet={getWalletFromTab(dropdownValue)}
            disabled={isDisabled(getWalletFromTab(dropdownValue))}
          />
        );
      case ENestedRoute.HISTORY:
        return <History />;
    }
  }, [tabView, getWalletFromTab, dropdownValue]);

  return (
    <ScreenContainer className="home-page">
      <div className="home-header">
        <div className="left-container">
          <Avatar onClick={handlePfpPress} iconUrl={userProfile.profileInfo.avatarUrl} size={35} />
          <div>
            <p className="normal">@{stache?.stacheid ?? keychain.name}</p>
            <TextCopy
              text={formatAddress(connectedWallet.address.toBase58())}
              address={connectedWallet.address.toBase58()}
            />
          </div>
        </div>
        <Link to={'/' + ERouteHome.NOTIFICATIONS}>
          <NotificationIcon />
        </Link>
      </div>
      <BalanceContainer>
        <p className="normal">Connected Wallet Balance:</p>
        <p className="header">{connectedWallet?.tokens[0]?.amountUiString}</p>
      </BalanceContainer>
      <TextCopy text={getWalletFromTab(dropdownValue)?.address?.toBase58()} />
      <div className="select-wallet">
        <p className="subheader">{walletText(dropdownValue.toUpperCase())}</p>
        <select onChange={handleDropdown}>
          {allWalletOptions.map((x) => (
            <option key={x.value} value={x.value}>
              {x.label}
            </option>
          ))}
        </select>
      </div>
      <div className="tabs">
        <div
          className={'touchable ' + (tabView === ENestedRoute.ASSETS ? 'active' : '')}
          onClick={() => setTabView(ENestedRoute.ASSETS)}
        >
          <p className="subheader">Assets</p>
          <div />
        </div>
        <div
          className={'touchable ' + (tabView === ENestedRoute.COLLECTIBLES ? 'active' : '')}
          onClick={() => setTabView(ENestedRoute.COLLECTIBLES)}
        >
          <p className="subheader">Collectibles</p>
          <div />
        </div>
        {/* <div
          className={'touchable ' + (tabView === ENestedRoute.HISTORY ? 'active' : '')}
          onClick={() => setTabView(ENestedRoute.HISTORY)}
        >
          <p className="subheader">History</p>
          <div />
        </div> */}
      </div>
      <div className="main-view">
        <RenderView />
      </div>
    </ScreenContainer>
  );
};
