import {IKeychain} from 'store/keychain';
import {IListing} from 'store/yardsale';

export enum EInputOption {
  FIGURE = 'figure',
  PERCENTAGE = 'percentage',
}

export enum MESSAGE_STATUS {
  READ = 'READ',
  UNREAD = 'UNREAD',
  DISMISSED = 'DISMISSED',
}

export enum MESSAGE_TYPE {
  SYSTEM = 'SYSTEM',
  SHOP = 'SHOP',
  LISTING = 'LISTING',
}

export interface IMessage {
  id: number;
  message: string;
  createdAt: Date;
  type: MESSAGE_TYPE;
  from?: IKeychain;
  title?: string;
  status?: MESSAGE_STATUS;
  listing?: IListing;
}

export interface INotificationState {
  numUnread: number;
  subscribed: boolean;
  notifications: IMessage[];
}

export interface IPersonalMessagesState {
  numUnread: number;
  messages: IMessage[];
}
