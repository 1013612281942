import {COLORS, SPACING} from 'constants/theme';
import React, {FC, ReactElement} from 'react';
import useSafeBack from 'hooks/useSafeBack';
import {useUserProfileActions} from 'store/userProfile';
import useToasts from 'hooks/useToasts';
import {ENOTI_STATUS} from 'store/toasts';
import {Input} from 'components/ui/input/Input';

import './Feedback.scss';
import ScreenHeader from 'components/Header/Screen/ScreenHeader';
import {ColoredButton} from 'components/ui/button/Button';
import {ScreenContainer} from 'components/ui/container/Container';

interface Props {}

export const Feedback: FC<any> = (props: Props): ReactElement => {
  const {goBack} = useSafeBack();
  const {createToast} = useToasts();
  const userProfileActions = useUserProfileActions();

  const [feedback, setFeedback] = React.useState('');
  const [submitted, setSubmitted] = React.useState(false);

  const handleSubmit = async () => {
    try {
      await userProfileActions.submitFeedback(feedback);
      createToast('Feedback received', ENOTI_STATUS.SUCCESS);
    } catch (e) {
      console.log('problem submitting feedback', e);
      // noop
      createToast('SORRY! There was a problem submitting your feedback!', ENOTI_STATUS.ERR);
    } finally {
      setSubmitted(true);
    }
  };

  return (
    <ScreenContainer hasBackButton>
      <div className="feedback">
        <p className="banner">Give us feedback</p>
        <p className="normal">
          We'd love to get your thoughts on Stache. What's broken, what feature we're missing, how we can improve it...
          or just tell us about your day. 🙃
        </p>
        <Input value={feedback} onChangeText={setFeedback} placeholder="Write here..." label="Feedback" multiline />
        <ColoredButton
            label={submitted ? "Your feedback is appreciated!" : "Submit"}
            onClick={handleSubmit}
            disabled={submitted}>Submit</ColoredButton>
      </div>
    </ScreenContainer>
  );
};

export default Feedback;
