///////////////////////
/*
  The purpose of organizing the routes as such is purely to make the RoutingConfig.ts file easier to manage.
  Every newly created screen is placed into 1 or more enums below, which are then easily iterated over while configuring the
  valid links for the NavigationContainers linking prop
*/
///////////////////////

export enum ENavStack {
  HOME = 'home',
  BAZAAR = 'bazaar',
  PLAYGROUND = 'playground',
  SETTINGS = 'settings',
}

export enum ERouteSettings {
  SETTINGS = 'settings-screen',
  MANAGE_WALLETS = 'managewallets',
  MANAGE_WALLET = 'managewallet',
  MANAGE_PENDING_WALLET = 'managependingwallet',
  FAQ = 'faq',
  FAQ_INFO = 'faqinfo',
  FEEDBACK = 'feedback',
}

export enum ERouteBazaar {
  BAZAAR = 'bazaar-screen',
  MYSHOP = 'myshop',
  LISTING = 'listing',
  MY_ASSETS = 'assets',
  MULTILISTING = 'multilisting',
}

export enum ERoutePlayground {
  PLAYGROUND = 'playground-screen',
}

export enum ERouteHome {
  HOMEPAGE = 'home-screen',
  NOTIFICATIONS = 'notifications',
  ASSETS_DETAILS = 'assetsdetails',
  SENDING = 'sending',
}

// NOT BEING USED CURRENTLY
export enum ERoute {
  CUSTOMIZE_NOTIFICATIONS = 'customizenotifications',
  NOTIFY = 'notify',
  SEARCH_ADDRESS = 'searchaddress',
  ADDRESS_BOOK = 'addressbook',
  SELECT_USER = 'selectuser',
  ADD_USER = 'adduser',
  NEW_USER = 'newuser',
  CONFIRM_PAYMENT = 'confirmpayment',
  STASHING = 'stashing',

  //Functionalities
  FUNCTIONS = 'functions',
  VAULT_DETAILS = 'vaultdetails',
  CREATE_VAULT = 'createvault',
  CREATE_AUTOMATION = 'createautomation',
  PICK_AUTOMATION_TYPE = 'pickautomationtype',

  NOTFOUND = 'notfound',
}

export enum EUnauthedRoute {
  CONNECT_WALLET = '',
  LINK_WALLET = 'linkwallet',
  CREATE_NEW_STACHE = 'createnewstache',
  BAZAAR = "bazaar",
  HUB = "hub"
}

// All apply to auth and/or unauth
// Must be manually added to RoutingConfig.ts config objs
export enum EUrlPathedRoute {
  ITEM_SALE = 'itemsale',
  OTHER_PROFILE = 'otherprofile',
}

// Not actually routes anymore. Just an enum I use for which tabView should be rendered
export enum ENestedRoute {
  CONVERSATION = 'conversation',
  DESCRIPTION = 'description',
  ATTRIBUTES = 'attributes',
  LISTINGS = 'listings',
  ACTIVITY = 'activity',
  ABOUT = 'about',
  ASSETS = 'assets',
  COLLECTIBLES = 'collectibles',
  HISTORY = 'history',
  ASSETS_HISTORY = 'assetshistory',
  SYSTEM_NOTIS = 'systemnotis',
  PERSONAL_MSGS = 'personalmsgs',
  FEATURED_ITEMS = "featureditems",
  FEATURED_SHOPS = "featuredshops"
}
