import React, {FC, ReactElement} from 'react';
import Modal from 'react-modal';
import BottomUpSlider from 'components/ui/bottomUpSlider/BottomUpSlider';
import * as Theme from 'constants/theme';
import './ModalWrapper.scss';
import useWindowDimensions from 'hooks/useWindowDimensions';

interface Props {
  title?: string;
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  conStyle?: any;
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    border: "none",
    background: "none"
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.2)',
  },
};

// Default handler for open/close a modal
export type Handle = {
  handler: (bool: Boolean) => void;
};

export const ModalWrapperAdjustable = (props: Props): ReactElement => {
  const dims = useWindowDimensions();

  return dims.width > Theme.VIEWPORT.MAX_MOBILE_WIDTH ? (
    <ModalWrapper title={props.title} isOpen={props.isOpen} onClose={props.onClose} conStyle={props.conStyle}>
      {props.children}
    </ModalWrapper>
  ) : (
    <ModalWrapperBottomSlider title={props.title} isOpen={props.isOpen} onClose={props.onClose}>
      {props.children}
    </ModalWrapperBottomSlider>
  );
};

const ModalWrapper: FC<any> = (props: Props): ReactElement => {

  return (
    <Modal 
      isOpen={props.isOpen} 
      onRequestClose={props.onClose} 
      style={{...customStyles}} 
      ariaHideApp={false}
    >
      <div className="modalCon" style={{...props.conStyle}}>
        {props.children}
      </div>
    </Modal>
  );
};

export const ModalWrapperBottomSlider: FC<any> = (props: Props): ReactElement => {
  console.log("Bottom up")

  return (
    <Modal 
      isOpen={props.isOpen} 
      onRequestClose={props.onClose} 
      style={{...customStyles}}
      ariaHideApp={false}
    >
      <BottomUpSlider className= "modalConBottom">
        {/* <HeaderText style={{ color: Theme.COLORS.BLACK, marginBottom: Theme.SPACING.MD }}>{props.title ?? ''}</HeaderText> */}
        {props.children}
      </BottomUpSlider>
    </Modal>
  );
};

export default ModalWrapper;
