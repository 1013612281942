import * as anchor from '@project-serum/anchor';
import {PublicKey} from '@solana/web3.js';
import {DOMAIN_STATE, KEY_SPACE, KEYCHAIN, KEYCHAIN_SPACE, KEYCHAIN_STATE_SPACE} from "./program-utils";

import {KEYCHAIN_DOMAIN, KeychainProgramId} from "constants/config";

// import { Keychain } from './types/keychain';

// const { SystemProgram } = anchor.web3;

export const findDomainPda = (domain: string = KEYCHAIN_DOMAIN): [PublicKey, number] => {
  return anchor.web3.PublicKey.findProgramAddressSync(
      [Buffer.from(anchor.utils.bytes.utf8.encode(domain)),
        Buffer.from(anchor.utils.bytes.utf8.encode(KEYCHAIN))],
      KeychainProgramId);
}

export const findDomainStatePda = (domain: string): [PublicKey, number] => {
  return anchor.web3.PublicKey.findProgramAddressSync(
      [
        Buffer.from(anchor.utils.bytes.utf8.encode(DOMAIN_STATE)),
        Buffer.from(anchor.utils.bytes.utf8.encode(domain)),
        Buffer.from(anchor.utils.bytes.utf8.encode(KEYCHAIN))],
      KeychainProgramId
  );
}

// finds the keychain pda for the given playername (for the domination domain)
export const findKeychainPda = (playername: string, domain: string = KEYCHAIN_DOMAIN): [PublicKey, number] => {
  // const [keychainPda, keychainPdaBump] = anchor.web3.PublicKey.findProgramAddressSync(
  return anchor.web3.PublicKey.findProgramAddressSync(
      [
        Buffer.from(anchor.utils.bytes.utf8.encode(playername)),
        Buffer.from(anchor.utils.bytes.utf8.encode(KEYCHAIN_SPACE)),
        Buffer.from(anchor.utils.bytes.utf8.encode(domain)),
        Buffer.from(anchor.utils.bytes.utf8.encode(KEYCHAIN)),
      ],
      KeychainProgramId,
  );
};

export const findKeychainStatePda = (keychainPda: PublicKey, domain: string = KEYCHAIN_DOMAIN): [PublicKey, number] => {
  return anchor.web3.PublicKey.findProgramAddressSync(
      [
        keychainPda.toBuffer(),
        Buffer.from(anchor.utils.bytes.utf8.encode(KEYCHAIN_STATE_SPACE)),
        Buffer.from(anchor.utils.bytes.utf8.encode(domain)),
        Buffer.from(anchor.utils.bytes.utf8.encode(KEYCHAIN)),
      ],
      KeychainProgramId,
  );
};

// find the keychain KEY pda for the given wallet address (for the domination domain)
export const findKeychainKeyPda = (walletAddress: PublicKey, domain: string = KEYCHAIN_DOMAIN): [PublicKey, number] => {
  return anchor.web3.PublicKey.findProgramAddressSync(
      [
        walletAddress.toBuffer(),
        Buffer.from(anchor.utils.bytes.utf8.encode(KEY_SPACE)),
        Buffer.from(anchor.utils.bytes.utf8.encode(domain)),
        Buffer.from(anchor.utils.bytes.utf8.encode(KEYCHAIN)),
      ],
      KeychainProgramId,
  );
};

export const [DOMAINPDA] = findDomainPda(KEYCHAIN_DOMAIN);
