import React, {useCallback, useState} from 'react';
import {ENestedRoute} from 'constants/routes';
import {COLORS, SPACING} from 'constants/theme';
import "./Tabs.scss";

export interface ITab {
  name: string;
  route?: ENestedRoute;
}

interface ITabsProps {
  tabs: ITab[];
  currentTab: ENestedRoute;
  setCurrentTab?: (route: ENestedRoute) => void;
  style?: any;
}

export const Tabs: React.FC<ITabsProps> = ({tabs, setCurrentTab, currentTab, style = {}}) => {
  

  return (
    <div className="wrapper" style={style}>
      {tabs.map((tab: ITab, i: number) => {
        return (
          <div key={i} style={{}}>
            <div
              onClick={() => setCurrentTab(tab.route)}
              className={`tab ${currentTab === tab.route ? "activeTab" : "inactiveTab"}`}
            >
                <div 
                    className={`textCon`}
                >
                  <p 
                    className="subheader" 
                    style={{color: currentTab === tab.route ? COLORS.TEXT_WHITE : '#737373'}}>
                    {tab.name}
                  </p>
                </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

