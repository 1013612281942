import React from 'react';
import "./Avatar.scss";

interface IAvatarProps {
    iconUrl: string
    size: number
    username?: string
    style?: any,
    onClick?: () => void,
}

export const Avatar: React.FC<IAvatarProps> = ({ iconUrl, username, onClick, size, style }) => {
    return (
      <div 
        className = "avatar-container touchable" 
        onClick={onClick} 
        style={{height: `${size}px`, width: `${size}px`, borderRadius: `${Math.ceil(size / 2)}px`, ...style}}
    >
          {iconUrl 
            ? <img src={iconUrl}/>
            : <p 
                className = "banner"
                style={{fontSize: `${Math.ceil(size / 2)}px`}}
               >
                {username ? username[0].toUpperCase() : ''}
               </p>
          }
      </div>
    )
}