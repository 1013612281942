//Recoil
import {EWalletType, ICollectible, IConnectedWallet, IStacheWallet, ITokenBag} from "store/connectedWallets";
import {IStache, stacheAtom, stacheWalletAtom} from "store/stache";
import {useRecoilCallback, useRecoilState, useRecoilValue} from "recoil";

//Clients
//Util
import {PublicKey, Transaction} from "@solana/web3.js";
import {useWalletAdapter} from "../../../hooks/useWalletAdapter";
import {getSolTokenBag} from "../../store-utils";
import {blacklistAtom, ogCollectionsAtom} from "store/system";
import {rpcClient, solanaClient} from "constants/factory";


function useStacheWalletActions() {

  const [stache, setStache] = useRecoilState<IStache>(stacheAtom);
  const [stacheWallet, setStacheWallet] = useRecoilState(stacheWalletAtom);
  const {signAndSendTransaction} = useWalletAdapter();
  const blacklist = useRecoilValue(blacklistAtom);
  const ogCollections = useRecoilValue(ogCollectionsAtom);

  // no idea if this is the right way to do this
  const stacheWalletLoader = useRecoilCallback(({snapshot, set}) => async () => {
    const stacheWallet = await snapshot.getPromise(stacheWalletAtom);
    if (!stacheWallet) {
      console.log('stache account not found, loading...');
      // const tokenAccounts = await rpcClient.getTokenAccounts(stache.stachePda);
      // console.log(`got token accounts for ${stache.pda.toString()}: ${JSON.stringify(tokenAccounts, null, 2)}`);
      set(stacheWalletAtom, {
        ...stache,
        label: stache.stachePda.toString(),
        address: stache.stachePda,
        walletType: EWalletType.STACHE,
        collectibles: [],
        tokens: [],
        assetsLoaded: true
      });
    } else {
      console.log('stache account found, not loading');
    }
  });

  // load the stache account info
  async function load(data?: IStache) {
    // Initial load requires the data parameter. Subsequent calls to this function will not
    const stacheData = data ?? stache;
    // fetch all the token accounts
    const assets = await rpcClient.fetchAssets(stacheData.stachePda, blacklist, ogCollections);

    const solBag = await getSolTokenBag(stacheData.stachePda);

    // make SOL the first tokenbag
    assets.tokens.unshift(solBag);

    setStacheWallet({
      ...stacheData,
      label: stacheWallet?.label || stacheData.stachePda.toString(),
      walletType: EWalletType.STACHE,
      address: stacheData.stachePda,
      collectibles: assets.collectibles,
      tokens: assets.tokens,
      assetsLoaded: true
    });
  }

  async function stashCollectible(collectible: ICollectible): Promise<string> {
    const tx = await solanaClient.getStashTokensTx(new PublicKey(collectible.tokenAccount), new PublicKey(collectible.mint), 1, 0);
    const txid = await signAndSendTransaction(tx);
    console.log(`stashed nft in txid: ${txid}`);
    await rpcClient.confirmTransaction(txid);
    return txid;
  }

  async function stashTokens(connectedWallet: IConnectedWallet, stacheWallet: IStacheWallet, tokenBag: ITokenBag, amountUi: number): Promise<string> {
    let txid = null;
    let tx = null;
    if (tokenBag.symbol === 'SOL' && !tokenBag.tokenAccount) {
      console.log(`stashing ${amountUi} SOL...`);
      // stashing sol - just a tx
      const ix = await solanaClient.getStashSolIx(amountUi);
      tx = new Transaction().add(ix);
      console.log(`stashed ${amountUi} SOL`);
    } else {
      console.log(`stashing ${amountUi} tokens from tokenbag: ${JSON.stringify(tokenBag, null, 2)}`);
      tx = await solanaClient.getStashTokensTx(tokenBag.tokenAccount, tokenBag.mint, amountUi, tokenBag.decimals);
    }

    txid = await signAndSendTransaction(tx);
    await rpcClient.confirmTransaction(txid);
    return txid;
  }

  async function unstashTokens(stacheWallet: IStacheWallet, connectedWallet: IConnectedWallet, tokenBag: ITokenBag, amountUi: number): Promise<string> {
    console.log(`tokenbag: ${JSON.stringify(tokenBag, null, 2)}`);
    let tx = null;

    if (tokenBag.symbol === 'SOL' && !tokenBag.tokenAccount) {
      console.log(`unstashing ${amountUi} SOL...`);
      const ix = await solanaClient.getUnstashSolIx(amountUi);
      tx = new Transaction().add(ix);
    } else {
      console.log(`unstashing ${amountUi} tokens from tokenbag: ${JSON.stringify(tokenBag, null, 2)}`);
      tx = await solanaClient.getUnstashTokenTx(tokenBag.tokenAccount, tokenBag.mint, amountUi, tokenBag.decimals);
    }
    const txid = await signAndSendTransaction(tx);
    await rpcClient.confirmTransaction(txid);
    return txid;
  }

  async function unstashCollectible(collectible: ICollectible): Promise<string> {
    const tx = await solanaClient.getUnstashTokenTx(new PublicKey(collectible.tokenAccount), new PublicKey(collectible.mint), 1, 0);
    const txid = await signAndSendTransaction(tx);
    console.log(`unstashed nft in txid: ${txid}`);
    await rpcClient.confirmTransaction(txid);
    return txid;
  }

  return {
    load,
    stashTokens,
    unstashTokens,
    unstashCollectible,
    stashCollectible
  }
}

export {useStacheWalletActions}
