import { FC, ReactElement } from "react";
import { IShop } from "store/yardsale";
import "./UserCard.scss"
import { Link } from "react-router-dom";
import { Avatar } from "components/Avatar/Avatar";

interface ShopProps {
  shop: IShop;
  link: string,
  onPress?: () => void;
  conStyle?: any;
}

export const UserCard: FC<any> = ({link, ...props}: ShopProps): ReactElement => {
  const {  
    stacheid,
    numSold,
    numListed,
    profilePic,
  } = props.shop.seller;


  return (
    <Link to = {link}>
      <div className = "user-card">
        <div className = "user-content">
          <Avatar 
            iconUrl={profilePic}
            username={stacheid}
            size={125}
            style={{alignSelf: "center"}}
          />
          <div className = "info">
            <p className = "normal id"> @{stacheid}</p>
            <div>
              <p className = "normal gr">
                Listings
              </p>
              <p className = "normal">
                {numListed}
              </p>

            </div>
            <div>
              <p className = "normal gr">
                Sales
              </p>
              <p className = "normal">
                {numSold}
              </p>
            </div>
          </div>

        </div>
      </div>
    </Link>
  )
};