import React, {FC, ReactElement} from 'react';
import {COLORS, SPACING} from 'constants/theme';
import {ReactComponent as Close} from 'assets/icons/ticks/Close.svg';
import {ReactComponent as Hamburger} from 'assets/icons/ticks/Hamburger.svg';
import "./Menu.scss";

interface Props {
  options: { label: string, onPress: () => void }[],
  overlayStyles?: any
}


// todo: the width of this container wasn't adjusting correctly when 'open' was toggled
// the unsatisfactory solution was to hardcode a width. This should be improved to calculate the width of the text
// once its expanded. Not a trivial task.


export const Menu: FC<any> = (props: Props): ReactElement => {

  const [open, toggleOpen] = React.useState(false);
  const [containerWidth, setContainerWidth] = React.useState(35 + (SPACING.XL * 2))


  return (
    <div 
        className= "con"
        style={{width: open ? 150 : containerWidth}}
    >
        <div className= "topCon">
            <div className="touchable" onClick={() => toggleOpen(!open)} style={{marginLeft: SPACING.LG}}>
                {open
                    ? <Close height={20} width={20} color={COLORS.TEXT_GRAY}/>
                    : <Hamburger height={20} width={20}/>
                }
            </div>
        </div>
        {open &&
           <div className="overlayCon" style={props.overlayStyles}>
              <div className="linksCon">
                {props.options.map(option => {
                  return (
                      <div
                        key={option.label}
                        onClick={() => {
                          option.onPress();
                          toggleOpen(false);
                        }}
                        className= "row touchable"
                      >
                        <p className= "text">
                          {option.label}
                        </p>
                      </div>
                    )
                })}
              </div>
           </div>
        }
      </div>
  );
};


export default Menu
