import React, {FC, ReactElement, useCallback, useEffect, useMemo} from 'react';
import {ConnectionProvider, WalletProvider} from '@solana/wallet-adapter-react';
import {
  BackpackWalletAdapter,
  CloverWalletAdapter,
  Coin98WalletAdapter,
  GlowWalletAdapter,
  LedgerWalletAdapter,
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  TorusWalletAdapter,
  TrustWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import {WalletModalProvider} from '@solana/wallet-adapter-react-ui';
import "./ProviderComponentWrapper.scss"

//Hooks
import useToasts from 'hooks/useToasts';

//Constants
import {APP_ENV, RPC_URL} from "../../constants/config";

import { SOLANA_NETWORK } from '../../constants/config';


//Types
import {ENOTI_STATUS} from 'store/toasts';
import {WalletAdapterNetwork} from '@solana/wallet-adapter-base';

interface Props {
  children: React.ReactNode
}

export const ProviderComponentWrapper: FC<Props> = ({children}): ReactElement => {

  const {createToast} = useToasts();

  const network = APP_ENV === 'dev' ? WalletAdapterNetwork.Devnet : WalletAdapterNetwork.Mainnet

  const onWalletError = useCallback(
      (error: any) => {
        if (error.toString().includes("WalletSendTransactionError")) {
          console.log("Wallet send transaction error. Acceptable", error);
        } else if (error.toString().includes('WalletDisconnectedError')) {
          console.log("Wallet disconnected error. Acceptable", error);
          localStorage.clear();
        } else {
          localStorage.clear();
          createToast(
              error.message ? `${error.name}: ${error.message}` : `Error interacting with wallet: ${error.name}`,
              ENOTI_STATUS.ERR
          );
        }
      },
      [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const wallets = useMemo(
      () => [
        new PhantomWalletAdapter(),
        new TorusWalletAdapter(),
        new LedgerWalletAdapter(),
        // @ts-ignore
        new SolflareWalletAdapter({network: SOLANA_NETWORK}),
        new BackpackWalletAdapter(),
        new TrustWalletAdapter(),
        new CloverWalletAdapter(),
        new Coin98WalletAdapter(),
        // @ts-ignore
        new GlowWalletAdapter({network: SOLANA_NETWORK}),
      ],
      [network]
  );

  return (
      <ConnectionProvider endpoint={RPC_URL}>
        <WalletProvider wallets={wallets} onError={onWalletError} autoConnect>
          <WalletModalProvider>
            {children}
          </WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
  );
}
