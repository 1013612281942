import React, {Suspense, useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import './styles/globals.scss';
import {RouterProvider} from 'react-router';
import router from './router/Router';
import {ProviderComponentWrapper} from 'components/ProviderComponentWrapper/ProviderComponentWrapper';
import {RecoilRoot, useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import DataRetrievalLayer from 'components/DataRetrievalLayer/DataRetrievalLayer';
import Toasts from 'components/Toasts/Toasts';
import {AnchorWallet, useAnchorWallet} from '@solana/wallet-adapter-react';
import useAuth from 'hooks/useAuth';
import {useConnectedWalletActions, IConnectedWallet, connectedWalletAtom} from 'store/connectedWallets';
import {isValidToken, userProfileAtom} from 'store/userProfile';
import useAsyncEffect from 'use-async-effect';
import Loader from 'components/Loader/Loader';
import { xNFTAtom } from 'store/xnft';
import {backpackPubkeyPromise, ENABLE_LOGGING} from "./constants/config";
import {PublicKey} from "@solana/web3.js";

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const Providers = ({children}) => (
  <RecoilRoot>
    <DataRetrievalLayer>
      <ProviderComponentWrapper>
        <Suspense fallback={<Loader />}>{children}</Suspense>
        <Toasts />
      </ProviderComponentWrapper>
    </DataRetrievalLayer>
  </RecoilRoot>
);

const App = () => {
  const auth = useAuth();
  const connectedWalletActions = useConnectedWalletActions();

  const isValidTokenSelector = useRecoilValue(isValidToken);
  const [connectedWallet, setConnectedWallet] = useRecoilState<IConnectedWallet>(connectedWalletAtom);
  const anchorWallet: AnchorWallet | undefined = useAnchorWallet();
  const [isXNFT, setIsXNFT] = useRecoilState(xNFTAtom);

  const [attemptingLogin, toggleAttempting] = useState(true);
  const [stupidBullshitWaitingForAnchor, toggleIt] = useState(true);

  useEffect(() => {
    setTimeout(() => toggleIt(false), 5000);
  }, []);

  async function attemptLogin(walletAddress: PublicKey) {
    const jwt: string = localStorage.getItem('jwt');
    if (jwt && isValidTokenSelector) {
      console.log('attempting full login (auto)... ');
      // todo jwt must match wallet being logged in with
      // could maybe do this with the stored stache setup from before, but I could see that getting messed with
      // server should associate the jwt with a specific wallet during login
      await auth.attemptFullLogin(walletAddress, jwt);
      toggleIt(false);
    }
    else toggleIt(false)
  }

   // Ensures console output is disabled during prod
  useEffect(() => {
    if (!ENABLE_LOGGING) {
      console.log = () => {
      };
      console.error = () => {
      };
      console.debug = () => {
      };
      console.warn = () => {
      };
      console.assert = () => {
      };
      console.log("Logging disabled. ENABLE_LOGGING = ", ENABLE_LOGGING);
    } else {
      console.log("Logging enabled. ENABLE_LOGGING = ", ENABLE_LOGGING);
    }
  }, []);

  useAsyncEffect(async () => {
    if (anchorWallet && !connectedWallet) {
      await connectedWalletActions.connectWallet(anchorWallet);
      await attemptLogin(anchorWallet.publicKey);
    }
    toggleAttempting(false);
  }, [anchorWallet]);

  // for backpack / nxnft
  useAsyncEffect(async () => {
    const backpackWalletAddress = await backpackPubkeyPromise;

    // @ts-ignore
    if (backpackWalletAddress) {
      console.log('>>>>> running xnft, pubkey: ' + backpackWalletAddress);
      setIsXNFT(true);
      // @ts-ignore
      await connectedWalletActions.connectWallet(window.xnft.solana);
    } else {
      console.log('>>>>> not running xnft');
    }
  }, []);

  if (attemptingLogin || stupidBullshitWaitingForAnchor) return <Loader />;

  return <RouterProvider router={router} />;
};

root.render(
  <Providers>
    <App />
  </Providers>
);
