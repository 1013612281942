import React, {FC, ReactElement} from 'react';

//SVGs
import Discord from 'assets/icons/social/discord.svg';
import Email from 'assets/icons/social/email.svg';
import Twitter from 'assets/icons/social/twitter.svg';

//Util
import useToasts from 'hooks/useToasts';

//Constants
import {ENOTI_STATUS} from 'store/toasts';
import {COLORS, SPACING, VIEWPORT} from 'constants/theme';

import "./Footer.scss";

interface Props {

}

export const Footer: FC<any> = (props: Props): ReactElement => {

  const {createToast} = useToasts();

  const copyLink = (text: string) => {
    navigator.clipboard.writeText(text)
    createToast(`Copied to clipboard`, ENOTI_STATUS.SUCCESS)
  }

  return (
      <div className = "footer">
        <div>
          {/* <img src = {require("assets/pngs/Stache_Logo_Color_Medium.png")} alt = "logo"/>
          <p className = "subheader">Stache</p> */}
        </div>
        <div>
          <a href = "https://twitter.com/SmartStache" className = "touchable social">
            <img src = {Twitter}/>
          </a>
          <div className = "touchable social" onClick = {() => copyLink("squeak@stache.io")}>
            <img src = {Email}/>
          </div>
          <a href = "https://discord.com/channels/940917176783749141/973379671813152799" className = "touchable social">
            <img src = {Discord}/>
          </a>
        </div>
      </div>
  );
};
export default Footer
