// import anchor from "@project-serum/anchor";
import {Idl} from '@project-serum/anchor';
import {PublicKey} from "@solana/web3.js";

import keychainIdl from 'programs/idl/keychain.json';
import stacheIdl from 'programs/idl/stache.json';
import squadsIdl from 'programs/idl/squads_mpl.json';
import yardsaleIdl from 'programs/idl/yardsale.json';
import bazaarIdl from 'programs/idl/bazaar.json';
import {DEFAULT_MULTISIG_PROGRAM_ID} from "../programs/squads-utils";
import {TokenRegistry} from "../utils/tokenregistry";


export const KEYCHAIN_DOMAIN =  process.env.REACT_APP_KEYCHAIN_DOMAIN;
export const KEYCHAIN_TREASURY =  process.env.REACT_APP_KEYCHAIN_TREASURY;
export const KEYCHAIN_LISTING_TREASURY =  process.env.REACT_APP_LISTING_DOMAIN_TREASURY;
export const APP_ENV =  process.env.REACT_APP_APP_ENV;
export const API_URL =  process.env.REACT_APP_BASE_API_URL;
export const SOLANA_NETWORK =  process.env.REACT_APP_SOLANA_NETWORK;
export const RPC_URL =  process.env.REACT_APP_SOLANA_RPC;
export const MIXPANEL_API_KEY =  process.env.REACT_APP_MIXPANEL_API_KEY;
export const ENABLE_ANALYTICS =  process.env.REACT_APP_ENABLE_ANALYTICS == "true";
export const ENABLE_LOGGING =  process.env.REACT_APP_ENABLE_LOGGING == "true";
export const BASE_MIXPANEL_API =  process.env.REACT_APP_BASE_MIXPANEL_API;
export const TOKEN_FAIRY_VAULT_PDA =  new PublicKey(process.env.REACT_APP_TOKEN_FAIRY_VAULT_PDA);
export const TOKEN_FAIRY_SESSION_PDA =  new PublicKey(process.env.REACT_APP_TOKEN_FAIRY_SESSION_PDA);
export const SYSTEM_STACHEID =  process.env.REACT_APP_SYSTEM_STACHEID;
export const LISTING_DOMAIN = process.env.REACT_APP_LISTING_DOMAIN;
export const LISTING_DOMAIN_INDEX = process.env.REACT_APP_LISTING_DOMAIN_INDEX;
export const ENABLE_FUNCTIONS_TAB =  process.env.REACT_APP_ENABLE_FUNCTIONS_TAB == "true";
export const ENABLE_PLAYGROUND_TAB =  process.env.REACT_APP_ENABLE_PLAYGROUND_TAB == "true";
export const ENABLE_STACHE =  process.env.REACT_APP_ENABLE_STACHE == "true";
export const RPC_REQ_PER_SEC = parseInt(process.env.REACT_APP_RPC_REQ_PER_SEC);
export const isDevnet = RPC_URL.indexOf('devnet') > 0;

if (ENABLE_LOGGING) {
  // console.log(`logging: ${ENABLE_LOGGING}, analytics: ${ENABLE_ANALYTICS}, env: ${Constants.expoConfig.extra.APP_ENV}, rpc: ${RPC_URL}`);
  console.log(`logging: ${ENABLE_LOGGING}, env: ${APP_ENV}`);
}

export const isHelius = RPC_URL.indexOf('helius') > 0;

export const tokenRegistry: TokenRegistry = new TokenRegistry(isDevnet);

// keychain stuff
export const KeychainIdl = keychainIdl as Idl;
export const KeychainProgramId = new PublicKey(keychainIdl.metadata.address);

export const StacheIdl = stacheIdl as Idl;
export const StacheProgramId = new PublicKey(stacheIdl.metadata.address);

export const SquadsIdl = squadsIdl as Idl;
export const SquadsProgramId = DEFAULT_MULTISIG_PROGRAM_ID;

export const YardsaleIdl = yardsaleIdl as Idl;
export const YardsaleProgramId = new PublicKey(yardsaleIdl.metadata.address);

export const BazaarIdl = bazaarIdl as Idl;
export const BazaarProgramId = new PublicKey(bazaarIdl.metadata.address);

// clockwork v1.4.2
export const ClockProgramId: PublicKey = new PublicKey('3XXuUFfweXBwFgFfYaejLvZE4cGZiHgKiGfMtdxNzYmv');
// export const ThreadProg = new Program(ThreadIdl, ThreadProgId, provider);

// // devnet mints - authority: devkey
export const TOKEN_FAIRY_MINTS = [
  {
    // bonk
    mint: new PublicKey('zX2aJ4pRH8wAUzDLJu3bbEYCgwUDMhN2YmLHDezcu2D'),
    numDecimals: 5,
    name: 'Fake Bonk'
  },
  {
    // dom
    mint: new PublicKey('BNmS9vEa6b6cyggw13zUjufdJCcxAthpfLPkq6ToW7Po'),
    numDecimals: 6,
    name: 'Fake DOM'
  },
  {
    // usdc
    mint: new PublicKey('EuAUmtFsLPmrGBfhQwZjHXif96gxsonZkACDNZsaWTxE'),
    numDecimals: 6,
    name: 'Fake USDC'

  },
  {
    // kai
    mint: new PublicKey('FqCKYHtvCTzHWgW34uhXwVPGC4KKJDWqkYpdwnq7K1rT'),
    numDecimals: 6,
    name: "Fake KAI"
  },
  {
    // usdt
    mint: new PublicKey('v1UsbjZv8QU2Zwgvn2fWFsVipodKDHe7S4NnoCTpiJN'),
    numDecimals: 9,
    name: 'Fake USDT'
  },
  {
    // shdw
    mint: new PublicKey('9sxLNejdNQzcELz5L2XFzZaWkxamWZrgSRq85i25osWA'),
    numDecimals: 9,
    name: 'Fake SHDW'
  }
];

export const backpackPubkeyPromise = new Promise<PublicKey|null>((resolve) => {
  let counter = 0;

  if(typeof window === "undefined") {
    console.log('>>> no window found');
    return resolve(null);
  }
  const interval = setInterval(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const pubkey = window?.xnft?.solana?.publicKey;

    if (pubkey) {
      clearInterval(interval)
      console.log('found xnft pubkey: ', pubkey);
      return resolve(pubkey);
    }
    if (counter > 50) {
      clearInterval(interval);
      console.error("No connected pubkey found");
      return resolve(null);
    }
    counter++;
  }, 200)
});

export const listingsPerPage = 20;