import mixpanel from 'mixpanel-browser';
import {BASE_MIXPANEL_API, ENABLE_ANALYTICS, MIXPANEL_API_KEY} from "../constants/config";
import {EVENTS} from "../constants/events";
import {xNFTAtom} from "../store/xnft";
import {useRecoilValue} from "recoil";

function useAnalyticsActions() {
  const enabled = ENABLE_ANALYTICS;

  const isXNFT = useRecoilValue(xNFTAtom);

  const DEFAULT_PROPS = {
    System: 'Stache',
    xNFT: isXNFT
  };

  if (enabled) {
    mixpanel.init(MIXPANEL_API_KEY, {api_host: BASE_MIXPANEL_API});
  }

  function identify(stacheid) {
    if (enabled) {
      console.log(`###### identify: ${stacheid}`);
      mixpanel.identify(stacheid);
    }
  }

  // add another identifier for this player
  function alias(aliasId, originalId) {
    if (enabled) {
      mixpanel.alias(aliasId, originalId);
    }
  }

  function trackEvent(eventName, properties = {}) {
    if (enabled) {
      // mixpanel.track(`app:${object}_${action}`);
      mixpanel.track(eventName, {...DEFAULT_PROPS, ...properties});
    }
  }

  function trackScreen(page, properties = {}) {
    if (enabled) {
      // mixpanel.track(`app:page_${page}`);
      mixpanel.track(EVENTS.screenView, {
        ...DEFAULT_PROPS,
        Page: page,
        ...properties,
      });
    }
  }

  function trackClick(name, properties = {}) {
    if (enabled) {
      trackEvent(EVENTS.click, {
        name,
        ...properties,
      });
    }
  }

  return {
    identify,
    alias,
    trackEvent,
    trackScreen,
    trackClick,
  };
}

export {useAnalyticsActions};
