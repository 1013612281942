import {debounce} from 'lodash';
import React, {FC, ReactElement, useCallback} from 'react';
import {useNavigate} from 'react-router';
import {useRecoilState} from 'recoil';
import {userProfileAtom} from 'store/userProfile';
import {IActivity, IListingThumb, IShop, useStorefrontActions} from 'store/yardsale';
import {ReactComponent as StoreFrontIcon} from 'assets/icons/Storefront.svg';
import './Bazaar.scss';
import NotAuthedHeader from 'components/Header/NotAuthed/NotAuthedHeader';
import {Link} from 'react-router-dom';
import {SearchInput} from 'components/ui/input/Input';
import {UserCard} from 'components/UserCard/UserCard';
import Loader from 'components/Loader/Loader';
import { ScreenContainer } from 'components/ui/container/Container';
import { xNFTAtom } from 'store/xnft';
import { Feed } from 'components/Feed/Feed';
import { ListingCard } from 'components/NftCard/ListingCard/ListingCard';
import { useHubActions } from 'store/hubs/actions';
import { IFeaturedHub } from 'store/hubs/types';
import { ENOTI_STATUS, useToastsActions } from 'store/toasts';
import useToasts from 'hooks/useToasts';

type Props = any;

const Listings = () => {
  const bazaarActions = useStorefrontActions();
  const [featured, setFeatured] = React.useState<IListingThumb[]>([]);
  const [isSearching, toggleSearching] = React.useState(true);
  const [searchResults, setSearchResults] = React.useState<IListingThumb[]>([]);
  const [search, setSearch] = React.useState('');

  const handleSearchMethod = React.useRef(
    debounce(
      async (str: string) => {
        const results = await bazaarActions.searchItems(str);

        setSearchResults(results);
      },
      500,
      {trailing: true, leading: false}
    )
  );

  React.useEffect(() => {
    toggleSearching(false);
  }, [searchResults]);

  React.useEffect(() => {
    if (search.length) {
      toggleSearching(true);
      handleSearchMethod?.current(search);
    } else setSearchResults([]);
  }, [search]);

  React.useEffect(() => {
    (async function () {
      const featured = (await bazaarActions.getFeatured()).listings;

      setFeatured(featured);
      toggleSearching(false);
    })();
  }, []);

  return (
    <>
      <SearchInput value={search} onChangeText={setSearch} placeholder="Search for items in listings" />
      <p className="subheader">Listings</p>
      <div className="listings">
        {isSearching ? (
          <Loader />
        ) : search.length && !searchResults.length ? (
          <div className="not-found">
            <p className="normal">No listings found</p>
          </div>
        ) : (
          <div className="grid">
            {(search.length ? searchResults : featured).map((x, idx) => {
              return (
                <div className="grid-item" key={x.id}>
                  <ListingCard
                    link={`shop/${x.seller?.stacheid}/listing/${x.id}`}
                    listing={x}
                    state={{}} // todo
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>

    </>
  )
}

const Shops = () => {
  const bazaarActions = useStorefrontActions();
  const [isSearching, toggleSearching] = React.useState(true);
  const [searchResults, setSearchResults] = React.useState<IShop[]>([]);
  const [search, setSearch] = React.useState('');
  const [shops, setShops] = React.useState <IShop[]> ([]);

  const handleSearchMethod = React.useRef(
    debounce(
      async (str: string) => {
        const results = (await bazaarActions.searchShops(str))

        setSearchResults(results);
      },
      500,
      {trailing: true, leading: false}
    )
  );

  React.useEffect(() => {
    toggleSearching(false);
  }, [searchResults]);

  React.useEffect(() => {
    if (search.length) {
      toggleSearching(true);
      handleSearchMethod?.current(search);
    } else {
      setSearchResults([]);
    }
  }, [search]);

  React.useEffect(() => {
    (async function () {
      const shops: IShop[] = await bazaarActions.getTopShops();

      setShops(shops);
    })();
  }, []);

  return (
    <>
      <SearchInput value={search} onChangeText={setSearch} placeholder="Search for a particular shop" />
      <p className="subheader">Shops</p>
      <div className="listings">
        {isSearching ? (
          <Loader />
        ) : search.length && !searchResults.length ? (
          <div className="not-found">
            <p className="normal">No shops found</p>
          </div>
        ) : (
          <div className="grid">
            {(search.length ? searchResults : shops).map((x, idx) => {
              return (
                <div className="grid-item" key={idx}>
                  <UserCard shop={x} link={`shop/${x.seller.stacheid}`} />
                </div>
              );
            })}
          </div>
        )}
      </div>

    </>
  )
}


export const Bazaar: FC<any> = (props: Props): ReactElement => {
  const bazaarActions = useStorefrontActions();
  const navigate = useNavigate();
  const [userProfile, setUserProfile] = useRecoilState(userProfileAtom);
  const [topShops, setTopShops] = React.useState<IShop[][]>([]);
  const [isXNFT, setIsXNFT] = useRecoilState(xNFTAtom);
  const [tabView, setTabView] = React.useState <"hubs" | "listings" | "shops" | "activity"> ("listings");
  const {getFeaturedHubs} = useHubActions();
  const [featuredHubs, setFeaturedHubs] = React.useState <IFeaturedHub[]> ([]);
  const {createToast} = useToasts();

  const RenderTab = useCallback(() => {
    switch (tabView) {
      case "listings":
        return <Listings/>
      case "shops":
        return <Shops/>
      case "hubs":
        return <></>
      case "activity":
        return <Feed getter = {async () => await bazaarActions.getRecentActivity()} itemsPerPage = {10}/>
    }
  }, [tabView]);

  React.useEffect(() => {
    (async function () {
      const shops: IShop[] = await bazaarActions.getTopShops();

      // sort array into (essentially) tuples for easy mapping
      const shopsNestedArray = [];
      for (let i = 0; i < shops.length; i++) {
        if (i % 2 === 0) {
          const arr = [shops[i]];
          if (!!shops[i + 1]) arr.push(shops[i + 1]);
          shopsNestedArray.push(arr);
        } else {
          continue;
        }
      }
      setTopShops(shopsNestedArray);
      try {
        setFeaturedHubs(await getFeaturedHubs());
      } catch (e) {
        createToast("No featured hubs founds", ENOTI_STATUS.ERR);
      }
    })();
  }, []);

  return (
    <ScreenContainer outerClassName="bazaar">
      {!userProfile.authed ? (
        <>
          <NotAuthedHeader />
          <p className="header exp" style={{ marginTop: "12px"}}>Featured Hubs</p>
        </>
      ) : (
        <div className="bazaar-header">
          <p className="header">Featured Hubs</p>
          <Link to="myshop">
            <StoreFrontIcon />
          </Link>
        </div>
      )}
      <div className="content">
        <div className = "hub-promo">
          {featuredHubs.map((x, idx) => (
            <Link key = {idx} to = {`/hub/${x.slug}`}>
              <img src = {x.logoImgUrl}/>
            </Link>
          ))}
        </div>
        {/* <div
          className="top-shops"
          style={{
            flexWrap: "nowrap",
            justifyContent: isXNFT ? "space-between" : "flex-start"
          }}
        >
          {topShops.map((shop: IShop[], i: number) => {
            if(isXNFT && i > 1) return;

            return (
                <div
                  key={i}
                  className="shopCon"
                >
                  <SmallUserCard shop={shop[0]}/>
                  {shop.length === 2 && <SmallUserCard shop={shop[1]}/>}
                </div>
              )
            })}
        </div> */}
        <div className = "controls">
          <div className="tabs">
            <div
              className={'touchable ' + (tabView === "listings" ? 'active' : '')}
              onClick={() => setTabView("listings")}
            >
              <p className="subheader">Listings</p>
              <div />
            </div>
            <div
              className={'touchable ' + (tabView === "shops" ? 'active' : '')}
              onClick={() => setTabView("shops")}
            >
              <p className="subheader">Shops</p>
              <div />
            </div>
            {/* <div
              className={'touchable ' + (tabView === "hubs" ? 'active' : '')}
              onClick={() => setTabView("hubs")}
            >
              <p className="subheader">Hubs</p>
              <div />
            </div> */}
            <div
              className={'touchable ' + (tabView === "activity" ? 'active' : '')}
              onClick={() => setTabView("activity")}
            >
              <p className="subheader">Activity</p>
              <div />
            </div>
          </div>
        </div>
        <RenderTab/>
      </div>
    </ScreenContainer>
  );
};

export default Bazaar;
