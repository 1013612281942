import {PublicKey} from '@metaplex-foundation/js';
import {getAssociatedTokenAddressSync, NATIVE_MINT} from '@solana/spl-token';
import {apiClient} from 'apis/server';
import {storefrontAtom} from './state';
import {stacheAtom} from 'store/stache';
import {useRecoilState, useRecoilValue} from 'recoil';
import {keychainAtom} from 'store/keychain';
import {ESEARCH_TYPE} from 'apis/server/types';
import {EAssetSelectionType, ELISTING_PROGRAM, IActivity, ICurrency, IListing, IListingThumb, IShop} from './types';
import {connectedWalletAtom, IWallet, NftType} from 'store/connectedWallets';
import {
  DelistBazaarPayload,
  ListingPayload,
  PurchasingBazaarPayload,
  PurchasingYardsalePayload,
  StandardListingsPayload,
  YardsaleDelistingPayload,
} from 'apis/solana/types';
import {ENOTI_STATUS} from 'store/toasts';
import {ComputeBudgetProgram, Transaction, TransactionInstruction} from '@solana/web3.js';
import {useWalletAdapter} from 'hooks/useWalletAdapter';
import {findListingPda} from 'programs/yardsale-utils';
import useToasts from 'hooks/useToasts';
import {BN, web3} from '@project-serum/anchor';
import {rpcClient, solanaClient} from 'constants/factory';
import {IMessage, MESSAGE_TYPE} from 'store/notification';
import {findListingDomainPda} from 'programs/bazaar-utils';
import {ensureIsPublicKey, ensureIsString} from 'utils/string-formatting';
import {userProfileAtom} from 'store/userProfile';

function useStorefrontActions() {
  const {createToast} = useToasts();
  const {wallet, confirmationResponse, constructErrorResponse, signAndSendTransaction} = useWalletAdapter();

  const [storefront, setStorefront] = useRecoilState(storefrontAtom);
  const [connectedWallet, setConnectedWallet] = useRecoilState(connectedWalletAtom);
  const stache = useRecoilValue(stacheAtom);
  const keychain = useRecoilValue(keychainAtom);
  const userProfile = useRecoilValue(userProfileAtom);

  const getMoneyAccounts = (currency: ICurrency, depositWallet: IWallet) => {
    let currencyAccount: PublicKey;
    let proceedsAccount: PublicKey | null;
    let proceedsTokenAccount: PublicKey | null;
    if (ensureIsString(currency.mint) === NATIVE_MINT.toBase58()) {
      currencyAccount = NATIVE_MINT;
      proceedsAccount = depositWallet.address;
      proceedsTokenAccount = null;
    } else {
      const token = depositWallet.tokens.find((token) => token.mint.toBase58() === ensureIsString(currency.mint));
      currencyAccount = token.mint;
      proceedsAccount = null;
      proceedsTokenAccount = token.tokenAccount;
    }

    return {currencyAccount, proceedsAccount, proceedsTokenAccount};
  };

  const createBazaarListing = async (
    nftMints: PublicKey[],
    itemQuantities: number[],
    price: number,
    currency: ICurrency,
    bagName: string,
    desc: string,
    depositWallet: IWallet,
    listingType: EAssetSelectionType
  ): Promise<any | string> => {
    const keychainPda = new PublicKey(keychain.pda);
    const sellerAccountPda = new PublicKey(userProfile.profileInfo.sellerAccountPda);
    const [listingDomainPda] = findListingDomainPda();
    const {currencyAccount, proceedsAccount, proceedsTokenAccount} = getMoneyAccounts(currency, depositWallet);
    const listingPda = await solanaClient.getBazaarListingPdaForCreateListing(sellerAccountPda);
    const fromTokenAccounts = nftMints.map((mint) =>
      !!mint ? getAssociatedTokenAddressSync(ensureIsPublicKey(mint), wallet.publicKey) : null
    );

    const listingIxs: TransactionInstruction[] = await solanaClient.createBazaarListingInstructions({
      nftMints,
      fromTokenAccounts,
      keychainPda,
      sellerAccountPda,
      listingDomainPda,
      currencyAccount,
      proceedsAccount,
      listingPda,
      proceedsTokenAccount,
      price,
      decimals: currency.decimals,
      itemQuantities: itemQuantities.map((quantity) => new BN(quantity)),
      assetListingType: listingType,
    } as StandardListingsPayload);

    let listingTx = new web3.Transaction().add(...listingIxs);
    const listingTxid = await signAndSendTransaction(listingTx);

    createToast('Confirming transaction... ', ENOTI_STATUS.DEFAULT);
    const listingConfirmation = await rpcClient.confirmTransaction(listingTxid);

    if (listingConfirmation) {
      // set this timeout to 8 seconds since backend takes a while to finalize confirmation
      createToast('Transaction confirmed! Syncing listing...', ENOTI_STATUS.DEFAULT, 8000);
      try {
        return await apiClient.createdListing(ELISTING_PROGRAM.BAZAAR, listingTxid, desc, bagName);
      } catch (err) {
        console.log('Error syncing created bazaar listing', err);
        return "Transaction was submitted but couldn't be confirmed.";
      }
    } else {
      // todo: this is stupid (returning a string vs data)
      return "Transaction couldn't be synced.";
    }
  }

  const createYardsaleListing = async (
    nftMint: PublicKey,
    price: number,
    currency: ICurrency,
    desc: string,
    depositWallet: IWallet,
    tokenType: NftType
  ): Promise<any | string> => {
    try {
      let fromItemToken = getAssociatedTokenAddressSync(nftMint, wallet.publicKey);
      let [listingPda] = findListingPda(nftMint, stache?.stacheid ?? keychain.name);
      let listingItemToken = getAssociatedTokenAddressSync(nftMint, listingPda, true);

      const {currencyAccount, proceedsAccount, proceedsTokenAccount} = getMoneyAccounts(currency, depositWallet);

      let ixs: TransactionInstruction[] = [];
      const payload: ListingPayload = {
        price,
        decimals: currency.decimals,
        currencyAccount,
        nftMint,
        proceedsAccount,
        proceedsTokenAccount,
        fromItemToken,
        listingPda,
        listingItemToken,
      };
      switch (tokenType) {
        case NftType.Programmable: {
          ixs = await solanaClient.createPNFTListingInstructions(payload);
          // need to increase the CU cause the tm program uses a LOT
          const modifyComputeUnits = ComputeBudgetProgram.setComputeUnitLimit({
            units: 300_000,
          });
          ixs.push(modifyComputeUnits);
          break;
        }
        case NftType.Compressed: {
          ixs = await solanaClient.createCNFTListingInstructions(payload);
          break;
        }
        default: {
          // this shouldn't happen
          console.log(`warning!!!! creating yardsale listing for standard token type ${tokenType}`);
          ixs = await solanaClient.createYardsaleListingInstructions(payload)
          break;
        }
      }

      // for doing versioned trasactions - not supported by wallet adapter yet
      // const messageV0 = new TransactionMessage({
      //   payerKey: wallet.publicKey,
      //   recentBlockhash: (await connection.getLatestBlockhash()).blockhash,
      //   instructions: ixs,
      // }).compileToLegacyMessage();
      // let tx = new web3.VersionedTransaction(messageV0);

      let tx = new web3.Transaction().add(...ixs);
      const txid = await signAndSendTransaction(tx);

      createToast('Confirming transaction... ', ENOTI_STATUS.DEFAULT);
      const confirmation = await rpcClient.confirmTransaction(txid);

      if (confirmation) {
        // set this timeout to 7 seconds since backend takes a while to finalize confirmation
        createToast('Transaction confirmed! Syncing listing...', ENOTI_STATUS.DEFAULT, 7000);
        // Now update the server db
        return await apiClient.createdListing(ELISTING_PROGRAM.YARDSALE, txid, desc);
      } else {
        return 'Failed to confirm transaction';
      }
    } catch (e) {
      console.log('Creating listing error: ', e);
      return confirmationResponse(false);
    }
  };

  const purchaseBazaarListing = async (
    listing: IListing,
    nftMints: (PublicKey | null)[],
    quantity: number,
    openConfettiModal: () => void
  ) => {
    try {
      let buyerCurrencyTokenAccount: PublicKey;
      if (ensureIsString(listing.currency?.mint) === NATIVE_MINT.toBase58()) {
        const bal = await rpcClient.connection.getBalance(wallet.publicKey); // Check for sufficient funds, and return if not
        if (bal <= parseInt(listing.priceBN)) return 'Wallet has insufficient funds';

        buyerCurrencyTokenAccount = null; // No token account required. Using Sol
      } else {
        const splAccount = await rpcClient.findTokenAccount(wallet.publicKey, ensureIsPublicKey(listing.currency.mint));
        if (splAccount) {
          // then check balance
          if (splAccount.amount <= parseInt(listing.priceBN)) {
            return `You don't have enough ${listing.currency.symbol}!`;
          } else {
            buyerCurrencyTokenAccount = splAccount.address;
          }
        } else {
          // then user doesn't have this token account/currency
          return `You don't have any ${listing.currency.symbol}!`;
        }
      }

      let buyerItemTokenAccounts = nftMints.map((mint) =>
        !!mint ? getAssociatedTokenAddressSync(mint, wallet.publicKey, false) : null
      );
      const payload: PurchasingBazaarPayload = {
        nftMints,
        buyerCurrencyTokenAccount,
        sellerAccountPda: new PublicKey(listing.seller.sellerAccountPda),
        buyerItemTokenAccounts,
        unitQuantity: quantity,
        listingPda: new PublicKey(listing.pda),
      };

      const tx: Transaction = await solanaClient.purchaseBazaarListing(payload);
      const txid = await signAndSendTransaction(tx);
      const confirmation = await rpcClient.confirmTransaction(txid);

      if(confirmation){
        // set this timeout to 8 seconds since backend takes a while to finalize confirmation
        createToast('Syncing listing...', ENOTI_STATUS.DEFAULT, 8000);

        const res = await apiClient.syncBazaarListing(ELISTING_PROGRAM.BAZAAR, txid);
        openConfettiModal();
        return confirmationResponse(res);
      } else {
        return "Purchase transaction couldn't be confirmed.";
      }
    } catch (e) {
      console.log('error purchasing: ', e);
      return "There was an unexpected error with your purchase.";
    }
  };

  const purchaseYardsaleListing = async (listing: IListing, stacheid: string, openConfettiModal: () => void) => {
    // Below is the correct way. If scaling solutions are required, uncomment this and complete it
    const stubIntoWallet = () => {
      // If logged in
      if (!!connectedWallet) {
        // gonna have to think about this one
      }
    };

    try {
      let buyerCurrencyTokenAccount: PublicKey;
      if (ensureIsString(listing.currency?.mint) === NATIVE_MINT.toBase58()) {
        const bal = await rpcClient.connection.getBalance(wallet.publicKey); // Check for sufficient funds, and return if not
        if (bal <= parseInt(listing.priceBN)) return 'Wallet has insufficient funds';

        buyerCurrencyTokenAccount = null; // No token account required. Using Sol
      } else {
        const splAccount = await rpcClient.findTokenAccount(wallet.publicKey, ensureIsPublicKey(listing.currency.mint));
        if (splAccount) {
          // then check balance
          if (splAccount.amount <= parseInt(listing.priceBN)) {
            return `You don't have enough ${listing.currency.symbol}!`;
          } else {
            buyerCurrencyTokenAccount = splAccount.address;
          }
        } else {
          // then user doesn't have this token account/currency
          return `You don't have any ${listing.currency.symbol}!`;
        }
      }

      // Gathering accounts
      const mintPubKey = new PublicKey(listing.items[0].mint);
      let buyerItemTokenAccount = getAssociatedTokenAddressSync(mintPubKey, wallet.publicKey, false);
      let [listingPda] = findListingPda(mintPubKey, stacheid);
      const payload: PurchasingYardsalePayload = {
        nftMint: mintPubKey,
        buyerItemTokenAccount,
        buyerCurrencyTokenAccount,
        listingPda,
      };

      let tx: Transaction;
      switch (listing.nftType) {
        case NftType.Programmable: {
          tx = await solanaClient.purchaseProgrammableNft(payload);

          // tm program uses a LOT of compute units
          const modifyComputeUnits = ComputeBudgetProgram.setComputeUnitLimit({
            units: 300_000,
          });
          tx.add(modifyComputeUnits);
          break;
        }
        case NftType.Compressed: {
          tx = await solanaClient.purchaseCompressedNft(payload);
          break;
        }
        default: {
          tx = await solanaClient.purchaseYardsaleNft(payload);
          break;
        }
      }

      const txid = await signAndSendTransaction(tx);
      createToast('Confirming transaction...', ENOTI_STATUS.DEFAULT);
      const confirmation = await rpcClient.confirmTransaction(txid);
      openConfettiModal();

      // Now update the server db
      const res = await apiClient.syncNonStandardListing(listing.id, txid);

      return confirmationResponse(confirmation && res, stubIntoWallet);
    } catch (e) {
      return constructErrorResponse(e);
    }
  };

  const delistBazaarListing = async (listing: IListing, nftMints: (PublicKey | null)[]) => {
    try {
      const sellerAccountPda = new PublicKey(userProfile.profileInfo.sellerAccountPda);
      const fromTokenAccounts = nftMints.map((mint) =>
        !!mint ? getAssociatedTokenAddressSync(ensureIsPublicKey(mint), wallet.publicKey) : null
      );
      const payload: DelistBazaarPayload = {
        sellerAccountPda,
        nftMints,
        fromTokenAccounts,
        listingPda: new PublicKey(listing.pda),
      };
      let tx: Transaction = new web3.Transaction();

      const ix: TransactionInstruction = await solanaClient.delistBazaarListing(payload);
      // increase tx size if >= 4 items
      if (listing.items.length >= 4) {
        tx.add(solanaClient.getIncreaseTxSizeIx(300_000));
      }
      tx.add(ix);

      const txid = await signAndSendTransaction(tx);
      const confirmation = await rpcClient.confirmTransaction(txid);
      if (confirmation) {
        createToast('Syncing delisting...', ENOTI_STATUS.DEFAULT, 8000);
        const res = await apiClient.syncBazaarListing(ELISTING_PROGRAM.BAZAAR, txid);
        // todo: god this is fucking stupid
        return confirmationResponse(!!res);
      } else {
        return "Delisting transaction submitted but couldn't be confirmed.";
      }
    } catch (e) {
      console.log('problem delisting nft', e);
      return "There was an unexpected problem delisting this item.";
    }
  };

  const delistYardsaleListing = async (listing: IListing) => {
    try {
      const mintPubKey = new PublicKey(listing.items[0].mint);
      let sellerItemToken = getAssociatedTokenAddressSync(mintPubKey, wallet.publicKey);
      let [listingPda] = findListingPda(mintPubKey, stache?.stacheid ?? keychain.name);
      let listingItemToken = getAssociatedTokenAddressSync(mintPubKey, listingPda, true);

      const payload: YardsaleDelistingPayload = {
        listingPda,
        nftMint: mintPubKey,
        sellerItemToken,
        listingItemToken,
      };

      let tx: Transaction = new web3.Transaction();
      switch (listing.nftType) {
        case NftType.Programmable: {
          tx = await solanaClient.delistPNFT(payload);
          // tm program uses a LOT of compute units
          const modifyComputeUnits = ComputeBudgetProgram.setComputeUnitLimit({
            units: 300_000,
          });
          tx.add(modifyComputeUnits);
          break;
        }
        case NftType.Compressed: {
          tx = await solanaClient.delistCNFT(payload);
          break;
        }
        default: {
          tx = await solanaClient.delistYardsaleListing(payload)
          break;
        }
      }

      const txid = await signAndSendTransaction(tx);
      const confirmation = await rpcClient.confirmTransaction(txid);

      createToast('Confirming delisting...', ENOTI_STATUS.DEFAULT, 9000);

      // Now update the server db
      const res = await apiClient.updateListing(listing.id, ELISTING_PROGRAM.YARDSALE, txid);
      return confirmationResponse(confirmation && !!res);
    } catch (e) {
      console.log('problem delisting nft', e);
      return confirmationResponse(false);
    }
  };

  const createSellerAccount = async () => {
    try {
      const keychainPda = new PublicKey(keychain.pda);
      const sellerIx: TransactionInstruction = await solanaClient.getCreateSellerForKeychainIx(keychainPda);
      let sellerTx: Transaction = new web3.Transaction().add(sellerIx);
      const sellerTxId = await signAndSendTransaction(sellerTx);
      createToast('Confirming seller transaction... ', ENOTI_STATUS.DEFAULT);
      const sellerConfirmation = await rpcClient.confirmTransaction(sellerTxId);

      if (sellerConfirmation) {
        createToast('Seller account created!', ENOTI_STATUS.DEFAULT, 7000);
        return confirmationResponse(true);
      } else {
        console.log('problem confirmating seller account');
        return confirmationResponse(false);
      }
    } catch (e) {
      console.log('problem creating seller account', e);
      return confirmationResponse(false);
    }
  };

  const updateBazaarListing = async (listing: IListing, newName: string, newDesc: string, newPrice?: number, decimals?: number) => {
    if (listing.program !== ELISTING_PROGRAM.BAZAAR) {
      throw new Error('This is not a Bazaar listing: :' + listing.program);
    }
    const stubInEdit = async () => {
      // todo is there a better way to do this instead of mapping the whole thing? Can I just mutate 1 value inside of it directly?
      const updatedList = storefront.map((item) => {
        if (item.id === listing.id) {
          return {...item, name: newName, price: newPrice, desc: newDesc};
        } else {
          return item;
        }
      });
      setStorefront(updatedList);
    };

    try {
      let txid: string = null;
      if (newPrice !== listing.price) {
        const tx = await solanaClient.updateBazaarListing(
          newPrice,
          decimals,
          new PublicKey(userProfile.profileInfo.sellerAccountPda),
          new PublicKey(listing.pda)
        );
        const txid = await signAndSendTransaction(tx);
        createToast('Syncing listing...', ENOTI_STATUS.DEFAULT, 8000);
        await rpcClient.confirmTransaction(txid);
      }
      const res = await apiClient.updateListing(listing.id, ELISTING_PROGRAM.BAZAAR, txid, newDesc, newName);
      return confirmationResponse(!!res, stubInEdit);
    } catch (e) {
      console.log('problem updating listing', e);
      return confirmationResponse(false);
    }
  };

  const updateYardsaleListing = async (listing: IListing, newDesc: string, newPrice?: number, decimals?: number) => {
    if (listing.program !== ELISTING_PROGRAM.YARDSALE) {
      throw new Error('This is not a Yardsale listing: :' + listing.program);
    }
    const stubInEdit = async () => {
      // todo is there a better way to do this instead of mapping the whole thing? Can I just mutate 1 value inside of it directly?
      const updatedList = storefront.map((item) => {
        if (item.id === listing.id) {
          return {...item, price: newPrice, desc: newDesc};
        } else {
          return item;
        }
      });
      setStorefront(updatedList);
    };

    try {
      let txid: string;
      // starts true because the entire confirmation process may be skipped if nothing on chain needs to updated (only server db to be changed)
      let confirmation: boolean = true;
      if (newPrice !== listing.price) {
        const mintPubKey = new PublicKey(listing.items[0].mint);
        let [listingPda] = findListingPda(mintPubKey, stache?.stacheid ?? keychain.name);
        const tx = await solanaClient.updateYardsaleListing(newPrice, decimals, mintPubKey, listingPda);
        const txid = await signAndSendTransaction(tx);
        confirmation = await rpcClient.confirmTransaction(txid);
      }

      // Now update the server db
      const res = await apiClient.updateListing(listing.id, ELISTING_PROGRAM.YARDSALE, txid, newDesc);
      return confirmationResponse(confirmation && !!res, stubInEdit);
    } catch (e) {
      console.log('problem updating listing', e);
      return confirmationResponse(false);
    }
  };

  const getStorefrontItems = async (stacheid: string) => {
    const res: IListing[] = await apiClient.getListingsForStacheid(stacheid);
    setStorefront(res);
  };

  const getTopShops = async (): Promise<IShop[]> => {
    const res = await apiClient.getTopShops();
    console.log('Top shops: ', res);
    return res;
  };

  const getRecentActivity = async (): Promise<IActivity[]> => {
    const res = await apiClient.getActivityFeed();
    return res;
  };

  const getFeatured = async (): Promise<{listings: IListingThumb[]; shops: IShop[]}> => {
    const res = await apiClient.getFeaturedFeed();
    return res;
  };

  const searchShops = async (query: string): Promise<IShop[]> => {
    const res = await apiClient.search(query, ESEARCH_TYPE.SHOPS);
    return res?.results ?? [];
  };

  const searchListings = async (query: string): Promise<IListingThumb[]> => {
    const res = await apiClient.search(query, ESEARCH_TYPE.LISTINGS);
    return res?.results ?? [];
  };

  const getStorefrontListing = async (listingId: number): Promise<IListing> => {
    return await apiClient.getListing(listingId);
  };

  async function postMessageToListing(name: string, text: string, listingId: number): Promise<IMessage> {
    const res = await apiClient.postMessageToKeychain(name, text, MESSAGE_TYPE.LISTING, listingId);
    return res;
  }

  async function getListingMessages(listingId: number): Promise<IMessage[]> {
    const res = await apiClient.getListingMessages(listingId);
    return res;
  }

  async function getListing(pda: PublicKey) {
    const res = await solanaClient.fetchYardsaleListing(pda, false);
    return res;
  }

  return {
    delistBazaarListing,
    delistYardsaleListing,
    updateBazaarListing,
    updateYardsaleListing,
    purchaseBazaarListing,
    purchaseYardsaleListing,
    createYardsaleListing,
    createBazaarListing,
    getStorefrontItems,
    getStorefrontItem: getStorefrontListing,
    getTopShops,
    getRecentActivity,
    getFeatured,
    getListing,
    searchShops,
    searchItems: searchListings,
    postMessageToListing,
    getListingMessages,
    createSellerAccount,
  };
}

export {useStorefrontActions};
