//Web3
import {PublicKey} from "@solana/web3.js";

//Recoil
import {useRecoilState, useResetRecoilState} from "recoil";
import {IStache, stacheAtom} from "store/stache";
import {IKeychain, useKeychainActions} from "store/keychain";

//Clients
import {AccountResponse, SKeychain, SStache} from "apis/solana/types";
import {useWalletAdapter} from "../../hooks/useWalletAdapter";
import {connectedWalletAtom} from "store/connectedWallets";
import {ENABLE_STACHE} from "constants/config";
import {rpcClient, solanaClient} from "constants/factory";
import {ENOTI_STATUS} from "../toasts";
import useToasts from "../../hooks/useToasts";


function useStacheActions() {

  const {signAndSendTransaction} = useWalletAdapter();

  const {createToast} = useToasts();
  const [stache, setStache] = useRecoilState(stacheAtom);
  const resetStacheState = useResetRecoilState(stacheAtom);
  const [, setConnectedWallet] = useRecoilState(connectedWalletAtom);
  const keychainActions = useKeychainActions();

  // first checks to see if a keychain for this wallet exists, then pulls the stacheid from the account and loads
  // the stache
  async function checkForStache(walletAddress: PublicKey = null): Promise<{
    keychainState: IKeychain,
    stacheState: IStache
  }> {
    console.log(`Checking for stache of wallet ${walletAddress.toBase58()}.... This triggers the keychain search by wallet in Solana client`);
    const keychainResponse: AccountResponse<SKeychain> = await solanaClient.getKeychainByKeyWallet(walletAddress);
    let stacheState: IStache = null;
    let keychainState = null;

    if (keychainResponse?.account) {
      keychainState = keychainActions.setKeychainState(keychainResponse, walletAddress);
      if (ENABLE_STACHE) {
        const stacheid = keychainResponse.account.name;
        const stacheResponse = await solanaClient.getStacheById(stacheid);
        if (stacheResponse.account) {
          // then we set the keychain & the stache
          console.log('wallet before checking stache: ', keychainResponse, stacheResponse);
          stacheState = createStacheState(stacheResponse.pda, stacheResponse.account);
          setStache(stacheState);
        }
      }
    }
    setConnectedWallet(prev => ({...prev, checkedForStache: true}));

    return {
      keychainState,
      stacheState
    };
  }

  async function createStache(stacheid: string, walletAddress: PublicKey): Promise<{
    stacheState?: IStache,
    keychainState: IKeychain
  }> {

    stacheid = stacheid.toLowerCase().trim();

    // create the stache
    const txHolder = await solanaClient.getCreateStacheTx(stacheid, walletAddress);

    const txid = await signAndSendTransaction(txHolder.tx);

    // note: we're waiting for finalization here instead of the standard 'confirm' commitment cause helius seems to take a long time for an
    // account that's created to be available for fetching

    createToast('Fully confirming transaction (this might take a bit)...', ENOTI_STATUS.DEFAULT, 8000);
    await rpcClient.confirmTransaction(txid, 'finalized');

    const keychainResponse = await solanaClient.getKeychainByKeyWallet(walletAddress);
    const keychainState = keychainActions.setKeychainState(keychainResponse);
    let stacheState: IStache;

    if (ENABLE_STACHE) {
      const stacheResponse: AccountResponse<SStache> = await solanaClient.fetchStacheByPda(txHolder.pda);
      if (stacheResponse.account) {
        const stache = stacheResponse.account;
        stacheState = createStacheState(stacheResponse.pda, stache);
        setStache(prev => ({...prev, ...stacheState}));
      } else {
        throw new Error(`Error creating stache for stacheid ${stacheid}`);
      }
    }

    return {
      stacheState,
      keychainState
    };
  }

  async function fetch(stachePda): Promise<IStache> {
    console.log('loading stache by pda >>> ', stachePda);
    const stacheResponse: AccountResponse<SStache> = await solanaClient.fetchStacheByPda(stachePda);
    const stache = stacheResponse.account;
    return {
      ...stache,
      avatarUrl: null,
      stachePda
    }
  }

  function createStacheState(stachePda: PublicKey, account: any): IStache {
    const vaults = new Uint8Array(account.vaults);
    const vaultIndexes = [];
    vaults.forEach((vaultNum) => {
      vaultIndexes.push(vaultNum);
    });
    const autos = new Uint8Array(account.autos);
    const autoIndexes = [];
    autos.forEach((vaultNum) => {
      autoIndexes.push(vaultNum);
    });
    return {
      stachePda,
      avatarUrl: null,
      stacheid: account.stacheid,
      nextVaultIndex: account.nextVaultIndex,
      nextAutoIndex: account.nextAutoIndex,
      vaults: vaultIndexes,
      autos: autoIndexes
    }
  }

  function incrementNextVaultIndex() {
    setStache(prev => ({...prev, nextVaultIndex: prev.nextVaultIndex + 1}));
  }

  function incrementNextAutoIndex() {
    setStache(prev => ({...prev, nextAutoIndex: prev.nextVaultIndex + 1}));
  }

  function resetStache(): boolean {
    resetStacheState();
    return true;
  }

  return {
    checkForStache,
    createStache,
    resetStache,
    incrementNextVaultIndex,
    incrementNextAutoIndex,
    fetch
  }
}

export {useStacheActions}

