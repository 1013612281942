import { COLORS, SPACING } from 'constants/theme';
import {useWalletAdapter} from "../../hooks/useWalletAdapter";
import {formatAddress} from "../../utils/string-formatting";

import "../Header/NotAuthed/NotAuthedHeader.scss";


const WalletButton = () => {

    const {wallet} = useWalletAdapter();

    return (
      <button
         className= "connectBut"
         disabled={true}
      >
        <p className="connectText">
          {formatAddress(wallet.publicKey.toBase58())}
        </p>
      </button>
    );
}

 export default WalletButton;
