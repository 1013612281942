// idea here is to use an error that contains "user-friendly" messages we can display, which will
// help the user (and us) when debugging issues

export class FriendlyError extends Error {
  constructor(message) {
    super(message)
    this.name = 'FriendlyError'
    this.message = message
  }
}
