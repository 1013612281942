import React, {useCallback} from 'react';
import {ReactComponent as MintIcon} from 'assets/icons/Mint.svg';
import {ReactComponent as FariyWand} from 'assets/icons/FariyWand.svg';
import {COLORS, SPACING, VIEWPORT} from 'constants/theme';
import {usePlayground} from 'hooks/usePlayground';
import useToasts from 'hooks/useToasts';
import {ENOTI_STATUS} from 'store/toasts';
import {useConnectedWalletActions} from 'store/connectedWallets';
import {ColoredButton} from 'components/ui/button/Button';
import './Playground.scss';
import {ScreenContainer} from 'components/ui/container/Container';

interface IPlaygroundProps {}

export const Playground: React.FC<IPlaygroundProps> = () => {
  const playground = usePlayground();
  const connectedWalletActions = useConnectedWalletActions();
  const {createToast} = useToasts();

  const handleMinting = useCallback(async () => {
    await playground.mintRandomNft();
    await connectedWalletActions.load(true);
    createToast('Your NFT has been minted to your wallet!', ENOTI_STATUS.SUCCESS);
  }, []);

  const handlePnftMinting = useCallback(async () => {
    await playground.mintRandomPnft();
    await connectedWalletActions.load(true);
    createToast('Your pNFT has been minted to your wallet!', ENOTI_STATUS.SUCCESS);
  }, []);

  const handleSftMinting = useCallback(async () => {
    await playground.mintSfts();
    await connectedWalletActions.load(true);
    createToast('Your 10 SFTs have been minted to your wallet!', ENOTI_STATUS.SUCCESS);
  }, []);

  const handleAirdrop = useCallback(async () => {
    const withdrawData = await playground.withdrawSessionVaultRandomTokens();
    await connectedWalletActions.load();
    createToast(
      `${withdrawData.amount} ${withdrawData.name} tokens have been dropped into your Stache account!`,
      ENOTI_STATUS.SUCCESS
    );
  }, []);

  return (
    <ScreenContainer className="playgroundCon">
      <div className="section">
        <div className="innerCon">
          <MintIcon height={35} width={35} color={COLORS.WHITE} />
          <h1 className="text">NFT Minter</h1>
        </div>
        <p className="innerText">Mint a random NFT for testing.</p>
        <ColoredButton onClick={handleMinting} className="buttons">
          Mint NFT
        </ColoredButton>
      </div>
      <div className="divider" />
      <div className="section">
        <div className="innerCon">
          <MintIcon height={35} width={35} color={COLORS.WHITE} />
          <h1 className="text">pNFT Minter</h1>
        </div>
        <p className="innerText">Mint a random pNFT for testing.</p>
        <ColoredButton onClick={handlePnftMinting} className="buttons">
          Mint pNFT
        </ColoredButton>
      </div>
      <div className="divider" />
      <div className="section">
        <div className="innerCon">
          <MintIcon height={35} width={35} color={COLORS.WHITE} />
          <h1 className="text">SFT Minter</h1>
        </div>
        <p className="innerText">Mint a few SFTs for testing.</p>
        <ColoredButton onClick={handleSftMinting} className="buttons">
          Mint 10 SFTs
        </ColoredButton>
      </div>
      {/* <div className="divider"/> */}
      {/* <div className="section">
          <div className="innerCon">
            <FariyWand height={35} width={35} color={COLORS.WHITE}/>
            <h1 className="text}>Token Fairy</h1>
          </div>
          <p className="ze: 10, marginBottom: SPACING.MD}}>Get airdropped some tokens for
            testing.</p>
          <ColoredButton
              label='Sprinkle'
              onClick={handleAirdrop}
              className="buttons}
          />
        </div> */}
    </ScreenContainer>
  );
};
