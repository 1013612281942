import {AnchorWallet, useWallet} from "@solana/wallet-adapter-react";
import {Transaction} from "@solana/web3.js";
import {connection} from "../constants/factory";
import { useRecoilValue } from "recoil";
import { xNFTAtom } from "store/xnft";
import {connectedWalletAtom} from "../store/connectedWallets";
import {Wallet} from "@project-serum/anchor";


export function useWalletAdapter() {

  // wallet adapter
  const walletContextState = useWallet();

  // backpack
  const isXNFT = useRecoilValue(xNFTAtom);

  const connectedWallet = useRecoilValue(connectedWalletAtom);

  // @ts-ignore
  const wallet: Wallet = isXNFT ? window.xnft.solana : walletContextState;

  async function updateTx(tx: Transaction) {
    tx.recentBlockhash = (await connection.getLatestBlockhash('max')).blockhash;
    tx.feePayer = connectedWallet.address;
  }

  async function signAndSendTransaction<T extends Transaction>(tx: T): Promise<string> {
    if (!tx.recentBlockhash) {
      await updateTx(tx);
    }
    if (isXNFT) {
      // @ts-ignore
      return await window.xnft.solana.send(tx);
    } else {
      // return await walletContextState.sendTransaction(tx, connection, {skipPreflight: true});
      return await walletContextState.sendTransaction(tx, connection);
    }
  }

  function getWallet(): AnchorWallet {
    if (isXNFT) {
      // @ts-ignore
      return window.xnft.solana;
    } else {
      return walletContextState;
    }
  }

  async function signMessage<T extends Transaction>(msg: Uint8Array): Promise<Uint8Array> {
    if (isXNFT) {
      // @ts-ignore
      return await window.xnft.solana.signMessage(msg);
    } else {
      return await walletContextState.signMessage(msg);
    }

  }

  // todo: fix whatever uses this. responses either return a response data object or a string. da fuq?
  async function confirmationResponse(confirmed: boolean, successFunc?: () => void) {
    if (confirmed) {
      !!successFunc && successFunc();
      return true;
    } else return "Transaction not completed"
  }

  async function constructErrorResponse(error: any) {
    if (error.name === 'FriendlyError') {
      return error.message;
    } else {
      console.error(error);
      return "Transaction error.";
    }
  }

  async function disconnect() {
    if (!isXNFT) {
      await walletContextState.disconnect();
    }
  }


  return {
    confirmationResponse,
    constructErrorResponse,
    signAndSendTransaction,
    signMessage,
    wallet,
    disconnect
  }
}
