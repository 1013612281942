import React, {useCallback, useState} from 'react';
import './ConnectWallet.scss';

// Icons
import {ReactComponent as WalletIcon} from 'assets/icons/WalletIcon.svg';
import {ReactComponent as BazarIcon} from 'assets/icons/Bazaar.svg';

// Components
import NotAuthedHeader from 'components/Header/NotAuthed/NotAuthedHeader';
import Footer from 'components/Footer/Footer';

// Constants
import {APP_ENV, API_URL} from 'constants/config';
import {ERoute, EUnauthedRoute} from 'constants/routes';
import {SPACING, VIEWPORT} from 'constants/theme';

// Hooks
import {useAnalyticsActions} from 'hooks/useAnalytics';
import useAuth from 'hooks/useAuth';
import useToasts from 'hooks/useToasts';

// Recoil
import {useRecoilState, useRecoilValue} from 'recoil';
import {IConnectedWallet, connectedWalletAtom} from 'store/connectedWallets';
import {useKeychainActions, IKeychain} from 'store/keychain';
import {useStacheActions, IStache} from 'store/stache';
import {ENOTI_STATUS} from 'store/toasts';

// Libs
import useAsyncEffect from 'use-async-effect';
import {redirect, useNavigate} from 'react-router';
import {WalletMultiButton} from '@solana/wallet-adapter-react-ui';
import {ColoredButton, ColoredButtonInverse} from 'components/ui/button/Button';
import ModalWrapper from 'components/Modals/ModalWrapper/ModalWrapper';
import {WalletConnectedModal, WalletNotLinkedModal, WalletVerifiedModal} from 'components/Modals/WalletLogin/WalletLoginModal';
import {userProfileAtom} from 'store/userProfile';
import {xNFTAtom} from 'store/xnft';
import {ScreenContainer} from 'components/ui/container/Container';

export interface Props {
  [key: string]: any;
}

const ConnectWallet = (props: Props) => {
  const navigate = useNavigate();

  const {createToast} = useToasts();
  const stacheActions = useStacheActions();
  const keychainActions = useKeychainActions();
  const analyticsActions = useAnalyticsActions();
  const auth = useAuth();

  const [connectedWallet, setConnectedWallet] = useRecoilState<IConnectedWallet>(connectedWalletAtom);
  const [openConnectedModal, setOpenConnectedModal] = useState(false);
  const [openNotLinkedModal, setOpenNotLinkedModal] = useState(false);
  const [openVerifiedModal, setVerifiedModal] = useState(false);
  const [email, setEmail] = useState('');
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [userProfile, setUserProfile] = useRecoilState(userProfileAtom);
  const isXNFT = useRecoilValue(xNFTAtom);

  // for backpack
  const [initialConnect, setInitialConnect] = useState(true);

  // Check if connected wallet is connected a stache account and then
  // open 1 of 2 modals to direct user navigation

  // React.useEffect(() => {
  //   auth.logout();
  // }, []);

  useAsyncEffect(async () => {
    if (userProfile.authed) navigate('/home');
  }, [userProfile]);

  useAsyncEffect(async () => {
    if ((!isXNFT || (isXNFT && !initialConnect)) && connectedWallet && !connectedWallet?.checkedForStache) {
      const states: {
        keychainState: IKeychain;
        stacheState?: IStache;
      } = await stacheActions.checkForStache(connectedWallet.address);

      const isLinked = states.keychainState?.walletLinked;
      if (isLinked) {
        await keychainActions.loadBasicData(states.keychainState);
        keychainActions.loadAssetData(states.keychainState); // do NOT await this
        setOpenConnectedModal(true); // Wallet has a keychain-linked account
      } else {
        setOpenNotLinkedModal(true); // Wallet is not linked to a keychain account
      }
    }
  }, [connectedWallet, initialConnect]);

  useAsyncEffect(async () => {
    analyticsActions.trackScreen('Landing');
  }, []);

  React.useEffect(() => {
    if (emailSubmitted) setTimeout(() => setEmailSubmitted(false), 3000);
  }, [emailSubmitted]);

  const handleNavigateToRoute = useCallback(
    async (route: ERoute | EUnauthedRoute) => {
      setOpenConnectedModal(false);
      setOpenNotLinkedModal(false);
      navigate(route);
    },
    [setOpenConnectedModal, setOpenNotLinkedModal]
  );

  return (
    <ScreenContainer outerClassName="connect-wallet">
      {!userProfile.authed && 
        <NotAuthedHeader />
      }
      <div className="main" >
        <p className="banner">Stache: Solana's First Smart Account System</p>
        <p className="subheader">Your programmable on-chain account.</p>
        <div className="info-box">
          <p className="header header-1">The power of smart contract wallets comes to Solana!</p>
          <p className="normal">
            Manage your on-chain Stache smart accounts with any of your favorite Solana wallets. Stache accounts are
            programmable, giving you abilities your normal wallet doesn't have.
          </p>
          <p className="header">Ready to get started?</p>
          {isXNFT && (
            <ColoredButton onClick={() => setInitialConnect(false)} style={{alignSelf: 'center', width: "100%"}}>
              Let's Go!
            </ColoredButton>
          )}
          {!isXNFT && (
            <div className="connect-button">
              <WalletMultiButton>
                <WalletIcon />
                <p className="normal">Connect Wallet</p>
              </WalletMultiButton>
            </div>
          )}
          <p className="header">Or just explore...</p>
          <ColoredButtonInverse icon={<BazarIcon />} onClick={() => navigate('/' + EUnauthedRoute.BAZAAR)}>
            The Bazaar
          </ColoredButtonInverse>
        </div>
      </div>
      <Footer />
      <WalletConnectedModal
        text={'Found your Stache Account!'}
        isOpen={openConnectedModal}
        close={() => setOpenConnectedModal(false)}
      />
      <WalletVerifiedModal isOpen={openVerifiedModal} close={() => setVerifiedModal(false)} />
      <WalletNotLinkedModal
        isOpen={openNotLinkedModal}
        close={() => setOpenNotLinkedModal(false)}
        handleNavigate={() => handleNavigateToRoute(EUnauthedRoute.LINK_WALLET)}
        handleRegister={() => handleNavigateToRoute(EUnauthedRoute.CREATE_NEW_STACHE)}
      />
    </ScreenContainer>
  );
};

export default ConnectWallet;
