//Web3
import {PublicKey, Transaction} from "@solana/web3.js";
import {createAssociatedTokenAccountInstruction, getAssociatedTokenAddressSync} from "@solana/spl-token";

//Constants
import {EActionType, EBalanceCondition, ETriggerType, IAutomation} from "./types";
import {SActionType, SAuto, SBalanceCondition, STriggerType} from "../../apis/solana/types";

//Clients
//Utils
import {findAutoPda, findThreadPda} from "../../programs/stache-utils";
import {ClockProgramId, KEYCHAIN_DOMAIN, StacheProgramId} from "../../constants/config";
import {connection, rpcClient, solanaClient,} from "../../constants/factory";
import {useWalletAdapter} from "../../hooks/useWalletAdapter";

//Recoil
import {connectedWalletAtom} from "store/connectedWallets";
import {IStache, stacheAtom} from "../stache";
import {automationsAtom} from "./state";
import {useRecoilState, useRecoilValue} from "recoil";


function useAutomationActions() {

  const stache = useRecoilValue(stacheAtom);
  const {signAndSendTransaction} = useWalletAdapter();
  const connectedWallet = useRecoilValue(connectedWalletAtom);
  const [autos, setAutos] = useRecoilState<IAutomation[]>(automationsAtom);

  function getETriggerType(triggerType: STriggerType): ETriggerType {
    if (triggerType) {
      switch (triggerType) {
        case STriggerType.BALANCE:
          return ETriggerType.BALANCE;
      }
    }
    return undefined;
  }

  function getEActionType(actionType: SActionType): EActionType {
    if (actionType) {
      switch (actionType) {
        case SActionType.TRANSFER:
          return EActionType.TRANSFER;
      }
    }
    return undefined;
  }

  function getSBalanceCondition(balanceCondition: EBalanceCondition): SBalanceCondition {
    if (balanceCondition) {
      switch (balanceCondition) {
        case EBalanceCondition.GREATER_THAN:
          return SBalanceCondition.GREATER_THAN;
        case EBalanceCondition.LESS_THAN:
          return SBalanceCondition.LESS_THAN;
      }
    }
    return undefined;
  }

  function validateTriggerAndActionParams(triggerParams, actionParams) {
    // actionParams are req'd
    if (actionParams['transferFromTokenAccount'] && actionParams['transferToTokenAccount'] && actionParams['transferAmount'] && actionParams['mint']) {
    } else {
      throw new Error("Invalid action parameters. Missing one or more transfer action parameters");
    }

    // trigger might not be specified (manual only)
    if (triggerParams) {
      if (triggerParams['balanceTriggerTokenAccount'] && triggerParams['balanceTriggerAmount'] && triggerParams['balanceTriggerCondition']) {
        // then check that one of the to/from accounts matches the trigger account
        // this is due to a limitation of clockwork where you can't send in the remaining accounts to a thread trigger, so the "balance trigger" account needs to be one of the to/from accounts
        // or there's a way and i just haven't figured it out yet
        if (!actionParams['transferFromTokenAccount'].equals(triggerParams['balanceTriggerTokenAccount']) && !actionParams['transferToTokenAccount'].equals(triggerParams['balanceTriggerTokenAccount'])) {
          throw new Error("Invalid trigger parameters. Balance trigger token account must be one of the transfer action token accounts");
        }
      } else {
        throw new Error("Invalid trigger parameters. Missing one or more balance trigger parameters");
      }
    }
    if (triggerParams['balanceTriggerTokenAccount'] && triggerParams['balanceTriggerAmount'] && triggerParams['balanceTriggerCondition']) {
      // check that the
      return true;
    } else {
      throw new Error("Invalid trigger parameters");
    }
  }

  /*
  actionParams: j{
      transferMint,
      transferFromWallet: _fromWallet,
      transferToWallet: _toWallet,
      transferAmount: transferAmount, (BN)
    }

    triggerParams (optional if it's a manual trigger): {
      balanceTriggerMint,
      balanceTriggerWallet: _focusedWallet,
      balanceTriggerCondition: goes,
      balanceTriggerAmount: amount,  (BN)
      }
   */


  // note: manual triggers aren't currently supported so triggerParams isn't optional atm
  async function createAutomation(name: string, actionParams: any, triggerParams: any): Promise<IAutomation> {
    // validateTriggerAndActionParams(triggerParams, actionParams);

    const [autoPda] = findAutoPda(stache.nextAutoIndex, stache.stacheid, KEYCHAIN_DOMAIN, StacheProgramId);

    // first, determine if we need to create any ata's pu

    const balanceTriggerTokenAccount = getAssociatedTokenAddressSync(triggerParams.balanceTriggerMint, triggerParams.balanceTriggerWallet, true);
    const transferFromTokenAccount = getAssociatedTokenAddressSync(actionParams.transferMint, actionParams.transferFromWallet, true);
    const transferToTokenAccount = getAssociatedTokenAddressSync(actionParams.transferMint, actionParams.transferToWallet, true);

    const tx = new Transaction();
    // add instructions to create any ata's that don't exist
    let ataInfo = await connection.getAccountInfo(balanceTriggerTokenAccount);
    if (!ataInfo) {
      console.log(`adding ix to create ata for ${balanceTriggerTokenAccount.toString()}`);
      tx.add(createAssociatedTokenAccountInstruction(connectedWallet.address, balanceTriggerTokenAccount, triggerParams.balanceTriggerWallet, triggerParams.balanceTriggerMint));
    }
    ataInfo = await connection.getAccountInfo(transferFromTokenAccount);
    if (!ataInfo) {
      console.log(`adding ix to create ata for ${transferFromTokenAccount.toString()}`);
      tx.add(createAssociatedTokenAccountInstruction(connectedWallet.address, transferFromTokenAccount, actionParams.transferFromWallet, actionParams.transferMint));
    }
    ataInfo = await connection.getAccountInfo(transferToTokenAccount);
    if (!ataInfo) {
      console.log(`adding ix to create ata for ${transferToTokenAccount.toString()}`);
      tx.add(createAssociatedTokenAccountInstruction(connectedWallet.address, transferToTokenAccount, actionParams.transferToWallet, actionParams.transferMint));
    }

    tx.add(await solanaClient.getCreateAutoIx(name, autoPda));

    if (triggerParams) {
      tx.add(await solanaClient.getSetAutoBalanceTriggerIx(
          autoPda,
          balanceTriggerTokenAccount,
          triggerParams.balanceTriggerAmount,
          getSBalanceCondition(triggerParams['balanceTriggerCondition']))
      );
    }
    tx.add(await solanaClient.getSetAutoTransferActionIx(
        autoPda,
        transferFromTokenAccount,
        transferToTokenAccount,
        actionParams['transferMint'],
        actionParams['transferAmount'])
    );

    const [threadPda] = findThreadPda(name, autoPda, ClockProgramId);
    console.log(`creating automation thread: ${threadPda.toBase58()}`);
    tx.add(await solanaClient.getActivateAutoIx(autoPda, threadPda));

    console.log(`creating automation tx: ${JSON.stringify(tx, null, 3)}`);

    const txid = await signAndSendTransaction(tx);
    console.log(`Create automation txid: ${txid}`);
    await rpcClient.confirmTransaction(txid);

    const automation = await getAutomation(autoPda);
    return automation;
  }

  async function loadAutomations(stacheState: IStache) {
    const data = stacheState ?? stache;
    if (data) {
      // console.log("Stache exists, loading automations.....", data);
      const fetchedAutos: IAutomation[] = await Promise.all(data.autos.map(async autoIndex => {
        const [autoPda] = findAutoPda(autoIndex, data.stacheid, KEYCHAIN_DOMAIN, StacheProgramId);
        return getAutomation(autoPda);
      }));
      // console.log(`setting loaded automations: ${JSON.stringify(fetchedAutos, null, 3)}`);
      setAutos(fetchedAutos);
    }
  }

  async function getAutomation(autoPda: PublicKey): Promise<IAutomation> {
    const auto: SAuto = await solanaClient.getAuto(autoPda);
    const resp = {
      ...auto,
      triggerType: getETriggerType(auto.triggerType),
      actionType: getEActionType(auto.actionType),
    }
    // todo: populate trigger and action when figured out in solanaclient

    // @ts-ignore
    return resp;
  }

  async function destroyAutomation(autoIndex: number): Promise<string> {
    const [autoPda] = await findAutoPda(autoIndex, stache.stacheid, KEYCHAIN_DOMAIN, StacheProgramId);
    const tx = new Transaction();
    tx.add(await solanaClient.getDestroyAutoIx(autoPda));
    const txid = await signAndSendTransaction(tx);
    console.log(`Destroy automation txid: ${txid}`);
    await rpcClient.confirmTransaction(txid);
    setAutos(autos.filter(auto => auto.index !== autoIndex));
    return txid;
  }

  return {
    loadAutomations,
    getAutomation,
    createAutomation,
    destroyAutomation
  }
}

export {useAutomationActions}
