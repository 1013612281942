//Recoil
import {ICollectible, ICollection, ITokenBag, NftType} from "store/connectedWallets/types";

//Web3
import {Commitment, PublicKey, Transaction} from "@solana/web3.js";
import {web3} from "@project-serum/anchor";

//Util
import {
  JsonMetadata,
  Metadata,
  Metaplex,
  Mint,
  Nft,
  NftEdition,
  Pda,
  Sft,
  WalletAdapter
} from "@metaplex-foundation/js";
import {TokenInfo} from "@solana/spl-token-registry";
import {hasSubstring} from "../../utils/misc";
import {Assets, LoadedAsset, RpcClient, STokenAccount, STokenInfo} from "../apiTypes";
import {TOKEN_PROGRAM_ID} from "@solana/spl-token";
import {TokenStandard} from "@metaplex-foundation/mpl-token-metadata";
import {TokenRegistry} from "../../utils/tokenregistry";
import Bottleneck from "bottleneck";


export class StandardRpcClient implements RpcClient {

  protected conn: web3.Connection;
  protected throttler: Bottleneck;
  protected metaplex: Metaplex;
  private tokenRegistry: TokenRegistry;

  // todo: connection into constructor
  constructor(connection: web3.Connection, throttler: Bottleneck, tokenRegistry: TokenRegistry) {
    this.conn = connection;
    this.throttler = throttler;
    this.metaplex = new Metaplex(connection);
    this.tokenRegistry = tokenRegistry;
  }

  get connection(): web3.Connection {
    return this.conn;
  }

  async confirmTransaction(txid: string, commitment: Commitment = "confirmed"): Promise<boolean> {
    console.log(`confirming txid: ${txid}`);
    try{
      const latestBlockHash = await this.conn.getLatestBlockhash(commitment);
      console.log("Latest: ", latestBlockHash);
      const confirmed = await this.conn.confirmTransaction({
        blockhash: latestBlockHash.blockhash,
        lastValidBlockHeight: latestBlockHash.lastValidBlockHeight,
        signature: txid,
      }, commitment);
      console.log("Cofirmed: ", confirmed);
      return confirmed.value.err === null;
    } catch(e) {
      console.log("What was err??", e);
      return false;
    }

    // no error means tx was successful
  }

  createTokenBag(tokenAccount: STokenAccount): ITokenBag | null {
    // check the registry
    const tokenInfo: TokenInfo = this.tokenRegistry.getTokenInfo(tokenAccount.mint.toString());

    if (!tokenInfo) {
      return null;
    }

    const symbol = tokenInfo?.symbol ? tokenInfo.symbol : 'UNK';

    return {
      mint: tokenAccount.mint,
      amount: tokenAccount.amount,
      amountUi: tokenAccount.amountUi,
      amountUiString: tokenAccount.amountUiString,
      decimals: tokenAccount.decimals,
      name: tokenInfo ? tokenInfo.name : 'Unknown',
      symbol,
      tokenAccount: tokenAccount.address,
      imageUrl: tokenInfo?.logoURI ? tokenInfo.logoURI : null,
      ruleset: null
    };
  }

  isVerified(nft: Omit<Metadata<JsonMetadata>, "model" | "address" | "mintAddress"> & {
    readonly model: "nft";
    readonly address: PublicKey;
    readonly metadataAddress: Pda;
    readonly mint: Mint;
    readonly edition: NftEdition
  }) {

    if (nft.collection) {
      return nft.collection.verified;
    } else {
      // todo: not part of a collection - for now just mark it as unverified, but for specific nfts that haven't migrated to a collection, might wanna have a mint list
      //       OR go by verified creator (candy machine)
      return false;
    }
  }

  getLoadedAsset(tokenAccount: STokenAccount, loadedMetaplexAsset: Metadata | Nft | Sft | null): LoadedAsset {
    if (loadedMetaplexAsset) {
      const name = loadedMetaplexAsset.name ? loadedMetaplexAsset.name : loadedMetaplexAsset.json?.name;
      let numNfts = 1;
      const ruleset = loadedMetaplexAsset.programmableConfig?.ruleSet ?? null;
      switch (loadedMetaplexAsset.model) {
        case 'sft':
          // console.log(`loaded sft for mint: ${mint.toString()}`, loadedAsset);
          if (tokenAccount.decimals > 0) {
            return {
              tokenBag: {
                mint: tokenAccount.mint,
                amount: tokenAccount.amount,
                amountUi: tokenAccount.amountUi,
                amountUiString: tokenAccount.amountUiString,
                decimals: tokenAccount.decimals,
                name,
                symbol: loadedMetaplexAsset.symbol,
                tokenAccount: tokenAccount.address,
                imageUrl: loadedMetaplexAsset.json.image,
                ruleset
              }
            };
          } else {
            numNfts = tokenAccount.amount;
          }

          // otherwise don't do anything and handle like an nft below (later we can handle SFTs properly)
          // intentional fallthrough
        case 'nft':
          if (numNfts === 1) {
            return {
              collectible: {
                mint: tokenAccount.mint.toBase58(),
                name,
                imageUrl: loadedMetaplexAsset.json.image,
                attributes: loadedMetaplexAsset.json.attributes,
                desc: loadedMetaplexAsset.json.description,
                tokenAccount: tokenAccount.address.toBase58(),
                collection: null,
                // @ts-ignore
                verified: this.isVerified(loadedMetaplexAsset),
                nftType: loadedMetaplexAsset.tokenStandard == TokenStandard.ProgrammableNonFungible ? NftType.Programmable : NftType.Standard,
                ruleset,
                compression: null,
                qty: 1
              }
            };
          } else {
            console.log('got an sft with multiple nfts: ', numNfts);
            const collectibles: ICollectible[] = [];
            return {
              collectible: {
                mint: tokenAccount.mint.toBase58(),
                name,
                imageUrl: loadedMetaplexAsset.json.image,
                attributes: loadedMetaplexAsset.json.attributes,
                desc: loadedMetaplexAsset.json.description,
                tokenAccount: tokenAccount.address.toBase58(),
                collection: null,
                // @ts-ignore
                verified: this.isVerified(loadedMetaplexAsset),
                nftType: NftType.Semi,
                ruleset,
                compression: null,
                qty: numNfts,
              }
            }
          }
          break;
        default:
          console.log(`unknown model type: ${loadedMetaplexAsset.model}`);
      }
    }
    return null;
  }

  // performs a metaplex load
  async loadMetaplexAsset(mint: PublicKey, tokenAccount: STokenAccount, metadata: Metadata): Promise<LoadedAsset | null> {
    try {
      const loadedMetaplexAsset = await this.metaplex.nfts().load({metadata});
      return this.getLoadedAsset(tokenAccount, loadedMetaplexAsset);
    } catch (e) {
      console.log(`failed to pull metadata for token ${mint}`);
      return null;
    }
  }


  async findTokenAccount(walletAddress: PublicKey, mint: PublicKey): Promise<STokenAccount | null> {
    const resp = await this.conn.getParsedTokenAccountsByOwner(walletAddress, {mint});
    if (resp.value.length > 0) {
      const tokenAccount = resp.value[0];
      return {
        address: tokenAccount.pubkey,
        mint,
        decimals: tokenAccount.account.data.parsed.info.tokenAmount.decimals,
        amount: tokenAccount.account.data.parsed.info.tokenAmount.amount,
        amountUi: tokenAccount.account.data.parsed.info.tokenAmount.uiAmount,
        amountUiString: tokenAccount.account.data.parsed.info.tokenAmount.uiAmountString,
        lamports: tokenAccount.account.lamports
      };
    } else {
      return null;
    }
  }

  async getTokenAccounts(walletAddress: PublicKey, includeEmpty = false): Promise<STokenAccount[]> {
    const resp = await this.throttler.schedule(() =>
      this.conn.getParsedTokenAccountsByOwner(walletAddress, {programId: TOKEN_PROGRAM_ID})
    );
    const tokenAccounts: STokenAccount[] = [];
    for (const a of resp.value) {
      if (!includeEmpty && a.account.data.parsed.info.tokenAmount.uiAmount === 0) {
        // noop
      } else {
        tokenAccounts.push({
          address: a.pubkey,
          mint: new PublicKey(a.account.data.parsed.info.mint),
          decimals: a.account.data.parsed.info.tokenAmount.decimals,
          amount: a.account.data.parsed.info.tokenAmount.amount,
          amountUi: a.account.data.parsed.info.tokenAmount.uiAmount,
          amountUiString: a.account.data.parsed.info.tokenAmount.uiAmountString,
          lamports: a.account.lamports,
        });
      }
    }
    return tokenAccounts;
  }

  // fetches the token data for the given token accounts and parses into collectibles and tokens
  async fetchAssetMetaData(tokenAccounts: STokenAccount[], blacklist: string[], ogCollections: Map<string, ICollection>): Promise<Assets> {
    // separate into collectibles and tokens

    const nonEmptyTokenAccounts = tokenAccounts.filter((tokenAccount) => tokenAccount.amount > 0);

    // Data is either already in cache, or should be set to be retrieved with metaplex
    const needToFetchMints = [];
    const cachedNftMetadata = [];

    /** NOTE: if we wanna store stuff in local storage, then JUST the necessary data should be stored AND we need to
     * make sure to handle max cache size being hit when putting in new items (5MB)
     mints.forEach(mint => {
      const cached = localStorage.getItem(`metadata-${mint.toBase58()}`)
      if (!!cached) {
        try {
          // Parse and change all addresses to PublicKey format
          const obj = FetchedNftMetadata(JSON.parse(cached))
          cachedNftMetadata.push(obj);
        } catch (e) {
          console.log("Error parsing: ", e);
        }
      } else {
        needToFetchMints.push(mint);
      }
    })
     */

    const collectibles: ICollectible[] = [];
    const tokens: ITokenBag[] = [];

    const needToFetchTokenAccounts = [];

    // first check token registry - these will load way faster
    for (let i = 0; i < nonEmptyTokenAccounts.length; i++) {
      const tokenBag = this.createTokenBag(nonEmptyTokenAccounts[i]);
      if (tokenBag) {
        tokens.push(tokenBag);
      } else {
        needToFetchTokenAccounts.push(nonEmptyTokenAccounts[i]);
      }
    }

    // Only retrieves nft data that has not been cached
    const fetchedNfts = await this.throttler.schedule(() =>
      this.metaplex.nfts().findAllByMintList({mints: needToFetchTokenAccounts.map((tokenAccount) => tokenAccount.mint)})
    );
    /* ---> caching disabled for now
  fetchingNfts.forEach((data: any) => {
    if (data) {
      // Cache meta data
      localStorage.setItem(`metadata-${data?.mintAddress?.toBase58()}`, JSON.stringify(data))
    }
    })
    const allFetched = [...fetchingNfts, ...cachedNftMetadata]
     */

    // console.log("ALL fetched???", allFetched);
    // console.log("NON EMPTY TOKEN ACCOUNTS: ", nonEmptyTokenAccounts);

    const assetLoadingPromises = [];

    const loadedAssets: LoadedAsset[] = [];
    for (let x = 0; x < needToFetchTokenAccounts.length; x++) {
      const fetchedNft = fetchedNfts[x];
      const tokenAccount = needToFetchTokenAccounts[x];
      if (fetchedNft) {
        // console.log("Fetched nft: ", fetchedNft);
        const name = fetchedNft.name ? fetchedNft.name : fetchedNft.json?.name;
        switch (fetchedNft.model) {
          case 'metadata':
            // console.log("Metadata push: ", fetchedNft);
            // then we need to fully load this guy
            assetLoadingPromises.push(this.loadMetaplexAsset(tokenAccount.mint, tokenAccount, fetchedNft));
            break;
          case 'nft':
          case 'sft':
            loadedAssets.push(this.getLoadedAsset(tokenAccount, fetchedNft));
            break;
          default:
            // @ts-ignore
            console.log(`unknown model type: ${fetchedNft.model}`);
        }
      }
    }

    const finishedPromises = (await Promise.all(assetLoadingPromises)).filter((n) => !!n);
    loadedAssets.push(...finishedPromises);

    for (const loadedAsset of loadedAssets) {
      if (loadedAsset.tokenBag) {
        tokens.push(loadedAsset.tokenBag);
      } else if (loadedAsset.collectible) {
        collectibles.push(loadedAsset.collectible);
      } else if (loadedAsset.collectibles) {
        collectibles.push(...loadedAsset.collectibles);
      }
    }

    // printObject({tokens, collectibles});

    // console.log("Black list: ", blacklist);
    // Filter out phishing scams
    const whitelistedCollectibles = collectibles.filter(collectible => !hasSubstring(collectible.imageUrl, blacklist));
    const whitelistedTokens = tokens.filter(token => !hasSubstring(token.imageUrl, blacklist));

    return {
      tokens: whitelistedTokens,
      collectibles: whitelistedCollectibles
    }
  }

  async fetchAssets(walletAddress: PublicKey, blacklist: string[], ogCollections: Map<string, ICollection>): Promise<Assets> {
    const tokenAccounts: STokenAccount[] = await this.getTokenAccounts(walletAddress);
    return await this.fetchAssetMetaData(tokenAccounts, blacklist, ogCollections);
  }

  async transferCompressed(walletAdapter: WalletAdapter, assetId: PublicKey, fromAddress: PublicKey, toAddress: PublicKey): Promise<Transaction> {
    throw Error('not implemented');
  }

  async fetchTokenInfo(mint: string): Promise<STokenInfo | null> {
    const tokenInfo: TokenInfo = this.tokenRegistry.getTokenInfo(mint);
    if (tokenInfo) {
      return {
        mint: new PublicKey(mint),
        name: tokenInfo.name,
        symbol: tokenInfo.symbol,
        decimals: tokenInfo.decimals,
      }
    } else {
      const fetchedMint = await this.metaplex.tokens().findMintByAddress({address: new PublicKey(mint)});
      const fetchedData = await this.metaplex.nfts().findByMint({mintAddress: new PublicKey(mint)});
      if (fetchedData) {
        return {
          mint: new PublicKey(mint),
          name: fetchedData.name,
          symbol: fetchedData.symbol,
          decimals: fetchedMint.decimals,
        }
      } else {
        console.log(`Could not find token info for mint: ${mint}`);
        return null;
      }
    }
  }

}
