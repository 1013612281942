import {atom} from 'recoil';
import {ICollection} from "../connectedWallets";

// blacklist for phising scams that trigger Google warnings
export const blacklistAtom = atom<string[]>({
  key: 'blacklist',
  default: []
});

// og collections that we use the first verified creator to determine verification (stored in db on backend)
// map of firstverified creator -> ICollection
export const ogCollectionsAtom = atom<Map<string, ICollection>>({
  key: 'ogCollections',
  default: new Map()
})

