import { COLORS } from "constants/theme";
import React, { Component } from "react";
import SwitchComponent from "react-switch";
import {ReactComponent as USDC} from "assets/icons/web3/usdc-logo.svg";
import {ReactComponent as SOL} from "assets/icons/web3/solana-logo.svg";

interface Props {
    checked: boolean,
    setChecked: () => void
}

export default class Switch extends Component <Props, {}>{

  constructor(props: Props) {
    super(props);
  }


  render() {
    return (
      <label>
        <SwitchComponent 
            onChange={this.props.setChecked} 
            checked={this.props.checked} 
            onColor={"gray"}
            activeBoxShadow={`0px 0px 1px 2px ${COLORS.ACCENT_PRIMARY}`}
            uncheckedIcon={<div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                              <USDC height={20} width={20} />
                            </div>
            }
            checkedIcon={<div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                              <SOL height={15} width={15} />
                            </div>
            }
        />
      </label>
    );
  }
}