import {atom} from 'recoil';
import {IStache} from './types';
import {IStacheWallet} from 'store/connectedWallets';

export const stacheAtom = atom<IStache | null>({
  key: 'stache',
  default: null,
  effects: [
    ({onSet}) => {
      onSet((newStache) => {
        console.log('stache state changed: ' + newStache);
      });
    }
  ]
});

export const stacheWalletAtom = atom<IStacheWallet | null>({
  key: 'stache-wallet',
  default: null,
  // effects: [{
  // getPromise: async ({setSelf}) => {
  // }
  // }]
});
