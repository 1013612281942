import React from 'react';
import {Link} from 'react-router-dom';
import {IListing, IListingThumb} from 'store/yardsale';
import {ReactComponent as CheckCircle} from 'assets/icons/ticks/CheckCircle.svg';
import {NFT_CARD_HEIGHT_BASE} from '../NftCard';
import '../NftCard.scss';
import {truncateStringWithLastWord} from 'utils/string-formatting';

interface IListingCardProps {
  listing: IListingThumb;
  link: string;
  state: any;
}

export const ListingCard: React.FC<IListingCardProps> = ({state, listing, link}) => {

  const formattedPrice = React.useMemo(() => {
    //@ts-ignore
    const multiplier = listing?.currency?.decimals === 1 ? 1 : 10 ** (listing?.currency?.decimals ?? 9);
    //@ts-ignore
    const num = (listing.priceBN / multiplier).toFixed(4);
    return Number(num);
  }, [listing]);

  const imagesDiv = React.useMemo(() => {
    switch (listing.numItems) {
      case 1:
        return (
          <div className="one-image">
            {listing.items[0].qty > 1 && (
              <div className="circle">
                <p className="normal">{listing.items[0].qty}</p>
              </div>
            )}
            <img src={listing.items[0].imageUrl} />
          </div>
        );
      case 2:
        return (
          <div className="two-images">
            <img src={listing.items[0].imageUrl} />
            <img src={listing.items[1].imageUrl} />
          </div>
        );
      case 3:
        return (
          <div className="three-images">
            <img src={listing.items[2].imageUrl} />
            <div>
              <img src={listing.items[0].imageUrl} />
              <img src={listing.items[1].imageUrl} />
            </div>
          </div>
        );
      default: 
        return (
          <div className="four-images">
            <div>
              <img src={listing.items[0].imageUrl} />
              <img src={listing.items[1].imageUrl} />
            </div>
            <div>
              <img src={listing.items[2].imageUrl} />
              <img src={listing.items[3].imageUrl} />
            </div>
          </div>
        )
    }
  }, []);

  const firstLine = React.useMemo(() => {
    if (listing.numItems === 1) {
      return <p className="normal">{truncateStringWithLastWord(listing.items[0].name, 22)}</p>;
    } else {
      return (
        <div className="text-line">
          <p className="normal">
            {listing.name ? 
              truncateStringWithLastWord(listing.name, 22) : 
              truncateStringWithLastWord(listing.items[0].name + ` ${'x'} ${listing.items[0].qty}`, 22)
            }
          </p>
        </div>
      );
    }
  }, []);

  const secondLine = React.useMemo(() => {
    if (listing.numItems === 1) {
      return !!listing.items[0].collection ? (
        <p className="medium accent">
          {truncateStringWithLastWord(listing.items[0].collection.name, 22)}
        </p>
      ) : (
        <p className="medium gr">No collection</p>
      );
    } else {
      return (
        <div className="text-line">
          <p className='normal'>
            {listing.name ? 
              listing.numItems + " items" :
              "+" + (listing.numItems - 1).toString() + " more items..." 
            }
          </p>
          {/* <p className="normal">
            {truncateStringWithLastWord(listing.items[1].name + ` ${'x'} ${listing.items[1].qty}`, 22)}
          </p> */}
        </div>
      );
    }
  }, []);

  const thirdLine = React.useMemo(() => {
    switch (listing.numItems) {
      case 1:
        return <p className="medium">{listing.items[0].nftType}</p>;
      // case 2:
      //   return <div style={{height: '14px', width: '100%'}} />;
      // case 3:
      //   return (
      //     <div className="text-line">
      //       <p className="normal">
      //         {truncateStringWithLastWord(listing.items[2].name + ` ${'x'} ${listing.items[2].qty}`, 22)}
      //       </p>
      //     </div>
      //   );
      default: 
        return <p className='medium'>Bundle</p>
    }
  }, []);


  return (
    <Link to={link} state={state} key={listing.id}>
      <div className="nft-card" style={{height: NFT_CARD_HEIGHT_BASE}}>
        {/* {item.} */}
        <div className="nft-content">
          {imagesDiv}
          <div className="info">
            {listing.numItems === 1 && !!listing.items[0].collection && <CheckCircle height={15} width={15} />}
            {firstLine}
            {secondLine}
            {thirdLine}
            {formattedPrice != 0 ?
              <div className="price-container">
                <img src={listing.currency.icon} className="currency-container" />
                <p className="medium">{formattedPrice}</p>
              </div> :
              <div className = "price-container">
                <p className = "medium free">FREE</p>
              </div>
            }
          </div>
        </div>
      </div>
    </Link>
  );
};
