import { ReactNode } from "react"
import "./SidePanelWrapper.scss";
import {ReactComponent as CloseIcon} from "assets/icons/ticks/Close.svg";

export interface SidePanelProps {
  isOpen: boolean,
  onClose: () => void,
  width: number,
  children: ReactNode,
  label?: string,
  animationTime?: number,
}

export const SidePanelWrapper = ({
  children, 
  isOpen, 
  onClose, 
  width, 
  animationTime = 0.5, 
  label = ""
}: SidePanelProps) => {
  return (
    <div className = {"sidepanel" + (isOpen ? " open" : "")} style = {{width, transition: `all ${animationTime}s`}}>
      <div className = "header">
        <p className = "header">{label}</p>
        <div onClick={onClose}>
          <CloseIcon/>
        </div>
      </div>
      <div className = "content">
        {children}
      </div>
    </div>
  )
}