import React, {FC, ReactElement} from 'react';
import {ReactComponent as Script} from 'assets/icons/Script.svg';
import useToasts from 'hooks/useToasts';
import {apiClient} from 'apis/server';
import {useRecoilValue} from 'recoil';
import {IProfileInfo, useUserProfileActions, userProfileAtom} from 'store/userProfile';
import {ENOTI_STATUS} from 'store/toasts';
import {IListing} from 'store/yardsale';
import {COLORS, SPACING} from 'constants/theme';
import {ENavStack, ENestedRoute, ERoute, ERouteBazaar} from 'constants/routes';
import ScreenHeader from 'components/Header/Screen/ScreenHeader';
import {Link, useParams} from 'react-router-dom';
import {Avatar} from 'components/Avatar/Avatar';
import {Listings, Activity, About} from 'pages/Myshop/MyShop';
import Loader from 'components/Loader/Loader';

import '../Myshop/MyShop.scss';
import {ScreenContainer} from 'components/ui/container/Container';
import NotAuthedHeader from 'components/Header/NotAuthed/NotAuthedHeader';
import {xNFTAtom} from 'store/xnft';
import useAsyncEffect from "use-async-effect";

type IProfileProps = any;

export const OtherShop: FC<any> = (props: IProfileProps): ReactElement => {
  const {stacheid} = useParams();

  const {createToast} = useToasts();
  const userActions = useUserProfileActions();

  const [localStorefront, setLocalStorefront] = React.useState<IListing[]>([]);
  const [localUserProfile, setLocalUserProfile] = React.useState<IProfileInfo>();
  const [tabView, setTabView] = React.useState<ENestedRoute>(ENestedRoute.LISTINGS);
  const userProfile = useRecoilValue(userProfileAtom);
  const [initialLoad, toggleInitialLoad] = React.useState(false);
  const [activities, setActivities] = React.useState([]);
  const isXNFT = useRecoilValue(xNFTAtom);

  useAsyncEffect(async () => {
    try {
      const userProfile = await apiClient.getUser(stacheid);
      setLocalUserProfile(userProfile);

      const itemsRes = await apiClient.getListingsForStacheid(stacheid);
      console.log(`setting items: `, itemsRes);
      setLocalStorefront(itemsRes);

      const activities = await userActions.getUserActivity(userProfile.id);
      setActivities(activities);
    } catch (error) {
      console.log('Error:', error);
      createToast('Error retrieving profile', ENOTI_STATUS.ERR);
    } finally {
      toggleInitialLoad(true);
    }
  }, [stacheid]);

  const RenderView = React.useCallback(() => {
    switch (tabView) {
      case ENestedRoute.LISTINGS:
        return <Listings listings={localStorefront} otherStacheid={stacheid} />;
      case ENestedRoute.ACTIVITY:
        return <Activity data={activities} />;
      case ENestedRoute.ABOUT:
        return <About profileInfo={localUserProfile} />;
    }
  }, [tabView, localUserProfile, localStorefront]);

  const handleToggleFavorite = () => {
    // todo
  };

  if (!initialLoad) return <Loader />;

  return (
    <ScreenContainer hasBackButton={isXNFT}>
      {!userProfile.authed && <NotAuthedHeader />}
      <div className="myshop">
        <div className="row">
          <Avatar iconUrl={localUserProfile.avatarUrl} username={localUserProfile.stacheid} size={50} />
          <div>
            <p className="header" style={{marginBottom: `${SPACING.MD}px`}}>
              @{localUserProfile?.stacheid}
            </p>
            <span className="touchable" onClick={() => setTabView(ENestedRoute.ABOUT)}>
              <Script fill={COLORS.ACCENT_PRIMARY} />
            </span>
          </div>
        </div>
        <div className="tabs">
          <div
            className={'touchable ' + (tabView === ENestedRoute.LISTINGS ? 'active' : '')}
            onClick={() => setTabView(ENestedRoute.LISTINGS)}
          >
            <p className="subheader">Listings</p>
            <div />
          </div>
          <div
            className={'touchable ' + (tabView === ENestedRoute.ACTIVITY ? 'active' : '')}
            onClick={() => setTabView(ENestedRoute.ACTIVITY)}
          >
            <p className="subheader">Activity</p>
            <div />
          </div>
          <div
            className={'touchable ' + (tabView === ENestedRoute.ABOUT ? 'active' : '')}
            onClick={() => setTabView(ENestedRoute.ABOUT)}
          >
            <p className="subheader">About</p>
            <div />
          </div>
        </div>
        <RenderView />
      </div>
    </ScreenContainer>
  );
};
export default OtherShop;
