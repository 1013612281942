import React, {FC, ReactElement} from 'react';

//Components
import Loader from 'components/Loader/Loader';
import {Input} from 'components/ui/input/Input';

//Constants
import {ICommentThread, IListing, useStorefrontActions} from 'store/yardsale';
import {BRADIUS, COLORS, SPACING} from 'constants/theme';

//Recoil
import {useRecoilValue} from 'recoil';
import {stacheAtom} from 'store/stache';

//Util
import {userProfileAtom} from 'store/userProfile';

//SVGs
import {ReactComponent as ChatText} from 'assets/icons/ChatText.svg';
import {ReactComponent as Chevron} from 'assets/icons/ticks/Chevron.svg';
import {ReactComponent as Close} from 'assets/icons/ticks/Close.svg';
import {ReactComponent as Hourglass} from 'assets/icons/Hourglass.svg';
import {Avatar} from 'components/Avatar/Avatar';
import {keychainAtom} from 'store/keychain';
import {IMessage} from 'store/notification';
import {Comment} from './Comment/SaleComment';
import useToasts from 'hooks/useToasts';
import {ENOTI_STATUS} from 'store/toasts';

import './SaleConversation.scss';
import useWindowDimensions from 'hooks/useWindowDimensions';

const NoConvo = React.memo(() => {
  return (
    <div style={{display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
      <Hourglass />
      <p style={{margin: `${SPACING.MD} 0`, color: COLORS.TEXT_DIRTY_WHITE}}>No comments</p>
    </div>
  );
});

interface Props {
  conHeight: number;
  conversation: IMessage[];
  setConversation: React.Dispatch<React.SetStateAction<IMessage[]>>;
  item: IListing;
}

export const SaleConversation: FC<any> = ({conHeight, conversation, setConversation, item}: Props): ReactElement => {
  const dims = useWindowDimensions();
  const storefrontActions = useStorefrontActions();
  const {createToast} = useToasts();

  const [focusedThread, setFocusedThread] = React.useState<ICommentThread>(undefined); // todo when nested threads are added
  const userProfile = useRecoilValue(userProfileAtom);
  const stache = useRecoilValue(stacheAtom);
  const keychain = useRecoilValue(keychainAtom);
  const [input, setInput] = React.useState('');

  React.useEffect(() => {
    // todo set event listeners for pressing enter key
  }, []);

  const submitComment = async () => {
    if (!!keychain?.name) {
      await storefrontActions
        .postMessageToListing(item.seller.stacheid, input, item.id)
        .then((res) => {
          setConversation((prev) => [res, ...prev]);
        })
        .catch((e) => createToast('Error sending message', ENOTI_STATUS.ERR))
        .finally(() => {
          setInput('');
        });
    } else {
      createToast('You must be logged in to your Stache account to send messages.', ENOTI_STATUS.DEFAULT);
      setInput('');
    }
  };

  const formattedConversationData = React.useMemo(() => {
    return [...conversation].reverse();
  }, [conversation.length]);

  return (
    <div className="sale-conversation-con">
      {/* <div 
          className= "conversation-con"
          style={{justifyContent: conversation.length ? "flex-start" : "center"}}
        > */}
      {conversation.length ? (
        formattedConversationData.map((comment: IMessage, index: number) => <Comment key={index} comment={comment} />)
      ) : (
        <NoConvo />
      )}
      {/* </div> */}
      <div className="botCon">
        <div className="main">
          <Avatar
            iconUrl={userProfile?.profileInfo?.avatarUrl}
            username={keychain?.name}
            style={{marginRight: SPACING.XL}}
            size={30}
          />
          <Input
            value={input}
            onChangeText={setInput}
            placeholder="Comment..."
            conStyle={{width: dims.width - SPACING.XL * 3 - 32, marginBottom: 0}}
            maxLength={200}
          />
        </div>
        {!!input.length && (
          <div className="commentButtons">
            <div className="touchable" onClick={() => setInput('')}>
              <p style={{color: COLORS.ACCENT_PRIMARY, marginRight: SPACING.XL}}>Cancel</p>
            </div>
            <div onClick={submitComment} className="commentYellow touchable">
              <p>Comment</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SaleConversation;
