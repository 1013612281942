export function sleep(ms: number) {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function chunkArray(myArray: any[], chunkSize: number) {
  var results = [];
  while (myArray.length) {
    results.push(myArray.splice(0, chunkSize));
  }
  return results;
}

// given a string, returns true if any of the given subsrings are a substring in the first string
export function hasSubstring(str: string, substrings: string[]): boolean {
  if (!str) {
    return false;
  }
  for (let i = 0; i < substrings.length; i++) {
    if (str.includes(substrings[i])) {
      return true;
    }
  }
  return false;
}

// given a list of strings, checks if any of the given substrings are a substring in any of the strings
export function hasAnySubstring(strs: string[], substrings: string[]): boolean {
  for (let i = 0; i < strs.length; i++) {
    if (hasSubstring(strs[i], substrings)) {
      return true;
    }
  }
  return false;
}

// given an array, selects the "best" string
export function selectString(strings: string[]) {
  for (let i = 0; i < strings.length; i++) {
    if (strings[i] && strings[i].length > 0) {
      return strings[i];
    }
  }
  return null;
}
