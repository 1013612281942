import React, {FC, ReactElement, useState} from 'react';
import useToasts from 'hooks/useToasts';
import {ENOTI_STATUS} from 'store/toasts';
import {ENavStack, ERoute, ERouteBazaar} from 'constants/routes';
import useFindWallets from 'hooks/useFindWallets';
import {useRecoilValue} from 'recoil';
import {IKey, keychainAtom, useKeychainActions} from 'store/keychain';
import {ensureIsPublicKey, ensureIsString, formatAddress} from 'utils/string-formatting';
import {connectedWalletAtom, ITokenBag, IWallet, NftType, useConnectedWalletActions} from 'store/connectedWallets';
import {EAssetSelectionType, ICurrency, useStorefrontActions} from 'store/yardsale';
import {stacheWalletAtom} from 'store/stache';
import {PublicKey} from '@solana/web3.js';
import {NATIVE_MINT} from '@solana/spl-token';
import {Input} from 'components/ui/input/Input';
import './CreateListing.scss';
import {useLocation, useNavigate, useParams} from 'react-router';
import {ColoredButton, ColoredButtonInverse} from 'components/ui/button/Button';
import ModalWrapper from 'components/Modals/ModalWrapper/ModalWrapper';
import {Link} from 'react-router-dom';
import {ScreenContainer} from 'components/ui/container/Container';
import {SPACING} from 'constants/theme';
import {userProfileAtom} from "../../store/userProfile";

type Props = any;

export interface ICollectibleListingInfo {
  mint: string;
  tokenType: NftType | 'SPL';
  quantity: number;
  name: string;
  decimals?: number;
  assetSelectionType?: EAssetSelectionType;
}

export const CreateListing: FC<any> = (props: Props): ReactElement => {
  const {
    state: {focusedWalletAddress, collectibleMints, tokenTypes, quantities, names, decimals, assetSelectionType},
  } = useLocation();

  const {createToast} = useToasts();
  const {getWalletFromTab, getTabFromPublicKey} = useFindWallets();
  const storefrontActions = useStorefrontActions();
  const connectedWalletActions = useConnectedWalletActions();

  const keychain = useRecoilValue(keychainAtom);
  const stacheWallet = useRecoilValue(stacheWalletAtom);
  const connectedWallet = useRecoilValue(connectedWalletAtom);
  const [loading, toggleLoading] = React.useState(false);
  const [amount, setAmount] = React.useState('');
  const [currency, setCurrency] = React.useState<ICurrency>({
    symbol: 'SOL',
    name: 'solana',
    mint: NATIVE_MINT.toBase58(),
    decimals: 9,
    icon: '',
  });
  const [depositWallet, setDepositWallet] = React.useState(getTabFromPublicKey(new PublicKey(focusedWalletAddress)));
  const [desc, setDesc] = React.useState('');
  const [link, setLink] = React.useState('');
  const [copying, toggleCopying] = React.useState(false);
  const [modal, setModal] = useState(false);
  const [bagName, setBagName] = useState('');
  const userProfile = useRecoilValue(userProfileAtom);

  const collectibleListingInfos = React.useMemo(() => {
    const parsedCollectibleMints = JSON.parse(collectibleMints);
    const parsedTokenTypes = JSON.parse(tokenTypes);
    const parsedQuantities = JSON.parse(quantities);
    const parsedNames = JSON.parse(names);
    const parsedDecimals = JSON.parse(decimals);
    return parsedCollectibleMints.map(
      (mint: string, i: number) =>
        ({
          mint,
          tokenType: parsedTokenTypes[i],
          quantity: parsedQuantities[i],
          name: parsedNames[i],
          decimals: parsedDecimals[i],
        } as ICollectibleListingInfo)
    );
  }, []);

  // Fired when a successful listing has occurred. Opens success modal
  const copyLink = () => {
    if (!!link) {
      toggleCopying(true);
      navigator.clipboard.writeText(link);
      setTimeout(() => toggleCopying(false), 3000);
    }
  };

  const coinOptions = React.useMemo<ICurrency[]>(() => {
    let wallet: IWallet;
    switch (depositWallet) {
      case 'stache': {
        wallet = stacheWallet;
        break;
      }
      case 'connected': {
        wallet = connectedWallet;
        break;
      }
      default: {
        let key: IKey;
        key = keychain.keys.find((key: IKey) => key.walletAddress.toBase58() === depositWallet);
        wallet = key.contents;
        break;
      }
    }

    return (
      wallet?.tokens?.reduce((arr: ICurrency[], token: ITokenBag) => {
        if (token.amount != 0) {
          arr.push({
            symbol: token.symbol,
            mint: ensureIsString(token.mint),
            decimals: token.decimals,
            icon: token.imageUrl,
            name: token.name,
          });
        }
        return arr;
      }, [] as ICurrency[]) ?? []
    );
  }, [depositWallet]);

  const openSharing = () => {
    // Share.share({
    //   message: `Check out this NFT for sale in my Stache. No marketplace required: ${link}`,
    //   url: link,
    //   title: 'NFT listing',
    // });
  };

  const handleSubmit = async () => {
    toggleLoading(true);
    const item = collectibleListingInfos[0];
    const nonStandards = [NftType.Compressed, NftType.Programmable];
    if (collectibleListingInfos.length === 1 && item.quantity === 1 && nonStandards.includes(item.tokenType)) {
      await submitYardsaleListing(); // cNFT or pNFT
    } else {
      // todo: remove this later when all users have a seller account
      if (userProfile.profileInfo.sellerAccountPda) {
        await submitBazaarListing(); // NFT(s) and/or SFT(s)
      } else {
        // then this user hasn't created a seller account yet, so use yardsale
        await submitYardsaleListing();
      }
    }
  };

  const submitBazaarListing = async () => {
    const wallet = getWalletFromTab(depositWallet);
    try {
      const nftMints: (PublicKey | null)[] = [
        ensureIsPublicKey(collectibleListingInfos[0].mint),
        !!collectibleListingInfos[1]?.mint ? ensureIsPublicKey(collectibleListingInfos[1].mint) : null,
        !!collectibleListingInfos[2]?.mint ? ensureIsPublicKey(collectibleListingInfos[2].mint) : null,
        !!collectibleListingInfos[3]?.mint ? ensureIsPublicKey(collectibleListingInfos[3].mint) : null,
        !!collectibleListingInfos[4]?.mint ? ensureIsPublicKey(collectibleListingInfos[4].mint) : null,
      ];
      const res = await storefrontActions.createBazaarListing(
        nftMints,
        collectibleListingInfos.map((item: ICollectibleListingInfo) => {
          // Non-SPL tokens will have decimals = 1 and therefore this won't do anything
          const multiplier = item.decimals === 1 ? 1 : 10 ** item.decimals;
          return item.quantity * multiplier;
        }),
        parseFloat(amount),
        currency,
        bagName,
        desc,
        wallet,
        assetSelectionType
      );

      console.log(res);

      if (typeof res !== 'string') {
        const base_url = window.location.origin;
        setLink(`${base_url}/bazaar/shop/${keychain.name}/listing/${res?.id}`);
        // todo stub this in too
        await storefrontActions.getStorefrontItems(keychain.name);
        // Might need to add multiplier
        console.log(collectibleListingInfos);
        nftMints.forEach((mint, idx) => !!mint && connectedWalletActions.removeNftFromCollection(mint.toBase58(), collectibleListingInfos[idx].quantity));
        setModal(true);
      } else {
        createToast(res, ENOTI_STATUS.ERR);
        toggleLoading(false);
      }
    } catch (e) {
      console.error(e);
      createToast(e.message, ENOTI_STATUS.ERR);
      toggleLoading(false);
    }
  };

  const submitYardsaleListing = async () => {
    const wallet = getWalletFromTab(depositWallet);
    const item = collectibleListingInfos[0];
    try {
      const res = await storefrontActions.createYardsaleListing(
        new PublicKey(item.mint),
        parseFloat(amount),
        currency,
        desc,
        wallet,
        item.tokenType
      );
      if (typeof res !== 'string') {
        const base_url = window.location.origin;
        setLink(`${base_url}/bazaar/shop/${keychain.name}/listing/${res?.id}`);
        // todo stub this in too
        await storefrontActions.getStorefrontItems(keychain.name);
        connectedWalletActions.removeNftFromCollection(item.mint);
        setModal(true);
      } else {
        createToast(res, ENOTI_STATUS.ERR);
        toggleLoading(false);
      }
    } catch (e) {
      createToast(e.message, ENOTI_STATUS.ERR);
      toggleLoading(false);
    }
  };

  return (
    <ScreenContainer hasBackButton>
      <ModalWrapper isOpen={modal} onClose={() => setModal(false)}>
        <div className="listing-modal">
          <div className="success">
            <img src={require('assets/pngs/checked.png')} />
          </div>
          <p className="subheader mb-xxxl" style={{marginBottom: SPACING.XXXL}}>
            Listing has been created!
          </p>
          <ColoredButton onClick={copyLink} style={{marginBottom: SPACING.LG}}>
            Copy Listing Link
          </ColoredButton>
          {/* <ColoredButton onClick={openSharing}>Share on social media</ColoredButton> */}
          <Link to={'/' + ENavStack.BAZAAR}>
            <ColoredButtonInverse>Return to Bazaar</ColoredButtonInverse>
          </Link>
        </div>
      </ModalWrapper>
      <div className="create-listing">
        <div className="main-con">
          <p className="header">Complete listing</p>
          <p className="normal">You have selected the following for your listing:</p>
          <ul className="selected-items">
            {collectibleListingInfos.map((item: ICollectibleListingInfo, i: number) => {
              return (
                <li key={i}>
                  <p>
                    {item.name} x {item.quantity}
                  </p>
                </li>
              );
            })}
          </ul>
          <p className="normal">{`Fill in the details below:`}</p>
          <Input value={bagName} onChangeText={setBagName} label={`Title`} name="bagName" />
          <Input value={desc} onChangeText={setDesc} multiline label="Description" />
          <Input
            value={amount}
            onChangeText={setAmount}
            inputMode="number"
            label={`Price ( ${currency?.symbol} )`}
            placeholder="0.00"
            name="amount"
          />
          <div>
            <p className="normal">Currency</p>
            <select
              value={currency.name}
              onChange={(e) => setCurrency(coinOptions.filter((x) => x.name === e.target.value)[0])}
            >
              {coinOptions.map((x, idx) => (
                <option key={idx} value={x.name}>
                  {x.symbol}
                </option>
              ))}
            </select>
          </div>
          <div>
            <p className="normal">Deposit wallet</p>
            <select value={depositWallet} onChange={(e) => setDepositWallet(e.target.value)}>
              {[
                {label: `Connected (${formatAddress(connectedWallet.address)})`, value: 'connected'},
                // ENABLE_STACHE && {label: 'Stache', value: 'stache'},
                ...keychain.keys
                  .filter((key) => key.walletAddress.toBase58() !== connectedWallet.address.toBase58())
                  .map((key) => ({
                    label: formatAddress(key.walletAddress.toBase58()),
                    value: key.walletAddress.toBase58(),
                  })),
              ].map((x, idx) => (
                <option key={idx} value={x.value}>
                  {x.label}
                </option>
              ))}
            </select>
            <p className="hint">*The wallet that proceeds will be deposited to</p>
          </div>
        </div>
        <ColoredButton onClick={handleSubmit} disabled={loading} style={{margin: `${SPACING.XXXL}px 0 0 0`}}>
          {loading ? 'Listing...' : 'Complete listing'}
        </ColoredButton>
      </div>
    </ScreenContainer>
  );
};

export default CreateListing;
