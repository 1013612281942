// import { ICollectible } from './types';
import {useRecoilState, useRecoilValue} from 'recoil';
import {EWalletType, ICollectible, IConnectedWallet, ITokenBag, NftType} from 'store/connectedWallets';
import {connectedWalletAtom} from './state';
import {AnchorWallet} from '@solana/wallet-adapter-react';
import {PublicKey} from '@solana/web3.js';
import {getSolTokenBag} from '../store-utils';
import {blacklistAtom, ogCollectionsAtom} from 'store/system';
import {AssetType, IAttribute, IListing} from 'store/yardsale';
import {rpcClient, solanaClient} from '../../constants/factory';
import {FriendlyError} from '../../utils/errors';
import {createProvider} from '../../utils/web3/connection';
import {useWalletAdapter} from '../../hooks/useWalletAdapter';
import { STokenInfo } from 'apis/apiTypes';
import { narrow } from 'apis/solana/util';
import { cloneDeep } from 'lodash';

function useConnectedWalletActions() {
  const {wallet, disconnect, signAndSendTransaction} = useWalletAdapter();
  const blacklist = useRecoilValue(blacklistAtom);
  const ogCollections = useRecoilValue(ogCollectionsAtom);

  const [connectedWallet, setConnectedWallet] = useRecoilState(connectedWalletAtom);

  async function connectWallet(anchorWallet: AnchorWallet) {
    try {
      const walletPublicKey: PublicKey = anchorWallet.publicKey;

      const provider = createProvider(anchorWallet as any);
      await solanaClient.initPrograms(provider);

      // set this last, as other atoms that depend on the wallet also depend on the solana client
      const walletState: IConnectedWallet = {
        address: walletPublicKey,
        checkedForStache: false,
        walletLinked: false,
        collectibles: [],
        tokens: [],
        label: undefined,
        walletType: EWalletType.CONNECTED,
        assetsLoaded: false,
      };
      setConnectedWallet(walletState);
    } catch (error) {
      console.log('Error connecting wallet: ', error);
      throw new Error("There was an error connecting your wallet. Sorry we're in beta! 😅");
    }
  }

  async function load(force: boolean = false) {
    // fetch all the token accounts
    // await stacheWalletLoader();
    if (!connectedWallet || force) {
      const assets = await rpcClient.fetchAssets(wallet.publicKey, blacklist, ogCollections);

      // console.log(`got token accounts for ${wallet.address.toString()}: ${JSON.stringify(tokenAccounts, null, 2)}`);
      const solBag = await getSolTokenBag(wallet.publicKey);
      assets.tokens.unshift(solBag); // make SOL the first tokenbag

      setConnectedWallet((prev) => ({
        ...prev,
        tokens: assets.tokens,
        assetsLoaded: true,
        collectibles: assets.collectibles.map(x => ({
          ...x,
          qty: typeof(x.qty) === "string" ? parseInt(x.qty) : x.qty // FUCK THIS RPC
        })) as ICollectible[],
      }));
    }
  }

  // This function must ALWAYS be called last in whatever block it's used in
  async function disconnectWallet() {
    setConnectedWallet(null);
    await disconnect();
  }

  function removeNftFromCollection(mint: string, qty: number = -1) {
    setConnectedWallet((prev) => {
      const index = prev.collectibles.findIndex((collectible) => collectible.mint === mint);
      console.log("REMOVE:", mint, qty, index);
      if (index === -1) return prev;
      if (qty === -1 || qty === prev.collectibles[index].qty) {
        const newArray = [...prev.collectibles.slice(0, index), ...prev.collectibles.slice(index + 1)];
        return {
          ...prev,
          collectibles: newArray,
        };
      }
      else {
        const newArray = [...prev.collectibles];
        console.log(newArray[index]);
        newArray[index] = {
          ...newArray[index],
          qty: newArray[index].qty - qty
        }
        console.log(newArray[index]);
        return {
          ...prev,
          collectibles: newArray
        };
      }
    });
  }

  // for delisting or purchase, adds the listing items to connected wallet
  async function addListingItemsToWallet(listing: IListing) {
    const newCollectibles: ICollectible[] = cloneDeep(connectedWallet.collectibles);
    const newTokenBags: ITokenBag[] = cloneDeep(connectedWallet.tokens);
    
    for (const item of listing.items) {
      if (item.assetType == AssetType.NFT && narrow <ICollectible> (item)) {
        const idx = newCollectibles.findIndex(x => x.mint === item.mint);
        if (idx === -1) newCollectibles.push(item);
        else {
          newCollectibles[idx].qty = newCollectibles[idx].qty + item.qty;
        }
      } else if (narrow <ITokenBag> (item)) {
        const idx = newTokenBags.findIndex(x => x.mint.toBase58() === item.mint);
        if (idx === -1) {
          const tokenInfo: STokenInfo = await rpcClient.fetchTokenInfo(item.mint);
          if (tokenInfo) {
           newTokenBags.push({
              ...item,
              amount: item.qty * (10 ** tokenInfo.decimals),
              amountUi: item.qty,
              amountUiString: item.qty.toString(),
              decimals: tokenInfo.decimals,
              symbol: tokenInfo.symbol,
              name: tokenInfo.name,
              tokenAccount: new PublicKey(item.tokenAccount),
              mint: new PublicKey(item.mint),
            });
          }
        }
        else {
          newTokenBags[idx] = {
            ...newTokenBags[idx],
            amount: newTokenBags[idx].amount + item.qty * (10 ** newTokenBags[idx].decimals),
            amountUi: newTokenBags[idx].amountUi + item.qty,
            amountUiString: newTokenBags[idx].amountUi.toString(),
          }
        }
      }
    }
    setConnectedWallet((prev) => {
      return {
        ...prev,
        collectibles: newCollectibles,
        tokens: newTokenBags,
      }
    });
  }

  async function sendNft(collectible: ICollectible, toAddress: PublicKey) {
    const nftMint = new PublicKey(collectible.mint);

    let tx;
    switch (collectible.nftType) {
      case NftType.Standard:
      case NftType.Semi:
        tx = await solanaClient.transferStandard(nftMint, new PublicKey(collectible.tokenAccount), toAddress, 1, 0);
        break;
      case NftType.Programmable:
        tx = await solanaClient.transferPnft(wallet, nftMint, wallet.publicKey, toAddress);
        break;
      case NftType.Compressed:
        tx = await rpcClient.transferCompressed(wallet, nftMint, wallet.publicKey, toAddress);
        break;
      default:
        throw new Error("can't handle token standard: " + collectible.nftType);
    }
    const txid = await signAndSendTransaction(tx);
    const confirmation = await rpcClient.confirmTransaction(txid);

    if (confirmation) {
      removeNftFromCollection(nftMint.toBase58());
    } else {
      throw new FriendlyError("Couldn't confirm transaction. NFT may not have been sent.");
    }
  }

  return {
    connectWallet,
    load,
    disconnectWallet,
    removeNftFromCollection,
    addListingItemsToWallet,
    sendNft,
  };
  
}

export {useConnectedWalletActions};
