import {atom, selector} from 'recoil';
import {IKey, IKeychain, IKeychainState} from "./types";
import {connectedWalletAtom} from 'store/connectedWallets';
import {PublicKey} from '@solana/web3.js';


export const keychainAtom = atom<IKeychain>({
  key: 'keychain',
  default: {
    accountAddress: null,
    name: '',
    walletLinked: false,
    keys: [] as IKey[],
  }
})

// keychainState is a bit of a confusing name, but it refers to a specific account type on the Keychain program
// used for pending actions related to linking keys
export const keychainStateAtom = atom<IKeychainState>({
  key: "keychainState",
  default: {
    keychain_version: 0,
    pending_action: null,
    action_threshold: 0
  }
})

// for testing
// default: {
//   keychain_version: 0,
//   pending_action: {
//     action_type: KeyChainActionType.AddKey,
//     votes: [new PublicKey('FqCKYHtvCTzHWgW34uhXwVPGC4KKJDWqkYpdwnq7K1rT')],
//     verified: false,
//     key: new PublicKey('FqCKYHtvCTzHWgW34uhXwVPGC4KKJDWqkYpdwnq7K1rT')
//   },
//   action_threshold: 2
// }

export const connectedKeySelector = selector({
  key: 'connectedKey',
  get: ({get}) => {
    const keychain = get(keychainAtom);
    const wallet = get(connectedWalletAtom);
    return keychain.keys.find((key: IKey) => key.walletAddress.equals(wallet.address));
  }
})

export const keysByVotingStatus = selector({
  key: "keysByVotingStatus",
  get: ({get}) => {
    const keychain = get(keychainAtom);
    const keychainState = get(keychainStateAtom);
    if (keychainState.pending_action) {
      // Converst BitSet-representing integer into a radix-2 (binary) value
      let voted: PublicKey[] = [];
      let binaryBitset = keychainState.pending_action.votes.data.toString(2)

      // Add some zeros in front of string to make the for loop below easier
      // They'll all indicate keychain keys that result to false (not voted)
      if (binaryBitset.length != keychain.keys.length) {
        const diff = keychain.keys.length - binaryBitset.length;
        for (let i = 0; i < diff; i++) {
          binaryBitset = `0${binaryBitset}`;
        }
      }
      const copy = Object.assign([], keychain.keys).reverse();

      for (let i = 0; i < binaryBitset.length; i++) {
        let val = binaryBitset.charAt(i)
        const key: IKey = copy[i]
        if (!!key && val === '1') voted.push(key.walletAddress)
      }

      return {
        voted,
        notVoted: copy.reduce((array: PublicKey[], key: IKey) => {
          if (!voted.includes(key.walletAddress)) array.push(key.walletAddress);
          return array;
        }, [])
      }
    } else {
      return {
        voted: [],
        notVoted: []
      }
    }

  }
})
