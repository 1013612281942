// log the accounts in the given object

import {PublicKey} from "@solana/web3.js";

export function printObj(jsonObject: object, preprintString: string = null) {
  if (preprintString) {
    console.log(`${preprintString}: \n`);
  }
  for (const key in jsonObject) {
    if (jsonObject[key] !== null) {
      console.log(`${key}: ${jsonObject[key].toString()}`);
    } else {
      console.log(`${key}: null`);
    }
  }
}


export function printObject(obj, indent = '') {
  for (const [key, value] of Object.entries(obj)) {
    if (value instanceof PublicKey) {
      console.log(`${indent}${key}: ${value.toBase58()}`);
    } else if (typeof value === 'object' && value !== null) {
      console.log(`${indent}${key}:`);
      printObject(value, `${indent}  `);
    } else {
      if (value) {
        console.log(`${indent}${key}: ${value.toString()}`);
      } else {
        console.log(`${indent}${key}: ${value}`);
      }
    }
  }
}
