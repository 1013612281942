//Web3
import {PublicKey} from '@solana/web3.js';
import {AccountResponse, SKeychain} from 'apis/solana/types';
import {findKeychainKeyPda, findKeychainPda, findKeychainStatePda} from '../../programs/keychain-utils';
import {getKeychainProgram} from 'programs/program-utils';

//Recoil
import {useRecoilState, useRecoilValue, useResetRecoilState} from 'recoil';
import {
  IKey,
  IKeychain,
  IKeychainState,
  KeyChainActionType,
  keychainAtom,
  keychainStateAtom,
  keysByVotingStatus
} from 'store/keychain';
import {connectedWalletAtom, EWalletType, IWallet, useConnectedWalletActions} from 'store/connectedWallets';

//Utils
import useWalletLoader from 'hooks/useWalletLoader';
import {useWalletAdapter} from '../../hooks/useWalletAdapter';
import {rpcClient, solanaClient} from "../../constants/factory";
import {createProvider} from "../../utils/web3/connection";
import {Wallet} from "@project-serum/anchor";

function useKeychainActions() {
  const walletLoader = useWalletLoader();
  const {signAndSendTransaction, wallet} = useWalletAdapter();

  const [keychain, setKeychain] = useRecoilState(keychainAtom);
  const resetKeychainState = useResetRecoilState(keychainAtom);
  const [keychainState, setActualKeychainState] = useRecoilState(keychainStateAtom);
  const resetActualKeychainState = useResetRecoilState(keychainStateAtom);
  const votes = useRecoilValue(keysByVotingStatus);
  const connectedWalletActions = useConnectedWalletActions();
  const connectedWallet = useRecoilValue(connectedWalletAtom);
 
  // Basic loading without assets. This allows for the program to move forward with all the appropriate keys listed
  async function loadBasicData(data?: IKeychain) {
    const keychainData = data ?? keychain;
    const keysWithBasicContent = keychainData.keys.map(
        (key) =>
            ({
              ...key,
              contents: {
                ...key.contents,
                assetsLoaded: false,
                label: key.label ?? key.walletAddress.toBase58(),
                walletType: EWalletType.LINKED,
                address: key.walletAddress,
              },
            } as IKey)
    );
    setKeychain((prev) => ({
      ...prev,
      keys: keysWithBasicContent,
    }));
  }

  // Do NOT await this function due to some user's wallets having massive amounts of nfts
  async function loadAssetData(data?: IKeychain) {
    const keychainData = data ?? keychain;
    const keysWithContent = await Promise.all(
        keychainData.keys.map(async (key) => {
          return getWalletData(key);
        })
    );
    setKeychain((prev) => ({
      ...prev,
      keys: keysWithContent,
    }));
  }

  const getKeychainStateAccount = async (stacheid: string) => {
    const [keychainPDA] = findKeychainPda(stacheid);
    const [keychainStatePDA] = findKeychainStatePda(keychainPDA);
    const provider = createProvider(wallet as Wallet); // todo might have refactored this wrong
    const keychainProg = getKeychainProgram(provider);

    return await keychainProg.account.keyChainState.fetch(keychainStatePDA);
  };

  const loadKeychainStateAccount = async (stacheid: string) => {
    const keychainStateAccount = await getKeychainStateAccount(stacheid);
    if (!keychainStateAccount) return;

    const {keychainVersion, actionThreshold, pendingAction} = keychainStateAccount;

    const _keychainState: IKeychainState = {
      keychain_version: keychainVersion as number,

      pending_action: !!pendingAction
          ? {
            // @ts-ignore
            action_type: pendingAction.actionType.hasOwnProperty('addKey')
                ? KeyChainActionType.AddKey
                : KeyChainActionType.RemoveKey,
            // @ts-ignore
            key: pendingAction.key,
            // @ts-ignore
            votes: pendingAction.votes,
            // @ts-ignore
            verified: pendingAction.verified,
          }
          : null,
      action_threshold: actionThreshold as number,
    };
    setActualKeychainState(_keychainState);
  };

  async function getWalletData(key: IKey): Promise<IKey> {
    const assets = await walletLoader.load(key.walletAddress);
    console.log('got assets!');
    const contents: IWallet = {
      address: key.walletAddress,
      label: key.label,
      walletType: EWalletType.LINKED,
      collectibles: assets.collectibles,
      tokens: assets.tokens,
      assetsLoaded: true,
    }
    return {
      ...key,
      contents,
    } as IKey;
  }

  // async function checkKeychainByKey(walletAddress: PublicKey): Promise<IKeychain> {
  //   const keychain = await solanaClient.getKeychainByKeyWallet(walletAddress);
  //   if (keychain) {
  //     return setKeychainState(keychain);
  //   } else {
  //     return null;
  //   }
  // }

  function setKeychainState(keychainResp: AccountResponse<SKeychain>, connectedWalletKey?: PublicKey): IKeychain {
    const keychainState = createKeychainState(keychainResp.pda, keychainResp.account, connectedWalletKey);
    setKeychain(keychainState);
    return keychainState;
  }

  function createKeychainState(accountAddress: PublicKey, account: any, connectedWalletKey?: PublicKey): IKeychain {
    let walletLinked = false;
    console.log('starting with: ', accountAddress, account);
    console.log('Connected wallet hasnt made it yet?', connectedWallet, connectedWalletKey);
    const keys = account.keys.map((key) => {
      const keyPda = findKeychainKeyPda(key.key);
      if (key.key.equals(connectedWallet?.address ?? connectedWalletKey)) {
        walletLinked = true;
      }
      return {
        label: key.label,
        walletAddress: key.key,
        accountAddress: keyPda,
      };
    });
    if (walletLinked) {
      console.log('wallet verified', wallet.publicKey.toBase58(), accountAddress.toBase58());
    } else {
      console.log('wallet not verified', wallet.publicKey.toBase58(), accountAddress.toBase58());
    }
    return {
      pda: accountAddress.toBase58(),
      accountAddress,
      name: account.name,
      keys,
      walletLinked,
    };
  }

  async function votePendingAction(isApproving: boolean): Promise<true | string> {
    const updatePendingAction = () => {
      const bitSet: number = calculateUpdatedBitset();
      setActualKeychainState(prev => ({
        ...prev,
        pending_action: {
          ...prev.pending_action,
          votes: {data: bitSet}
        }
      }))
    }

    const stubUpdatedKeychainState = (isApproving: boolean) => {
      if (isApproving) {
        // Adding
        if (keychainState.pending_action.action_type === KeyChainActionType.AddKey) {
          // If final vote
          if (keychainState.action_threshold === votes.voted.length - 1) {
            // If final vote and pending wallet has been verified
            if (keychainState.pending_action.verified) {
              resetActualKeychainState();
              // If final vote and pending wallet has not been verified
            } else {
              updatePendingAction()
            }
          } else {
            updatePendingAction()
          }
          // Removing
        } else {
          // If final vote
          if (keychainState.action_threshold === votes.voted.length - 1) {
            resetActualKeychainState();
            // If not final vote (note that voting to remove a wallet doesnt require wallet verification process)
          } else {
            updatePendingAction()
          }
        }
        // 'Deny' automatically cancels the pendingAction
      } else {
        resetActualKeychainState();
      }

    };
    try {
      const [keychainKeyPda] = findKeychainKeyPda(keychainState.pending_action?.key);
      const tx = await solanaClient.getVotePendingActionTx(
          keychainKeyPda,
          isApproving,
          keychainState.pending_action.action_type === KeyChainActionType.RemoveKey
      );
      const txid = await signAndSendTransaction(tx);
      const confirmation = await rpcClient.confirmTransaction(txid);
      if (confirmation) {
        stubUpdatedKeychainState(isApproving);
        return true;
      } else return 'Transaction did not complete';
    } catch (err) {
      return 'Transaction did not complete';
    }
  }

  async function removeKey(keyWallet: PublicKey): Promise<true | string> {
    const stubUpdatedKeychainState = () => {
      const bitSet: number = calculateUpdatedBitset();
      setActualKeychainState(prev => ({
        ...prev,
        pending_action: {
          action_type: KeyChainActionType.RemoveKey,
          key: new PublicKey(keyWallet),
          verified: false,
          votes: {data: bitSet}
        },
        action_threshold: 2
      }))
    }

    let keyState = null;
    for (let key of keychain.keys) {
      if (key.walletAddress.equals(keyWallet)) {
        keyState = key;
      }
    }
    if (keyState === null) {
      console.log('key not found in keychain!!');
    }

    const [keychainKeyPda] = findKeychainKeyPda(keyState.walletAddress);

    try {
      const tx = await solanaClient.getRemoveKeyTx(keyWallet, keychainKeyPda);
      const txid = await signAndSendTransaction(tx);
      const confirmed = await rpcClient.confirmTransaction(txid);

      if (confirmed) {
        stubUpdatedKeychainState();
        return true;
      } else return 'Transaction did not complete';
    } catch (err) {
      return 'Transaction did not complete';
    }
  }

  // adds an unverified key to the keychain
  async function addKey(addingWalletAddress: PublicKey): Promise<true | string> {
    const stubUpdatedKeychainState = (bitSetCalculator: () => number) => {
      console.log("Trying stub...")
      const bitSet: number = bitSetCalculator();
      console.log("Found stub", bitSet);
      setActualKeychainState(prev => ({
        ...prev,
        pending_action: {
          action_type: KeyChainActionType.AddKey,
          key: new PublicKey(addingWalletAddress),
          verified: false,
          votes: {data: bitSet}
        },
        action_threshold: 2
      }))
      console.log("Set new state...")
    }

    try {
      const tx = await solanaClient.getAddKeyTx(addingWalletAddress);
      const txid = await signAndSendTransaction(tx);
      const confirmed = await rpcClient.confirmTransaction(txid);

      if (confirmed) {
        console.log("Confirmed...")
        stubUpdatedKeychainState(calculateUpdatedBitset);
        return true;
      } else return 'Transaction did not complete';
    } catch (err) {
      return 'Transaction did not complete';
    }
  }

  // verifies the connected wallet on the keychain. returns true if successful, false if not
  async function verifyKey(stacheid: string): Promise<true | string> {
    try {
      let tx = await solanaClient.getVerifyKeyTx(connectedWallet.address, stacheid);
      const txid = await signAndSendTransaction(tx);
      const confirmed = await rpcClient.confirmTransaction(txid);
      if (confirmed) return true;
      else return 'Transaction did not complete';
    } catch (err) {
      return 'Transaction did not complete';
    }
  }

  async function checkIfKeyAvailable(address: PublicKey): Promise<boolean> {
    const res = await solanaClient.getKeychainByKeyWallet(address);
    return !!res ? false : true;
  }

  async function resetKeychain(disconnectWallet = false): Promise<boolean> {
    if (disconnectWallet) {
      await connectedWalletActions.disconnectWallet();
    }
    resetKeychainState();
    return true;
  }

  function calculateUpdatedBitset(): number {
    console.log("Trying... 1")
    const copy = Object.assign([], keychain.keys).reverse();
    console.log("Trying 2....")
    const votedKeys = votes.voted.map(vote => vote.toBase58());
    console.log("Trying 3...")
    let binaryString: string = "";
    console.log("Copy: ", copy);
    copy.forEach((key: IKey) => {
      const binaryVal = votedKeys.includes(key.walletAddress.toBase58()) ? 1 : 0;
      console.log("Binary val:", binaryVal);
      binaryString = binaryString + binaryVal.toString();
    });
    console.log("Previous binary string: ", binaryString);
    const connectedWalletKeyIndex = copy.findIndex(
        (key: IKey) => key.walletAddress.toBase58() === connectedWallet.address.toBase58()
    )
    const newBinaryString = binaryString.substring(0, connectedWalletKeyIndex)
        + "1"
        + binaryString.substring(connectedWalletKeyIndex + 1);
    console.log("Updated binary string:  ", newBinaryString)
    const binary = parseInt(newBinaryString, 2);
    console.log("Actual binary", binary);
    const bitSet = binary.toString(10);
    console.log("Bitset: ", bitSet);
    const bitSetNumber = parseInt(bitSet);
    return bitSetNumber;
  }

  return {
    loadBasicData,
    loadAssetData,
    resetKeychain,
    setKeychainState,
    getKeychainStateAccount,
    addKey,
    verifyKey,
    removeKey,
    votePendingAction,
    checkIfKeyAvailable,
    loadKeychainStateAccount,
  };
}

export {useKeychainActions};
