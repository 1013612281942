import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { toastsAtom, IToasts, IToast } from '.'

function useToastsActions() {
    
    const [toasts, setToasts] = useRecoilState<IToasts>(toastsAtom);

    function removeToast(id: string) {
        setToasts({ toasts: toasts.toasts.filter((toast: IToast) => toast.id !== id) });
    }

    //** Better approach - explained in hooks/useToasts */
    // const removeToast = useCallback((id: string) => {
    //     setToasts(toasts => {
    //         return {
    //             ...toasts,
    //             toasts: toasts.toasts.filter((toast: Toast) => toast.id !== id)
    //         }
    //     })
    // }, [setToasts])

    return {
        removeToast
    };
}

export { useToastsActions };