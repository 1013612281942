import {PublicKey} from '@solana/web3.js';

export const formatAddress = (address: any) => {
  if (!address) return '';
  if (typeof address == 'object') {
    // assume this is a PublicKey
    address = address.toString();
  }
  return address.length > 10
    ? `${address.substring(0, 4)}...${address.substring(address.length - 4, address.length)}`
    : address;
};

export const formatSecondsToTime = (secs: number) => {
  if (secs < 60) return `${Math.round(secs)} second${secs === 1 ? '' : 's'}`; // seconds

  if (secs >= 60 && secs < 3600) {
    // minutes
    const minutes = Math.floor(secs / 60);
    return `${minutes} minute${minutes === 1 ? '' : 's'}`;
  }

  if (secs >= 3600) {
    // hours
    const hours = Math.floor(secs / 3600);
    return `${hours} hour${hours === 1 ? '' : 's'}`;
  }

  return 0;
};

export function truncateStringWithLastWord(string: string, maxLength: number): string {
  if (!string) {
    return '';
  }
  if (string.length <= maxLength) {
    return string;
  }

  const lastSpaceIndex = string.lastIndexOf(' ');

  if (lastSpaceIndex !== -1) {
    return (
      string.substring(0, maxLength - (string.length - lastSpaceIndex + 3)) +
      '... ' +
      string.substring(lastSpaceIndex + 1)
    );
  } else {
    return string.substring(0, maxLength - 3) + '...';
  }
}

export function truncateStringWithEllipsis(string: string, maxLength: number): string {
  if (!string) {
    return '';
  }
  if (string.length <= maxLength) {
    return string;
  }

  return string.slice(0, maxLength - 2) + "...";
}


export function ensureIsString(key: PublicKey | string) {
  if (typeof key === 'string') {
    return key;
  } else {
    return key.toBase58();
  }
}

export function ensureIsPublicKey(key: PublicKey | string) {
  if (typeof key === 'string') {
    return new PublicKey(key);
  } else {
    return key;
  }
}
