import {PublicKey} from "@solana/web3.js";
import * as anchor from '@project-serum/anchor';
import {BN, Idl, IdlTypes} from '@project-serum/anchor';
import {IdlTypeDef} from "@project-serum/anchor/dist/cjs/idl";
import {TypeDef} from "@project-serum/anchor/dist/cjs/program/namespace/types";

export const DEFAULT_MULTISIG_PROGRAM_ID = new PublicKey(
    "SMPLecH534NA9acpos4G6x7uf3LWbCAwZQE9e8ZekMu"
);
export const DEFAULT_PROGRAM_MANAGER_PROGRAM_ID = new PublicKey(
    "SMPLKTQhrgo22hFCVq2VGX1KAktTWjeizkhrdB1eauK"
);


export const getMsPDA = (create_key: PublicKey, programId: PublicKey) =>
    PublicKey.findProgramAddressSync(
        [
          anchor.utils.bytes.utf8.encode("squad"),
          create_key.toBuffer(),
          anchor.utils.bytes.utf8.encode("multisig"),
        ],
        programId
    );

export const getTxPDA = (
    msPDA: PublicKey,
    txIndexBN: BN,
    programId: PublicKey
) =>
    PublicKey.findProgramAddressSync(
        [
          anchor.utils.bytes.utf8.encode("squad"),
          msPDA.toBuffer(),
          txIndexBN.toArrayLike(Buffer, "le", 4),
          anchor.utils.bytes.utf8.encode("transaction"),
        ],
        programId
    );

export const getIxPDA = (
    txPDA: PublicKey,
    iXIndexBN: BN,
    programId: PublicKey
) =>
    PublicKey.findProgramAddressSync(
        [
          anchor.utils.bytes.utf8.encode("squad"),
          txPDA.toBuffer(),
          iXIndexBN.toArrayLike(Buffer, "le", 1), // note instruction index is an u8 (1 byte)
          anchor.utils.bytes.utf8.encode("instruction"),
        ],
        programId
    );

export const getAuthorityPDA = (
    msPDA: PublicKey,
    authorityIndexBN: BN,
    programId: PublicKey
) =>
    PublicKey.findProgramAddressSync(
        [
          anchor.utils.bytes.utf8.encode("squad"),
          msPDA.toBuffer(),
          authorityIndexBN.toArrayLike(Buffer, "le", 4), // note authority index is an u32 (4 byte)
          anchor.utils.bytes.utf8.encode("authority"),
        ],
        programId
    );

export const getProgramManagerPDA = (msPDA: PublicKey, programId: PublicKey) =>
    PublicKey.findProgramAddressSync(
        [
          anchor.utils.bytes.utf8.encode("squad"),
          msPDA.toBuffer(),
          anchor.utils.bytes.utf8.encode("pmanage"),
        ],
        programId
    );

export const getManagedProgramPDA = (
    programManagerPDA: PublicKey,
    managedProgramIndexBN: BN,
    programId: PublicKey
) =>
    PublicKey.findProgramAddressSync(
        [
          anchor.utils.bytes.utf8.encode("squad"),
          programManagerPDA.toBuffer(),
          managedProgramIndexBN.toArrayLike(Buffer, "le", 4), // note authority index is an u32 (4 byte)
          anchor.utils.bytes.utf8.encode("program"),
        ],
        programId
    );

export const getProgramUpgradePDA = (
    managedProgramPDA: PublicKey,
    upgradeIndexBN: BN,
    programId: PublicKey
) =>
    PublicKey.findProgramAddressSync(
        [
          anchor.utils.bytes.utf8.encode("squad"),
          managedProgramPDA.toBuffer(),
          upgradeIndexBN.toArrayLike(Buffer, "le", 4), // note authority index is an u32 (4 byte)
          anchor.utils.bytes.utf8.encode("pupgrade"),
        ],
        programId
    );


// Copied (with slight modification) from @project-serum/anchor/src/program/namespace/types (not exported)
type TypeDefDictionary<T extends IdlTypeDef[], Defined> = {
  [K in T[number]["name"]]: TypeDef<T[number] & { name: K }, Defined> & {
  publicKey: PublicKey;
};
};

type AccountDefDictionary<T extends Idl> = TypeDefDictionary<
    NonNullable<T["accounts"]>,
    IdlTypes<T>
>;

/*
export type MultisigAccount = AccountDefDictionary<SquadsMpl>["ms"];
export type TransactionAccount =
    AccountDefDictionary<SquadsMpl>["msTransaction"];
export type InstructionAccount =
    AccountDefDictionary<SquadsMpl>["msInstruction"];

export type ProgramManagerAccount =
    AccountDefDictionary<ProgramManager>["programManager"];
export type ManagedProgramAccount =
    AccountDefDictionary<ProgramManager>["managedProgram"];
export type ProgramUpgradeAccount =
    AccountDefDictionary<ProgramManager>["programUpgrade"];

export type SquadsMethods = MethodsBuilder<
    SquadsMpl,
    AllInstructions<SquadsMpl>
>;
export type SquadsMethodsNamespace = MethodsNamespace<
    SquadsMpl,
    AllInstructions<SquadsMpl>
>;

export type ProgramManagerMethodsNamespace = MethodsNamespace<
    ProgramManager,
    AllInstructions<ProgramManager>
>;
*/
