import {BN} from '@project-serum/anchor';
import {PublicKey, Transaction} from '@solana/web3.js';
import {EAssetSelectionType, ICurrency} from 'store/yardsale';

export interface SKey {
  key: PublicKey;
}

export interface SDomain {
  name: string;
}

export interface SKeychain {
  name: string;
  domain: string;
  numKeys: number;
  bump: number;
  keys: SKey[];
}

export interface STransactionInfo {
  txid: string;
}

export interface SStache {
  stacheid: string;
  keychainPda: PublicKey;
  domain: PublicKey;
  keychain: SKeychain | null; // might be null depending on context
  bump: number;
  version: number;
  nextVaultIndex: number;
  nextAutoIndex: number;
  vaults: number[];
  autos: number[];
}

export interface AccountResponse<T> {
  pda: PublicKey;
  account?: T;
}

export interface StandardListingsPayload {
  nftMints: (PublicKey | null)[];
  fromTokenAccounts: (PublicKey | null)[];
  keychainPda: PublicKey;
  sellerAccountPda: PublicKey;
  listingDomainPda: PublicKey;
  price: number;
  decimals: number;
  itemQuantities: BN[];
  listingPda: PublicKey;
  assetListingType: EAssetSelectionType;
  currencyAccount: PublicKey;
  proceedsAccount?: PublicKey;
  proceedsTokenAccount?: PublicKey;
}

export interface DelistBazaarPayload {
  nftMints: (PublicKey | null)[];
  fromTokenAccounts: (PublicKey | null)[];
  sellerAccountPda: PublicKey;
  listingPda: PublicKey;
}

export interface ListingPayload {
  price: number;
  decimals: number;
  currencyAccount: PublicKey;
  nftMint: PublicKey;
  fromItemToken: PublicKey;
  listingPda: PublicKey;
  listingItemToken: PublicKey;
  proceedsAccount?: PublicKey;
  proceedsTokenAccount?: PublicKey;
}

export interface YardsaleDelistingPayload {
  listingPda: PublicKey;
  nftMint: PublicKey;
  sellerItemToken: PublicKey;
  listingItemToken: PublicKey;
}

export interface PurchasingYardsalePayload {
  nftMint: PublicKey;
  buyerItemTokenAccount: PublicKey;
  listingPda: PublicKey;
  buyerCurrencyTokenAccount?: PublicKey;
}

export interface PurchasingBazaarPayload {
  nftMints: (PublicKey | null)[];
  buyerCurrencyTokenAccount?: PublicKey;
  sellerAccountPda: PublicKey;
  buyerItemTokenAccounts: (PublicKey | null)[];
  unitQuantity?: number;
  listingPda: PublicKey;
}

export type MoneyAccounts = {
  currencyAccount: PublicKey;
  proceedsAccount: PublicKey | null;
  proceedsTokenAccount: PublicKey | null;
}

export enum SVaultType {
  EASY = 'EASY',
  TWOSIG = 'TWOSIG',
  MULTISIG = 'MULTISIG', // squads
}

export enum SActionType {
  TRANSFER = 'TRANSFER',
}

export enum STriggerType {
  BALANCE = 'BALANCE',
}

export interface STrigger {
  type: STriggerType;
}

export enum SBalanceCondition {
  // not supported yet
  // EQUAL = 'EQUAL',
  GREATER_THAN = 'GREATER_THAN',
  LESS_THAN = 'LESS_THAN',
}

export interface SAction {
  type: SActionType;
}

export interface SVaultParams {
  threshold?: number;
  members?: PublicKey[];
  multisig?: PublicKey;
}

export interface SAuto {
  name: string;
  stache: PublicKey;
  index: number;
  bump: number;
  active: boolean;
  paused: boolean;
  numExecutions: number;
  numTriggers: number;
  triggerType?: STriggerType;
  actionType?: SActionType;
  trigger?: STrigger;
  action?: SAction;
}

export interface SVault {
  name?: string;
  label?: string;
  stache: PublicKey;
  index: number;
  bump: number;
  vaultType: SVaultType;
  locked: boolean;
  nextActionIndex: number;
  actions: any;
  // only for multisig
  sigs?: number;
  multisig?: PublicKey;
}

export interface STransactionHolder {
  tx: Transaction;
  pda?: PublicKey;
}
