import React from 'react';
import {useRecoilValue} from 'recoil';
import {connectedWalletAtom, EWalletType, IWallet} from 'store/connectedWallets';
import {IKey, keychainAtom} from 'store/keychain';
import {stacheWalletAtom} from 'store/stache';
import {vaultsAtom} from 'store/vault';
import {formatAddress} from 'utils/string-formatting';
import {IOption} from 'components/ui/picker';
import {PublicKey} from '@solana/web3.js';
import {ENABLE_STACHE} from 'constants/config';


export const useFindWallets = () => {

  const stacheWallet = useRecoilValue(stacheWalletAtom);
  const connectedWallet = useRecoilValue(connectedWalletAtom);
  const vaults = useRecoilValue(vaultsAtom);
  const keychain = useRecoilValue(keychainAtom);

  const getWalletFromTab = React.useCallback((tabString: string): IWallet => {
    if (tabString === 'stache') return stacheWallet;
    else if (tabString === 'connected') return connectedWallet;
    else {
      const vaultWallet = vaults.find(vault => tabString === vault.address.toBase58())
      if (vaultWallet) return vaultWallet;
      else {
        const key = keychain.keys.find(key => tabString === key.walletAddress.toBase58());
        return key?.contents;
      }
    }
  }, [vaults, keychain, stacheWallet, connectedWallet])

  const getTabFromPublicKey = (pubKey: PublicKey) => {
    switch (pubKey?.toBase58()) {
      case stacheWallet?.address?.toBase58():
        return 'stache';
      case connectedWallet.address.toBase58():
        return 'connected';
      default: {
        const keyWallet = keychain.keys.find(key => key.walletAddress.toBase58() === pubKey?.toBase58())
        return keyWallet.walletAddress.toBase58();
      }
    }
  }

  const getWalletFromPublicKey = React.useCallback((pubKey: PublicKey) => {
    const arr = [connectedWallet, ...(keychain.keys.map(key => key.contents))]
    if (ENABLE_STACHE) arr.unshift(stacheWallet);
    return arr.find(wallet => wallet.address.toBase58() === pubKey?.toBase58());
  }, [])

  const allWalletOptions = React.useMemo(() => {

    const vaultWalletNames: IOption[] = vaults.reduce((array: IOption[], vault) => {
      array.push({
        label: "Vault - " + formatAddress(vault.address),
        value: vault.address
      });
      return array;
    }, []);

    const keychainWalletNames: IOption[] = keychain.keys.reduce((array: IOption[], key: IKey) => {
      if (!connectedWallet) {
        console.log('no connected wallet! connectedwallet: ', connectedWallet);
      } else {
        if (!key?.walletAddress?.equals(connectedWallet?.address)) {
          array.push({
            label: "Linked - " + formatAddress(key.label),
            value: key.walletAddress.toBase58()
          });
        }
      }

      return array;
    }, []);

    const stacheOption = {label: 'Stache', value: 'stache'}
    const arr = [
      {label: 'Connected', value: 'connected'},
      ...vaultWalletNames,
      ...keychainWalletNames,
    ]
    if (ENABLE_STACHE) arr.unshift(stacheOption)
    return arr;
  }, [keychain, vaults, connectedWallet])

  const walletText = React.useCallback((text: string) => {
    switch (text) {
      case EWalletType.LINKED:
        return '[Linked Wallet View]'
      case EWalletType.CONNECTED:
        return '[Connected Wallet]'
      case EWalletType.VAULT:
        return '[Vault Smart Account]'
      case EWalletType.STACHE:
        return '[Stache Smart Account]'
    }
  }, [])

  return {
    walletText,
    allWalletOptions,
    getWalletFromTab,
    getTabFromPublicKey,
    getWalletFromPublicKey
  };
};


export default useFindWallets
