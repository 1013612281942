import {API_URL, KEYCHAIN_DOMAIN, listingsPerPage} from '../../constants/config';
import axios, {AxiosResponse} from 'axios';
import {PublicKey} from '@solana/web3.js';
import {IContactPayload} from 'store/addressBook';
import {ESEARCH_TYPE, EUpdateListingStatus, ICreateListingResponse, LoginResponse, NonceResponse} from './types';
import {ELISTING_PROGRAM, IActivity, IListing, IListingThumb, IShop} from 'store/yardsale';
import {IProfileInfo, IUpdateableUserInfo} from 'store/userProfile';
import {MESSAGE_TYPE} from 'store/notification';
import {Filter, IHub, ListingQuery, Pagination} from "../../store/hubs/types";

class ServerClient {
  private apiUrl: string;
  // jwt token when logged in
  private authToken: string;

  constructor(apiUrl: string) {
    this.apiUrl = apiUrl;
  }

  // retrieves the data object from an api response or throws an error
  getDataFromResponse(resp: AxiosResponse): any {
    const data = resp?.data;

    // success
    if (data?.success) {
      return data.data;
    } else {
      // for 500s and error messages - always throw an error from here
      if (data?.message) {
        throw new Error(data.message);
      } else {
        throw new Error('Unknown server error');
      }
    }
  }

  async getNonce(address: PublicKey): Promise<NonceResponse> {
    const nonceResp = await axios.get(this.apiUrl + '/security/nonce?pubkey=' + address.toBase58());
    const data = this.getDataFromResponse(nonceResp);
    return {
      nonceId: data.id,
      nonce: data.nonce,
    };
  }

  async login(nonceId: number, signature: number[], stacheid: string): Promise<LoginResponse> {
    const loginResp = await axios.post(this.apiUrl + '/auth/login', {
      nonceId,
      signature,
      stacheid,
      domain: KEYCHAIN_DOMAIN,
    });
    const data = this.getDataFromResponse(loginResp);
    // save the auth token for future requests
    this.authToken = data.authToken;
    return data;
  }

  async validateAccessCode(code: string): Promise<boolean> {
    const resp = await axios.get(this.apiUrl + '/invite/validate?code=' + code);
    return this.getDataFromResponse(resp);
  }

  async getUser(stacheid: string): Promise<IProfileInfo> {
    const resp = await axios.get(this.apiUrl + '/stache/user?stacheid=' + stacheid);
    return this.getDataFromResponse(resp);
  }

  async getBlacklist() {
    const resp = await axios.get(this.apiUrl + '/system/blacklist');
    return this.getDataFromResponse(resp);
  }

  async getOgCollections(): Promise<any> {
    const resp = await axios.get(this.apiUrl + '/nft/og-verified');
    return this.getDataFromResponse(resp);
  }

  setAuthToken(token: string) {
    console.log('setting auth token for api calls...');
    this.authToken = token;
  }

  // async getQualifiedCollections() {
  //   return axios({
  //    url: `${this.apiUrl}/collection/all`,
  //    method: 'GET',
  //   })
  // }

  logout() {
    this.authToken = null;
  }

  getRequestConfig() {
    if (this.authToken) {
      return {
        headers: {Authorization: `Bearer ${this.authToken}`},
      };
    } else {
      return {};
    }
  }

  // Yardsale apis
  async getListingsForStacheid(stacheid: string) {
    const resp = await axios.get(this.apiUrl + `/bazaar/${stacheid}/listings`);
    const data: IListing[] = this.getDataFromResponse(resp);
    return data;
  }

  async getListing(listingId: number): Promise<IListing> {
    const resp = await axios.get(this.apiUrl + `/yardsale/listing/${listingId}`);
    const data: IListing = this.getDataFromResponse(resp);
    return data;
  }

  async createdListing(program: ELISTING_PROGRAM, txid: string, description: string, bagName: string) {
    const resp = await axios({
      url: this.apiUrl + `/bazaar/listing`,
      method: 'POST',
      data: {
        description, 
        program, 
        txid, 
        ...(bagName !== "" && {name: bagName})
      },
      ...this.getRequestConfig(),
    });
    const data: ICreateListingResponse = this.getDataFromResponse(resp);
    return data;
  }

  async updateListing(listingId: number, program: ELISTING_PROGRAM, _txid?: string, _description?: string, _name?: string) {
    console.log('trying to update listing...', listingId, program, _txid, _description);
    try {
      const txid = _txid ? {txid: _txid} : {};
      const description = _description ? {description: _description} : {};
      const name = _name ? {name: _name} : {};

      const resp = await axios({
        url: this.apiUrl + `/bazaar/listing/${listingId}`,
        method: 'PUT',
        data: {
          ...description,
          ...name,
          ...txid,
          program,
        },
        ...this.getRequestConfig(),
      });
      const data: any = this.getDataFromResponse(resp);
      console.log('GOt this from updating listing...', data);
      return data;
    } catch (e) {
      console.log('What err is happening', e);
    }
  }

  async syncNonStandardListing(listingId: number, txid: string) {
    const resp = await axios({
      url: this.apiUrl + `/yardsale/listing/${listingId}/sync`,
      method: 'PUT',
      data: {
        status: EUpdateListingStatus.SOLD,
        txid,
      },
      // No authorization header required because anyone with a wallet can transact
    });
    const data: any = this.getDataFromResponse(resp);
    return data;
  }

  async syncBazaarListing(program: ELISTING_PROGRAM.BAZAAR, txid: string) {
    // console.log("txid ", txid);
    const resp = await axios({
      url: this.apiUrl + `/bazaar/sync-tx`,
      method: 'PUT',
      data: {
        program,
        txid,
      },
      // No authorization header required because anyone with a wallet can transact
    });
    const data: any = this.getDataFromResponse(resp);
    return data;
  }

  async getTopShops() {
    const res = await axios.get(this.apiUrl + '/yardsale/top-shops', this.getRequestConfig());
    return this.getDataFromResponse(res);
  }

  async getFeaturedFeed() {
    const res = await axios.get(this.apiUrl + '/bazaar/top-listings', this.getRequestConfig());
    return this.getDataFromResponse(res);
  }

  async getActivityFeed() {
    const res = await axios.get(this.apiUrl + '/yardsale/activity-feed', this.getRequestConfig());
    return this.getDataFromResponse(res);
  }

  async search(query: string, type: ESEARCH_TYPE) {
    const res = await axios.post(this.apiUrl + `/bazaar/search`, {query, type}, this.getRequestConfig());
    return this.getDataFromResponse(res);
  }

  // Contact apis
  async getSearchQuery(query: string) {
    const searchQueryResp = await axios.get(this.apiUrl + `/stache/search?query=${query}`, this.getRequestConfig());
    const searchQueryData = this.getDataFromResponse(searchQueryResp);

    return searchQueryData;
  }

  async getPublicContacts(stacheId: number) {
    const publicContactsResp = await axios.get(
      this.apiUrl + `/contacts/all-pub?stacheId=${stacheId}`,
      this.getRequestConfig()
    );
    const publicContactsData = this.getDataFromResponse(publicContactsResp);

    return publicContactsData;
  }

  async setContact(contact: IContactPayload): Promise<any> {
    const contactsResp = await axios.post(this.apiUrl + '/contacts/contact', contact, this.getRequestConfig());
    const data = this.getDataFromResponse(contactsResp);

    return data;
  }

  async updateContact(contactId: number, label: string): Promise<any> {
    const contactsResp = await axios.put(
      this.apiUrl + `/contacts/contact/${contactId}`,
      {label},
      this.getRequestConfig()
    );
    const data = this.getDataFromResponse(contactsResp);

    return data;
  }

  async deleteContact(contactId: number): Promise<any> {
    console.log('Endpoint: ', this.apiUrl + `/contacts/contact/${contactId}`);
    const contactsResp = await axios.delete(this.apiUrl + `/contacts/contact/${contactId}`, this.getRequestConfig());
    const data = this.getDataFromResponse(contactsResp);

    return data;
  }

  async getAllContacts() {
    const contactsResp = await axios.get(this.apiUrl + '/contacts/all', this.getRequestConfig());
    const contactsData = this.getDataFromResponse(contactsResp);
    return contactsData;
  }

  //Messages
  async setNotificationsSubscription(subscribed: boolean) {
    const subscriptionResp = await axios.put(
      this.apiUrl + '/messaging/subscription',
      {subscribed},
      this.getRequestConfig()
    );
    const data = this.getDataFromResponse(subscriptionResp);
    return data;
  }

  async getMessages() {
    const messages = await axios.get(this.apiUrl + '/messaging', this.getRequestConfig());
    const data = this.getDataFromResponse(messages);
    return data;
  }

  async getPersonalMessages() {
    const messages = await axios.get(this.apiUrl + '/messaging/shop', this.getRequestConfig());
    const data = this.getDataFromResponse(messages);
    return data;
  }

  async getUnreadMessagesCount() {
    const res = await axios.get(this.apiUrl + '/messaging/info', this.getRequestConfig());
    const data = this.getDataFromResponse(res);
    return data;
  }

  async dismissMessages(maxId: number) {
    const bool = await axios.post(this.apiUrl + '/messaging/dismiss', {id: maxId}, this.getRequestConfig());
    const data = this.getDataFromResponse(bool);
    return data;
  }

  async readMessages(maxId: number) {
    const messages = await axios.post(this.apiUrl + '/messaging/read', {id: maxId}, this.getRequestConfig());
    const data = this.getDataFromResponse(messages);
    return data;
  }

  async readMessage(id: number) {
    const message = await axios.post(this.apiUrl + '/messaging/read?id=' + id, {}, this.getRequestConfig());
    const data = this.getDataFromResponse(message);
    return data;
  }

  async getListingMessages(id: number) {
    const message = await axios.get(this.apiUrl + '/messaging/listing/' + id, this.getRequestConfig());
    const data = this.getDataFromResponse(message);
    return data;
  }

  async getShopMessages() {
    const message = await axios.get(this.apiUrl + '/messaging/shop', this.getRequestConfig());
    const data = this.getDataFromResponse(message);
    return data;
  }

  async postMessageToKeychain(name: string, text: string, type: MESSAGE_TYPE, listingId?: number) {
    const message = await axios.post(
      this.apiUrl + '/messaging/message',
      {
        text,
        type,
        name,
        domain: KEYCHAIN_DOMAIN,
        listingId,
      },
      this.getRequestConfig()
    );
    const data = this.getDataFromResponse(message);
    return data;
  }

  // User Profile Apis
  async updateUser(userInfo: IUpdateableUserInfo) {
    const userResp = await axios.put(this.apiUrl + '/user', {...userInfo}, this.getRequestConfig());
    const data = this.getDataFromResponse(userResp);
    return data;
  }

  async submitFeedback(text: string) {
    const res = await axios.post(this.apiUrl + '/user/feedback', {text}, this.getRequestConfig());
    const data = this.getDataFromResponse(res);
    return data;
  }

  async getUserActivity(userid: number) {
    const resp = await axios.get(this.apiUrl + '/user/activity?userid=' + userid, this.getRequestConfig());
    const data = this.getDataFromResponse(resp);
    return data;
  }

  async getHub (hubname: string): Promise<IHub> {
    // I don't really like this way of making requests since hubname could have uri unsafe characters
    const resp = await axios.get(this.apiUrl + '/hubs/hub/' + hubname, this.getRequestConfig());
    const data = this.getDataFromResponse(resp);
    return data;
  }

  async getFeaturedHubs (): Promise<IHub[]> {
    // I don't really like this way of making requests since hubname could have uri unsafe characters
    const resp = await axios.get(this.apiUrl + '/hubs/featured', this.getRequestConfig());
    const data = this.getDataFromResponse(resp);
    return data;
  }

  async getHubTopListings (hubname: string): Promise <IListingThumb[]> {
    const resp = await axios.get(this.apiUrl + `/hubs/hub/${hubname}/top-listings`, this.getRequestConfig());
    const data = this.getDataFromResponse(resp);
    return data.listings;
  }

  async getHubTopShops (hubname: string): Promise <IShop[]> {
    const resp = await axios.get(this.apiUrl + `/hubs/hub/${hubname}/top-shops`, this.getRequestConfig());
    const data = this.getDataFromResponse(resp);
    return data.shops;
  }

  async getHubFeed (hubname: string): Promise <IActivity[]> {
    const resp = await axios.get(this.apiUrl + `/hubs/hub/${hubname}/feed`, this.getRequestConfig());
    const data = this.getDataFromResponse(resp);
    // Temporary fix to map data to the current IActivity type
    return data.activities.map(x => ({
      ...x,
      listing: {
        items: undefined,
        ...x.listing,
        ...x.listing.items[0]
      }
    }));
  }

  async searchHub(hubname: string, query: string, type: ESEARCH_TYPE) {
    const res = await axios.post(this.apiUrl + `/hubs/hub/${hubname}/search`, {query, type}, this.getRequestConfig());
    return this.getDataFromResponse(res);
  }

  async getHubFilters (hubname: string): Promise <Filter[]> {
    const res = await axios.get(this.apiUrl + `/hubs/hub/${hubname}/filters`, this.getRequestConfig());
    return this.getDataFromResponse(res).filters;
  }

  async getHubListings (hubname: string, query: ListingQuery): Promise <Pagination <IListingThumb[]> > {
    const resp = await axios.post(this.apiUrl + `/hubs/hub/${hubname}/listings`, query, this.getRequestConfig());
    const data = this.getDataFromResponse(resp);
    return data;
  }

  // async toggleFavorite (mint: PublicKey, turnOn: boolean): Promise<AddFavoriteRes> {
  //     return axios.post(
  //       `${this.apiUrl}api/v1/nft/action`,
  //       { mint, action: turnOn ? 'FAVORITE' : 'UNFAVORITE' },
  //       this.getRequestConfig()
  //     )
  // }
}

export const apiClient = new ServerClient(API_URL);
