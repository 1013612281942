import React, {FC, ReactElement, useState} from 'react';
import {connectedWalletAtom, ICollectible, ITokenBag, NftType} from 'store/connectedWallets';
import {debounce} from 'lodash';
import {useRecoilState, useRecoilValue} from 'recoil';
import {SearchInput} from 'components/ui/input/Input';
import './MyAssets.scss';
import {EAssetDetailsScreenType} from 'pages/AssetDetails/AssetDetails';
import {AssetCardMulti, NFT_CARD_HEIGHT_BASE, NFT_CARD_TICKER_HEIGHT, NftCard} from 'components/NftCard/NftCard';
import {ScreenContainer} from 'components/ui/container/Container';
import {useLocation, useNavigate} from 'react-router';
import {ENavStack, ERouteBazaar} from 'constants/routes';
import {COLORS} from 'constants/theme';
import {ensureIsString} from 'utils/string-formatting';
import useToasts from 'hooks/useToasts';
import {ENOTI_STATUS} from 'store/toasts';
import {EAssetSelectionType, useStorefrontActions} from 'store/yardsale';
import {userProfileAtom} from 'store/userProfile';
import ModalWrapper from 'components/Modals/ModalWrapper/ModalWrapper';
import {ColoredButton, ColoredButtonInverse} from 'components/ui/button/Button';
import {findSellerAccountPda} from 'programs/bazaar-utils';
import {keychainAtom} from 'store/keychain';
import {PublicKey} from '@solana/web3.js';
import {NATIVE_MINT} from '@solana/spl-token';
import Loader from 'components/Loader/Loader';
import {xNFTAtom} from 'store/xnft';
import { narrow } from 'apis/solana/util';

// todo: deprecate this since we now have a qty field in the collectible
export type ItemWithQuantity = {
  item: ICollectible | ITokenBag;
  quantity: number;
};

export const MyAssets: FC<any> = (): ReactElement => {
  let {
    state: {screenType},
  } = useLocation();

  const navigate = useNavigate();
  const storefrontActions = useStorefrontActions();
  const {createToast} = useToasts();

  const connectedWallet = useRecoilValue(connectedWalletAtom);
  const keychain = useRecoilValue(keychainAtom);
  const [userProfile, setUserProfile] = useRecoilState(userProfileAtom);
  const [searchInput, setSearchInput] = useState('');
  const [assets, setAssets] = React.useState<(ICollectible | ItemWithQuantity)[]>([]);
  const [filteredAssets, setFilteredAssets] = React.useState<(ICollectible | ItemWithQuantity)[]>([]);
  const [selectedItems, setSelectedItems] = React.useState<ItemWithQuantity[]>([] as ItemWithQuantity[]);
  const [initialLoad, toggleInitialLoad] = React.useState(true);
  const [transition, setTransition] = useState(false);
  const [isOpen, toggleModal] = React.useState(false);
  const [isXNFT, setIsXNFT] = useRecoilState(xNFTAtom);

  console.log(assets);

  // console.log(transition, filteredAssets);

  const handleSearchMethod = React.useRef(
    debounce(
      (str: string, arr: (ICollectible | ItemWithQuantity)[]) => {
        if (str.length) {
          const filtered = arr.filter((asset: ICollectible | ItemWithQuantity) => {
            if (asset.hasOwnProperty('mint') && narrow<ICollectible>(asset)) {
              return (
                asset.name?.toLowerCase().includes(str.toLowerCase()) ||
                (asset.collection && asset.collection?.name.toLowerCase().includes(str.toLowerCase()))
              );
            } 
            else if (narrow<ItemWithQuantity>(asset)) {
              let bit = false;
              if (asset.item.hasOwnProperty("symbol") && narrow <ITokenBag> (asset.item)) {
                bit = bit || asset.item?.symbol.toLowerCase().includes(str.toLowerCase())
              }
              else if (narrow<ICollectible>(asset.item)) {
                bit = bit || asset.item?.name.toLowerCase().includes(str.toLowerCase())
                bit = bit || asset.item?.collection && asset.item.collection.name.toLowerCase().includes(str.toLowerCase())
              }
              return bit;
            } 
          });
          setFilteredAssets(filtered);
        } else {
          setFilteredAssets(arr);
        }
      },
      500,
      {trailing: true, leading: false}
    )
  );

  // Set the base array that will be used
  React.useEffect(() => {
    console.log(connectedWallet.collectibles);
    switch (screenType) {
      case EAssetSelectionType.INDIVIDUAL: {
        setAssets(connectedWallet.collectibles);
        setFilteredAssets(connectedWallet.collectibles);
        break;
      }
      case EAssetSelectionType.BUNDLE: {
        // if it's a bundle, we collect the tokens and collectibles

        // filter out native mint and create the ItemWithQuantity array
        const tokens: ItemWithQuantity[] = connectedWallet.tokens.filter(tokenBag => !tokenBag.mint.equals(NATIVE_MINT))
          .map((tokenBag: ITokenBag) => {
            return {
              item: tokenBag,
              quantity: tokenBag.amountUi,
            };
          });
        const collectibles: ItemWithQuantity[] = filterByTypes(connectedWallet.collectibles, [NftType.Semi, NftType.Standard])
            .map((collectible: ICollectible) => {
              return {
                item: {
                  ...collectible,
                  // collection: {
                  //   name: "Test collection",
                  //   symbol: "test symbol",
                  //   mint: "test mint"
                  // }
                },
                quantity: collectible.qty,
              };
            });
        console.log(":::: setting  collectibles for bundle creation: ", collectibles);
        console.log(":::: setting  tokens for bundle creation: ", tokens);

        const arr: ItemWithQuantity[] = [ ...collectibles, ...tokens ];
        setAssets(arr);
        setFilteredAssets(arr);
        break;
      }
      case EAssetSelectionType.UNIT: {
        // for unit: no tokens
        const collectibles: ItemWithQuantity[] = filterByTypes(connectedWallet.collectibles, [NftType.Semi, NftType.Standard])
            .map((collectible: ICollectible) => {
              return {
                item: collectible,
                quantity: collectible.qty,
              };
            });
        const arr: ItemWithQuantity[] = [ ...collectibles];
        setAssets(arr);
        setFilteredAssets(arr);
        break;
      }
    }
    setTransition(false);
    toggleInitialLoad(false);
  }, [screenType]);

  React.useEffect(() => {
    if (!initialLoad) {
      handleSearchMethod?.current(searchInput, assets);
    }
  }, [searchInput]);

  // given an array of collectibles, filter out and return a new array of just the given types
  const filterByTypes = (collectibles: ICollectible[], nftTypes: NftType[]) => {
    return collectibles.filter((item: ICollectible) => {
      return nftTypes.includes(item.nftType);
    });
  }

  const createSeller = async () => {
    storefrontActions
      .createSellerAccount()
      .then((res: true | 'Transaction not completed') => {
        if (res === true) {
          let [sellerAccountPda] = findSellerAccountPda(new PublicKey(keychain.pda));
          setUserProfile((prev) => ({
            ...prev,
            profileInfo: {
              ...prev.profileInfo,
              sellerAccountPda: sellerAccountPda.toBase58()
            },
          }));
          navigate(ERouteBazaar.MULTILISTING);
        } else {
          createToast('Error creating seller account', ENOTI_STATUS.ERR);
        }
      })
      .catch(() => createToast('Error creating seller account', ENOTI_STATUS.ERR));
  };

  const handleMenuPress = () => {
    if (screenType === EAssetSelectionType.INDIVIDUAL) {
      if (!userProfile.profileInfo?.sellerAccountPda) {
        toggleModal(true);
        return;
      }
      // navigate(ERouteBazaar.MULTILISTING);
      setTransition(true)
      navigate('/' + ENavStack.BAZAAR + '/' + ERouteBazaar.MYSHOP + '/' + ERouteBazaar.MY_ASSETS, {state: {screenType: EAssetSelectionType.BUNDLE}});
    } else {
      if (selectedItems.length === 0) {
        createToast("You need to add at least 1 item to the bundle to create a listing", ENOTI_STATUS.ERR);
        return;
      }
      navigate('/' + ENavStack.BAZAAR + '/' + ERouteBazaar.MYSHOP + '/' + ERouteBazaar.LISTING, {
        state: {
          assetSelectionType: screenType,
          collectibleMints: JSON.stringify(
            selectedItems.map((item: ItemWithQuantity) => ensureIsString(item.item.mint))
          ),
          //@ts-ignore
          tokenTypes: JSON.stringify(selectedItems.map((item: ItemWithQuantity) => item.item.nftType ?? 'SPL')),
          quantities: JSON.stringify(selectedItems.map((item) => item.quantity)),
          names: JSON.stringify(selectedItems.map((item: ItemWithQuantity) => item.item.name)),
          //@ts-ignore
          decimals: JSON.stringify(selectedItems.map((item: ItemWithQuantity) => item.item.decimals ?? 1)),
          focusedWalletAddress: connectedWallet.address.toBase58(),
        },
      });
    }
  };

  const updateSelectedItems = (quantity: number, item: ICollectible | ITokenBag) => {
    const i = selectedItems.findIndex((selected) => {
      let selectedStr: string = ensureIsString(selected.item.mint);
      let itemStr: string = ensureIsString(item.mint);
      return selectedStr === itemStr;
    });
    // Add unique item to selected list (with specified quantity)
    if (i === -1 && quantity !== 0) {
      setSelectedItems((prev) => [...prev, {item, quantity}]);
    } else if (i !== -1) {
      // Remove item from selected list
      if (quantity === 0) {
        setSelectedItems((prev) => {
          const copy = [...prev];
          copy.splice(i);
          return copy;
        });
      } else {
        // Update the quantity of an item already in selected list
        setSelectedItems((prev) => {
          const copy = [...prev];
          copy[i] = {item, quantity};
          return copy;
        });
      }
    }
  };

  console.log(filteredAssets);

  if (transition) return <Loader/>

  return (
    <ScreenContainer
      hasBackButton={isXNFT}
      menu={
        <div onClick={handleMenuPress} style={{cursor: 'pointer'}}>
          <p style={{color: COLORS.ACCENT_PRIMARY}}>
            {screenType === EAssetSelectionType.INDIVIDUAL ? 'Create Bundle' : 'Next'}
          </p>
        </div>
      }
    >
      <ModalWrapper isOpen={isOpen} onClose={() => toggleModal(false)}>
        <div className="seller-modal">
          <p className="subheader mb-xxxl">
            To create bundles, you'll need to upgrade your Stache account first.
          </p>
          <ColoredButton onClick={createSeller} className="mb-lg" style={{width: '100%'}}>
            OK, continue
          </ColoredButton>
          <ColoredButtonInverse onClick={() => toggleModal(false)} style={{width: '100%'}}>
            No, cancel
          </ColoredButtonInverse>
        </div>
      </ModalWrapper>
      <div className="mynfts-page">
        <p className="banner">My Assets</p>
        <SearchInput placeholder={'Search for asset...'} value={searchInput} onChangeText={setSearchInput} />
        {[connectedWallet.assetsLoaded] && (
          <div
            className="grid"
            style={{
              marginTop: 0,
              gridTemplateRows:
                screenType === EAssetSelectionType.INDIVIDUAL
                  ? NFT_CARD_HEIGHT_BASE
                  : NFT_CARD_HEIGHT_BASE + NFT_CARD_TICKER_HEIGHT,
            }}
          >
            {screenType === EAssetSelectionType.INDIVIDUAL
              ? filteredAssets.map((x: ICollectible, idx: number) => {
                  return (
                    <div className="grid-item" key={idx}>
                      <NftCard
                        item={x}
                        key={idx}
                        link={`/home/assetsdetails`}
                        state={{
                          collectible: x,
                          walletAddress: connectedWallet?.address?.toBase58(),
                          collectibleMint: x.mint,
                          screenType: EAssetDetailsScreenType.LISTING,
                        }}
                      />
                    </div>
                  );
                })
              : filteredAssets.map((x: ItemWithQuantity, idx: number) => {
                  return (
                    <div className="grid-item" key={idx}>
                      <AssetCardMulti
                        item={x.item}
                        key={idx}
                        onClick={updateSelectedItems}
                        totalQuantity={x.quantity}
                        selectedItems={selectedItems}
                        screenType={screenType}
                      />
                    </div>
                  );
                })}
          </div>
        )}
      </div>
    </ScreenContainer>
  );
};

export default MyAssets;
