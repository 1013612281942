import { toastsAtom, IToasts, ENOTI_STATUS } from "store/toasts";
import { useRecoilState } from 'recoil';


export const useToasts = () => {

  const [toasts, setToasts] = useRecoilState<IToasts>(toastsAtom);

  // default timeout = 5 seconds
  const createToast = (text: string, type: ENOTI_STATUS, timeout: number = 5000) => {
    const newArray = [
      ...toasts.toasts,
      { id: (toasts.toasts.length + 1).toString(), text, type, timeout }
    ];
    setToasts({ toasts: newArray });
  }

  return {
    createToast
  };
};


export default useToasts;
