//Web3
import {PublicKey, Transaction} from "@solana/web3.js";

//Recoil
import {useRecoilState, useRecoilValue} from "recoil";
import {IStache, stacheAtom} from "../stache";
import {EWalletType, linkedWalletsListSelector} from "store/connectedWallets";
import {findVaultPda} from "../../programs/stache-utils";
import {DOMAINPDA} from "../../programs/keychain-utils";
import {vaultsAtom} from "./state";

//Clients
//Constants
import {EVaultType, IVaultHistoricalTx, IVaultPendingTx, IVaultWallet} from "./types";
import {KEYCHAIN_DOMAIN, StacheProgramId} from "../../constants/config";
import {SVault, SVaultType} from "../../apis/solana/types";

//Util
import {useWalletAdapter} from "../../hooks/useWalletAdapter";
import {getSolTokenBag} from "../store-utils";
import {blacklistAtom, ogCollectionsAtom} from "store/system";
import {STokenAccount} from "../../apis/apiTypes";
import {rpcClient, solanaClient} from "../../constants/factory";


function useVaultActions() {

  const stache = useRecoilValue(stacheAtom);
  const {signAndSendTransaction} = useWalletAdapter();
  const blacklist = useRecoilValue(blacklistAtom);
  const ogCollections = useRecoilValue(ogCollectionsAtom);
  const linkedWallets = useRecoilValue(linkedWalletsListSelector);
  const [vaults, setVaults] = useRecoilState<IVaultWallet[]>(vaultsAtom);

  function getSVaultType(vaultType: EVaultType): SVaultType {
    switch (vaultType) {
      case EVaultType.EASY:
        return SVaultType.EASY;
      case EVaultType.TWOSIG:
        return SVaultType.TWOSIG;
      case EVaultType.MULTISIG:
        return SVaultType.MULTISIG;
    }
  }

  function getEVaultType(vaultType: SVaultType): EVaultType {
    switch (vaultType) {
      case SVaultType.EASY:
        return EVaultType.EASY;
      case SVaultType.TWOSIG:
        return EVaultType.TWOSIG;
      case SVaultType.MULTISIG:
        return EVaultType.MULTISIG;
    }
  }

  // load up all the vaults
  async function loadVaults(stacheState: IStache) {
    const data = stacheState ?? stache;
    if (data) {
      console.log("Stache exists.....", data);
      const fetchedVaults: IVaultWallet[] = await Promise.all(data.vaults.map(async vaultIndex => {
        const [vaultPda] = findVaultPda(vaultIndex, data.stacheid, KEYCHAIN_DOMAIN, StacheProgramId);
        return getVault(vaultPda);
      }));
      // console.log(`setting loaded vaults: ${JSON.stringify(fetchedVaults, null, 3)}`);
      setVaults(fetchedVaults);
    }
  }

  async function createVault(name: string, vaultType: EVaultType): Promise<IVaultWallet> {

    console.log(`Creating vault ${name} with type ${vaultType} and index ${stache.nextVaultIndex}. stacheid: ${stache.stacheid}, domainpda: ${DOMAINPDA.toString()}, stacheprogid: ${StacheProgramId.toString()}...`);
    const [vaultPda] = findVaultPda(stache.nextVaultIndex, stache.stacheid, KEYCHAIN_DOMAIN, StacheProgramId);
    const sVaultType = getSVaultType(vaultType);

    // create multisig squads vault first
    const params = {};

    if (vaultType === EVaultType.MULTISIG) {
      const linkedWalletAddresses = linkedWallets.map((wallet) => wallet.address);
      params['members'] = [vaultPda, ...linkedWalletAddresses];
      params['threshold'] = 2;
    }

    console.log(`Creating vault ${name} with type ${vaultType} and params: ${JSON.stringify(params, null, 3)}`);

    const tx = await solanaClient.getCreateVaultTx(name, sVaultType, vaultPda, params);
    console.log(`Create Vault tx: ${tx}`);

    const txid = await signAndSendTransaction(tx);
    console.log(`Create Vault txid: ${txid}`);
    await rpcClient.confirmTransaction(txid);

    const ivault = await getVault(vaultPda);
    setVaults([...vaults, ivault]);
    return ivault;
  }

  async function destroyVault(vaultIndex: number): Promise<string> {
    const [vaultPda] = findVaultPda(vaultIndex, stache.stacheid, KEYCHAIN_DOMAIN, StacheProgramId);
    const ix = await solanaClient.getDestroyVaultIx(vaultPda);
    const tx = new Transaction().add(ix);
    const txid = await signAndSendTransaction(tx);
    await rpcClient.confirmTransaction(txid);
    console.log(`destroyed vault ${vaultIndex} with txid: ${txid}`);
    setVaults(vaults.filter(vault => vault.index !== vaultIndex));
    return txid;
  }

  async function getVault(vaultPda: PublicKey): Promise<IVaultWallet> {
    const vault: SVault = await solanaClient.getVault(vaultPda);

    // load up the contents
    const tokenAccounts: STokenAccount[] = await rpcClient.getTokenAccounts(vaultPda);

    const assets = await rpcClient.fetchAssetMetaData(tokenAccounts, blacklist, ogCollections);
    const solBag = await getSolTokenBag(vaultPda);

    // make SOL the first tokenbag
    assets.tokens.unshift(solBag);

    const resp = {
      ...vault,
      assetsLoaded: true,
      label: vault.label ?? vault.name,
      walletType: EWalletType.VAULT,
      address: vaultPda,
      type: getEVaultType(vault.vaultType),
      collectibles: assets.collectibles,
      tokens: assets.tokens,
      pendingTxs: [] as IVaultPendingTx[], // TODO populate
      txHistory: [] as IVaultHistoricalTx[] // TODO populate
    };
    return resp;
  }

  return {
    createVault,
    loadVaults,
    destroyVault
  }
}

export {useVaultActions}
