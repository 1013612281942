import {atom, selector} from 'recoil';
import { IContact, IAddressBook } from "./types";


export const addressBookAtom = atom<IAddressBook>({
    key: 'addressBook',
    default: {
        contacts: [] as IContact[]
    }
})

export const contactsStacheIds = selector({
    key: 'contactsStacheIds',
    get: ({get}) => {
      const contacts = get(addressBookAtom);
      return contacts.contacts.map(contact => contact.stacheid);
    }
  })
