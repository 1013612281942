import React, {useState} from 'react';

//Util
import useToasts from '../../hooks/useToasts';

//SVGs
import {ReactComponent as ChevronIcon} from 'assets/icons/ticks/Chevron.svg';
import {ReactComponent as GearIcon} from 'assets/icons/Gear.svg';
import {ReactComponent as QuestionIcon} from 'assets/icons/QuestionIcon.svg';
import {ReactComponent as ChatIcon} from 'assets/icons/ChatText.svg';
import {ReactComponent as SignOutIcon} from 'assets/icons/SignOutIcon.svg';

//Recoil
import {useRecoilValue, useResetRecoilState} from 'recoil';
import {stacheAtom} from '../../store/stache';
import {keychainAtom, keychainStateAtom} from 'store/keychain';
import {userProfileAtom} from 'store/userProfile';
import {ENOTI_STATUS} from '../../store/toasts';

//Constants
import {ERoute, ERouteHome, ERouteSettings, EUnauthedRoute} from 'constants/routes';
import {COLORS, SPACING} from 'constants/theme';
import {Avatar} from 'components/Avatar/Avatar';
import useAuth from 'hooks/useAuth';
import {Link, useNavigate} from 'react-router-dom';

import './Settings.scss';
import ModalWrapper from 'components/Modals/ModalWrapper/ModalWrapper';
import {ColoredButton, ColoredButtonInverse} from 'components/ui/button/Button';
import {ScreenContainer} from 'components/ui/container/Container';

export const Settings: React.FC<any> = (props: any) => {
  const {createToast} = useToasts();
  const auth = useAuth();
  const stache = useRecoilValue(stacheAtom);
  const keychain = useRecoilValue(keychainAtom);
  const keychainState = useRecoilValue(keychainStateAtom);
  const userProfile = useRecoilValue(userProfileAtom);
  const resetUserProfile = useResetRecoilState(userProfileAtom);
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();

  // This doesnt actually call auth.logout(), and instead forces a switch to the UnAuthed Navigator
  // which will navigate to the ConnectWallet screen, which calls auth.logout()
  const handleLogOut = async () => {
    resetUserProfile();
    auth.logout(); // Why shouldn't it call auth.logout()?
    navigate('/');
  };

  const handlePfpPress = () => {
    createToast('To set your pfp: Open an NFT in Home > Collectibles and set from the menu.', ENOTI_STATUS.DEFAULT);
  };

  return (
    <ScreenContainer className="settings-page">
      <div className="avatar-con">
        <Avatar iconUrl={userProfile.profileInfo.avatarUrl} username={userProfile.profileInfo.stacheid} size={125} />
      </div>
      <p className="header">@{stache?.stacheid ?? keychain.name}</p>
      <Link to={ERouteSettings.MANAGE_WALLETS}>
        <div className="settings-card">
          <div>
            <GearIcon />
            <p className="subheader">Wallet Management</p>
          </div>
          <ChevronIcon />
        </div>
      </Link>
      <Link to={ERouteSettings.FAQ}>
        <div className="settings-card">
          <div>
            <QuestionIcon />
            <p className="subheader">Help & FAQ</p>
          </div>
          <ChevronIcon />
        </div>
      </Link>
      <Link to={ERouteSettings.FEEDBACK}>
        <div className="settings-card">
          <div>
            <ChatIcon stroke="red" strokeWidth={1} />
            <p className="subheader">Give us feedback</p>
          </div>
          <ChevronIcon />
        </div>
      </Link>
      <Link to="" onClick={() => setModal(true)}>
        <div className="settings-card">
          <div>
            <SignOutIcon />
            <p className="subheader">Logout</p>
          </div>
          <ChevronIcon />
        </div>
      </Link>

      <ModalWrapper isOpen={modal} onClose={() => setModal(false)}>
        <div className="logout-modal">
          <p className="header">Logout</p>
          <p className="subheader mb-xxxl">Are you sure you want to log out of your account?</p>
          <ColoredButton className="mb-lg" onClick={handleLogOut}>Yes, continue</ColoredButton>
          <ColoredButtonInverse onClick={() => setModal(false)}>No, cancel</ColoredButtonInverse>
        </div>
      </ModalWrapper>
    </ScreenContainer>
  );
};
