import React, {BaseSyntheticEvent} from 'react';
import {EAssetSelectionType, IListing} from 'store/yardsale';
import {ICollectible, ITokenBag} from 'store/connectedWallets';
import {ReactComponent as CheckCircle} from 'assets/icons/ticks/CheckCircle.svg';
import {ensureIsString, truncateStringWithLastWord} from '../../utils/string-formatting';

import './NftCard.scss';
import {Link} from 'react-router-dom';
import {COLORS, SPACING} from 'constants/theme';
import {ENOTI_STATUS} from 'store/toasts';
import useToasts from 'hooks/useToasts';
import {ItemWithQuantity} from 'pages/MyAssets/MyAssets';
import Quantity from 'components/ui/quantity/Quantity';

interface INftCardProps {
  item: ICollectible;
  link?: string;
  state?: any;
}

export const NFT_CARD_WIDTH = 160;
export const NFT_CARD_HEIGHT_BASE = 230;
export const NFT_CARD_TICKER_HEIGHT = 22 + SPACING.MD * 2;

export const NftCard: React.FC<INftCardProps> = ({state, item, link}) => {

  return (
    <Link to={link} state={state}>
      <div className="nft-card" style={{height: NFT_CARD_HEIGHT_BASE}}>
        <div className="nft-content">
          <div className="nft-image" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            {item.qty &&
              <div className="circle">
                <p className="normal">{item.qty}</p>
              </div>
            }
            <img src={item.imageUrl} className="nft-image" />
          </div>
          <div className="info">
            {!!item.collection && <CheckCircle height={15} width={15} />}
            <p className="normal"> {!!item.name && truncateStringWithLastWord(item.name, 22)}</p>
            {!!item?.collection?.name ? (
              <p className="medium accent">{item.collection.name}</p>
            ) : (
              <p className="medium gr">No collection</p>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};

interface INftCardMultiProps {
  item: ICollectible | ITokenBag;
  onClick: (num: number, item: ICollectible | ITokenBag) => void;
  totalQuantity?: number;
  screenType: EAssetSelectionType.BUNDLE | EAssetSelectionType.UNIT;
  selectedItems: ItemWithQuantity[];
}

export const AssetCardMulti: React.FC<INftCardMultiProps> = ({
  item,
  onClick,
  totalQuantity,
  screenType,
  selectedItems,
}: INftCardMultiProps) => {
  const {createToast} = useToasts();
  const [quantity, setQuantity] = React.useState(0);

  const formattedTotalQuantity = React.useMemo(() => {
    const sbquantity = totalQuantity - quantity;
    const lookup = [
      {value: 1, symbol: ''},
      {value: 1e3, symbol: 'k'},
      {value: 1e6, symbol: 'M'},
      {value: 1e9, symbol: 'B'},
      {value: 1e12, symbol: 'T'},
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var _item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return sbquantity >= item.value;
      });
    return _item ? (sbquantity / _item.value).toFixed(4).replace(rx, '$1') + _item.symbol : '0';
  }, [totalQuantity, quantity]);

  const validityCheck = () => {
    const index = selectedItems.findIndex(
      (selectedItem) => ensureIsString(selectedItem.item.mint) === ensureIsString(item.mint)
    );
    // Success, item is already included
    if (index !== -1) {
      return true;
    }
    // Only 1 SFT mint allowed in Unit type
    if (screenType === EAssetSelectionType.UNIT && selectedItems.length > 0) {
      createToast('Only 1 item type allowed for unit listings.', ENOTI_STATUS.ERR);
      return false;
      // Maximum 5 SFT mints allowed in Bag type
    } else if (screenType === EAssetSelectionType.BUNDLE && selectedItems.length > 4) {
      createToast('Maximum of 5 item types allowed for bundles.', ENOTI_STATUS.ERR);
      return false;
      // Success
    } else {
      return true;
    }
  };

  console.log(item);


  return (
    <div className="nft-card" style={{height: NFT_CARD_HEIGHT_BASE + NFT_CARD_TICKER_HEIGHT}}>
      <div className="nft-content">
        <div className="nft-image" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <img src={item.imageUrl} className={`${item.hasOwnProperty('symbol') ? 'token-img' : 'nft-image'}`} />
        </div>
        <Quantity
          maxQuantity={totalQuantity}
          onClick={onClick}
          validityCheck={validityCheck}
          item={item}
          quantity={quantity}
          setQuantity={setQuantity}
        />
        <div className="info">
          {!!item.collection && <CheckCircle height={15} width={15} />}
          <p className="normal"> {!!item.name && truncateStringWithLastWord(item.name, 22)}</p>
          {!!item?.collection?.name ? (
            <p className="medium accent">{item.collection.name}</p>
          ) : (
            <p className="medium gr">No collection</p>
          )}
        </div>
      </div>
      <div className="check-con" style={{right: SPACING.SM}}>
        <div className="circle" style={{backgroundColor: quantity > 0 ? COLORS.ACCENT_ORANGE_DEEP : 'transparent'}}>
          {quantity > 0 && <p className="normal">{quantity}</p>}
        </div>
      </div>
      <div className="check-con" style={{left: SPACING.SM}}>
        <div className="circle" style={{backgroundColor: COLORS.ACCENT_ORANGE_DEEP}}>
          <p className="normal">{formattedTotalQuantity}</p>
        </div>
      </div>
    </div>
  );
};
