import {atom} from "recoil";
import {INotificationState, IPersonalMessagesState} from "./types";


export const notificationsAtom = atom<INotificationState>({
  key: 'notifications',
  default: {
    numUnread: 0,
    subscribed: false,
    notifications: []
  }
})

export const personalMessagesAtom = atom<IPersonalMessagesState>({
  key: "personal-messages",
  default: {
    numUnread: 0,
    messages: []
  }
})
