import { Avatar } from 'components/Avatar/Avatar';
import { BRADIUS, COLORS, SPACING } from 'constants/theme';
import React from 'react';
import { IMessage } from 'store/notification';

import "../SaleConversation.scss";

interface ThreadProps {
    comment: IMessage, // in future will be changed to ICommentThread. Currently there are no nested replies
    // showThread: () => void,
    isFirstReply?: boolean,
    conStyle?: any
}

export const Comment = React.memo(({ comment, conStyle }: ThreadProps) => {

    const { createdAt, message, from } = comment;
  
    return (
        <div style={{ width: '100%', marginBottom: SPACING.MD, ...conStyle }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Avatar
                iconUrl={from?.pfpUrl}
                username={from?.name}
                size={40}
              />
              <div className= "messageCon">
                <p className= "normal">{message}</p>
              </div>
            </div>
            <div style={{display: "flex", flexDirection: 'row', justifyContent: 'space-between'}}>
              <div style={{display: "flex", flexDirection: 'row', alignItems: "center"}}>
                <p style={{ marginRight: SPACING.XL, color: COLORS.TEXT_WHITE }}>{from?.name}</p>
                <p style={{ color: COLORS.GRAY }}>{new Date(createdAt).toLocaleDateString()}</p>
              </div>
              {/* {!!isFirstReply &&
                 <TouchableOpacity onPress={showThread}>
                    <ChatText/>
                 </TouchableOpacity>
              } */}
            </div>
          {/* {replies.length &&
             <TouchableOpacity onPress={showThread}>
                <HintText style={{color: COLORS.ACCENT_ORANGE_DEEP}}>
                  {`${replies.length} ${replies.length === 1 ? 'response' : 'responses'}`}
                </HintText>
             </TouchableOpacity>
          } */}
        </div>
    )
  });