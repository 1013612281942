import React, {useState} from 'react';
import {ReactComponent as InfoIcon} from 'assets/icons/InfoIcon.svg';
import useToasts from 'hooks/useToasts';
import useAuth from 'hooks/useAuth';
import {formatAddress} from 'utils/string-formatting';
import {PublicKey} from '@solana/web3.js';
import {
  IKeychain,
  KeyChainActionType,
  keychainAtom,
  keychainStateAtom,
  keysByVotingStatus,
  useKeychainActions,
} from 'store/keychain';
import {connectedWalletAtom} from 'store/connectedWallets';
import {useRecoilState, useRecoilValue} from 'recoil';
import {stacheAtom, useStacheActions} from 'store/stache';
import {BRADIUS, COLORS, SPACING, VIEWPORT} from 'constants/theme';
import {ENOTI_STATUS} from 'store/toasts';
import {ERouteSettings} from 'constants/routes';
import {ReactComponent as SuccessCheckCircle} from 'assets/icons/ticks/SuccessCheckCircle.svg';
import ScreenHeader from 'components/Header/Screen/ScreenHeader';
import {TextCopy} from 'components/TextCopy/TextCopy';
import {ColoredButton, ColoredButtonInverse} from 'components/ui/button/Button';
import './ManagePending.scss';
import {useNavigate} from 'react-router';
import ModalWrapper, {ModalWrapperAdjustable} from 'components/Modals/ModalWrapper/ModalWrapper';
import {ScreenContainer} from 'components/ui/container/Container';

type Props = any;

export const ManagePending: React.FC<any> = (props: Props) => {
  const keychainActions = useKeychainActions();
  const {createToast} = useToasts();
  const stacheActions = useStacheActions();
  const auth = useAuth();

  const keychain = useRecoilValue(keychainAtom);
  const stache = useRecoilValue(stacheAtom);
  const [keychainState, setKeychainState] = useRecoilState(keychainStateAtom);
  const {pending_action} = keychainState;
  const votes = useRecoilValue(keysByVotingStatus);
  const connectedWallet = useRecoilValue(connectedWalletAtom);
  const [loading, toggleLoading] = React.useState(false);
  const [modal, setModal] = useState<boolean | string>(false);
  const navigate = useNavigate();

  const handleNavigateToRoute = () => {
    navigate('/settings/managewallets');
  };

  const handleToggleModal = (bool: boolean) => setModal(modal ? false : 'confirm');
  const handleToggleRejectModal = (bool: boolean) => setModal(modal ? false : 'reject');

  const hasVoted = React.useMemo(() => {
    let bool: boolean = false;
    votes.voted.every((vote) => {
      if (vote.toBase58() === connectedWallet.address.toBase58()) {
        bool = true;
        return false;
      }
      return true;
    });
    return bool;
  }, [connectedWallet?.address, votes.voted]);

  const handleConfirm = React.useCallback(
    async (isApproving: boolean) => {
      toggleLoading(true);
      const res: true | string = await keychainActions.votePendingAction(isApproving);
      if (res === true) {
        const states: {keychainState: IKeychain} = await stacheActions.checkForStache(connectedWallet.address);
        if (!!states.keychainState) {
          // Refresh all data, including the new key's data and keychainState
          await keychainActions.loadBasicData(states.keychainState); // todo is this getting updating in time????
          keychainActions.loadAssetData(states.keychainState);
        } else {
          // User removed their connected wallet from the stache account
          auth.logout();
        }
        createToast(isApproving ? `Approval submitted` : 'Wallet denied', ENOTI_STATUS.SUCCESS);

        handleNavigateToRoute();
      } else {
        createToast(res, ENOTI_STATUS.ERR);
      }
      toggleLoading(false);
      setModal(false);
    },
    [pending_action?.votes]
  );

  const isAdding = React.useMemo(() => pending_action?.action_type === KeyChainActionType.AddKey, [pending_action]);
  const isFirstLinking = React.useMemo(() => keychain.keys.length === 1, []);

  const approvalsRemaining = React.useMemo(() => {
    if (isFirstLinking) {
      return 0;
    } else {
      return keychainState.action_threshold - votes.voted.length;
    }
  }, [keychainState.action_threshold, votes.voted, isFirstLinking]);

  const label = React.useMemo(() => {
    if (pending_action?.action_type === KeyChainActionType.AddKey) {
      console.log('Approvals: ', approvalsRemaining);
      return hasVoted || approvalsRemaining === 0 ? 'Approved!' : 'Approve wallet';
    } else {
      return hasVoted || approvalsRemaining === 0 ? 'Approved' : 'Remove wallet';
    }
  }, [pending_action?.votes]);

  const modalText = React.useCallback(
    (isRejecting: boolean) => {
      let t = "Are you sure you'd like to ";

      if (pending_action?.action_type === KeyChainActionType.AddKey) {
        const text = isRejecting
          ? 'deny this wallet? This will cancel the entire process of linking this wallet to your Stache account.'
          : 'approve linking this wallet to your Stache account?';
        return t + text;
      } else {
        const text = isRejecting
          ? 'deny the removal of this wallet? This will cancel the entire process of removing this wallet from your Stache account.'
          : 'remove this wallet from your Stache account?';
        return t + text;
      }
    },
    [pending_action?.votes]
  );

  const infoText = React.useMemo(() => {
    return pending_action?.action_type === KeyChainActionType.AddKey
      ? 'link this wallet to your Stache account.'
      : 'remove this wallet from your Stache account.';
  }, [pending_action?.votes]);

  const addingAndUnverified = React.useMemo(() => {
    return !pending_action?.verified && pending_action?.action_type === KeyChainActionType.AddKey;
  }, [pending_action?.verified]);

  const showButton = React.useMemo(() => {
    return !(
      pending_action?.action_type === KeyChainActionType.AddKey &&
      connectedWallet.address.toBase58() === pending_action?.key.toBase58()
    );
  }, [keychain.keys, hasVoted, pending_action, connectedWallet]);

  return (
    <ScreenContainer hasBackButton title={`${isAdding ? 'Linking' : 'Removing'} Wallet`}>
      <ModalWrapper isOpen={modal === 'confirm'} onClose={() => setModal(false)}>
        <div className="linking-modal">
          <p className="subheader mb-xxxl">{modalText(false)}</p>
          <ColoredButton className="mb-lg" onClick={() => handleConfirm(true)}>Yes, continue</ColoredButton>
          <ColoredButtonInverse onClick={() => setModal(false)}>No, cancel</ColoredButtonInverse>
        </div>
      </ModalWrapper>
      <ModalWrapper isOpen={modal === 'reject'} onClose={() => setModal(true)}>
        <div className="linking-modal">
          <p className="subheader mb-xxxl">{modalText(true)}</p>
          <ColoredButton className="mb-lg" onClick={() => handleConfirm(false)}>Yes, continue</ColoredButton>
          <ColoredButtonInverse onClick={() => setModal(false)}>No, cancel</ColoredButtonInverse>
        </div>
      </ModalWrapper>
      <div className="manage-pending-page">
        <TextCopy text={pending_action?.key.toBase58()} />
        <div className="info-box">
          <InfoIcon />
          <p className="normal">
            {approvalsRemaining === 0
              ? `${
                  !isFirstLinking ? 'Approvals completed! ' : ''
                }Login with your pending wallet to complete the process.`
              : `${approvalsRemaining} more approval${approvalsRemaining > 1 ? 's' : ''} required to ${infoText}`}
          </p>
        </div>
        <div className="steps">
          <div className="item">
            <SuccessCheckCircle />
            <p className="normal">{`1. Add the wallet for ${isAdding ? 'approval.' : 'removal.'}`}</p>
          </div>
          {(!isAdding || (isAdding && !isFirstLinking)) && (
            <>
              <div className="sep" />
              <div className="item withbig">
                {votes.voted.length === keychainState.action_threshold ? (
                  <SuccessCheckCircle />
                ) : (
                  <div className="big-circle">
                    <div />
                  </div>
                )}
                <p className="normal">
                  {`2. Approve the ${
                    isAdding ? 'pending wallet' : 'removal'
                  } with other wallets linked to your Stache account.`}
                </p>
              </div>
            </>
          )}
          {isAdding && (
            <>
              <div className="sep" />
              <div className="item">
                {pending_action.verified ? (
                  <SuccessCheckCircle />
                ) : keychainState.action_threshold === votes.voted.length ? (
                  <div className="filled-circle">
                    <div />
                  </div>
                ) : (
                  <div className="empty-circle">
                    <div />
                  </div>
                )}
                <p className="normal">{`${isFirstLinking ? '2' : '3'}. Verify ownership.`}</p>
              </div>
            </>
          )}
        </div>
        {keychain.keys.length > 1 && (
          <div className="votes-container">
            <div>
              <p className="subheader">Wallets that have approved:</p>
              {votes.voted.map((address: PublicKey) => (
                <div key={address.toBase58()} style={{marginBottom: SPACING.MD}}>
                  <img src={require('assets/pngs/checked.png')} />
                  <p className="normal">{formatAddress(address.toBase58())}</p>
                </div>
              ))}
            </div>
            <div>
              <p className="subheader">Wallets that have not approved:</p>
              {votes.notVoted.map((address: PublicKey) => (
                <div key={address.toBase58()} style={{marginBottom: SPACING.MD}}>
                  <div>
                    <p className="subheader">?</p>
                  </div>
                  <p className="normal">{formatAddress(address.toBase58())}</p>
                </div>
              ))}
            </div>
          </div>
        )}
        <div className="actions">
          <ColoredButtonInverse disabled={loading} onClick={() => setModal('reject')}>
            {loading ? 'Please wait...' : 'Deny'}
          </ColoredButtonInverse>
          {!isFirstLinking &&
            (hasVoted || approvalsRemaining === 0 ? (
              <ColoredButton className="sn" disabled={true} onClick={() => {}}>
                {label}
              </ColoredButton>
            ) : (
              <ColoredButtonInverse className="sn" disabled={loading} onClick={() => setModal('confirm')}>
                {loading ? 'Please wait...' : label}
              </ColoredButtonInverse>
            ))}
        </div>
      </div>
    </ScreenContainer>
  );
};
