import {isArray} from 'lodash';
import React, {FC} from 'react';
import {ReactElement} from 'react-markdown';
import {ICollectible} from 'store/connectedWallets';
import {IAttribute, IListing, IListingItem} from 'store/yardsale';
import '../AssetDetails.scss';

interface ItemProps {
  item: IListing | ICollectible;
  idx: number,
}

type SectionProps = {
  attributes: IAttribute[];
  name: string;
  isMultiBag: boolean;
};

const Section: FC<any> = ({attributes, name, isMultiBag}: SectionProps): ReactElement => {
  return (
    <div className="attribute">
      {isMultiBag && (
        <div className="header-name" style={{width: '100%'}}>
          <h4>{name}</h4>
        </div>
      )}
      <div className="sales-attributes">
        {attributes && isArray(attributes)
          ? attributes?.map((attribute: IAttribute, i: number) => (
              <div key={i}>
                <p className="normal gr">{attribute.trait_type}</p>
                <p className="normal">{attribute.value}</p>
              </div>
            ))
          : !!attributes
          ? //@ts-ignore StarAtlas doesnt give af about standards
            Object.entries(attributes).map((entry: [string, string], i: number) => (
              <div key={i}>
                <p className="normal gr">{entry[0]}</p>
                <p className="normal">{entry[1]}</p>
              </div>
            ))
          : null}
      </div>
    </div>
  );
};

const isIListing = (obj: IListing | ICollectible): obj is IListing => {
  return !!Object.hasOwn(obj, "items");
}

export const SaleAttributes: FC<any> = ({item, idx}: ItemProps): ReactElement => {
  // IListing
  if (isIListing(item)) {
    const listing = item.items[idx];
    const _isArray = isArray(listing.attributes);
    const hasAttributes = _isArray ? !!listing.attributes.length : !!listing.attributes;

    return (
      <Section key={idx} name={listing.name} attributes={listing.attributes} isMultiBag={item.numItems > 1} />
    );
  }
  else {
    return <Section name={item.name} attributes={item.attributes} isMultiBag={false} />;
  }
};

export default SaleAttributes;
