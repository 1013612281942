import {PublicKey} from '@solana/web3.js';
import {IAttribute} from 'store/yardsale';
import {ReadApiCompressionMetadata} from '@metaplex-foundation/js';

//// // these are the states/interfaces used when dealing with home screen functions
// "actionable" wallets: stache, connected, vault
export interface IStacheWallet extends IWallet {
  nextVaultIndex: number;
  vaults: number[];
}

export interface IConnectedWallet extends IWallet {
  walletLinked: boolean;
  checkedForStache: boolean;
}

// view-only wallets
export interface ILinkedWallet extends IWallet {}

export enum EWalletType {
  STACHE = 'STACHE',
  CONNECTED = 'CONNECTED',
  LINKED = 'LINKED',
  VAULT = 'VAULT',
}

// if the tokenbag == sol, then no mint, and no tokenAccount
export interface ITokenBag {
  amount: number;
  amountUi: number;
  amountUiString: string;
  decimals: number;
  name: string;
  symbol: string;
  ruleset?: PublicKey;
  tokenAccount?: PublicKey;
  imageUrl?: string;
  mint?: PublicKey;
}

export interface IWallet {
  label: string;
  walletType: EWalletType;
  address: PublicKey;
  collectibles: ICollectible[];
  tokens: ITokenBag[];
  assetsLoaded: boolean;
}

export interface ITokenBalance {
  // iconUrl, name & abbrev type not stated
  iconUrl: any;
  name: any;
  abbrev: any;
  amount: number;
  value: number;
}

export interface ICollectible {
  mint: string; // for compressed nfts, this is the asset id
  name: string;
  imageUrl: string;
  verified: boolean; // haven't exactly decided what this should be used for
  nftType: NftType;
  // only when it's a compressed nft - thought this was going to be used but it's currently not. but just leaving it in for now (maybe remove later)
  compression?: ReadApiCompressionMetadata;
  ruleset?: PublicKey;
  tokenAccount?: string; // nullable cause compressed nfts don't have these
  collection?: ICollection;
  attributes?: IAttribute[];
  desc?: string;
  qty: number;
}

export interface ICollection {
  name: string;
  symbol?: string;
  mint?: string;
}

// same as backend, don't change
export enum NftType {
  Standard = 'Standard',
  Semi = 'Semi',
  Programmable = 'Programmable',
  Compressed = 'Compressed',
}

export namespace NftType {
  export function fromString(nftType: string): NftType {
    switch (nftType) {
      case 'Standard':
        return NftType.Standard;
      case 'Semi':
        return NftType.Semi;
      case 'Programmable':
        return NftType.Programmable;
      case 'Compressed':
        return NftType.Compressed;
      default:
        throw new Error('Invalid NFT type: ' + nftType);
    }
  }
}

