import React, { useState } from 'react'
import { Link, useNavigate, useNavigation } from 'react-router-dom';
import {WalletMultiButton} from '@solana/wallet-adapter-react-ui';
import {ReactComponent as Hamburger} from "assets/icons/ticks/Hamburger.svg";
import {ReactComponent as Close} from "assets/icons/ticks/Close.svg";
import {ReactComponent as Twitter} from "assets/icons/social/twitter.svg";
import {ReactComponent as Discord} from "assets/icons/social/discord.svg";
import {ReactComponent as Email} from "assets/icons/social/email.svg";
import { ENOTI_STATUS } from 'store/toasts';
import useToasts from 'hooks/useToasts';
import { AnchorWallet, useAnchorWallet } from '@solana/wallet-adapter-react';
import { COLORS, SPACING } from 'constants/theme';
import { formatAddress } from 'utils/string-formatting';
import { EUnauthedRoute } from 'constants/routes';

import "./NotAuthedHeader.scss";
import { useRecoilValue } from 'recoil';
import {useWalletAdapter} from "../../../hooks/useWalletAdapter";
import WalletButton from "../../WalletButton/WalletButton";
import { xNFTAtom } from 'store/xnft';

export interface Props {
    [key: string]: any
    conStyle?: any
}

export const WALLET_BUTTON_STYLES = {
   border: `1px solid ${COLORS.ACCENT_PRIMARY}`,
   backgroundColor: 'transparent',
   height: '35px',
 }

const NotAuthedHeader = (props: Props) => {

    const nav = useNavigate();
    const anchorWallet: AnchorWallet = useAnchorWallet();
    const {wallet} = useWalletAdapter();
    const {createToast} = useToasts();

    const [open, toggleOpen] = React.useState(false);
    const isXNFT = useRecoilValue(xNFTAtom);

    const openTab = (url: string) => {
      window.open(url, '_newtab');
    }

    const copyLink = (text: string) => {
      navigator.clipboard.writeText(text)
      createToast(`Copied to clipboard`, ENOTI_STATUS.SUCCESS)
    }

    const handleNavigate = (route: string) => {
      toggleOpen(false);
      nav("/" + route);
    }


    return (
      <div
         className= "notAuthedCon"
         style={{...props.conStyle, top: 0}}
      >
         <div className= "topCon">
            <div
               onClick={() => handleNavigate(EUnauthedRoute.BAZAAR)}
               style={{display: "flex", alignItems: 'center', cursor: "pointer"}}
            >
               <img
                  src={require("../../../assets/pngs/Stache_Logo_Color_Medium.png")}
                  style={{height: 35, width: 35, zIndex: 999}}
               />
               <p className="subheader" style={{marginLeft: SPACING.MD, zIndex: 999}}>Stache</p>
            </div>
          <div style={{display: "flex", alignItems: 'center', justifyContent: 'flex-end', zIndex: 999}}>
            {isXNFT
               ? <WalletButton/>
               : <WalletMultiButton className= "connectBut">
                     <p className= "connectText">
                        {!!anchorWallet ? formatAddress(anchorWallet.publicKey) : 'Connect Wallet'}
                     </p>
                  </WalletMultiButton>
            }
            <div
               onClick={() => toggleOpen(!open)}
               style={{
                  marginLeft: SPACING.LG,
                  cursor: "pointer"
               }}
            >
              {open
                  ? <Close height={20} width={20}/>
                  : <Hamburger height={20} width={20}/>
              }
            </div>
          </div>
        </div>
        {open &&
           <div className= "middleCon">
              <div className= "linksCon">
                 <div
                    onClick={() => handleNavigate(EUnauthedRoute.CONNECT_WALLET)}
                     className= "row"
                  >
                    <p>Home</p>
                 </div>
                 <div
                    onClick={() => handleNavigate(EUnauthedRoute.CREATE_NEW_STACHE)}
                     className= "row"
                  >
                    <p>Create your Stache</p>
                 </div>
                 <div onClick={() => openTab('https://docs.stache.io/stache')} className= "row">
                    <p>Documentation</p>
                 </div>
                 <div onClick={() => openTab('https://docs.stache.io/stache/faq')} className= "row">
                    <p>FAQ</p>
                 </div>
              </div>
              <div className= "bottomCon">
                 <p style={{color: COLORS.TEXT_GRAY, textAlign: "center"}}>
                    Ditch the marketplace - your shop, your rules.
                </p>
                 <div className="socialsCon">
                    <a
                       style={{textDecoration: "none"}}
                       href='https://twitter.com/SmartStache'
                       target={'_blank'}
                    >
                       <Twitter/>
                    </a>
                    <div onClick={() => copyLink("squeak@stache.io")} className= "socialButton touchable">
                       <Email/>
                    </div>
                    <a
                       style={{textDecoration: "none"}}
                       href='https://discord.com/channels/940917176783749141/973379671813152799'
                       target={'_blank'}
                    >
                       <Discord/>
                    </a>
                 </div>
                 {!isXNFT &&
                     <div className= "linCon">
                        <WalletMultiButton className= "connectBut">
                           <p className= "connectText">
                              {!!anchorWallet ? formatAddress(anchorWallet.publicKey) : 'Connect Wallet'}
                           </p>
                        </WalletMultiButton>
                     </div>
                 }
              </div>
              {open && <div className={
                    `overlayCon ${(isXNFT && props.hasHeader) 
                       ? "overlay-xnft-w-header" 
                       : isXNFT 
                          ? "overlay-xnft"
                          : ""
                       }`
                    } 
                 />
              }
           </div>
        }
      </div>
    );
}

 export default NotAuthedHeader;
