import './Hub.scss';
import {ReactComponent as TwitterIcon} from "assets/icons/social/twitter.svg"
import {ReactComponent as DiscordIcon} from "assets/icons/social/discord.svg"
import {ReactComponent as FilterIcon} from "assets/icons/Filter.svg"
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useAsyncEffect from 'use-async-effect';
import { apiClient } from 'apis/server';
import useToasts from 'hooks/useToasts';
import { useNavigate, useParams } from 'react-router';
import { ENOTI_STATUS } from 'store/toasts';
import Loader from 'components/Loader/Loader';
import { IActivity, IListingThumb, IShop } from 'store/yardsale';
import { ScreenContainer } from 'components/ui/container/Container';
import NotAuthedHeader from 'components/Header/NotAuthed/NotAuthedHeader';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userProfileAtom } from 'store/userProfile';
import Footer from 'components/Footer/Footer';
import { UserCard } from 'components/UserCard/UserCard';
import { SearchInput } from 'components/ui/input/Input';
import { NftCard } from 'components/NftCard/NftCard';
import { Feed } from 'components/Feed/Feed';
import { ListingCard } from 'components/NftCard/ListingCard/ListingCard';
import { Filter, IHub, ListingQuery } from 'store/hubs/types';
import { useHubActions } from 'store/hubs/actions';
import {debounce} from "lodash";
import {SidePanelWrapper } from 'components/SidePanel/SidePanelWrapper';
import { FiltersPanel } from 'components/SidePanel/FiltersPanel/FiltersPanel';
import {ReactComponent as Faders} from "assets/icons/Faders.svg";
import {ReactComponent as Funnel} from "assets/icons/Funnel.svg";
import { Pagination } from 'store/hubs/types';
import { listingsPerPage } from 'constants/config';
import { Pagination as PaginationComponent } from 'components/Pagination/Pagination';
import { Context } from 'components/ui/contextmenu/ContextMenu';
import {ReactComponent as SortupIcon} from "assets/icons/ticks/Sortup.svg";
import {ReactComponent as SortdownIcon} from "assets/icons/ticks/Sortdown.svg";


export interface Props {
  [key: string]: any
}

interface ShopProps {
  hub: string
}

interface FeedProps extends ShopProps {
  itemsPerPage: number,
}

interface SortProps {
  field: "name" | "age" | "price",
  order: "asc" | "desc"
}

const TopShops = ({hub}: ShopProps) => {
  const {searchShops} = useHubActions();
  const [isSearching, toggleSearching] = React.useState(true);
  const [data, setData] = useState <IShop[] | undefined> ();
  const [searchInput, setSearchInput] = useState ("");
  const [searchResults, setSearchResults] = React.useState<IShop[]>([]);
  const {createToast} = useToasts();
  // const [sort, setSort] = useState <SortProps> ("most-sales");

  useAsyncEffect(async () => {
    try {
      const data = await apiClient.getHubTopShops(hub);
      setData(data);
    }
    catch(e) {
      createToast("Error in retrieving feed", ENOTI_STATUS.ERR);
    }
  }, []);

  const handleSearchMethod = React.useRef(
      debounce(
          async (str: string) => {
            const results = (await searchShops(hub, str))
            setSearchResults(results);
          },
          500,
          {trailing: true, leading: false}
      )
  );

  React.useEffect(() => {
    toggleSearching(false);
  }, [searchResults]);

  React.useEffect(() => {
    if (searchInput.length) {
      toggleSearching(true);
      handleSearchMethod?.current(searchInput);
    } else {
      setSearchResults([]);
    }
  }, [searchInput]);

  if (!data) return <Loader noBg/>

  return (
    <div className = "top-shops-hub">
      <div className = "cont">
        <SearchInput placeholder={'Search'} value={searchInput} onChangeText={setSearchInput} />
        {/* <select value = {sort} onChange = {(e) => setSort(e.target.value as SortProps)}>
          <option value = "most-sales">
            Sort by: Most sales
          </option>
        </select> */}
      </div>
       {isSearching ? (
          <Loader />
        ) : searchInput.length && !searchResults.length ? (
          <div className="not-found">
            <p className="normal">No shops found</p>
          </div>
        ) : (
          <div className="grid">
            {(searchInput.length ? searchResults : data).map((x, idx) => {
              return (
                <UserCard shop = {x} link={`/bazaar/shop/${x.seller.stacheid}`} />
              );
            })}
          </div>
        )}
    </div>
  )
}

const TopListings = ({hub}: ShopProps) => {
  const hubActions = useHubActions();
  const [isSearching, toggleSearching] = React.useState(true);
  const [data, setData] = useState <Pagination <IListingThumb[]> | undefined> ();
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState <Filter[] | undefined> ();
  const [sort, setSort] = useState <SortProps> ({field: "age", order: "desc"});
  const [searchInput, setSearchInput] = useState ("");
  const [search, setSearch] = useState("");
  const {createToast} = useToasts();
  const [panel, setPanel] = useState (false);

  useEffect(() => {
    (async () => {
      try {
        setPanel(false);
        toggleSearching(true);
        const res = await hubActions.getListings(hub, getListingQuery());
        setData(res);
        toggleSearching(false);
      } catch (e) {
        createToast("Couldn't fetch filters", ENOTI_STATUS.ERR);
      }
    })();
  }, [page])

  useEffect(() => {
    (async () => {
      try {
        setPanel(false);
        toggleSearching(true);
        setPage(1);
        const res = await hubActions.getListings(hub, {
          ...getListingQuery(),
          pageNum: 1
        });
        setData(res);
        toggleSearching(false);
      } catch (e) {
        createToast("Couldn't fetch filters", ENOTI_STATUS.ERR);
      }
    })();
  }, [search, filters, sort])

  const getListingQuery = (): ListingQuery => ({
    pageNum: page,
    pageSize: listingsPerPage,
    ...(search && {searchQuery: search}),
    ...(filters && {filters}),
    ...(sort && {
      sordField: sort.field,
      sortOrder: sort.order
    })
  })

  const handleSearchMethod = React.useRef(
    debounce(
      async (str: string) => {
        setSearch(str);
        // toggleSearching(false);
      },
      500,
      {trailing: true, leading: false}
    )
  );

  const handleSort = (field: "name" | "age" | "price") => () => {
    if (sort.field === field) {
      setSort({
        field,
        order: sort.order === "asc" ? "desc" : "asc"
      })
    }
    else {
      setSort({
        field,
        order: "asc"
      })
    }
  }

  React.useEffect(() => {
    if (searchInput.length) {
      toggleSearching(true);
      handleSearchMethod?.current(searchInput);
    } 
  }, [searchInput]);

  const SortMenu = useMemo(() => {

    return (
      <div className = "sort-menu">
        {["age", "name", "price"].map((x: "name" | "price" | "age") => (
          <div onClick={handleSort(x)} className = {sort.field === x ? "active" : ""}>
            {(sort.field === x && sort.order === "desc") ? 
              <SortdownIcon/> :
              <SortupIcon/>
            }
            <p className = "subheader">
              Sort by {x}
            </p>
          </div>

        ))}
      </div>
    )
  }, [sort])
  
  if (!data) return <Loader noBg/>


  return (
    <div className = "top-shops">
      <FiltersPanel applyFilters = {setFilters} hubId={hub} isOpen = {panel} onClose = {() => setPanel(false)}/>
      <div className = "cont">
        <SearchInput placeholder={'Search'} value={searchInput} onChangeText={setSearchInput} />
        <Context leftClick menu = {SortMenu}>
          <div className = "fbt">
            <Funnel/>
          </div>

        </Context>
        <div className = "fbt" onClick={() => setPanel(true)}>
          <Faders/>
        </div>
      </div>

      <div className = "content">
        {isSearching ? (
            <Loader noBg />
          ) : (data.results.length === 0) ? (
            <div className="not-found">
              <p className="normal">No listings found</p>
            </div>
          ) : (
            <div className="grid">
              {data.results.map((x, idx) => {
                return (
                  <div className="grid-item" key={idx}>
                    <ListingCard
                      link={`/bazaar/shop/${x.seller?.stacheid}/listing/${x.id}`}
                      listing={x}
                      state={{}} // todo
                    />
                  </div>
                );
              })}
            </div>
        )}
      </div>
      <PaginationComponent page = {page} total = {Math.ceil(data.total / listingsPerPage)} setPage = {setPage} />
    </div>
  )
}


export const Hub = (props: Props) => {
  const {hub} = useParams();
  const {createToast} = useToasts();
  const [tabView, setTabView] = useState <"feed" | "shops" | "listings"> ("listings");
  const [hubData, setHubData] = useState <IHub | undefined> (undefined);
  const [userProfile, setUserProfile] = useRecoilState(userProfileAtom);
  // const [hubListings, setHubListings] = useState <IStoreItem | undefined> (undefined);
  const navigate = useNavigate();
  const {getHubByName} = useHubActions();

  useAsyncEffect(async () => {
    try {
      const data = await getHubByName(hub);
      setHubData(data);
    }
    catch(e) {
      createToast("Hub does not exist", ENOTI_STATUS.ERR);
      // setTimeout(() => navigate(-1), 2000); // This should maybe changed to avoid confusion when the server fails
    }
  }, [])

  const RenderTab = useCallback(() => {
    switch (tabView) {
      case "feed":
        return <Feed getter={async () => await apiClient.getHubFeed(hub)} itemsPerPage={10}/>
      case "shops":
        return <TopShops hub={hub}/>
      case "listings":
        return <TopListings hub={hub}/>
    }
  }, [tabView]);

  if (!hubData) return <Loader/>

  return (
    <ScreenContainer outerClassName={"hub-page-wrapper"}>
      {!userProfile.authed && <NotAuthedHeader/>}
      <div className = "hub-page content">
        <div className = "top">
          {/* <img src = {hubData.bannerImgUrl} className = "banner"/> */}
          <div className = "banner" style={{backgroundImage: `url(${hubData.bannerImgUrl})`}}/>
          <div className = "top-content">
            <div className = "img-container">
              <img src = {hubData.avatarImgUrl}/>
            </div>
            <div className = "description">
              <img className = "logo" src = {hubData.logoImgUrl}/>
              <p className = "banner">{hubData.name}</p>
              <p className = "normal">
                {hubData.desc}
              </p>
              <div className = "social">
                <a target='_blank' href = {hubData.twitterUrl}>
                  <TwitterIcon/>
                </a>
                <a target='_blank' href = {hubData.discordUrl}>
                  <DiscordIcon/>
                </a>
              </div>
            </div>
            <div className = "metadata">
              {hubData.stats.map((x, idx) => (
                <div key = {idx}>
                  <p className = "medium gray">{x.name}</p>
                  <p className = "subheader">{x.value}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* <div className = "description mob">
          <img className = "logo" src = {hubData.logoImgUrl}/>
          <p className = "banner">{hubData.name}</p>
          <p className = "normal">
            {hubData.desc}
          </p>
          <div className = "social">
            <a href = {hubData.twitterUrl}>
              <TwitterIcon/>
            </a>
            <a href = {hubData.discordUrl}>
              <DiscordIcon/>
            </a>
          </div>
        </div> */}
        <div className = "controls">
          <div className="tabs">
            <div
              className={'touchable ' + (tabView === "listings" ? 'active' : '')}
              onClick={() => setTabView("listings")}
            >
              <p className="subheader">Listings</p>
              <div />
            </div>
            <div
              className={'touchable ' + (tabView === "shops" ? 'active' : '')}
              onClick={() => setTabView("shops")}
            >
              <p className="subheader">Shops</p>
              <div />
            </div>
            <div
              className={'touchable ' + (tabView === "feed" ? 'active' : '')}
              onClick={() => setTabView("feed")}
            >
              <p className="subheader">Activity Feed</p>
              <div />
            </div>
          </div>
          {/* <div className = "filters">
            <FilterIcon/>
            <p className = "subheader">Filter</p>
          </div> */}
        </div>
        <div className = "custom-tab">
          <RenderTab/>
        </div>
      </div>
      {/* <Footer/> */}
    </ScreenContainer>
  );
}
