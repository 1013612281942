import './Container.scss';
import useWindowDimensions from 'hooks/useWindowDimensions';
import {COLORS, SPACING, VIEWPORT} from 'constants/theme';
import {ReactComponent as ChevronIcon} from 'assets/icons/ticks/Chevron.svg';
import {xNFTAtom} from 'store/xnft';
import {useRecoilValue} from 'recoil';
import useSafeBack from 'hooks/useSafeBack';
import React from 'react';

export interface Props {
  [key: string]: any;
}

interface AppSpacingProps {
  children: React.ReactNode;
  hasBackButton?: boolean;
  menu?: React.ReactNode;
  overrideBack?: () => void;
  title?: string;
  className?: string;
  style?: any;
  headerStyle?: any;
  disabled?: boolean;
  outerClassName?: any;
}

export const ScreenContainer = ({
  children,
  hasBackButton = false,
  menu,
  overrideBack,
  title,
  className,
  style,
  headerStyle,
  disabled,
  outerClassName
}: AppSpacingProps) => {
  const isXNFT = useRecoilValue(xNFTAtom);
  const {goBack} = useSafeBack();

  const hasHeader = React.useMemo(() => {
    return (hasBackButton || !!title || menu) 
  }, [hasBackButton, menu, title])
  
  return (
    <div
      className={`${!!outerClassName ? outerClassName : ""} screen-con`}
      style={{
        paddingTop: hasHeader ? 0 : SPACING.XL,
        backgroundColor: "transparent",
        ...style
      }}
    >
      {hasHeader
        ? <div 
            className={'nav-con'}
            style={headerStyle}
          >
            {!!hasBackButton
              ? <div 
                  className={`go-back touchable ${disabled ? "disabled" : ""}`} 
                  onClick={() => (!!overrideBack ? overrideBack() : goBack(''))}
                >
                  <ChevronIcon height={20} width={20} />
                </div>
              : <div />
            }
            <p className="header">{title ?? ''}</p>
            {!!menu
              ? <div style={{marginRight: isXNFT ? SPACING.XNFT_POWER_BUTTON_WIDTH : 0}}>
                  {menu}
                </div>
              : <div />
            }
          </div>
        : null
      }
      <div 
        className={`${!!className ? className : ""}`} 
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          paddingTop: (isXNFT && !hasHeader) ? SPACING.XNFT_POWER_BUTTON_HEIGHT - SPACING.XL * 2 : 0
        }}
      >
        {children}
      </div>
    </div>
  );
};

export const BALANCE_WRAPPER_BASE_HEIGHT = 118;

export const BalanceContainer = (props: Props) => {
  const dims = useWindowDimensions();

  return (
    <div
      className="balance-container"
      style={{
        height: dims.width <= VIEWPORT.MAX_MOBILE_WIDTH ? BALANCE_WRAPPER_BASE_HEIGHT / 2 : BALANCE_WRAPPER_BASE_HEIGHT,
      }}
    >
      <img src={require("../../../assets/pngs/balance_bkg.png")} className="img-bg" />
      <div className="children">{props.children}</div>
      <img
        src={require('assets/pngs/Stache_Logo_Clear.png')}
        style={{
          height:
            dims.width <= VIEWPORT.MAX_MOBILE_WIDTH ? BALANCE_WRAPPER_BASE_HEIGHT / 2 : BALANCE_WRAPPER_BASE_HEIGHT,
          width: dims.width <= VIEWPORT.MAX_MOBILE_WIDTH ? 61 : 122,
        }}
      />
    </div>
  );
};

export default BalanceContainer;
