import React from 'react';

//Components
import {WalletMultiButton} from '@solana/wallet-adapter-react-ui';
import {ReactComponent as BackNavigationIcon} from 'assets/icons/ticks/Chevron.svg';
import {WalletConnectedModal} from 'components/Modals/WalletLogin/WalletLoginModal';
import {ColoredButton} from 'components/ui/button/Button';
import {Input} from 'components/ui/input/Input';
import {ReactComponent as InfoTriangleIcon} from 'assets/icons/InfoTriangle.svg';

//Constants
import {ERouteHome, EUnauthedRoute} from 'constants/routes';
import {solanaClient} from '../../constants/factory';
import {EVENTS} from '../../constants/events';

//Hooks
import {useNavigate} from 'react-router';
import useAuth from 'hooks/useAuth';
import {useAnalyticsActions} from '../../hooks/useAnalytics';
import useToasts from 'hooks/useToasts';

//Recoil
import {IKeychain, useKeychainActions} from 'store/keychain';
import {IStache, useStacheActions} from 'store/stache';
import {useRecoilState} from 'recoil';
import {ENOTI_STATUS} from 'store/toasts';
import {connectedWalletAtom} from 'store/connectedWallets';

//Web3
import {AccountResponse, SKeychain, SStache} from 'apis/solana/types';
import {useWallet} from '@solana/wallet-adapter-react';

//Style
import './LinkWallet.scss';
import useSafeBack from 'hooks/useSafeBack';
import {Link} from 'react-router-dom';
import ModalWrapper from 'components/Modals/ModalWrapper/ModalWrapper';
import {ScreenContainer} from 'components/ui/container/Container';

interface IProps {}

export const LinkWallet: React.FC<IProps> = ({}) => {
  const navigate = useNavigate();
  const keychainActions = useKeychainActions();
  const stacheActions = useStacheActions();
  const auth = useAuth();
  const analyticsActions = useAnalyticsActions();
  const {createToast} = useToasts();
  const {goBack} = useSafeBack();

  const [buttonLabel, setButtonLabel] = React.useState('Verify wallet');
  const [loading, toggleLoading] = React.useState(false);
  const [modal, setModal] = React.useState<Boolean | string>(false);
  const [stacheid, setStacheid] = React.useState('');
  const [errorText, setErrorText] = React.useState('');
  const [connectedWallet, setConnectedWallet] = useRecoilState(connectedWalletAtom);

  const getStache = React.useCallback(async (): Promise<AccountResponse<SStache>> => {
    let stacheidlower = stacheid.toLowerCase().trim();
    console.log(`getting stache by id: ${stacheidlower}`);
    return await solanaClient.getStacheById(stacheidlower);
  }, [stacheid]);

  const getKeychain = React.useCallback(async (): Promise<SKeychain> => {
    let stacheidlower = stacheid.toLowerCase().trim();
    console.log(`getting keychain by id: ${stacheidlower}`);
    return await solanaClient.fetchKeychainByName(stacheidlower);
  }, [stacheid]);

  const checkPendingButAlreadyVerified = async (stacheid: string): Promise<boolean> => {
    const keychainStateAccount = await keychainActions.getKeychainStateAccount(stacheid);
    if (keychainStateAccount) {
      const key = keychainStateAccount.pendingAction?.key;
      const verified = keychainStateAccount.pendingAction?.verified;
      console.log('key: ', key, 'verified: ', verified);
      return verified && key.toBase58() === connectedWallet.address.toBase58();
    } else return false;
  };

  const resetUi = () => {
    toggleLoading(false);
    setButtonLabel('Verify wallet');
    setStacheid('');
  };

  const verifyWallet = async () => {
    toggleLoading(true);
    setButtonLabel('Fetching Stache...');

    let stache: SKeychain;
    let stacheidlower = stacheid.toLowerCase().trim();
    try {
      stache = await getKeychain();
      console.log(`found stache for verification: ${JSON.stringify(stache)}`);
    } catch (e) {
      setErrorText('Stache ID does not exist');
      setModal('failure');
      resetUi();
    }

    if (stache) {
      // Check for repeat verification attempt
      if (await checkPendingButAlreadyVerified(stache.name)) {
        setModal('repeatVerified');
        resetUi();
        return;
      }

      // Check if balance is above 0
      const isAboveZero = await solanaClient.isBalanceAboveZero(connectedWallet.address);
      if (!isAboveZero) {
        createToast('Solana balance is 0. Add some SOL to your wallet to continue', ENOTI_STATUS.ERR);
        resetUi();
        return;
      }

      try {
        setButtonLabel(`Verifying wallet...`);
        const res = await keychainActions.verifyKey(stacheidlower);
        if (res === true) {
          analyticsActions.trackEvent(EVENTS.linkedWallet, {
            stacheid: stacheidlower,
            wallet: connectedWallet.address.toBase58(),
          });
          analyticsActions.identify(stacheidlower);

          // Determine if this was the final step of the approval process and the user can login
          // Or if they verified the account before the Stache had finished its approval process
          const states: {
            keychainState: IKeychain;
            stacheState?: IStache;
          } = await stacheActions.checkForStache(connectedWallet.address);

          if (states?.keychainState?.walletLinked) {
            setModal('success');
          } else {
            setModal('onlyVerified');
          }
        } else createToast(res, ENOTI_STATUS.ERR);
      } catch (err) {
        setModal('failure');
        setErrorText('There was an error verifying your wallet: ' + err.message);
      }
    } else {
      setErrorText("Stache ID doesn't exist.");
      setModal('failure');
    }
    resetUi();
  };

  const handleBack = () => goBack(EUnauthedRoute.CONNECT_WALLET);

  const handleProfilePress = async () => {
    setModal(false);
    auth.logout();
    navigate('/' + EUnauthedRoute.CONNECT_WALLET);
  };

  return (
    <ScreenContainer hasBackButton className="link-wallet">
      <div className="content">
        <p className="banner">Verify Wallet</p>
        <p className="normal">Verify your wallet to link it to your existing Stache account</p>
        <Input label="Your existing Stache ID" value={stacheid} placeholder="Hi_Im_Mundo" onChangeText={setStacheid} />
        <p className="medium">
          Don't have a Stache ID?
          <Link to={`/${EUnauthedRoute.CREATE_NEW_STACHE}`}>Create one</Link>
        </p>
        <Input
          value={connectedWallet?.address.toBase58()}
          placeholder={connectedWallet?.address.toBase58()}
          onChangeText={() => null}
          disabled={true}
          label="Linking Connected Wallet"
        />
      </div>
      <div className="submit">
        <ColoredButton onClick={verifyWallet} disabled={loading}>
          {buttonLabel}
        </ColoredButton>
      </div>

      {/* Failure modal */}
      <ModalWrapper isOpen={modal === 'failure'} onClose={() => setModal(false)}>
        <div className="link-modal">
          <img src={require('assets/pngs/not-checked.png')} />
          <p className="normal mb-xxxl">{errorText}</p>
          <ColoredButton className="mb-lg" onClick={() => setModal(false)}>Try again</ColoredButton>
        </div>
      </ModalWrapper>

      {/* Success modal */}
      <WalletConnectedModal
        text="Wallet verified, approved, and ready to login!"
        isOpen={modal === 'success'}
        close={() => setModal(false)}
        skipKeychainStateLoad={true}
      />

      {/* Partial success modal */}
      <ModalWrapper isOpen={modal === 'onlyVerified' || modal === 'repeatVerified'} onClose={handleProfilePress}>
        <div className="link-modal">
          {modal === 'onlyVerified' ? <img src={require('assets/pngs/checked.png')} /> : <InfoTriangleIcon />}
          <p className="normal">
            {modal === 'onlyVerified' ? 'Wallet is now verified!' : 'Wallet has already been verified.'}
          </p>
          <p className="normal">
            Next step is to log back in with your main account, or one of your other confirmed wallets.
          </p>
          <p className="normal mb-xxxl">From there you can continue the authorization process for this wallet.</p>
          <ColoredButton className="mb-lg" onClick={handleProfilePress}>Return home</ColoredButton>
        </div>
      </ModalWrapper>
    </ScreenContainer>
  );
};

export default LinkWallet;
