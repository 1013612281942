export enum ENOTI_STATUS {
  SUCCESS = "SUCCESS",
  ERR = "ERR",
  DEFAULT = "DEFAULT"
}

export interface IToasts {
  toasts: IToast[]
}

export interface IToast {
  id: string,
  text: string,
  type: ENOTI_STATUS,
  timeout: number
}
