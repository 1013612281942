import {IWallet} from "../connectedWallets";
import {PublicKey} from "@solana/web3.js";

export enum EVaultType {
  EASY = 'EASY',
  TWOSIG = 'SMART',
  MULTISIG = 'SECURE'   // squads
}

export enum EVaultTxStatus {
  PENDING = "Waiting for Approval"
}

export enum EVaultTxType {
  WITHDRAWAL = "Withdrawal",
  DEPOSIT = "Deposit"
}

export interface IVaultWallet extends IWallet {
  type: EVaultType,
  index: number,
  pendingTxs?: IVaultPendingTx[],
  txHistory?: IVaultHistoricalTx[]
}

export interface IVaultHistoricalTx {

}

export interface IVaultPendingTx {
  status: EVaultTxStatus,
  approvedBy: PublicKey[],
  approvals: number,
  approvalsNeeded: number,
  amount: number,
  token: string,
  created_at: Date,
  type: EVaultTxType,
  withdrawWallet: IWallet,
  depositWallet: IWallet
}


