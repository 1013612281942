import React, {ReactNode} from "react";
import {useSetRecoilState} from "recoil";
import {apiClient} from "apis/server";
import {blacklistAtom, ogCollectionsAtom} from "../../store/system";
import {ICollection} from "../../store/connectedWallets";

interface Props {
  children?: ReactNode
}

export const DataRetrivalLayer: React.FC<Props> = ({children}) => {

  const setBlacklist = useSetRecoilState(blacklistAtom);
  const setOgCollections = useSetRecoilState(ogCollectionsAtom);

  React.useEffect(() => {

    // load the blacklist
    apiClient.getBlacklist()
        .then(res => {
          const sites = res.sites
          if (!!sites) {
            setBlacklist(sites.map((site: { stringMatch: string }) => site.stringMatch))
          }
        })
        .catch(e => console.log("ERR blacklist", e))

    // load the og collections
    apiClient.getOgCollections()
        .then(res => {
          const collections = res.collections;
          const ogCollections = new Map<string, ICollection>();
          for (let collection of collections) {
            ogCollections.set(collection.firstVerifiedCreator, {name: collection.name, symbol: collection.symbol});
          }
          setOgCollections(ogCollections);
        })
        .catch(e => console.log("problem populating og collections", e));

  }, []);

  return <>{children}</>;
}

export default DataRetrivalLayer;
