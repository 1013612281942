import {ReactComponent as Arrow} from "assets/icons/ticks/ArrowIcon.svg";
import "./Card.scss";

interface Props{
    children: React.ReactNode;
    onClick?: () => void;
    style?: any;
}

export default function Card({ style, children, onClick }: Props){

    return (
        <div 
            className={`container-card-con`}
            onClick={onClick}
            style={{
                justifyContent: !!onClick ? "space-between" : "flex-start",
                cursor: !!onClick ? "pointer" : "default",
                ...style
            }}
        >
            {children}
            {!!onClick 
                ? <Arrow /> 
                : null
            }
        </div>
    )
}