import React, {FC, ReactElement} from 'react';
import useToasts from 'hooks/useToasts';
import {apiClient} from 'apis/server';
import {useRecoilState, useRecoilValue} from 'recoil';
import {IProfileInfo, useUserProfileActions, userProfileAtom} from 'store/userProfile';
import {ENOTI_STATUS} from 'store/toasts';
import {EActivityAction, EAssetSelectionType, IActivity, IListing, storefrontAtom} from 'store/yardsale';
import {ENavStack, ENestedRoute, ERoute, ERouteBazaar} from 'constants/routes';
import {Link, useNavigation} from 'react-router-dom';
import {ReactComponent as PlusIcon} from 'assets/icons/ticks/PlusCircle.svg';
import {ReactComponent as Plus} from 'assets/icons/ticks/Plus.svg';
import {keychainAtom} from 'store/keychain';
import {stacheAtom} from 'store/stache';
import {Avatar} from 'components/Avatar/Avatar';
import {ReactComponent as Telegram} from 'assets/icons/social/telegram.svg';
import {ReactComponent as Discord} from 'assets/icons/social/discord.svg';
import {ReactComponent as Twitter} from 'assets/icons/social/twitter.svg';
import './MyShop.scss';
import {ColoredButton} from 'components/ui/button/Button';
import {getBuyerDisplay} from 'utils/yardsale-utils';
import {formatSecondsToTime} from 'utils/string-formatting';
import {COLORS, SPACING} from 'constants/theme';
import {Input} from 'components/ui/input/Input';
import {ScreenContainer} from 'components/ui/container/Container';
import { ListingCard } from 'components/NftCard/ListingCard/ListingCard';
import { xNFTAtom } from 'store/xnft';

interface Props {
  listings: IListing[];
  otherStacheid?: string;
}

export const Listings: FC<any> = ({listings, otherStacheid}: Props): ReactElement => {
  return (
    <div className="profile-listings">
      {listings?.length ? (
        <div className="grid">
          {listings.map((x, idx) => {
            return (
              <div className="grid-item" key={idx}>
                <ListingCard
                  link={`/bazaar/shop/${x.seller.stacheid}/listing/${x.id}`}
                  listing={x}
                  state={{}}
                />
              </div>
            );
          })}
        </div>
      ) : (
        <div className="empty-wrapper">
          <div className="empty">
            <img src={require('assets/pngs/EmptyBox.png')} />
            <p className="header">{`${!otherStacheid ? "You're not" : 'Not'} currently selling anything.`}</p>
            {!otherStacheid && (
              <Link to={ERouteBazaar.MY_ASSETS} state={{screenType: EAssetSelectionType.INDIVIDUAL}}>
                <ColoredButton style={{width: "250px"}} icon={<Plus />}>Create a Listing</ColoredButton>
              </Link>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

interface Props {
  data: IActivity[];
}

export const Activity: FC<any> = ({data}: Props): ReactElement => {
  const getActionText = React.useCallback((item: IActivity) => {
    switch (item.activityType) {
      case EActivityAction.SOLD:
        const buyer = getBuyerDisplay(item);
        return buyer ? 'Purchased by ' + buyer : 'Purchased';
      case EActivityAction.DELISTED:
        return 'Delisted';
      case EActivityAction.UPDATED:
        return 'Updated';
      case EActivityAction.LISTED:
        return `Listed for ${item.listing.price} ${item.listing.currency.symbol}`;
    }
  }, []);

  return (
    <div className="profile-activity">
      {data.length ? (
        data.map((x, idx) => (
          <div className="activity-item" key={idx}>
            <img src={x.listing.imageUrl} />
            <div>
              <p className="normal">{getActionText(x)}</p>
              <p className="normal gr">
                {formatSecondsToTime((Date.now() - Math.floor(new Date(x.createdAt).getTime())) / 1000)} ago
              </p>
            </div>
          </div>
        ))
      ) : (
        <div className="empty">
          <p className="normal">No recent activity found</p>
        </div>
      )}
    </div>
  );
};

interface Props {
  profileInfo: IProfileInfo;
}

export const About: FC<any> = ({profileInfo}: Props): ReactElement => {
  const userProfileActions = useUserProfileActions();
  const {createToast} = useToasts();

  const keychain = useRecoilValue(keychainAtom);
  const [input, setInput] = React.useState('');
  const [isLoading, toggleLoading] = React.useState(false);

  const openLink = (url: string) => {
    window.open(url, '_newTab');
  };

  const isSelf = React.useMemo(() => {
    return keychain?.name && keychain.name === profileInfo.keychain.name;
  }, []);

  const handleSend = async () => {
    if (!!keychain.name) {
      toggleLoading(true);
      await userProfileActions
        .postMessageToShop(input, profileInfo.keychain.name)
        .then(() => {
          createToast('Message sent', ENOTI_STATUS.SUCCESS);
          setInput('');
        })
        .catch(() => createToast('Error sendig message', ENOTI_STATUS.ERR))
        .finally(() => toggleLoading(false));
    } else {
      createToast('You must be logged into your Stache account in order to send messages', ENOTI_STATUS.DEFAULT);
      setInput('');
    }
  };

  return (
    <div className="profile-about">
      <div className="row">
        <div className="cell">
          <p className="normal gr">Listings</p>
          <p className="normal">{profileInfo.numListed}</p>
        </div>
        <div className="cell">
          <p className="normal gr">Sold</p>
          <p className="normal">{profileInfo.numSold}</p>
        </div>
      </div>
      <div className="sep cell">
        <p className="normal">Social links</p>
        <div>
          <Twitter />
          <Telegram />
          <Discord />
        </div>
      </div>
      <div style={{display: 'flex', flexDirection: 'column', flex: 1, marginBottom: !isSelf ? SPACING.XL : 0}}>
        <p style={{color: COLORS.TEXT_GRAY}}>{profileInfo.desc}</p>
      </div>
      {!isSelf && (
        <div className="leave-message">
          <p className="normal">{`Send @${profileInfo.stacheid} a message`}</p>
          <div>
            <Input value={input} onChangeText={setInput} placeholder="Message..." />
            <ColoredButton onClick={handleSend} disabled={isLoading} style={{width: '100%'}}>
              <p className="normal">Send</p>
            </ColoredButton>
          </div>
        </div>
      )}
    </div>
  );
};

export const Myshop: FC<any> = (props: Props): ReactElement => {
  const {createToast} = useToasts();
  const userActions = useUserProfileActions();

  const storefront = useRecoilValue(storefrontAtom);
  const [tabView, setTabView] = React.useState<ENestedRoute>(ENestedRoute.LISTINGS);
  const stache = useRecoilValue(stacheAtom);
  const keychain = useRecoilValue(keychainAtom);
  const userProfile = useRecoilValue(userProfileAtom);
  const [activities, setActivities] = React.useState([]);
  const [isXNFT, setIsXNFT] = useRecoilState(xNFTAtom);

  React.useEffect(() => {
    if (true) {
      userActions
        .getUserActivity(userProfile.profileInfo.id)
        .then((res) => setActivities(res))
        .catch((e) => console.log('Err getting activities', e));
    }
  }, []);

  const handlePfpPress = () => {
    createToast(
      'To set your profile picture: Open an NFT in Home > Collectibles and set from the menu.',
      ENOTI_STATUS.DEFAULT
    );
  };

  const RenderView = React.useCallback(() => {
    switch (tabView) {
      case ENestedRoute.LISTINGS:
        return <Listings listings={storefront} />;
      case ENestedRoute.ACTIVITY:
        return <Activity data={activities} />;
      case ENestedRoute.ABOUT:
        return <About profileInfo={userProfile.profileInfo} />;
    }
  }, [tabView, storefront, userProfile.profileInfo]);

  return (
    <ScreenContainer hasBackButton = {isXNFT}>
      <div className="myshop">
        <div className="row" style={{display: 'flex', alignItems: 'start', justifyContent: 'space-between'}}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <Avatar
              iconUrl={userProfile.profileInfo.avatarUrl}
              username={userProfile?.profileInfo?.stacheid}
              size={50}
            />
            <p className="header" style={{marginLeft: SPACING.MD}}>@{stache?.stacheid ?? keychain.name}</p>
          </div>
          <Link
            to={ERouteBazaar.MY_ASSETS}
            state={{screenType: EAssetSelectionType.INDIVIDUAL}}
            style={{display: 'flex', alignItems: 'center'}}
          >
            <PlusIcon />
          </Link>
        </div>
        <div className="tabs">
          <div
            className={'touchable ' + (tabView === ENestedRoute.LISTINGS ? 'active' : '')}
            onClick={() => setTabView(ENestedRoute.LISTINGS)}
          >
            <p className="subheader">Listings</p>
            <div />
          </div>
          <div
            className={'touchable ' + (tabView === ENestedRoute.ACTIVITY ? 'active' : '')}
            onClick={() => setTabView(ENestedRoute.ACTIVITY)}
          >
            <p className="subheader">Activity</p>
            <div />
          </div>
          <div
            className={'touchable ' + (tabView === ENestedRoute.ABOUT ? 'active' : '')}
            onClick={() => setTabView(ENestedRoute.ABOUT)}
          >
            <p className="subheader">About</p>
            <div />
          </div>
        </div>
        <RenderView />
      </div>
    </ScreenContainer>
  );
};

export default Myshop;
