import React, {useState} from 'react';

//Components
import {ColoredButtonInverse} from 'components/ui/button/Button';
import ModalWrapper from 'components/Modals/ModalWrapper/ModalWrapper';
import AddWallet from 'components/Modals/AddWallet/AddWallet';
import {Link, useNavigate} from 'react-router-dom';

//SVGs
import {ReactComponent as ChevronIcon} from 'assets/icons/ticks/Chevron.svg';

//Utils
import {ENavStack, ERouteSettings} from 'constants/routes';
import {BRADIUS, COLORS, SPACING, VIEWPORT} from 'constants/theme';
import useToasts from 'hooks/useToasts';
import {formatAddress} from 'utils/string-formatting';

//Recoil
import {useRecoilState, useRecoilValue} from 'recoil';
import {IKey, KeyChainActionType, keychainAtom, keychainStateAtom} from 'store/keychain';
import {connectedWalletAtom} from 'store/connectedWallets/state';

//Constants
import {ENOTI_STATUS} from 'store/toasts';
import ScreenHeader from 'components/Header/Screen/ScreenHeader';
import BalanceContainer, {ScreenContainer} from 'components/ui/container/Container';

import './ManageWallets.scss';
import useWindowDimensions from 'hooks/useWindowDimensions';

type IManageWalletsProps = any;

export const ManageWallets: React.FC<IManageWalletsProps> = (props: IManageWalletsProps) => {
  const navigate = useNavigate();
  const {width} = useWindowDimensions();

  const keychain = useRecoilValue(keychainAtom);
  const [keychainState, setKeychainState] = useRecoilState(keychainStateAtom);
  const connectedWallet = useRecoilValue(connectedWalletAtom);
  const {createToast} = useToasts();
  const [modal, setModal] = useState(false);

  const openModal = () => setModal(true);

  const showToast = () => createToast('You must first conclude the currently pending wallet action', ENOTI_STATUS.ERR);

  return (
    <ScreenContainer hasBackButton title="Manage Wallets" overrideBack={() => navigate('/' + ENavStack.SETTINGS)}>
      <AddWallet isOpen={modal} onClose={() => setModal(false)} />
      <div className="manage-wallets">
        <BalanceContainer>
          <p className="normal">{`Total wallets ( ${keychain.keys.length} )`}</p>
        </BalanceContainer>
        <div className="connected-wallet">
          <p className="header">Connected Wallet</p>
          <div className="wallet-card">
            <p className="subheader">
              {width <= 700
                ? formatAddress(connectedWallet?.address?.toBase58())
                : connectedWallet?.address?.toBase58()}
            </p>
          </div>
        </div>
        {!!keychainState.pending_action && (
          <>
            <p className="header mr">
              Pending Action -
              {keychainState.pending_action.action_type === KeyChainActionType.AddKey
                ? ' Adding Wallet'
                : ' Removing Wallet'}
            </p>
            <Link to={'/' + ENavStack.SETTINGS + '/' + ERouteSettings.MANAGE_PENDING_WALLET}>
              <div className="wallet-card">
                <p className="subheader">
                  {width <= 700
                    ? formatAddress(keychainState?.pending_action?.key.toBase58())
                    : keychainState?.pending_action?.key.toBase58()}
                </p>
                <div>
                  <p className="normal">PENDING APPROVAL</p>
                  <ChevronIcon />
                </div>
              </div>
            </Link>
          </>
        )}
        <div className="wallets">
          <p className="header">Wallets</p>
          {keychain.keys.map((x, idx) => {
            if (x.walletAddress.toBase58() === connectedWallet.address.toBase58()) return null;
            return (
              <Link key={idx} to={x?.walletAddress?.toBase58()}>
                <div className="wallet-card">
                  <p className="subheader">
                    {width <= 700 ? formatAddress(x?.walletAddress?.toBase58()) : x?.walletAddress?.toBase58()}
                  </p>
                  <div>
                    <ChevronIcon />
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
        <ColoredButtonInverse
          onClick={!!keychainState.pending_action ? showToast : openModal}
          style={{marginTop: SPACING.XXXL}}
        >
          + Add wallet
        </ColoredButtonInverse>
      </div>
    </ScreenContainer>
  );
};
