import {LoginResponse} from 'apis/server/types';
import './WalletLoginModal.scss';
import {ColoredButton} from 'components/ui/button/Button';
import {EVENTS} from 'constants/events';
import {SPACING} from 'constants/theme';
import {useAnalyticsActions} from 'hooks/useAnalytics';
import useAuth from 'hooks/useAuth';
import useToasts from 'hooks/useToasts';
import {useWalletAdapter} from 'hooks/useWalletAdapter';
import {useRecoilValue, useResetRecoilState} from 'recoil';
import {keychainAtom, keychainStateAtom} from 'store/keychain';
import {ENOTI_STATUS} from 'store/toasts';
import ModalWrapper from '../ModalWrapper/ModalWrapper';
import {ReactComponent as InfoTriangleIcon} from 'assets/icons/InfoTriangle.svg';
import {useNavigate} from 'react-router';
import {ENavStack} from 'constants/routes';

interface IWalletModalProps {
  isOpen: boolean;
  text?: string;
  close: () => void;
  handleNavigate?: (bool: boolean) => void;
  handleRegister?: () => void;
  skipKeychainStateLoad?: boolean;
}

export const WalletConnectedModal: React.FC<IWalletModalProps> = ({
  isOpen,
  text,
  close,
  skipKeychainStateLoad = false,
}) => {
  const auth = useAuth();
  const {createToast} = useToasts();
  const analyticsActions = useAnalyticsActions();
  const {signMessage, wallet} = useWalletAdapter();
  const navigate = useNavigate();

  const keychain = useRecoilValue(keychainAtom);
  const resetKeychainState = useResetRecoilState(keychainStateAtom);

  async function login() {
    try {
      close();
      const loginResponse: LoginResponse = await auth.login(signMessage, keychain.name);

      analyticsActions.trackEvent(EVENTS.login, {
        stacheid: loginResponse.stache.stacheid,
        wallet: wallet.publicKey,
      });
      analyticsActions.identify(loginResponse.stache.stacheid);

      createToast(`Welcome to your Stache Account @${loginResponse.stache.stacheid}!`, ENOTI_STATUS.SUCCESS);
      const res = await auth.attemptFullLogin(wallet.publicKey, loginResponse.authToken);
      // Solana is too slow to update chain state sometimes, and this value should be forced to null
      // if the user is logging in from a recently verified (and approved) wallet
      if (skipKeychainStateLoad) {
        resetKeychainState();
      }
      if (!res) {
        // todo error res
      }
      setTimeout(() => navigate('/' + ENavStack.HOME), 200);
    } catch (err) {
      createToast(err.message, ENOTI_STATUS.ERR);
    }
  }

  const handleCancel = async () => {
    close();
    await auth.logout();
  };

  return (
    <ModalWrapper isOpen={isOpen} onClose={handleCancel}>
      <div className="modal-login">
        <img src={require('assets/pngs/Stache_Found.png')} />
        <p className="normal mb-xxxl">{text}</p>
        <ColoredButton 
          onClick={login}
          style={{marginBottom: SPACING.LG}}
        >
            Login
        </ColoredButton>
        <ColoredButton onClick={handleCancel}>Cancel</ColoredButton>
      </div>
    </ModalWrapper>
  );
};

export const WalletNotLinkedModal: React.FC<IWalletModalProps> = ({isOpen, close, handleNavigate, handleRegister}) => {
  const auth = useAuth();

  const handleCancel = async () => {
    close();
    await auth.logout();
  };

  return (
    <ModalWrapper isOpen={isOpen} onClose={handleCancel}>
      <div className="modal-login">
        <img src={require('assets/pngs/Stache_Not_Found.png')} />
        <p className="normal mb-xxxl">Wallet isn't linked to a Stache account.</p>
        <ColoredButton 
          className="white-button" 
          onClick={() => handleNavigate(true)}
          style={{marginBottom: SPACING.LG}}
        >
          Verify wallet with an existing Stache
        </ColoredButton>
        <ColoredButton onClick={handleRegister}>Create a new Stache account</ColoredButton>
      </div>
    </ModalWrapper>
  );
};

export const WalletVerifiedModal: React.FC<IWalletModalProps> = ({isOpen, close}) => {
  const auth = useAuth();

  const handleCancel = async () => {
    close();
    await auth.logout();
  };

  return (
    <ModalWrapper isOpen={isOpen} onClose={handleCancel}>
      <div className="modal-login">
        <InfoTriangleIcon />
        <p className="normal">Wallet has been verified!</p>
        <p className="normal">To login and continue with this wallet, more approvals are required.</p>
        <p className="normal">
          Login with a wallet that is linked with this Stache account to see which wallets still require an approval.
        </p>
        <ColoredButton onClick={close}>Okay</ColoredButton>
      </div>
    </ModalWrapper>
  );
};
