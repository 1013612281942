import {PublicKey} from "@solana/web3.js";
import * as anchor from '@project-serum/anchor';
import {KEYCHAIN_DOMAIN, YardsaleProgramId} from "constants/config";

export const YARDSALE = 'yardsale';
export const LISTINGS_SPACE = 'listings';

export const findListingPda = (nftMint: PublicKey, keychainName: string): [PublicKey, number] => {

  return anchor.web3.PublicKey.findProgramAddressSync(
      [
        nftMint.toBuffer(),
        Buffer.from(anchor.utils.bytes.utf8.encode(LISTINGS_SPACE)),
        Buffer.from(anchor.utils.bytes.utf8.encode(keychainName)),
        Buffer.from(anchor.utils.bytes.utf8.encode(KEYCHAIN_DOMAIN)),
        Buffer.from(anchor.utils.bytes.utf8.encode(YARDSALE)),
      ],
      YardsaleProgramId,
  );
}
