import { apiClient } from "apis/server"
import {IListingThumb, IShop} from "../yardsale";
import {ESEARCH_TYPE} from "../../apis/server/types";
import { Filter, ListingQuery, Pagination } from "./types";

export function useHubActions() {

  const getHubByName = async (hubname: string) => {
    return await apiClient.getHub(hubname)
  }

  const getFeaturedHubs = async () => { // This is a placeholder for the bazaar page
    return await apiClient.getFeaturedHubs();
  }

  const searchShops = async (hubname: string, query: string): Promise<IShop[]> => {
    const res = await apiClient.searchHub(hubname, query, ESEARCH_TYPE.SHOPS);
    return res?.results ?? [];
  };

  const getFilters = async (hubname: string): Promise <Filter[]> => {
    return await apiClient.getHubFilters(hubname);
  }

  // const searchListings = async (hubname: string, query: string): Promise<IListingThumb[]> => {
  //   const res = await apiClient.searchHub(hubname, query, ESEARCH_TYPE.LISTINGS);
  //   return res?.results ?? [];
  // };

  const getListings = async (hubname: string, query: ListingQuery) => {
    const res = await apiClient.getHubListings(hubname, query);
    return res as Pagination <IListingThumb[]>;
  }

  return {
    getHubByName,
    getFeaturedHubs,
    searchShops,
    getFilters,
    getListings
  }
}

