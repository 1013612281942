import React from 'react';
import {ScreenContainer} from 'components/ui/container/Container';
import {ColoredButtonInverse} from 'components/ui/button/Button';
import Loader from 'components/Loader/Loader';
import {Tabs} from 'components/Tabs/Tabs';
import {COLORS, SPACING} from '../../constants/theme';
import {ENOTI_STATUS} from '../../store/toasts';
import {ENestedRoute} from 'constants/routes';
import useToasts from '../../hooks/useToasts';
import {useRecoilState} from 'recoil';
import {
  MESSAGE_STATUS,
  MESSAGE_TYPE,
  notificationsAtom,
  personalMessagesAtom,
  useNotificationActions,
} from 'store/notification';
import './Notifications.scss';
import ScreenHeader from 'components/Header/Screen/ScreenHeader';

interface INotificationsProps {}

export const Notifications: React.FC<INotificationsProps> = () => {
  const notificationActions = useNotificationActions();
  const {createToast} = useToasts();

  const [initialLoad, toggleInitialLoad] = React.useState(true);
  const [notificationState, setNotificationState] = useRecoilState(notificationsAtom);
  const [personalMessages, setPersonalMessages] = useRecoilState(personalMessagesAtom);
  const [loadingDismiss, toggleLoadingDismiss] = React.useState(false);
  // const [loadingReadAll, toggleReadAll] = React.useState(false);
  const [updateData, toggleData] = React.useState(false);
  const [tabView, setTabView] = React.useState(ENestedRoute.SYSTEM_NOTIS);

  React.useEffect(() => {
    (async function () {
      // will get added to notifications recoil store
      await notificationActions.getMessages();
      toggleInitialLoad(false);
    })();
  }, []);

  const handleClearNotifications = async () => {
    toggleLoadingDismiss(true);
    try {
      const maxIndex: number = notificationState.notifications[notificationState.notifications.length - 1].id;
      await notificationActions.dismissMessages(maxIndex);
      setNotificationState((prev) => ({...prev, notifications: []}));
      createToast('Cleared all messages', ENOTI_STATUS.DEFAULT);
    } catch (e) {
      createToast('Error clearing messages', ENOTI_STATUS.ERR);
    } finally {
      toggleLoadingDismiss(false);
    }
  };

  // const handleReadAllMessages = useCallback(async () => {
  //   toggleReadAll(true);
  //   try{
  //     await notificationActions.readMessages();
  //     setNotificationState(prev =>
  //       ({
  //         ...prev,
  //         notifications: prev.notifications.map(noti => ({ ...noti, status: MESSAGE_STATUS.READ }))
  //       })
  //     )
  //     createToast('Marked all messages as read', ENOTI_STATUS.DEFAULT);
  //   } catch(e) {
  //     createToast('Error reading messages', ENOTI_STATUS.ERR);
  //   } finally {
  //     toggleReadAll(false);
  //   }
  // }, [])

  const handleReadMessage = async (id: number) => {
    setNotificationState((prev) => ({
      ...prev,
      notifications: prev.notifications.map((noti) => {
        if (noti.id === id) return {...noti, status: MESSAGE_STATUS.READ};
        else return noti;
      }),
    }));
    toggleData(!updateData);
    try {
      await notificationActions.readMessage(id);
    } catch (e) {
      createToast('Error reading message', ENOTI_STATUS.ERR);
    }
  };

  if (initialLoad) {
    return <Loader />;
  }

  return (
    <ScreenContainer className="notifications-con" hasBackButton title="Notifications">
      <Tabs
        currentTab={tabView}
        setCurrentTab={setTabView}
        tabs={[
          {name: 'Notifications', route: ENestedRoute.SYSTEM_NOTIS},
          {name: 'Messages', route: ENestedRoute.PERSONAL_MSGS},
        ]}
        style={{padding: `0 ${SPACING.XL}px`}}
      />
      {(tabView === ENestedRoute.SYSTEM_NOTIS && notificationState.notifications.length > 0) ||
      (tabView === ENestedRoute.PERSONAL_MSGS && personalMessages.messages.length > 0) ? (
        <div className="content-con">
          {(tabView === ENestedRoute.SYSTEM_NOTIS ? notificationState.notifications : personalMessages.messages).map(
            (x, idx) => {
              console.log('itemmmm', x);
              return (
                <div key={idx} onClick={() => handleReadMessage(x.id)} className="notification touchable">
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    {x.type !== MESSAGE_TYPE.SYSTEM && <p className="normal">{`${x.from?.name}:`}&nbsp;</p>}
                    <p className="medium" style={{wordBreak: 'break-word'}}>
                      {x.message}
                    </p>
                    {x.type === MESSAGE_TYPE.LISTING && <p className="normal">&nbsp;{`on item: ${x.listing?.name}`}</p>}
                  </div>
                  <div>
                    <p className="medium gr">{new Date(x.createdAt).toLocaleString()}</p>
                    {x.title && <p className="medium">{x.title}</p>}
                  </div>
                </div>
              );
            }
          )}
          <div style={{display: 'flex', width: '100%', alignItems: 'center', flexDirection: 'row'}}>
            <ColoredButtonInverse
              onClick={handleClearNotifications}
              style={{flex: 1, marginTop: `${SPACING.XXXL}px`}}
              disabled={loadingDismiss}
            >
              {loadingDismiss ? 'Clearing...' : 'Clear all'}
            </ColoredButtonInverse>
          </div>
        </div>
      ) : (
        <div className="empty-con">
          <img src={require('../../assets/pngs/noNotification.png')} className="image" />
          <p
            className="subheader"
            style={{color: COLORS.TEXT_DIRTY_WHITE, fontWeight: '600', marginBottom: SPACING.XXL}}
          >
            {`No ${tabView === ENestedRoute.SYSTEM_NOTIS ? 'notifications' : 'messages'}`}
          </p>
          <p className="normal" style={{textAlign: 'center'}}>
            {`New ${tabView === ENestedRoute.SYSTEM_NOTIS ? 'notifications' : 'messages'} will appear here`}
          </p>
        </div>
      )}
    </ScreenContainer>
  );
};
